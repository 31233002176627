import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {initRoleValues} from '../models/RoleModel'

const initialState: any = {
  loading: false,
  role: initRoleValues,
  roles: [],
  permissions: '',
  pagination: {total: 0, pages: 0},
}

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setRoles: (state: {roles: any}, action: PayloadAction<any>) => {
      state.roles = action.payload.roles
    },
    setRole: (state: {role: any}, action: PayloadAction<any>) => {
      state.role = action.payload.role
    },
    setPermissions: (state: {permissions: any}, action: PayloadAction<any>) => {
      state.permissions = action.payload.permissions
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
