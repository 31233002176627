import React from 'react'
import {ProductTable} from './components/Products'
import {Route, Routes} from 'react-router-dom'

const ProductPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<ProductTable className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}

export {ProductPage}
