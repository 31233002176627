export interface ProductCategory {
  _id: string
  name: string
  assetType: string
  parent: string
}

export const ProductCategoryInitValues: ProductCategory = {
  _id: '',
  name: '',
  assetType: 'CURRENT',
  parent: '',
}

export interface ProductCategorySearchModel {
  number: string
  assetType: string
}
export const ProductCategorySearchModelInitValues: ProductCategorySearchModel = {
  number: '',
  assetType: '',
}

export interface ProductCategoryTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: ProductCategorySearchModel
}

export const ProductCategoryTableModelInitValues: ProductCategoryTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: ProductCategorySearchModelInitValues,
}
