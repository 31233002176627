import {deepCopy, formatNumber} from '../../../helper'
import {Budget, BudgetTableModel} from '../models/BudgetModel'
import * as BudgetCRUD from './BudgetCRUD'
import {BudgetSlice} from './BudgetSlice'

const {actions} = BudgetSlice

export const findAll: any = (params: BudgetTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return BudgetCRUD.findAll(params).then((response) => {
    dispatch(actions.setBudgets({budgets: response.data.data}))
    dispatch(actions.setPagination({pagination: response.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const findOne = (id: string) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await BudgetCRUD.findOne(id)
    dispatch(actions.setBudget({budget: res.data.data}))
    dispatch(actions.stopLoading())
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const fetchReport = (year: number) => (dispatch: any) => {
  return BudgetCRUD.report(year).then((response) => {
    dispatch(actions.setBudgetsReport({report: response.data.data}))
  })
}

export const create = (data: any, params: BudgetTableModel) => async (dispatch: any) => {
  try {
    const res = await BudgetCRUD.create(data)
    const successMsg = res.data.message
    BudgetCRUD.findAll(params).then((response) => {
      dispatch(actions.setBudgets({budgets: response.data.data}))
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const update =
  (_id: string, data: Budget, params: BudgetTableModel) => async (dispatch: any) => {
    try {
      const res = await BudgetCRUD.update(_id, data)
      const successMsg = res.data.message
      BudgetCRUD.findAll(params).then((response) => {
        dispatch(actions.setBudgets({budgets: response.data.data}))
      })
      return successMsg
    } catch (error: any) {
      const errors = error?.response?.data?.message
      let errorMsg
      if (typeof errors === 'string') {
        errorMsg = errors
      } else {
        errorMsg = 'Something went wrong'
      }
      throw errorMsg
    }
  }

export const approve = (id: any) => async (dispatch: any) => {
  try {
    const res = await BudgetCRUD.approve(id)
    const budget = res.data.data
    const successMsg = res.data.message
    dispatch(actions.setBudget({budget: res.data.data}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const reject = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await BudgetCRUD.reject(id, data)
    const budget = res.data.data
    const successMsg = res.data.message
    dispatch(actions.setBudget({budget: budget}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const remove = (_id: string, params: BudgetTableModel) => async (dispatch: any) => {
  try {
    const res = await BudgetCRUD.remove(_id)
    const successMsg = res.data.message
    BudgetCRUD.findAll(params).then((response) => {
      dispatch(actions.setBudgets({budgets: response.data.data}))
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const resetBudget = (data: any) => (dispatch: any) => {
  dispatch(actions.setBudget({budget: data}))
}

export const removeBudgetSection = (values: any, sidx: number) => (dispatch: any) => {
  let uBudget = deepCopy(values)
  const uSections = values.sections.filter((section: any, index: number) => index !== sidx)
  uBudget.sections = uSections
  dispatch(actions.setBudget({budget: uBudget}))
  dispatch(onChangeBudgetItem(uBudget))
}

export const addBudgetSection = (values: any, section: any) => (dispatch: any) => {
  let uBudget = deepCopy(values)
  uBudget.sections.push(section)
  dispatch(actions.setBudget({budget: uBudget}))
  dispatch(onChangeBudgetItem(uBudget))
}

export const addBudgetSectionItem = (values: any, sidx: number, item: any) => (dispatch: any) => {
  let uBudget = deepCopy(values)
  uBudget.sections[sidx].items.push(item)
  dispatch(actions.setBudget({budget: uBudget}))
  dispatch(onChangeBudgetItem(uBudget))
}

export const removeBudgetSectionItem =
  (values: any, sidx: number, ridx: number) => (dispatch: any) => {
    let uBudget = deepCopy(values)
    const uSections = values.sections.map((section: any, s: number) => {
      if (sidx == s) {
        let uSection = deepCopy(section)
        const items = section.items.filter((item: any, i: any) => i !== ridx)
        uSection.items = items
        return uSection
      } else {
        return section
      }
    })
    uBudget.sections = uSections
    dispatch(actions.setBudget({budget: uBudget}))
    dispatch(onChangeBudgetItem(uBudget))
  }

export const onChangeBudgetItem = (values: any) => (dispatch: any) => {
  let uBudget = deepCopy(values)

  const uSections = uBudget?.sections?.map((section: any, s: any) => {
    let uSection = deepCopy(section)

    // Section Items Total
    const items = section.items.map((item: any, i: any) => {
      let uItem = deepCopy(item)
      const amount = +formatNumber(item.quantity || 0) * +formatNumber(item.rate || 0)

      uItem.amount = +formatNumber(amount)
      return uItem
    })
    uSection.items = items

    // Section Total
    const sectionTotal = uSection?.items?.reduce((total: any, item: any) => {
      return total + item.amount
    }, 0)

    uSection.total = +formatNumber(sectionTotal)
    return uSection
  })

  uBudget.sections = uSections

  let budgetTotal = 0
  uBudget.sections.forEach((element: any) => {
    budgetTotal += +element.total
  })
  uBudget.total = +formatNumber(budgetTotal)
  dispatch(actions.setBudget({budget: uBudget}))
}
