import {UserTableModel} from '../models/UserModel'
import * as UserCRUD from './UserCRUD'
import {UserSlice} from './UserSlice'

const {actions} = UserSlice

export const fetchAll: any = (params: UserTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return UserCRUD.findAll(params).then((response) => {
    dispatch(actions.setUsers({users: response.data.data}))
    dispatch(actions.setPagination({pagination: response.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchAllRole = () => (dispatch: any) => {
  return UserCRUD.findAllRole().then((response) => {
    dispatch(actions.setRoles({roles: response.data.data}))
  })
}

export const fetchOne = (id: string) => (dispatch: any) => {
  return UserCRUD.findOne(id).then((response) => {
    dispatch(actions.setUser({user: response.data.data}))
  })
}

export const create = (data: any, params: UserTableModel) => async (dispatch: any) => {
  try {
    const res = await UserCRUD.create(data)
    const successMsg = res.data.message
    UserCRUD.findAll(params).then((response) => {
      dispatch(actions.setUsers({users: response.data.data}))
      dispatch(actions.setPagination({pagination: response.data.pagination}))
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const updateAccess =
  (_id: string, values: string, params: UserTableModel) => async (dispatch: any) => {
    try {
      const res = await UserCRUD.updateAccess(_id, values)
      const successMsg = res.data.message
      UserCRUD.findAll(params).then((response) => {
        dispatch(actions.setUsers({users: response.data.data}))
        dispatch(actions.setPagination({pagination: response.data.pagination}))
      })
      return successMsg
    } catch (error: any) {
      const errors = error?.response?.data?.message
      let errorMsg
      if (typeof errors === 'string') {
        errorMsg = errors
      } else {
        errorMsg = 'Something went wrong'
      }
      throw errorMsg
    }
  }
