export interface DepartmentModel {
  _id: string
  name: string
}

export const DepartmentInitValues: DepartmentModel = {
  _id: '',
  name: '',
}

export interface DepartmentSearchModel {
  name: string
}

export const DepartmentSearchModelInitValues: DepartmentSearchModel = {
  name: '',
}

export interface DepartmentTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: DepartmentSearchModel
}

export const DepartmentTableModelInitValues: DepartmentTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: DepartmentSearchModelInitValues,
}
