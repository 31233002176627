import {AssetTableModel} from '../models/AssetModel'
import * as AssetCrud from './AssetCRUD'
import {AssetSlice} from './AssetSlice'

const {actions} = AssetSlice
export const fetchAll: any = (params: AssetTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return AssetCrud.fetchAll(params).then((res) => {
    dispatch(actions.setAssets({assets: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchOne = (id: any) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await AssetCrud.fetchOne(id)
    dispatch(actions.setAsset({asset: res.data.data}))
    dispatch(actions.stopLoading())
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const create = (data: any) => async (dispatch: any) => {
  try {
    const res = await AssetCrud.create(data)
    const asset = res.data.asset
    const successMsg = res.data.message
    dispatch(actions.setAsset({asset: asset}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg = errors
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong!'
    }
    throw errorMsg
  }
}

export const update = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await AssetCrud.update(id, data)
    const asset = res.data.data
    const successMsg = res.data.message
    dispatch(actions.setAsset({asset: asset}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const remove = (_id: any, params: AssetTableModel) => async (dispatch: any) => {
  try {
    const res = await AssetCrud.remove(_id)
    const successMsg = res.data.message
    AssetCrud.fetchAll(params).then((response) => {
      dispatch(actions.setAssets({assets: response.data.data}))
      dispatch(actions.setPagination({pagination: res.data.pagination}))
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const resetAsset = (data: any) => (dispatch: any) => {
  dispatch(actions.setAsset({asset: data}))
}
