import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import * as SupplierBillAction from '../redux/SupplierBillAction'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDateTime, formatMoney, formatNumber} from '../../../helper'
import {PrintButton} from '../../../components/PrintButton'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const SupplierBillDetail: React.FC<Props> = ({className}) => {
  const supplierBill: any = useSelector<RootState>(
    ({supplierBills}) => supplierBills.supplierBill,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({supplierBills}) => supplierBills.loading,
    shallowEqual
  )

  const dispatch: any = useDispatch()

  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(SupplierBillAction.fetchOne(id))
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Supplier Bills List', url: '/supplier-bills'},
    {name: 'Supplier Bill Detail', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3'>Supplier Bill Detail</span>
          </h1>
          <PrintButton />
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            supplierBill && supplierBill?._id !== '' ? (
              <>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Supplier Bill No.</b>
                      </td>
                      <td>{supplierBill?.number}</td>
                      <td>
                        <b>Created Date</b>
                      </td>
                      <td>{formatDateTime(supplierBill?.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Goods Received Note No.</b>
                      </td>
                      <td>{supplierBill?.goodsReceivedNote?.number}</td>
                      <td>
                        <b>Purchase Order No.</b>
                      </td>
                      <td>
                        {supplierBill?.goodsReceivedNote?.materialInspection?.purchaseOrder?.number}
                      </td>
                    </tr>
                    {supplierBill && supplierBill?.purchaseOrderReturn && (
                      <tr>
                        <td>
                          <b>Purchase Order Return No.</b>
                        </td>
                        <td>{supplierBill && supplierBill?.purchaseOrderReturn?.number}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <b>Supplier Name</b>
                      </td>
                      <td>
                        {
                          supplierBill?.goodsReceivedNote?.materialInspection?.purchaseOrder
                            ?.supplier.name
                        }
                      </td>
                      <td>
                        <b>Delivery Challan No.</b>
                      </td>
                      <td>{supplierBill?.goodsReceivedNote?.deliveryChallanNumber}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Status</b>
                      </td>

                      <td>{supplierBill?.status}</td>
                    </tr>
                    {supplierBill?.approvedAt && supplierBill?.approvedBy && (
                      <tr>
                        <td>
                          <b>Approved At</b>
                        </td>
                        <td>{formatDateTime(supplierBill?.approvedAt)}</td>
                        <td>
                          <b>Approved By</b>
                        </td>
                        <td>{supplierBill?.approvedBy?.fullName}</td>
                      </tr>
                    )}
                    {supplierBill?.rejectedAt && supplierBill?.rejectedBy && (
                      <tr>
                        <td>
                          <b>Rejected At</b>
                        </td>
                        <td>{formatDateTime(supplierBill?.rejectedAt)}</td>
                        <td>
                          <b>Rejected By</b>
                        </td>
                        <td>{supplierBill?.rejectedBy?.fullName}</td>
                      </tr>
                    )}
                    {supplierBill?.rejectionReason && (
                      <tr>
                        <td>
                          <b>Reason to Reject</b>
                        </td>
                        <td colSpan={3}>{supplierBill?.rejectionReason}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                  <thead>
                    <tr>
                      <th style={{width: 40}}>
                        <b>#</b>
                      </th>
                      <th>
                        <b>Product</b>
                      </th>
                      <th>
                        <b>UOM</b>
                      </th>
                      <th style={{width: 80}}>
                        <b>Quantity</b>
                      </th>
                      <th>
                        <b>Rate</b>
                      </th>
                      <th>
                        <b>Price</b>
                      </th>
                      <th style={{width: 100}}>
                        <b>Tax</b>
                      </th>
                      <th>
                        <b>Price Inclusive Tax</b>
                      </th>
                      <th>
                        <b>Discount</b>
                      </th>
                      <th>
                        <b>Total</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {supplierBill?.items.map((materialItem: any, index: any) => (
                      <tr key={index}>
                        <td data-label='#'>{index + 1}</td>
                        <td data-label='Name'>{materialItem.name}</td>
                        <td data-label='UOM'>{materialItem.UOM}</td>
                        <td data-label='Quantity'>{formatNumber(materialItem.quantity)}</td>
                        <td data-label='Rate'>{formatMoney(materialItem.rate)}</td>
                        <td data-label='Price'>{formatMoney(materialItem.price)}</td>
                        <td data-label='Tax'>{formatMoney(materialItem.tax)}</td>
                        <td data-label='Price Inclusive Tax'>
                          {formatMoney(materialItem.priceInclusiveTax)}
                        </td>
                        <td data-label='Discount'>{formatMoney(materialItem.discount)}</td>
                        <td data-label='Total'>{formatMoney(materialItem.total)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={8}></td>
                      <td>
                        <b>Total Price</b>
                      </td>
                      <td data-label='Total Price'>{formatMoney(supplierBill?.totalPrice)}</td>
                    </tr>
                    <tr>
                      <td colSpan={8}></td>
                      <td>
                        <b>Total Tax</b>
                      </td>
                      <td data-label='Total Tax'>{formatMoney(supplierBill?.totalTax)}</td>
                    </tr>
                    <tr>
                      <td colSpan={8}></td>
                      <td>
                        <b>Total Discount</b>
                      </td>
                      <td data-label='Total Discount'>
                        {formatMoney(supplierBill?.totalDiscount)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={8}></td>
                      <td>
                        <b>Total Amount</b>
                      </td>
                      <td data-label='Total Amount'>{formatMoney(supplierBill?.totalAmount)}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
