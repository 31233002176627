export const NoItemFound: React.FC = () => {
  const noItemFoundSrc = '/media/images/no-item-found.png'
  return (
    <>
      <div className='d-flex align-items-center flex-column'>
        <img src={noItemFoundSrc} className='img-fluid p-3' alt='icon' />
        <p className='fs-1 text-700 text-center text-primary w-100 fs-800'>No items found</p>
      </div>
    </>
  )
}
