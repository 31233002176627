interface MaterialReturnItem {
  _id: string
  product: string
  name: string
  UOM: string
  qrCode: string
  expiryDate: string
  quantity: number | string
  remarks: string
}

export interface MaterialReturnModel {
  _id: string
  number: string
  materialIssuance: string
  providedToDepartment: string
  receivedByDepartment: string
  location: string
  createdAt: string
  approvedAt: string
  approvedBy: string
  rejectedBy: string
  rejectedAt: string
  rejectionReason: string
  items: [MaterialReturnItem]
}

export const MaterialReturnInitValues: MaterialReturnModel = {
  _id: '',
  number: '',
  materialIssuance: '',
  providedToDepartment: '',
  receivedByDepartment: '',
  location: '',
  createdAt: '',
  approvedAt: '',
  approvedBy: '',
  rejectedBy: '',
  rejectedAt: '',
  rejectionReason: '',
  items: [
    {
      _id: '',
      product: '',
      qrCode: '',
      expiryDate: '',
      name: '',
      UOM: '',
      quantity: '',
      remarks: '',
    },
  ],
}

export interface MaterialReturnSearchModel {
  number: string
  status: string
  createdAt: any
  approvedBy: string
  approvedAt: any
}
export const MaterialReturnSearchModelInitValues: MaterialReturnSearchModel = {
  number: '',
  status: '',
  createdAt: [],
  approvedBy: '',
  approvedAt: [],
}

export interface MaterialReturnTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: MaterialReturnSearchModel
}

export const MaterialReturnTableModelInitValues: MaterialReturnTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: MaterialReturnSearchModelInitValues,
}
