import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import * as actions from '../redux/PurchaseOrderAction'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import * as SupplierAction from '../../suppliers/redux/SupplierAction'
import * as ProductAction from '../../products/redux/ProductAction'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {formatNumber} from '../../../helper'
import * as SettingAction from '../../settings/redux/SettingAction'
import {
  PurchaseOrderInitValues,
  PurchaseOrderTableModelInitValues,
} from '../models/PurchaseOrderModel'
import {
  ProductListingModelInitValues,
  ProductTableModelInitValues,
} from '../../products/models/ProductModel'
import {SupplierTableModelInitValues} from '../../suppliers/models/SupplierModel'

type Props = {
  className: string
}

export const PurchaseOrderEdit: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  const purchase: any = useSelector<RootState>(
    (state) => state.purchaseOrders.purchaseOrder,
    shallowEqual
  )
  const items = purchase?.items?.map((item: any, index: number) => {
    const itemsData = {
      product: item?.product,
      name: item?.name,
      UOM: item?.UOM,
      quantity: item?.quantity,
      rate: item.rate,
      price: formatNumber(item?.price),
      tax: item.tax,
      priceInclusiveTax: formatNumber(item?.priceInclusiveTax),
      discount: item?.discount,
      total: formatNumber(item?.total),
    }
    return itemsData
  })

  const suppliers: any = useSelector<RootState>(({suppliers}) => suppliers.suppliers, shallowEqual)

  const purchaseForOptions: any = useSelector<RootState>(
    ({setting}) => setting.data['purchaseFor']?.options,
    shallowEqual
  )
  const settingInitValues = {name: 'purchaseFor'}

  const products: any = useSelector<RootState>((state) => state.products.products, shallowEqual)
  let productsOptions = products.map((product: any) => {
    return {
      value: product._id,
      label: product.name,
      UOM: product.UOM,
    }
  })
  const purchaseOrderUpdatedValues = {
    ...purchase,
    suppliers,
    items,
    totalPrice: formatNumber(purchase?.totalPrice),
    totalTax: formatNumber(purchase?.totalTax),
    totalDiscount: formatNumber(purchase?.totalDiscount),
    totalAmount: formatNumber(purchase?.totalAmount),
  }

  const animatedComponents = makeAnimated()

  useEffect(() => {
    dispatch(ProductAction.findAll(ProductListingModelInitValues))
    dispatch(SupplierAction.fetchApproved())
    dispatch(SettingAction.get(settingInitValues))
    if (id) {
      dispatch(actions.fetchOne(id))
    }
    return () => {
      dispatch(actions.resetPurchaseOrder(PurchaseOrderInitValues))
    }
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Purchase Orders List', url: '/purchase-orders'},
    {name: 'Edit Purchase Order', url: ''},
  ]

  return (
    <div className={`card card-custom print ${className}`}>
      <a href='/' className='print-view-show'>
        <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
      </a>
      <Breadcrumbs routes={routes} />
      <div className='card-header border-0 pt-5'>
        <h1 className='card-title'>
          <span className='card-label fw-bolder fs-3 mb-1'>Edit Purchase Order</span>
        </h1>
      </div>
      <div className='card-body py-10'>
        <div className='mt-10'></div>
        <Formik
          enableReinitialize
          initialValues={purchaseOrderUpdatedValues || purchase}
          validationSchema={Yup.object({
            purchaseFor: Yup.string()
              .trim('Purchase For must have non-empty spaces')
              .strict(true)
              .required('Purchase For is required'),

            supplier: Yup.object().shape({
              name: Yup.string().required('Supplier is required'),
            }),
            items: Yup.array().of(
              Yup.object().shape({
                product: Yup.string().required('Product is required'),

                quantity: Yup.number()
                  .required('Quantity is required')
                  .typeError('Quantity is invalid')
                  .nullable()
                  .positive('Quantity must be more than 0'),
                rate: Yup.number()
                  .required('Rate is required')
                  .typeError('Rate is invalid')
                  .nullable()
                  .positive('Rate must be more than 0'),

                tax: Yup.number()
                  .typeError('Tax is invalid')
                  .nullable()
                  .min(0, 'Tax must be 0 or more than 0'),

                priceInclusiveTax: Yup.number(),

                discount: Yup.number()
                  .typeError('Discount/Return is invalid')
                  .nullable()
                  .min(0, 'Discount/Return must be 0 or more than 0')
                  .test(
                    'discount',
                    'Discount/Return must be smaller then price inclusive tax',
                    function (value: any) {
                      return value <= this.parent.priceInclusiveTax || value == null
                    }
                  ),
              })
            ),
          })}
          onSubmit={(values: any) => {
            setLoading(true)
            dispatch(actions.update(values._id, values, PurchaseOrderTableModelInitValues))
              .then((response: string) => {
                toast.success(response)
                setLoading(false)
                navigate('/purchase-orders')
              })
              .catch((errorMsg: any) => {
                toast.error(errorMsg)
                setLoading(false)
              })
          }}
        >
          {({values, setFieldValue}) => (
            <Form>
              <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                <div className='form-group col'>
                  <label htmlFor='Purchase For' className='fw-bold required'>
                    Purchase For
                  </label>
                  <Field
                    as='select'
                    className='form-select form-select-md form-select '
                    data-control='select2'
                    name='purchaseFor'
                  >
                    <option>Select</option>
                    {purchaseForOptions &&
                      purchaseForOptions.map((option: string, index: number) => (
                        <option value={option} key={index}>
                          {option}
                        </option>
                      ))}
                  </Field>
                  <div className='text-danger'>
                    <ErrorMessage name='purchaseFor' />
                  </div>
                </div>
                <div className='form-group col'>
                  <label htmlFor='supplier' className='fw-bold required'>
                    Supplier
                  </label>
                  <Field
                    as='select'
                    className='form-select form-select-md'
                    data-control='select2'
                    name='supplier.id'
                    onClick={(e: any) => dispatch(actions.onChangeSupplier(e, values, suppliers))}
                  >
                    <option>Select</option>
                    {suppliers.map((supplier: any, index: any) => (
                      <option value={supplier._id} key={index}>
                        {supplier.name}
                      </option>
                    ))}
                  </Field>
                  <div className='text-danger'>
                    <ErrorMessage name='supplier.name' />
                  </div>
                </div>
                <div className='form-group col'>
                  <label htmlFor='NTN' className='fw-bold'>
                    NTN No.
                  </label>
                  <Field
                    name='supplier.NTN'
                    placeholder='NTN No.'
                    className='form-control form-control-lg'
                    type='text'
                    disabled={true}
                  />
                </div>
                <div className='form-group col'>
                  <label htmlFor='address' className='fw-bold'>
                    Address
                  </label>
                  <Field
                    name='supplier.city'
                    placeholder='Address'
                    className='form-control form-control-lg'
                    disabled={true}
                  />
                </div>
                <div className='form-group col'>
                  <label htmlFor='strnNo' className='fw-bold'>
                    STRN No.
                  </label>
                  <Field
                    name='supplier.STRN'
                    placeholder='STRN No.'
                    className='form-control form-control-lg'
                    type='text'
                    disabled={true}
                  />
                </div>
                <div className='form-group col'>
                  <label htmlFor='supplier.phone' className='fw-bold'>
                    Phone No.
                  </label>
                  <Field
                    name='supplier.phone'
                    placeholder='Phone No.'
                    className='form-control form-control-lg'
                    disabled={true}
                  />
                </div>
                <div className='form-group col'>
                  <label htmlFor='supplier.bank.accountTitle' className='fw-bold'>
                    Account Title
                  </label>
                  <Field
                    name='supplier.bank.accountTitle'
                    placeholder='Account Title'
                    className='form-control form-control-lg'
                    disabled={true}
                  />
                </div>
                <div className='form-group col'>
                  <label htmlFor='supplier.bank.name' className='fw-bold'>
                    Bank Name
                  </label>
                  <Field
                    name='supplier.bank.name'
                    placeholder='Bank Name'
                    className='form-control form-control-lg'
                    disabled={true}
                  />
                </div>
              </div>

              <FieldArray
                name='items'
                render={() => (
                  <div className='mt-15'>
                    <table className='table tbl-xl-responsivness'>
                      <thead>
                        <tr>
                          <th>
                            <label className='form-label required'>Product</label>
                          </th>
                          <th>
                            <label className='form-label'>UOM</label>
                          </th>
                          <th>
                            <label className='form-label required'>Quantity</label>
                          </th>
                          <th>
                            <label className='form-label required'>Rate</label>
                          </th>
                          <th>
                            <label className='form-label'>Price</label>
                          </th>
                          <th>
                            <label className='form-label'>Tax</label>
                          </th>
                          <th>
                            <label className='form-label'>Price Inclusive Tax</label>
                          </th>
                          <th>
                            <label className='form-label'>Discount/Return</label>
                          </th>
                          <th>
                            <label className='form-label'>Total</label>
                          </th>
                          <th>
                            <label className='form-label'></label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.items?.length > 0 &&
                          values.items?.map((materialItem: any, index: any) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td data-label='Product'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field name={`items.${index}.name`} type='hidden' />
                                    <Select
                                      options={productsOptions}
                                      placeholder='Select'
                                      name={`items.${index}.product`}
                                      className='multi-select-container'
                                      classNamePrefix='multi-select'
                                      value={productsOptions.filter(function (option: any) {
                                        return option.value == values.items[index].product
                                      })}
                                      onChange={(e: any) => {
                                        if (e?.value) {
                                          setFieldValue(`items.${index}.product`, e.value)
                                          setFieldValue(`items.${index}.name`, e.label)
                                          setFieldValue(`items.${index}.UOM`, e.UOM)
                                        } else {
                                          setFieldValue(`items.${index}.product`, '')
                                          setFieldValue(`items.${index}.name`, '')
                                          setFieldValue(`items.${index}.UOM`, '')
                                        }
                                      }}
                                      closeMenuOnSelect={true}
                                      isClearable={true}
                                      components={animatedComponents}
                                    />
                                    <div className='text-danger'>
                                      <ErrorMessage name={`items.${index}.product`} />
                                    </div>
                                  </div>
                                </td>

                                <td data-label='UOM'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name={`items.${index}.UOM`}
                                      placeholder='UOM'
                                      disabled={true}
                                      className='form-control form-control-md'
                                    />
                                  </div>
                                </td>

                                <td data-label='Quantity'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name={`items.${index}.quantity`}
                                      placeholder='Quantity'
                                      className='form-control form-control-md'
                                      onBlur={(e: any) => {
                                        const value: number = e.target.value
                                        const roundedValue = formatNumber(value)
                                        setFieldValue(`items.${index}.quantity`, roundedValue)
                                      }}
                                      onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                    />
                                    <div className='text-danger'>
                                      <ErrorMessage name={`items.${index}.quantity`} />
                                    </div>
                                  </div>
                                </td>

                                <td data-label='Rate'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name={`items.${index}.rate`}
                                      placeholder='Rate'
                                      className='form-control form-control-md'
                                      onBlur={(e: any) => {
                                        const value: number = e.target.value
                                        const roundedValue = formatNumber(value)
                                        setFieldValue(`items.${index}.rate`, roundedValue)
                                      }}
                                      onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                    />
                                    <div className='text-danger'>
                                      <ErrorMessage name={`items.${index}.rate`} />
                                    </div>
                                  </div>
                                </td>

                                <td data-label='Price'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name={`items.${index}.price`}
                                      placeholder='Price'
                                      className='form-control form-control-md'
                                      onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                      disabled={true}
                                    />
                                  </div>
                                </td>

                                <td data-label='Tax'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name={`items.${index}.tax`}
                                      placeholder='Tax'
                                      className='form-control form-control-md'
                                      onBlur={(e: any) => {
                                        const value: number = e.target.value
                                        const roundedValue = formatNumber(value)
                                        setFieldValue(`items.${index}.tax`, roundedValue)
                                      }}
                                      onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                    />
                                    <div className='text-danger'>
                                      <ErrorMessage name={`items.${index}.tax`} />
                                    </div>
                                  </div>
                                </td>

                                <td data-label='Price Inclusive Tax'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name={`items.${index}.priceInclusiveTax`}
                                      placeholder='Price Inclusive Tax'
                                      className='form-control form-control-md'
                                      onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                      disabled={true}
                                    />
                                  </div>
                                </td>

                                <td data-label='Discount/Return'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name={`items.${index}.discount`}
                                      placeholder='Discount/Return'
                                      className='form-control form-control-md'
                                      onBlur={(e: any) => {
                                        const value: number = e.target.value
                                        const roundedValue = formatNumber(value)
                                        setFieldValue(`items.${index}.discount`, roundedValue)
                                      }}
                                      onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                    />
                                    <div className='text-danger'>
                                      <ErrorMessage name={`items.${index}.discount`} />
                                    </div>
                                  </div>
                                </td>
                                <td data-label='Total'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name={`items.${index}.total`}
                                      placeholder='Total'
                                      className='form-control form-control-md'
                                      onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex flex-row w-100'>
                                    <button
                                      type='button'
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                      data-bs-toggle='tooltip'
                                      data-bs-placement='top'
                                      title='Add New Item'
                                      onClick={() => {
                                        const item = {
                                          product: '',
                                          name: '',
                                          UOM: '',
                                          quantity: '',
                                          rate: '',
                                          price: '',
                                          tax: '',
                                          priceInclusiveTax: '',
                                          discount: '',
                                          total: '',
                                        }
                                        dispatch(actions.addPurchaseOrderItem(values, item))
                                      }}
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/arrows/arr013.svg'
                                        className='svg-icon  svg-icon-3'
                                      />
                                    </button>

                                    <button
                                      type='button'
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                      onClick={() => {
                                        dispatch(actions.removePurchaseOrderItem(values, index))
                                      }}
                                      data-bs-toggle='tooltip'
                                      data-bs-placement='top'
                                      title='Remove'
                                      disabled={values.items.length > 1 ? false : true}
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen027.svg'
                                        className='svg-icon  svg-icon-3'
                                      />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}

                        <tr>
                          <td colSpan={8} className='ps-0 dl' data-label=''>
                            <div className='d-flex flex-column w-100'>
                              <label className='col-form-label d-flex justify-content-end align-items-end'>
                                Total Price
                              </label>
                            </div>
                          </td>
                          <td data-label='Total Price'>
                            <div className='d-flex flex-column w-100'>
                              <Field
                                name='totalPrice'
                                placeholder='Total Price'
                                className='form-control form-control-md'
                                disabled={true}
                              />
                            </div>
                          </td>
                          <td></td>
                        </tr>

                        <tr>
                          <td colSpan={8} className='ps-0 dl' data-label=''>
                            <div className='d-flex flex-column w-100'>
                              <label className='col-form-label d-flex justify-content-end align-items-end'>
                                Total Tax
                              </label>
                            </div>
                          </td>
                          <td data-label='Total Tax'>
                            <div className='d-flex flex-column w-100'>
                              <Field
                                name='totalTax'
                                placeholder='Total Tax'
                                className='form-control form-control-md'
                                disabled={true}
                              />
                            </div>
                          </td>
                          <td></td>
                        </tr>

                        <tr>
                          <td colSpan={8} className='ps-0 dl' data-label=''>
                            <div className='d-flex flex-column w-100'>
                              <label className='col-form-label d-flex justify-content-end align-items-end'>
                                Total Discount
                              </label>
                            </div>
                          </td>
                          <td data-label='Total Discount'>
                            <div className='d-flex flex-column w-100'>
                              <Field
                                name='totalDiscount'
                                placeholder='Total Discount'
                                className='form-control form-control-md'
                                disabled={true}
                              />
                              <div className='text-danger'>
                                <ErrorMessage name='totalDiscount' />
                              </div>
                            </div>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan={8} className='ps-0 dl' data-label=''>
                            <div className='d-flex flex-column w-100'>
                              <label className='col-form-label d-flex justify-content-end align-items-end'>
                                Total Amount
                              </label>
                            </div>
                          </td>
                          <td data-label='Total Amount'>
                            <div className='d-flex flex-column w-100'>
                              <Field
                                name='totalAmount'
                                placeholder='Total Amount'
                                className='form-control form-control-md'
                                disabled={true}
                              />
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              />

              <div className='d-flex justify-content-end mt-10'>
                <Link
                  to='/purchase-orders'
                  className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Cancel'
                >
                  <span className='indicator-label'>Cancel</span>
                </Link>

                <button
                  type='submit'
                  className='btn btn-lg  btn-primary me-3'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Submit'
                  id='liveToastBtn'
                  disabled={loading}
                >
                  <span className='indicator-label'>{loading ? 'Submitting...' : 'Submit'}</span>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
