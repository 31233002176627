import {SupplierTableModel} from '../models/SupplierModel'
import * as supplierCrud from './SupplierCRUD'
import {supplierSlice} from './SupplierSlice'

const {actions} = supplierSlice
export const fetchAll: any = (params: SupplierTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return supplierCrud.fetchAll(params).then((res) => {
    dispatch(actions.setSuppliers({suppliers: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchOne = (id: any) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await supplierCrud.fetchOne(id)
    dispatch(actions.setSupplier({supplier: res.data.data}))
    dispatch(actions.stopLoading())
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const fetchApproved = () => (dispatch: any) => {
  return supplierCrud.findApproved().then((res) => {
    dispatch(actions.setSuppliers({suppliers: res.data.data}))
  })
}

export const create = (data: any) => async (dispatch: any) => {
  try {
    const res = await supplierCrud.create(data)
    const supplier = res.data.supplier
    const successMsg = res.data.message
    dispatch(actions.setSupplier({supplier: supplier}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg = errors
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong!'
    }
    throw errorMsg
  }
}

export const update = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await supplierCrud.update(id, data)
    const supplier = res.data.data
    const successMsg = res.data.message
    dispatch(actions.setSupplier({supplier: supplier}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const approve = (id: any) => async (dispatch: any) => {
  try {
    const res = await supplierCrud.approve(id)

    const supplier = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setSupplier({supplier: supplier}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const reject = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await supplierCrud.reject(id, data)

    const supplier = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setSupplier({supplier: supplier}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}
