import axios from 'axios'
import {LocationTableModel} from '../models/LocationModel'

const API_URL = process.env.REACT_APP_API_URL
const LOCATION_URL = `${API_URL}/locations`

export function findAll(params: LocationTableModel) {
  return axios.get(LOCATION_URL, {params})
}

export function findOne(id: string) {
  return axios.get(`${LOCATION_URL}/${id}`)
}

export function create(name: string) {
  return axios.post(LOCATION_URL, {name})
}
export function update(_id: string, name: string) {
  return axios.patch(`${LOCATION_URL}/${_id}`, {name})
}

export function remove(id: string) {
  return axios.delete(`${LOCATION_URL}/${id}`)
}
