/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import '../../../../_metronic/assets/sass/components/cards.scss'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import * as actions from '../redux/RoleAction'
import {RootState} from '../../../../setup'
import {initRoleValues, Role, RoleTableModelInitValues} from '../models/RoleModel'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {toast} from 'react-toastify'
import {useNavigate, useParams} from 'react-router-dom'

type Props = {
  className: string
}
const inits: Role = initRoleValues

export const RoleEdit: FC<any> = ({className, onHide}) => {
  const [loading, setLoading] = useState(false)
  const dispatch: any = useDispatch()
  const {id} = useParams()

  const {role, permissions}: any = useSelector<RootState>(
    (state) => ({
      role: state.roles.role,
      permissions: state.roles.permissions,
    }),
    shallowEqual
  ) as any

  let navigate = useNavigate()

  const rolesValidationSchema = {
    name: Yup.string()
      .required('Role name is required')
      .label('Role name')
      .min(1, 'Role name  must contain more than 2 digits'),
    roleType: Yup.string().required('Role type is required'),
  }
  const createAppSchema = Yup.object().shape(rolesValidationSchema)

  useEffect(() => {
    if (id) {
      dispatch(actions.findOne({id}))
    }
    dispatch(actions.fetchPermissions())
  }, [])

  const initialValuesOnEdit = {
    name: role?.name,
    subject: role?.permissions,
    roleType: role.roleType,
  }

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Roles List', url: '/roles'},
    {
      name: id ? 'Edit Role' : 'Create Role',
      url: `/roles/${id ? `${id}/edit` : 'create'}`,
    },
  ]
  return (
    <div className={`card card-custom ${className}`}>
      <Breadcrumbs routes={routes} />
      <div className='card-header border-0 pt-5'>
        <h1 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{id ? 'Edit Role' : 'Create Role'}</span>
        </h1>
      </div>

      <div className='card-body'>
        <Formik
          validationSchema={createAppSchema}
          initialValues={id ? {...inits, ...initialValuesOnEdit} : inits}
          enableReinitialize
          onSubmit={(values: any) => {
            setLoading(true)
            if (id) {
              values.id = id

              dispatch(actions.update(values, RoleTableModelInitValues))
                .then((response: any) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/roles')
                  onHide()
                })
                .catch((error: any) => {
                  toast.error(error)
                })
            } else {
              dispatch(actions.create(values, RoleTableModelInitValues))
                .then((response: any) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/roles')
                  onHide()
                })
                .catch((error: any) => {
                  toast.error(error)
                  setLoading(false)
                })
            }
          }}
        >
          {({setFieldValue, values, errors}) => (
            <Form className='form' noValidate id='kt_modal_create_app_form'>
              <div className='current' data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='row'>
                    <div className='row fv-row'>
                      <div className='col-6'>
                        <label className='fs-6 fw-bold mb-2'>
                          <span className='required'>Role Name</span>
                        </label>
                        <Field
                          type='text'
                          name='name'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Role Name'
                          style={{minHeight: '41px', maxHeight: '41px'}}
                        />

                        <div className='text-danger'>
                          <ErrorMessage name='name' />
                        </div>
                      </div>

                      <div className='col-6'>
                        <label className='fs-6 fw-bold mb-2'>
                          <span className='required'>Role Type</span>
                        </label>
                        <Field
                          as='select'
                          name='roleType'
                          className='form-control form-control-lg form-control-solid'
                          style={{minHeight: '41px', maxHeight: '41px'}}
                        >
                          <option value=''>Select a role</option>
                          <option value='administrator'>Administrator</option>
                          <option value='manager'>Manager</option>
                          <option value='user'>User</option>
                        </Field>
                        <div className='text-danger'>
                          <ErrorMessage name='roleType' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='col-md-12 mt-10'>
                  <h3 className='pb-5' title='Permissions'>
                    Permissions
                  </h3>
                </div>
                {permissions?.length > 0 &&
                  permissions?.map((permission: any, index: number) => {
                    return (
                      <div className='row mb-10 pb-6 border-bottom' key={index}>
                        <h5 className='fs-6 text-dark' title={permission.label}>
                          {permission.label}
                        </h5>
                        <div className='row'>
                          {permission?.action?.map((action: string, key: number) => {
                            return (
                              <div className='col-md-2 p-3' key={key}>
                                <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                                  <label className='text-capitalize' title={action}>
                                    {action}
                                  </label>
                                  <Field
                                    className='form-check-input mx-3'
                                    type='checkbox'
                                    name={'subject[' + permission.subject + ']'}
                                    value={action}
                                  />
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
              </div>
              <div className='d-flex flex-stack pt-5'>
                <div> </div>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                    onClick={onHide}
                    title='Cancel'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary fw-bolder py-4 pe-8 me-3'
                    title={id ? 'Update' : 'Create'}
                    disabled={loading}
                  >
                    <span className='indicator-label'>Save</span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
