/* eslint-disable jsx-a11y/anchor-is-valid */
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  ProductCategory,
  ProductCategoryInitValues,
  ProductCategoryTableModelInitValues,
} from '../models/ProductCategoryModel'
import {assetType} from '../ProductCategoriesUIHelper'
import * as actions from '../redux/ProductCategoryAction'
interface Props {
  show: boolean
  handleClose: () => void
}

export const ProductCategoryAdd: FC<Props> = ({show, handleClose}) => {
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const dispatch: any = useDispatch()

  const createProductCategorySchema = Yup.object().shape({
    name: Yup.string()
      .trim('Name must have non-empty spaces')
      .strict(true)
      .required('Name is required')
      .label('Name'),
  })

  const animatedComponents = makeAnimated()

  const parentCategories: any = useSelector<RootState>(
    (state) => state.productCategories.parentCategories,
    shallowEqual
  ) as ProductCategory

  useEffect(() => {
    if (show) {
      dispatch(actions.findAllParentCategories())
    }
  }, [show])

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Add Product Category</h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body flex-row-fluid py-lg-10 px-lg-10'>
          <Formik
            validationSchema={createProductCategorySchema}
            enableReinitialize
            initialValues={ProductCategoryInitValues}
            onSubmit={(values: any) => {
              setLoading(true)
              values.parent = values?.parent?.value ? values?.parent?.value : values.parent || null
              dispatch(
                actions.create(
                  values.name,
                  values.assetType,
                  values.parent,
                  ProductCategoryTableModelInitValues
                )
              )
                .then((response: any) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/product-categories')
                  handleClose()
                })
                .catch((error: any) => {
                  toast.error(error)
                  setLoading(false)
                })
            }}
          >
            {({setFieldValue, values}) => (
              <Form className='form' noValidate id='kt_modal_create_app_form'>
                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2 required'>
                    Name
                  </label>
                  <Field
                    type='text'
                    className='form-control form-control-lg'
                    name='name'
                    placeholder='Add name'
                    maxLength={255}
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='name' />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6 fv-row mb-5'>
                    <label className='fs-6 fw-bold'>Asset Type</label>
                    <div className='col-12'>
                      <Field
                        as='select'
                        name='assetType'
                        className='form-select'
                        style={{minHeight: '41px', maxHeight: '41px'}}
                        disabled={values.parent ? true : false}
                      >
                        {assetType.map((value, i) => (
                          <option key={i} value={value}>
                            {value}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>

                  <div className='col-md-6 fv-row mb-5'>
                    <label className='fs-6 fw-bold'>Parent Category</label>
                    <div className='col-12 multisel'>
                      <Select
                        options={parentCategories.map((category: any) => {
                          return {
                            value: category._id,
                            label: category.name,
                            assetType: category.assetType,
                          }
                        })}
                        placeholder='Select'
                        name='parent'
                        defaultValue={values?.parent}
                        className='multi-select-container'
                        classNamePrefix='multi-select'
                        onChange={(e: any) => {
                          if (e?.value) {
                            setFieldValue('parent', e.value)
                            setFieldValue('assetType', e.assetType)
                          } else {
                            setFieldValue('parent', '')
                          }
                        }}
                        closeMenuOnSelect={true}
                        isClearable={true}
                        components={animatedComponents}
                      />

                      <div className='text-danger'>
                        <ErrorMessage name='assignee' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-stack pt-5'>
                  <div className='me-2'></div>
                  <div>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary me-3'
                      disabled={loading}
                    >
                      <span className='indicator-label'>
                        {loading ? 'Submitting...' : 'Submit'}
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
