import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {MaterialIssuanceAdd} from './components/MaterialIssuanceAdd'
import {MaterialIssuances} from './components/MaterialIssuances'
import {MaterialIssuanceDetail} from './components/MaterialIssuanceDetail'
import {MaterialIssuanceApprove} from './components/MaterialIssuanceApprove'

export const MaterialIssuancePage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<MaterialIssuances className='mb-5 mb-xl-8' />} />
        <Route path='/add' element={<MaterialIssuanceAdd className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<MaterialIssuanceDetail className='mb-5 mb-xl-8' />} />
        <Route path='/:id/approve' element={<MaterialIssuanceApprove className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
