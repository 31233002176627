import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {StepNav1} from '../../../components/step-nav1'
import * as MaterialRequisitionsAction from '../../material-requisition/redux/MaterialRequisitionAction'
import * as ProductAction from '../../products/redux/ProductAction'
import {toast} from 'react-toastify'
import {create} from '../redux/MaterialIssuanceAction'
import {MaterialIssuanceInitValues} from '../models/MaterialIssuanceModel'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {ProductTableModelInitValues} from '../../products/models/ProductModel'
import * as actions from '../redux/MaterialIssuanceAction'
import {InventoryReportListingModelInitValues} from '../../inventory/models/InventoryReportModel'
import {fetchReport} from '../../inventory/redux/InventoryAction'
import {Loader} from '../../../components/Loader'
import {formatDate, formatNumber} from '../../../helper'

type Props = {
  className: string
}

export const MaterialIssuanceAdd: React.FC<Props> = ({className}) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const dispatch: any = useDispatch()
  let navigate = useNavigate()

  const materialIssuance: any = useSelector<RootState>(
    (state) => state.materialIssuance.materialIssuance,
    shallowEqual
  )

  const updatedMaterialIssuance = {
    ...materialIssuance,
  }

  const materialRequisitions: any = useSelector<RootState>(
    ({materialRequisition}) => materialRequisition.materialRequisitions,
    shallowEqual
  )

  const materialRequisition: any = useSelector<RootState>(
    ({materialRequisition}) => materialRequisition.materialRequisition,
    shallowEqual
  )

  const animatedComponents = makeAnimated()
  const inventories: any = useSelector<RootState>(
    ({inventories}) => inventories?.inventories,
    shallowEqual
  )

  useEffect(() => {
    dispatch(ProductAction.findAll(ProductTableModelInitValues))
    dispatch(MaterialRequisitionsAction.fetchApproved())
    dispatch(actions.resetMaterialIssuance(MaterialIssuanceInitValues))
    dispatch(fetchReport(InventoryReportListingModelInitValues))
  }, [])

  function onChangeMaterialRequisition(e: any) {
    const materialRequisition = materialRequisitions?.find((r: any) => r._id === e.value)
    let deepCopyMaterialRequisition = JSON.parse(JSON.stringify(materialRequisition))

    deepCopyMaterialRequisition.items = []
    const newData =
      materialRequisition &&
      materialRequisition.items.filter((item: any) => {
        let uItem = JSON.parse(JSON.stringify(item))
        if (uItem.status == 'APPROVED') {
          return uItem
        }
      })

    deepCopyMaterialRequisition.items = newData
    dispatch(MaterialRequisitionsAction.setMaterialRequisition(deepCopyMaterialRequisition))
    let uMaterialIssuance = JSON.parse(JSON.stringify(materialIssuance))

    if (materialRequisition) {
      uMaterialIssuance.materialRequisition = materialRequisition?._id
      uMaterialIssuance.providedToEmployee = materialRequisition?.requestedBy?.id
      uMaterialIssuance.providedToDepartment = materialRequisition?.department._id
      uMaterialIssuance.location = materialRequisition?.location?.id
      uMaterialIssuance.providedToEmployeeName = materialRequisition?.requestedBy?.fullName
      uMaterialIssuance.providedToDepartmentName = materialRequisition?.department?.name
      uMaterialIssuance.locationName = materialRequisition?.location?.name
      dispatch(actions.resetMaterialIssuance(uMaterialIssuance))
    }
  }

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Issuances List', url: '/material-issuance'},
    {name: 'Material Issuance Form', url: ''},
  ]

  const loading: any = useSelector<RootState>(
    ({materialIssuance}) => materialIssuance.loading,
    shallowEqual
  )

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='border-0 pt-5 p-5'>
          <StepNav1 itemNumber={2} />
        </div>
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Material Issuance Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          <Formik
            enableReinitialize={true}
            initialValues={updatedMaterialIssuance}
            validationSchema={Yup.object({
              materialRequisition: Yup.string().required('Material requisition No is required'),
              items: Yup.array().of(
                Yup.object().shape({
                  qrCode: Yup.string()
                    .required('QR Code is required')
                    .max(22, 'QR Code must be 22 digits')
                    .test(
                      'qrCode',
                      'Please ensure the QR code is scanned correctly',
                      function (value: any, el: any) {
                        const index = parseInt(el.path.match(/\d+/)[0], 10)
                        console.log(updatedMaterialIssuance.items[index].qrCode, value)
                        return updatedMaterialIssuance?.items[index]?.qrCode === value
                      }
                    ),
                  name: Yup.string().required('Product is required'),
                  UOM: Yup.string().required('UOM is required'),
                  quantity: Yup.number()
                    .required('Quantity is required')
                    .typeError('Quantity is invalid')
                    .nullable()
                    .positive('Quantity must be more than 0')
                    .test(
                      'quantity',
                      'Quantity must be smaller than available in stock',
                      function (value: any, itemData: any) {
                        const data = inventories.find(
                          (inventory: any, index: any) => itemData.parent.qrCode == inventory.qrCode
                        )
                        return value <= +formatNumber(data?.quantityInStock)
                      }
                    ),
                  assetNumber: Yup.string().nullable(),
                  remarks: Yup.string()
                    .trim('Remarks must have non-empty spaces')
                    .strict(true)
                    .required('Remarks is required'),
                })
              ),
            })}
            onSubmit={(values: any) => {
              setLoadingButton(true)
              const updatedItems: any = []
              {
                values?.items?.map((item: any) => {
                  const existingItem = updatedItems.find(
                    (updatedItem: any) => item.product === updatedItem.product
                  )

                  if (existingItem) {
                    existingItem.quantity += parseFloat(item.quantity)
                  } else {
                    updatedItems.push({
                      product: item.product,
                      quantity: parseFloat(item.quantity),
                    })
                  }
                })
              }

              let checkError = true
              {
                updatedItems?.forEach((item: any) => {
                  const mrdata = materialRequisition?.items.find(
                    (mrItem: any, index: any) => mrItem.product == item.product
                  )
                  if (mrdata?.quantity !== item.quantity) {
                    toast.error(
                      `Quantity of ${mrdata?.name} must be equal to quantity in Material Requisition`
                    )

                    checkError = false
                    setLoadingButton(false)
                  }
                })
              }
              if (checkError) {
                dispatch(create(values))
                  .then((response: any) => {
                    toast.success(response)
                    setLoadingButton(false)
                    navigate('/material-issuance')
                  })
                  .catch((error: any) => {
                    toast.error(error)
                    setLoadingButton(false)
                  })
              }
            }}
          >
            {({setFieldValue, values, errors}) => (
              <Form>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='form-group col' hidden>
                    <label htmlFor='providedToEmployee' className='fw-bold required'>
                      Provided to Employee Name
                    </label>
                    <Field
                      name='providedToEmployee'
                      placeholder='Provided To Employee'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col' hidden>
                    <label htmlFor='providedToDepartment' className='fw-bold required'>
                      Provided To Department
                    </label>
                    <Field
                      name='providedToDepartment'
                      placeholder='Provided To Department'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col' hidden>
                    <label htmlFor='location' className='fw-bold required'>
                      Location
                    </label>
                    <Field
                      name='location'
                      placeholder='Location'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='materialRequisition' className='fw-bold required'>
                      Material Requisition No.
                    </label>
                    <Select
                      options={
                        Array.isArray(materialRequisitions)
                          ? materialRequisitions.map((materialRequisition: any, index: any) => {
                              return {
                                value: materialRequisition._id,
                                label: `${materialRequisition.number}`,
                                key: index,
                              }
                            })
                          : []
                      }
                      placeholder='Select'
                      className='multi-select-container'
                      classNamePrefix='multi-select'
                      name='materialRequisition'
                      onChange={(e: any) => {
                        if (e?.value) {
                          setFieldValue('materialRequisition', e.value)
                          onChangeMaterialRequisition(e)
                        } else {
                          setFieldValue('materialRequisition', '')
                        }
                      }}
                      closeMenuOnSelect={true}
                      isClearable={true}
                      components={animatedComponents}
                    />

                    <div className='text-danger'>
                      <ErrorMessage name='materialRequisition' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='providedToEmployeeName' className='fw-bold'>
                      Provided to Employee Name
                    </label>
                    <Field
                      name='providedToEmployeeName'
                      placeholder='Provided To Employee'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='providedToDepartmentName' className='fw-bold'>
                      Provided To Department
                    </label>
                    <Field
                      name='providedToDepartmentName'
                      placeholder='Provided To Department'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='locationName' className='fw-bold'>
                      Location
                    </label>
                    <Field
                      name='locationName'
                      placeholder='Location'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                </div>
                <FieldArray
                  name='items'
                  render={(arrayHelpers) => (
                    <div className='mt-15'>
                      <table className='table tbl-md-responsivness'>
                        <thead>
                          <tr>
                            <th>
                              <label className='form-label required'>QR Code</label>
                            </th>
                            <th>
                              <label className='form-label required'>Product</label>
                            </th>
                            <th>
                              <label className='form-label required'>UOM</label>
                            </th>
                            <th>
                              <label className='form-label '>Expiry Date</label>
                            </th>
                            <th>
                              <label className='form-label required'>Quantity</label>
                            </th>
                            <th>
                              <label className='form-label'>Asset No.</label>
                            </th>
                            <th>
                              <label className='form-label required'>Remarks</label>
                            </th>
                            <th>
                              <label className='form-label'></label>
                            </th>
                          </tr>
                        </thead>
                        {loading ? (
                          <Loader />
                        ) : (
                          <tbody>
                            {values.items?.length > 0 &&
                              values.items?.map((materialItem: any, index: any) => (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td data-label='QR Code'>
                                      <div className='d-flex flex-column w-100'>
                                        <Field
                                          name={`items.${index}.qrCode`}
                                          placeholder='QR Code'
                                          className='form-control form-control-md'
                                          onKeyUp={(e: any) => {
                                            if (e.key == 'Enter') {
                                              dispatch(
                                                actions.fetchByQRCode(
                                                  values,
                                                  index,
                                                  materialRequisition
                                                )
                                              )
                                            }
                                          }}
                                        />
                                        <div className='text-danger'>
                                          <ErrorMessage name={`items.${index}.qrCode`} />
                                        </div>
                                      </div>
                                    </td>

                                    <td data-label='Product'>
                                      <div className='d-flex flex-column w-100'>
                                        <Field
                                          name={`items.${index}.name`}
                                          placeholder='Product'
                                          disabled={true}
                                          className='form-control form-control-md'
                                        />
                                        <div className='text-danger'>
                                          <ErrorMessage name={`items.${index}.name`} />
                                        </div>
                                      </div>
                                    </td>

                                    <td data-label='UOM'>
                                      <div className='d-flex flex-column w-100'>
                                        <Field
                                          name={`items.${index}.UOM`}
                                          placeholder='UOM'
                                          disabled={true}
                                          className='form-control form-control-md'
                                        />
                                        <div className='text-danger'>
                                          <ErrorMessage name={`items.${index}.UOM`} />
                                        </div>
                                      </div>
                                    </td>

                                    <td data-label='ExpiryDate'>
                                      <div className='d-flex flex-column w-100'>
                                        <Field
                                          name={`items.${index}.expiryDate`}
                                          placeholder='Expiry Date'
                                          disabled={true}
                                          className='form-control form-control-md'
                                          value={
                                            values.items[index].expiryDate
                                              ? formatDate(values.items[index].expiryDate)
                                              : ''
                                          }
                                        />
                                        <div className='text-danger'>
                                          <ErrorMessage name={`items.${index}.expiryDate`} />
                                        </div>
                                      </div>
                                    </td>

                                    <td data-label='Quantity'>
                                      <div className='d-flex flex-column w-100'>
                                        <Field
                                          name={`items.${index}.quantity`}
                                          placeholder='Quantity'
                                          className='form-control form-control-md'
                                          onBlur={(e: any) => {
                                            const value: number = e.target.value
                                            const roundedValue = formatNumber(value)
                                            setFieldValue(`items.${index}.quantity`, roundedValue)
                                          }}
                                        />
                                        <div className='text-danger'>
                                          <ErrorMessage name={`items.${index}.quantity`} />
                                        </div>
                                      </div>
                                    </td>

                                    <td data-label='Asset No.'>
                                      <div className='d-flex flex-column w-100'>
                                        <Field
                                          name={`items.${index}.assetNumber`}
                                          placeholder='Asset No.'
                                          className='form-control form-control-md'
                                          maxLength={255}
                                        />
                                        <div className='text-danger'>
                                          <ErrorMessage name={`items.${index}.assetNumber`} />
                                        </div>
                                      </div>
                                    </td>

                                    <td data-label='Remarks'>
                                      <div className='d-flex flex-column w-100'>
                                        <Field
                                          name={`items.${index}.remarks`}
                                          placeholder='Remarks'
                                          className='form-control form-control-md'
                                          maxLength={255}
                                        />
                                        <div className='text-danger'>
                                          <ErrorMessage name={`items.${index}.remarks`} />
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className='d-flex flex-row w-100'>
                                        <button
                                          type='button'
                                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                          onClick={() => arrayHelpers.remove(index)}
                                          data-bs-toggle='tooltip'
                                          data-bs-placement='top'
                                          title='Remove'
                                          disabled={values.items.length > 1 ? false : true}
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen027.svg'
                                            className='svg-icon  svg-icon-3'
                                          />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  )}
                />

                <div className='d-flex justify-content-end mt-10'>
                  <Link
                    to='/material-issuance'
                    className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Cancel'
                  >
                    <span className='indicator-label'>Cancel</span>
                  </Link>

                  <button
                    type='submit'
                    className='btn btn-lg  btn-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Submit'
                    id='liveToastBtn'
                    disabled={loadingButton}
                  >
                    <span className='indicator-label'>
                      {loadingButton ? 'Submitting...' : 'Submit'}
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
