import {Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {PrintButton} from '../../../components/PrintButton'
import {formatDateTime, formatMoney, formatNumber} from '../../../helper'
import {MaterialInspectionInitValues} from '../models/MaterialInspectionModel'
import * as MaterialInspectionAction from '../redux/MaterialInspectionAction'
import * as actions from '../redux/MaterialInspectionAction'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const MaterialInspectionDetail: React.FC<Props> = ({className}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const materialInspection: any = useSelector<RootState>(
    ({materialInspections}) => materialInspections.materialInspection,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({materialInspections}) => materialInspections.loading,
    shallowEqual
  )

  const materialInspectionUpdatedValues = {
    ...materialInspection,
    createdAt: formatDateTime(materialInspection.createdAt),
    approvedAt: formatDateTime(materialInspection.approvedAt),
  }

  const dispatch: any = useDispatch()
  let navigate = useNavigate()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(MaterialInspectionAction.fetchOne(id))

    return () => {
      dispatch(actions.resetMaterialInspection(MaterialInspectionInitValues))
    }
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Inspections List', url: '/material-inspection'},
    {name: 'Material Inspection Detail', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Material Inspection Detail</span>
          </h1>
          <PrintButton />
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            materialInspection && materialInspection?._id !== '' ? (
              <>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Material Inspection No.</b>
                      </td>
                      <td>{materialInspection?.number}</td>
                      <td>
                        <b>Created Date</b>
                      </td>
                      <td>{formatDateTime(materialInspection?.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Purchase Order No.</b>
                      </td>
                      <td>{materialInspection?.purchaseOrder?.number}</td>
                      <td>
                        <b>Requested By</b>
                      </td>
                      <td>{materialInspection?.createdBy?.fullName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Invoice No.</b>
                      </td>
                      <td>{materialInspection?.invoiceNo}</td>
                      <td>
                        <b>Location</b>
                      </td>
                      <td>{materialInspection?.location?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Status</b>
                      </td>
                      <td>{materialInspection?.status}</td>
                      <td colSpan={2}></td>
                    </tr>
                    {materialInspection?.approvedAt && materialInspection?.approvedBy && (
                      <tr>
                        <td>
                          <b>Approved At</b>
                        </td>
                        <td>{formatDateTime(materialInspection?.approvedAt)}</td>
                        <td>
                          <b>Approved By</b>
                        </td>
                        <td>{materialInspection?.approvedBy?.fullName}</td>
                      </tr>
                    )}
                    {materialInspection?.rejectedAt && materialInspection?.rejectedBy && (
                      <tr>
                        <td>
                          <b>Rejected At</b>
                        </td>
                        <td>{formatDateTime(materialInspection?.rejectedAt)}</td>
                        <td>
                          <b>Rejected By</b>
                        </td>
                        <td>{materialInspection?.rejectedBy?.fullName}</td>
                      </tr>
                    )}
                    {materialInspection?.rejectionReason && (
                      <tr>
                        <td>
                          <b>Reason to Reject</b>
                        </td>
                        <td colSpan={3}>{materialInspection?.rejectionReason}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                  <thead>
                    <tr>
                      <th style={{width: 40}}>
                        <b>#</b>
                      </th>
                      <th>
                        <b>Product</b>
                      </th>
                      <th>
                        <b>UOM</b>
                      </th>
                      <th style={{width: 80}}>
                        <b>Quantity</b>
                      </th>
                      <th>
                        <b>Rate</b>
                      </th>
                      <th>
                        <b>Price</b>
                      </th>
                      <th style={{width: 100}}>
                        <b>Tax</b>
                      </th>
                      <th>
                        <b>Price Inclusive Tax</b>
                      </th>
                      <th>
                        <b>Discount</b>
                      </th>
                      <th>
                        <b>Total</b>
                      </th>
                      <th>
                        <b>Remarks</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {materialInspection?.items.map((materialItem: any, index: any) => (
                      <tr key={index}>
                        <td data-label='#'>{index + 1}</td>
                        <td data-label='Name'>{materialItem.name}</td>
                        <td data-label='UOM'>{materialItem.UOM}</td>
                        <td data-label='Quantity'>{formatNumber(materialItem.quantity)}</td>
                        <td data-label='Rate'>{formatMoney(materialItem.rate)}</td>
                        <td data-label='Price'>{formatMoney(materialItem.price)}</td>
                        <td data-label='Tax'>{formatMoney(materialItem.tax)}</td>
                        <td data-label='Price Inclusive Tax'>
                          {formatMoney(materialItem.priceInclusiveTax)}
                        </td>
                        <td data-label='Discount'>{formatMoney(materialItem.discount)}</td>
                        <td data-label='Total'>{formatMoney(materialItem.total)}</td>
                        <td data-label='Remarks'>{materialItem.remarks}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={8}></td>
                      <td>
                        <b>Total Price</b>
                      </td>
                      <td data-label='Total Price'>
                        {formatMoney(materialInspection?.totalPrice)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={8}></td>
                      <td>
                        <b>Total Tax</b>
                      </td>
                      <td data-label='Total Tax'>{formatMoney(materialInspection?.totalTax)}</td>
                    </tr>
                    <tr>
                      <td colSpan={8}></td>
                      <td>
                        <b>Total Discount</b>
                      </td>
                      <td data-label='Total Discount'>
                        {formatMoney(materialInspection?.totalDiscount)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={8}></td>
                      <td>
                        <b>Total Amount</b>
                      </td>
                      <td data-label='Total Amount'>
                        {formatMoney(materialInspection?.totalAmount)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
