import {Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {
  MaterialInspectionInitValues,
  MaterialInspectionModel,
} from '../models/MaterialInspectionModel'
import * as MaterialInspectionAction from '../redux/MaterialInspectionAction'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDateTime, formatNumber} from '../../../helper'
import {MaterialInspectionRejectConfirmModal} from './MaterialInspectionRejectConfirmModal'
import {MaterialInspectionApproveConfirmModal} from './MaterialInspectionApproveConfirmModal'
import * as actions from '../redux/MaterialInspectionAction'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const MaterialInspectionApprove: React.FC<Props> = ({className}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const materialInspection: any = useSelector<RootState>(
    ({materialInspections}) => materialInspections.materialInspection,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({materialInspections}) => materialInspections.loading,
    shallowEqual
  )

  const items = materialInspection?.items?.map((item: any, index: number) => {
    const itemsData = {
      rate: formatNumber(item.rate),
      tax: formatNumber(item.tax),
      UOM: item?.UOM,
      name: item?.name,
      price: formatNumber(item?.price),
      priceInclusiveTax: formatNumber(item?.priceInclusiveTax),
      quantity: item?.quantity,
      product: item?._id,
      discount: formatNumber(item?.discount),
      total: formatNumber(item?.total),
      remarks: item?.remarks,
      _id: item?._id,
    }
    return itemsData
  })

  const materialInspectionUpdatedValues = {
    ...materialInspection,
    items,
    createdAt: formatDateTime(materialInspection.createdAt),
    totalPrice: formatNumber(materialInspection?.totalPrice),
    totalTax: formatNumber(materialInspection?.totalTax),
    totalDiscount: formatNumber(materialInspection?.totalDiscount),
    totalAmount: formatNumber(materialInspection?.totalAmount),
  }

  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [materialInspectionToApprove, setMaterialInspectionToApprove] =
    useState<MaterialInspectionModel>(MaterialInspectionInitValues)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [materialInspectionToReject, setMaterialInspectionToReject] =
    useState<MaterialInspectionModel>(MaterialInspectionInitValues)

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(MaterialInspectionAction.fetchOne(id))
    return () => {
      dispatch(actions.resetMaterialInspection(MaterialInspectionInitValues))
    }
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Inspections List', url: '/material-inspection'},
    {name: 'Material Inspection Approval Form', url: ''},
  ]

  return (
    <>
      <MaterialInspectionRejectConfirmModal
        show={showRejectDialog}
        handleClose={() => {
          setShowRejectDialog(false)
        }}
        materialInspection={materialInspectionToReject}
      />
      <MaterialInspectionApproveConfirmModal
        show={showApproveDialog}
        handleClose={() => {
          setShowApproveDialog(false)
        }}
        materialInspection={materialInspectionToApprove}
      />
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              Material Inspection Approval Form
            </span>
          </h1>
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            materialInspection && materialInspection?._id !== '' ? (
              <Formik
                initialValues={materialInspectionUpdatedValues || MaterialInspectionInitValues}
                enableReinitialize
                onSubmit={(values: any) => {
                  setShowApproveDialog(true)
                  setMaterialInspectionToApprove(materialInspectionUpdatedValues)
                }}
              >
                {({values}) => (
                  <Form>
                    <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                      <div className='form-group col'>
                        <label htmlFor='number' className='fw-bold  '>
                          Material Inspection No.
                        </label>
                        <Field
                          name='number'
                          className='form-control form-control-lg '
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='createdAt' className='fw-bold'>
                          Created Date
                        </label>
                        <Field
                          name='createdAt'
                          className='form-control form-control-lg '
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='purchaseOrder' className='fw-bold'>
                          Purchase Order No.
                        </label>

                        <Field
                          className='form-control form-control-md '
                          name='purchaseOrder.number'
                          disabled={true}
                        ></Field>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='requestedBy' className='fw-bold'>
                          Employee Name
                        </label>
                        <Field
                          name='createdBy.fullName'
                          className='form-control form-control-lg '
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='invoiceNo' className='fw-bold'>
                          Invoice No.
                        </label>
                        <Field
                          name='invoiceNo'
                          disabled={true}
                          className='form-control form-control-lg '
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='location' className='fw-bold'>
                          Location
                        </label>
                        <Field
                          name='location.name'
                          className='form-control form-control-lg '
                          disabled={true}
                        />
                      </div>
                    </div>

                    <FieldArray
                      name='items'
                      render={(arrayHelpers) => (
                        <div className='mt-15'>
                          <table className='table tbl-md-responsivness'>
                            <thead>
                              <tr>
                                <th>
                                  <label className='form-label '>Product</label>
                                </th>
                                <th>
                                  <label className='form-label '>UOM</label>
                                </th>
                                <th>
                                  <label className='form-label '>Quantity</label>
                                </th>
                                <th>
                                  <label className='form-label '>Rate</label>
                                </th>
                                <th>
                                  <label className='form-label '>Price</label>
                                </th>
                                <th>
                                  <label className='form-label '>Tax</label>
                                </th>
                                <th>
                                  <label className='form-label '>Price Inclusive Tax</label>
                                </th>
                                <th>
                                  <label className='form-label'>Discount</label>
                                </th>
                                <th>
                                  <label className='form-label'>Total</label>
                                </th>
                                <th>
                                  <label className='form-label '>Remarks</label>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.items?.length > 0 &&
                                values.items?.map((materialItem: any, index: any) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td data-label='Product'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.name`}
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='UOM'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.UOM`}
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Quantity'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.quantity`}
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Rate'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.rate`}
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Price'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.price`}
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Tax'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.tax`}
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Price Inclusive Tax'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.priceInclusiveTax`}
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Discount'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.discount`}
                                            placeholder='Discount'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>
                                      <td data-label='Total'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.total`}
                                            placeholder='Total'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Remarks'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.remarks`}
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              <tr>
                                <td colSpan={8} className='ps-0 dl' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Price
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Price'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalPrice'
                                      placeholder='Total Price'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                                <td></td>
                              </tr>

                              <tr>
                                <td colSpan={8} className='ps-0 dl' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Tax
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Tax'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalTax'
                                      placeholder='Total Tax'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td colSpan={8} className='ps-0 dl' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Discount
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Discount'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalDiscount'
                                      placeholder='Total Discount'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td colSpan={8} className='ps-0' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Amount
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Amount'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalAmount'
                                      placeholder='Total Amount'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    />

                    <div className='d-flex justify-content-end mt-10'>
                      <button
                        type='button'
                        className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Reject'
                        onClick={() => {
                          setShowRejectDialog(true)
                          setMaterialInspectionToReject(materialInspectionUpdatedValues)
                        }}
                      >
                        <span className='indicator-label'>Reject</span>
                      </button>

                      <button
                        type='submit'
                        className='btn btn-lg  btn-primary me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Approve'
                        id='liveToastBtn'
                      >
                        <span className='indicator-label'>Approve</span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
