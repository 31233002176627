import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {StatisticsModel, StatisticsInitValues} from '../models/StatisticsModel'

interface StatisticsState {
  loading: boolean
  statistics: StatisticsModel
}

const initialState: StatisticsState = {
  loading: false,
  statistics: StatisticsInitValues,
}

export const StatisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setStatistics: (state: {statistics: any}, action: PayloadAction<any>) => {
      state.statistics = action.payload.statistics
    },
  },
})
