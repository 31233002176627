import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {Inwards} from './components/Inwards'
import {InwardAdd} from './components/InwardAdd'
import {InwardApproval} from './components/InwardApproval'
import {InwardDetail} from './components/InwardDetail'

export const InwardPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Inwards className='mb-5 mb-xl-8' />} />
        <Route path='/add' element={<InwardAdd className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<InwardDetail className='mb-5 mb-xl-8' />} />
        <Route path='/:id/approve' element={<InwardApproval className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
