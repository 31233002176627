import axios from 'axios'
import {MaterialReturnModel, MaterialReturnTableModel} from '../models/MaterialReturnModel'
const API_URL = process.env.REACT_APP_API_URL
const MATERIAL_RETURN_URL = `${API_URL}/material-return`

export function findAll(params: MaterialReturnTableModel) {
  return axios.get(MATERIAL_RETURN_URL, {params})
}

export function findOne(id: any) {
  return axios.get(`${MATERIAL_RETURN_URL}/${id}`)
}

export function findApproved() {
  return axios.get(`${MATERIAL_RETURN_URL}/approved`)
}

export function create(data: MaterialReturnModel) {
  return axios.post(MATERIAL_RETURN_URL, data)
}

export function approve(id: any) {
  return axios.patch(`${MATERIAL_RETURN_URL}/${id}/approve`)
}

export function reject(id: any, data: any) {
  return axios.patch(`${MATERIAL_RETURN_URL}/${id}/reject`, data)
}

export function remove(id: any) {
  return axios.delete(`${MATERIAL_RETURN_URL}/${id}`)
}
