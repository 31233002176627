import * as StatisticsCRUD from './StatisticsCRUD'
import {StatisticsSlice} from './StatisticsSlice'

const {actions} = StatisticsSlice

export const fetchAll: any = () => (dispatch: any) => {
  dispatch(actions.startLoading())
  return StatisticsCRUD.findAll().then((res) => {
    dispatch(actions.setStatistics({statistics: res?.data?.data}))
    dispatch(actions.stopLoading())
  })
}
