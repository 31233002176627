import {Field, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {NoItemFound} from '../../../components/NoItemFound'
import {PrintButton} from '../../../components/PrintButton'
import {getYears} from '../../../helper/index'
import * as actions from '../redux/MaterialRequisitionAction'
import {Pie} from 'react-chartjs-2'
import {Loader} from '../../../components/Loader'
import {DatePicker} from 'rsuite'
import 'rsuite/dist/rsuite.css'

type Props = {
  className: string
}

const monthNames = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const MaterialRequisitionReport: React.FC<Props> = ({className}) => {
  const dispatch: any = useDispatch()

  const reports: any = useSelector<RootState>(
    (state) => state.materialRequisition.report,
    shallowEqual
  )

  const totalYearlyData =
    reports.length &&
    reports?.reduce(
      (acc: any, curr: any) => ({
        totalMR: acc.totalMR + curr.totalMR,
        totalNew: acc.totalNew + curr.totalNew,
        totalApproved: acc.totalApproved + curr.totalApproved,
        totalRejected: acc.totalRejected + curr.totalRejected,
        totalCompleted: acc.totalCompleted + curr.totalCompleted,
        totalCancelled: acc.totalCancelled + curr.totalCancelled,
        totalIssuance: acc.totalIssuance + curr.totalIssuance,
        totalOutward: acc.totalOutward + curr.totalOutward,
        totalInward: acc.totalInward + curr.totalInward,
      }),
      {
        totalMR: 0,
        totalNew: 0,
        totalApproved: 0,
        totalRejected: 0,
        totalCompleted: 0,
        totalCancelled: 0,
        totalIssuance: 0,
        totalOutward: 0,
        totalInward: 0,
      }
    )

  const labels = [
    'Requisitions',
    'Issuances',
    'Outwards',
    'Inwards',
    'New',
    'Approved',
    'Rejected',
    'Completed',
    'Cancelled',
  ]

  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1

  const yearList = getYears(2022)
  const [initValues] = useState<any>({year: year, month: month})
  const [currentYear, setCurrentYear] = useState(initValues.year)
  const handleUpdate = (e: any) => {
    setCurrentYear(e.target.value)
  }

  const [showChart, setShowChart] = useState('table')
  const [view, setView] = useState('table')
  const [selectedMonth, setSelectedMonth] = useState(initValues.month)

  const onDatePickerChange = (value: any) => {
    if (value) {
      setCurrentYear(value.getFullYear())
      setSelectedMonth(value.getMonth() + 1)
    }
  }

  useEffect(() => {
    dispatch(actions.fetchReport(currentYear))
  }, [dispatch, currentYear, selectedMonth])

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          boxWidth: 50,
        },
        position: 'top' as const,
        title: {
          display: true,
          text: 'Material Requisition Report ' + monthNames[selectedMonth] + ' ' + currentYear,
          font: {
            family: 'Arial',
            size: 16,
            weight: 'bold',
          },
          color: '#333',
          padding: 5,
        },
      },
    },
  }

  const totalMR =
    reports.length &&
    (reports?.find((report: any) => report?.month === selectedMonth)?.totalMR || '')

  const totalIssuance =
    reports.length &&
    (reports?.find((report: any) => report?.month === selectedMonth)?.totalIssuance || '')

  const totalOutward =
    reports.length &&
    (reports?.find((report: any) => report?.month === selectedMonth)?.totalOutward || '')

  const totalInward =
    reports.length &&
    (reports?.find((report: any) => report?.month === selectedMonth)?.totalInward || '')

  const totalNew =
    reports.length &&
    (reports?.find((report: any) => report?.month === selectedMonth)?.totalNew || '')

  const totalApproved =
    reports.length &&
    (reports?.find((report: any) => report?.month === selectedMonth)?.totalApproved || '')

  const totalRejected =
    reports.length &&
    (reports?.find((report: any) => report?.month === selectedMonth)?.totalRejected || '')

  const totalCompleted =
    reports.length &&
    reports.length &&
    (reports?.find((report: any) => report?.month === selectedMonth)?.totalCompleted || '')

  const totalCancelled =
    reports.length &&
    (reports?.find((report: any) => report?.month === selectedMonth)?.totalCancelled || '')

  const data = {
    labels: labels,
    datasets: [
      {
        data: [
          totalMR,
          totalIssuance,
          totalOutward,
          totalInward,
          totalNew,
          totalApproved,
          totalRejected,
          totalCompleted,
          totalCancelled,
        ],
        backgroundColor: [
          'rgba(249, 200, 0, 1)',
          'rgba(65, 147, 90, 1)',
          'rgba(69, 102, 159, 1)',
          'rgba(107, 113, 123, 0.87)',
          'rgba(95, 63, 50, 0.96)',
          'rgba(120, 199, 85, 0.96)',
          'rgba(184, 76, 57, 0.99)',
          'rgba(130, 83, 230, 1)',
          'rgba(145, 51, 101, 1)',
        ],
      },
    ],
  }

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Requisitions List', url: '/material-requisition'},
    {name: 'Material Requisition Report', url: '/material-requisition/report'},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Material Requisition Report</span>
          </h1>
          <PrintButton />
        </div>
        <Formik
          validationSchema={Yup.object().shape({
            year: Yup.string(),
          })}
          initialValues={initValues}
          onSubmit={(values: any) => {}}
        >
          {({values}) => (
            <Form className='form' noValidate id='kt_modal_create_app_form'>
              <div className='row ms-5'>
                <div className='col-md-4 print-view-none fv-row'>
                  <div className='d-flex report-row'>
                    {showChart == 'chart' ? (
                      <>
                        {' '}
                        <label htmlFor='Market PR' className='fs-6 fw-bold m-2'>
                          Month
                        </label>
                        <div className='col-6 '>
                          <DatePicker
                            placeholder='Month & Year'
                            format='MMM yyyy'
                            ranges={[]}
                            style={{width: 300}}
                            shouldDisableDate={(date: any) => {
                              return date.getFullYear() < 2022 || date.getFullYear() > year
                            }}
                            onChange={onDatePickerChange}
                            defaultValue={date}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {' '}
                        <label className='fs-6 fw-bold m-2'>Year</label>
                        <div className='col-6 '>
                          <Field
                            as='select'
                            name='year'
                            className='form-select'
                            style={{minHeight: '41px', maxHeight: '41px'}}
                            value={currentYear}
                            onChange={handleUpdate}
                          >
                            <option value=''>Select</option>
                            {yearList?.map((year, i) => (
                              <option key={i} value={year}>
                                {year}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </>
                    )}
                    <div className='report-btn'>
                      <div className='btn-group ms-5' role='group' aria-label='Basic example '>
                        <button
                          type='button'
                          className={`btn btn-sm btn-secondary ${view == 'table' ? 'active' : ''}`}
                          onClick={() => {
                            setShowChart('table')
                            setView('table')
                          }}
                        >
                          Table
                        </button>
                        <button
                          type='button'
                          className={`btn btn-sm btn-secondary ${view == 'chart' ? 'active' : ''}`}
                          onClick={() => {
                            setShowChart('chart')
                            setView('chart')
                          }}
                        >
                          Chart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <div className='card-body py-10'>
          {reports ? (
            showChart == 'chart' && reports?.length !== 0 ? (
              <div className='pie-chart-container'>
                <Pie options={options} data={data} />
              </div>
            ) : (
              <>
                {reports?.length !== 0 ? (
                  <table className='table tbl-border tbl-md-responsivness table-striped'>
                    <thead>
                      <tr>
                        <th>
                          <b> Month </b>
                        </th>
                        <th>
                          <b> Requisitions </b>
                        </th>
                        <th>
                          <b> Issuances </b>
                        </th>
                        <th>
                          <b> Outwards </b>
                        </th>
                        <th>
                          <b> Inwards </b>
                        </th>
                        <th>
                          <b> New </b>
                        </th>
                        <th>
                          <b> Approved </b>
                        </th>
                        <th>
                          <b> Rejected </b>
                        </th>
                        <th>
                          <b> Completed </b>
                        </th>
                        <th>
                          <b> Cancelled </b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports?.map((report: any, index: any) => (
                        <tr key={index}>
                          <td data-label='Month'>
                            {monthNames[report?.month]} - {report?.year}
                          </td>
                          <td data-label=' Requisitions'>{report?.totalMR}</td>
                          <td data-label=' Issuances'>{report?.totalIssuance}</td>
                          <td data-label=' Outwards'>{report?.totalOutward}</td>
                          <td data-label=' Inwards'>{report?.totalInward}</td>
                          <td data-label=' New'>{report?.totalNew}</td>
                          <td data-label=' Approved'>{report?.totalApproved}</td>
                          <td data-label=' Rejected'>{report?.totalRejected}</td>
                          <td data-label=' Completed'>{report?.totalCompleted}</td>
                          <td data-label=' Cancelled'>{report?.totalCancelled}</td>
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <b>Overall Total</b>
                        </td>
                        <td data-label='Total MR'>
                          <b>{totalYearlyData?.totalMR}</b>
                        </td>
                        <td data-label='Total Issuance'>
                          <b>{totalYearlyData?.totalIssuance}</b>
                        </td>
                        <td data-label='Total Outward'>
                          <b>{totalYearlyData?.totalOutward}</b>
                        </td>
                        <td data-label='Total Inward'>
                          <b>{totalYearlyData?.totalInward}</b>
                        </td>
                        <td data-label='Total New'>
                          <b>{totalYearlyData?.totalNew}</b>
                        </td>
                        <td data-label='Total Approved'>
                          <b>{totalYearlyData?.totalApproved}</b>
                        </td>
                        <td data-label='Total Rejected'>
                          <b>{totalYearlyData?.totalRejected}</b>
                        </td>
                        <td data-label='Total Completed'>
                          <b>{totalYearlyData?.totalCompleted}</b>
                        </td>
                        <td data-label='Total Cancelled'>
                          <b>{totalYearlyData?.totalCancelled}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <NoItemFound />
                )}
              </>
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
