import React from 'react'
import {MaterialRequisitionAdd} from './components/MaterialRequisitionAdd'
import {MaterialRequisitionApprove} from './components/MaterialRequisitionApprove'
import {MaterialRequisitionDetail} from './components/MaterialRequisitionDetail'
import {MaterialRequisitions} from './components/MaterialRequisitions'
import {MaterialRequisitionReport} from './components/MaterialRequisitionReport'
import {MaterialRequisitionDepartmentReport} from './components/MaterialRequisitionDepartmentsReport'
import {MaterialRequisitionItemsReport} from './components/MaterialRequisitionItemsReport'
import {Route, Routes} from 'react-router-dom'

export const MaterialRequisitionPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<MaterialRequisitions className='mb-5 mb-xl-8' />} />
        <Route path='/report' element={<MaterialRequisitionReport className='mb-5 mb-xl-8' />} />
        <Route
          path='/report/departments'
          element={<MaterialRequisitionDepartmentReport className='mb-5 mb-xl-8' />}
        />
        <Route
          path='/report/items'
          element={<MaterialRequisitionItemsReport className='mb-5 mb-xl-8' />}
        />
        <Route path='/add' element={<MaterialRequisitionAdd className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<MaterialRequisitionDetail className='mb-5 mb-xl-8' />} />
        <Route
          path='/:id/approve'
          element={<MaterialRequisitionApprove className='mb-5 mb-xl-8' />}
        />
      </Routes>
    </>
  )
}
