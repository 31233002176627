interface OutwardItem {
  _id: string
  product: string
  name: string
  UOM: string
  quantity: number | string
  assetNumber: number | string
  remarks: string
}

export interface OutwardModel {
  _id: string
  createdAt?: string
  approvedAt?: string
  approvedBy?: string
  rejectedBy?: string
  rejectedAt?: string
  rejectionReason?: string
  number: string
  materialIssuance: string
  providedToEmployee: string
  providedToDepartment: string
  location: string
  providedToEmployeeName: string
  providedToDepartmentName: string
  locationName: string
  items: [OutwardItem]
}

export const OutwardInitValues: OutwardModel = {
  _id: '',
  createdAt: '',
  approvedAt: '',
  approvedBy: '',
  rejectedBy: '',
  rejectedAt: '',
  rejectionReason: '',
  number: '',
  materialIssuance: '',
  providedToEmployee: '',
  providedToDepartment: '',
  location: '',
  providedToEmployeeName: '',
  providedToDepartmentName: '',
  locationName: '',
  items: [
    {
      _id: '',
      product: '',
      name: '',
      UOM: '',
      quantity: '',
      assetNumber: '',
      remarks: '',
    },
  ],
}

export interface OutwardSearchModel {
  number: string
  status: string
  createdAt: any
  approvedBy: string
  approvedAt: any
}
export const OutwardSearchModelInitValues: OutwardSearchModel = {
  number: '',
  status: '',
  createdAt: [],
  approvedBy: '',
  approvedAt: [],
}

export interface OutwardTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: OutwardSearchModel
}

export const OutwardTableModelInitValues: OutwardTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: OutwardSearchModelInitValues,
}
