import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {PrintButton} from '../../../components/PrintButton'
import {formatMoney} from '../../../helper'
import {formatDateTime} from '../../../helper'
import * as AssetAction from '../redux/AssetAction'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const AssetDetail: React.FC<Props> = ({className}) => {
  const asset: any = useSelector<RootState>(({assets}) => assets.asset, shallowEqual)

  const loading: any = useSelector<RootState>(({assets}) => assets.loading, shallowEqual)

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(AssetAction.fetchOne(id))
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Assets List', url: '/assets'},
    {name: 'Asset Detail', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Asset Detail</span>
          </h1>
          <PrintButton />
        </div>
        <div className='card-body'>
          {!loading ? (
            asset && asset?._id !== '' ? (
              <>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Asset No.</b>
                      </td>
                      <td>{asset?.number}</td>
                      <td>
                        <b>Created Date</b>
                      </td>
                      <td>{formatDateTime(asset?.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b> Goods Received Note No.</b>
                      </td>
                      <td>{asset?.grn ?? '-'}</td>
                      <td>
                        <b>Created By</b>
                      </td>
                      <td>{asset?.createdBy?.fullName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Location</b>
                      </td>
                      <td>{asset?.location?.name}</td>
                      <td>
                        <b>Market Value</b>
                      </td>
                      <td>{formatMoney(asset?.marketValue)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Notes</b>
                      </td>
                      <td>{asset?.notes ?? '-'}</td>
                    </tr>
                  </tbody>
                </table>
                <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                  <thead>
                    <tr>
                      <th>
                        <b>Product</b>
                      </th>
                      <th>
                        <b>UOM</b>
                      </th>
                      <th>
                        <b>Quantity</b>
                      </th>
                      <th>
                        <b>Disposal</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label='Name'>{asset?.product?.name}</td>
                      <td data-label='UOM'>{asset?.product?.UOM}</td>
                      <td data-label='Quantity'>{asset.quantity}</td>
                      <td data-label='Disposal'>{asset.disposal}</td>
                    </tr>
                  </tbody>
                </table>

                <h1 className='card-label fw-bolder fs-4 mt-10'>Asset History</h1>
                {asset?.history?.length > 0 ? (
                  <table className='table tbl-border tbl-md-responsivness table-striped '>
                    <thead>
                      <tr>
                        <th style={{width: 40}}>
                          <b>#</b>
                        </th>
                        <th>
                          <b>Updated At</b>
                        </th>
                        <th>
                          <b>Updated By</b>
                        </th>
                        <th>
                          <b>Asset No.</b>
                        </th>
                        <th>
                          <b>GRN</b>
                        </th>
                        <th>
                          <b>Product</b>
                        </th>
                        <th>
                          <b>Quantity</b>
                        </th>
                        <th>
                          <b>UOM</b>
                        </th>
                        <th>
                          <b>Location</b>
                        </th>
                        <th>
                          <b>Market Value</b>
                        </th>
                        <th>
                          <b>Notes</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {asset?.history
                        .slice(0)
                        .reverse()
                        .map((assetItem: any, index: any) => (
                          <tr key={index}>
                            <td data-label='#'>{index + 1}</td>
                            <td data-label='updatedAt'>{formatDateTime(assetItem?.updatedAt)}</td>
                            <td data-label='updatedBy'>
                              {assetItem?.updatedBy?.fullName ?? asset?.createdBy?.fullName}
                            </td>
                            <td data-label='number'>{assetItem?.number}</td>
                            <td data-label='grn'>{assetItem?.grn ?? '-'}</td>
                            <td data-label='product'>{assetItem?.product?.name}</td>
                            <td data-label='quantity'>{assetItem?.quantity}</td>
                            <td data-label='uom'>{assetItem?.product?.UOM}</td>
                            <td data-label='location'>{assetItem?.location?.name}</td>
                            <td data-label='marketValue'>{formatMoney(assetItem?.marketValue)}</td>
                            <td data-label='notes'>{assetItem?.notes ?? '-'}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  'No history found of this asset'
                )}
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
