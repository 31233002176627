import {FC, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import {SupplierBillTableModelInitValues, SupplierBillModel} from '../models/SupplierBillModel'
import * as SupplierBillAction from '../redux/SupplierBillAction'

interface Props {
  show: boolean
  handleClose: () => void
  supplierBill: SupplierBillModel
}

export const SupplierBillDeleteConfirmModal: FC<Props> = ({show, handleClose, supplierBill}) => {
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const dispatch: any = useDispatch()

  const deleteSupplierBill = () => {
    setLoading(true)
    dispatch(SupplierBillAction.remove(supplierBill?._id, SupplierBillTableModelInitValues))
      .then((response: any) => {
        toast.success(response)
        setLoading(false)
        navigate('/supplier-bills')
      })
      .catch((error: any) => {
        toast.error(error)
        setLoading(false)
      })
    handleClose()
  }

  return (
    <>
      <Modal
        id='kt_modal_delete_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered mw-500px h-auto'
        show={show}
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Delete Supplier Bill</h2>
          </div>

          <div className='modal-body flex-row-fluid py-lg-10 px-lg-10'>
            <p className='d-flex align-items-center fs-5 fw-bold'>
              Are you sure you want to delete this Supplier Bill ({supplierBill?.number})?
            </p>
            <div className='d-flex flex-stack mt-5'>
              <button
                type='button'
                className='btn btn-lg btn-light-primary float-left'
                onClick={() => {
                  handleClose()
                }}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='btn btn-lg btn-primary float-right'
                onClick={() => {
                  deleteSupplierBill()
                }}
                disabled={loading}
              >
                <span className='indicator-label'>{loading ? 'Deleting...' : 'Delete'}</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
