import axios from 'axios'
import {DepartmentTableModel} from '../models/DepartmentModel'

const API_URL = process.env.REACT_APP_API_URL
const DEPARTMENT_URL = `${API_URL}/departments`

export function findAll(params: DepartmentTableModel) {
  return axios.get(DEPARTMENT_URL, {params})
}

export function findOne(id: string) {
  return axios.get(`${DEPARTMENT_URL}/${id}`)
}

export function create(name: string) {
  return axios.post(DEPARTMENT_URL, {name})
}
export function update(_id: string, name: string) {
  return axios.patch(`${DEPARTMENT_URL}/${_id}`, {name})
}
export function remove(_id: string) {
  return axios.delete(`${DEPARTMENT_URL}/${_id}`)
}
