import React, {useCallback, useContext, useEffect, useState, useMemo, Fragment} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as MaterialReturnAction from '../redux/MaterialReturnAction'
import {RootState} from '../../../../setup/redux/RootReducer'
import {formatDateTime} from '../../../helper'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {ApprovalLegend} from '../../../components/ApprovalLegend'
import {AbilityContext, Can} from '../../../rbac/Can'
import {MaterialReturnDeleteConfirmModal} from './MaterialReturnDeleteConfirmModal'
import {
  MaterialReturnInitValues,
  MaterialReturnModel,
  MaterialReturnSearchModelInitValues,
  MaterialReturnTableModelInitValues,
} from '../models/MaterialReturnModel'
import {Table} from '../../../components/ReactTable'
import {Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {DateRangePicker} from 'rsuite'
import 'rsuite/dist/rsuite.css'

type Props = {
  className: string
}

export const defaultColDef = {
  resizable: true,
  initialWidth: 140,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const MaterialReturns: React.FC<Props> = ({className}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [materialReturnToDelete, setMRToDelete] =
    useState<MaterialReturnModel>(MaterialReturnInitValues)
  const dispatch = useDispatch()

  const pages: any = useSelector<RootState>(
    ({materialReturn}) => materialReturn.pagination?.pages,
    shallowEqual
  )

  const [searchParams, setSearchParams]: any = useState({})
  const [createdAt, setCreatedAt]: any = useState([])
  const [approvedAt, setApprovedAt]: any = useState([])
  const [show, setShow] = useState(false)
  const filterHandel = () => {
    setShow(!show)
  }

  useEffect(() => {
    dispatch(MaterialReturnAction.fetchAll(MaterialReturnTableModelInitValues))
  }, [dispatch])

  const materialReturns: any = useSelector<RootState>(
    ({materialReturn}) => materialReturn.materialReturns,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({materialReturn}) => materialReturn.loading,
    shallowEqual
  )

  const ability = useContext(AbilityContext)

  const fetchData = useCallback(
    ({pageSize, pageIndex, params, sortBy}: any) => {
      const queryParams = {
        page: pageIndex + 1,
        sortBy: sortBy[0]?.id || 'createdAt',
        sortOrder: sortBy[0]?.desc === false ? 'asc' : 'desc',
        search: params,
      }
      dispatch(MaterialReturnAction.fetchAll(queryParams))
    },
    [searchParams]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: (cellInfo: any) => {
          if (cellInfo?.row?.original?.createdAt) {
            return formatDateTime(cellInfo?.row?.original?.createdAt)
          } else {
            return '-'
          }
        },
      },
      {
        Header: 'Material Return',
        accessor: 'number',
        cellStyle: {textAlign: 'left'},
        Cell: (params: any) => (
          <Link to={`/material-return/${params?.row?.original?._id}`}>
            {params?.row?.original?.number}
          </Link>
        ),
      },
      {
        Header: 'Material Issuance',
        accessor: 'materialIssuance.number',
        disableSortBy: true,
        Cell: (params: any) => (
          <Fragment>
            {ability.can('read', 'material-issuance') ? (
              <Link to={`/material-issuance/${params?.row?.original?.materialIssuance?._id}`}>
                {params?.row?.original?.materialIssuance?.number}
              </Link>
            ) : (
              params?.row?.original?.materialIssuance?.number
            )}
          </Fragment>
        ),
      },
      {
        Header: 'Location',
        disableSortBy: true,
        accessor: 'location.name',
        show: true,
      },
      {
        Header: 'Item Count',
        disableSortBy: true,
        accessor: 'items.length',
        show: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        show: true,
      },
      {
        Header: 'Approved By',
        disableSortBy: true,
        accessor: 'approvedBy',
        Cell: (cellInfo: any) => {
          if (!cellInfo?.row?.original?.approvedBy) return '-'
          return cellInfo?.row?.original?.approvedBy?.fullName
        },
        show: true,
      },
      {
        Header: 'Approved Date',
        accessor: 'approvedAt',
        Cell: (params: any) => {
          if (!params?.row?.original?.approvedAt) return '-'
          return formatDateTime(params?.row?.original?.approvedAt)
        },
      },
      {
        Header: 'Actions',
        Cell: (params: any) => (
          <div className='action-btn-wrapper d-flex flex-shrink-0'>
            <Can do='approve' on='material-return'>
              <div className='action-btn-wrapper'>
                {params?.row?.original?.status === 'NEW' ? (
                  <Link to={`/material-return/${params?.row?.original?._id}/approve`}>
                    <button
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      data-bs-toggle='tooltip'
                      title='Approval'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen026.svg'
                        className='svg-icon-primary svg-icon-3'
                      />
                    </button>
                  </Link>
                ) : params?.row?.original?.status === 'APPROVED' ||
                  params?.row?.original?.status === 'COMPLETED' ? (
                  <button
                    className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                    data-bs-toggle='tooltip'
                    title='Approved'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-success svg-icon-3'
                    />
                  </button>
                ) : (
                  <button
                    className='btn btn-icon btn-bg-light btn-active-color-default btn-sm me-1'
                    data-bs-toggle='tooltip'
                    title='Rejected'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-default svg-icon-3'
                    />
                  </button>
                )}
              </div>
            </Can>
            <Can do='delete' on='material-return'>
              {params?.row?.original?.status === 'NEW' && (
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_delete_app'
                  title='Delete Material Return'
                  onClick={() => {
                    setMRToDelete(params?.row?.original)
                    setShowDeleteDialog(true)
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen027.svg'
                    className='m-3 svg-icon-3'
                  />
                </button>
              )}
            </Can>
          </div>
        ),
      },
    ],
    []
  )

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Returns List', url: '/material-return'},
  ]

  return (
    <>
      <MaterialReturnDeleteConfirmModal
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        materialReturn={materialReturnToDelete}
      />
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3'>Material Returns List</span>
          </h1>
          <ApprovalLegend />
          <Can do='create' on='material-return'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Add Material Return'
            >
              <Link to='/material-return/add' className='btn btn-sm btn-light-primary'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add Material Return
              </Link>
            </div>
          </Can>
        </div>
        <div className='card-body'>
          <div
            className={`d-flex align-items-center ${
              !show ? 'justify-content-end' : 'justify-content-between'
            }`}
          >
            <p className='my-0 me-3' style={{color: '#004B87', fontSize: '14px'}}>
              <b>Search Filters</b>
            </p>
            <span onClick={filterHandel}>
              <img src='/media/svg/icons/General/filter.svg' alt='' />
            </span>
          </div>
          {show ? (
            <Formik
              initialValues={MaterialReturnSearchModelInitValues}
              validationSchema={Yup.object({
                number: Yup.string(),
                status: Yup.string(),
                createdAt: Yup.array(Yup.string()),
                approvedAt: Yup.array(Yup.string()),
                approvedBy: Yup.string(),
              })}
              onSubmit={(values: any) => {
                if (values.status === 'Status') {
                  values.status = ''
                }

                setSearchParams(values)
              }}
            >
              {({values, setFieldValue}) => (
                <Form>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                    <div className='form-group col'>
                      <label htmlFor='createdAt' className='fw-bold'>
                        Created Date
                      </label>
                      <DateRangePicker
                        value={createdAt}
                        block
                        name='createdAt'
                        cleanable={true}
                        size='md'
                        appearance='default'
                        character='-'
                        showOneCalendar
                        placeholder='Select Date'
                        format='dd/MM/yyyy'
                        onClean={(value: any) => {
                          setFieldValue('createdAt', '')
                        }}
                        onChange={(value: any) => {
                          setFieldValue('createdAt', value)
                          setCreatedAt(value)
                        }}
                      />
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='number' className='fw-bold'>
                        Material Return
                      </label>
                      <Field
                        name='number'
                        placeholder='Material Return Number'
                        className='form-control form-control-lg'
                      />
                    </div>
                  </div>

                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5 mt-1'>
                    <div className='form-group col'>
                      <label htmlFor='status' className='fw-bold'>
                        Status
                      </label>
                      <Field
                        as='select'
                        className='form-select form-select-md'
                        data-control='select2'
                        name='status'
                      >
                        <option>Status</option>
                        <option value='NEW'>NEW</option>
                        <option value='APPROVED'>APPROVED</option>
                        <option value='COMPLETED'>COMPLETED</option>
                        <option value='CANCELLED'>CANCELLED</option>
                        <option value='REJECTED'>REJECTED</option>
                      </Field>
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='approvedAt' className='fw-bold'>
                        Approved Date
                      </label>
                      <DateRangePicker
                        value={approvedAt}
                        block
                        name='approvedAt'
                        cleanable={true}
                        size='md'
                        appearance='default'
                        character='-'
                        showOneCalendar
                        placeholder='Select Date'
                        format='dd/MM/yyyy'
                        onClean={(value: any) => {
                          setFieldValue('approvedAt', '')
                        }}
                        onChange={(value: any) => {
                          setFieldValue('approvedAt', value)
                          setApprovedAt(value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5 mt-1'>
                    <div className='form-group col'>
                      <label htmlFor='number' className='fw-bold'>
                        Approved By
                      </label>
                      <Field
                        name='approvedBy'
                        placeholder='Approved By'
                        className='form-control form-control-lg'
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-end mt-10'>
                    <Link
                      to='/material-return'
                      className='btn btn-sm btn-light-primary me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancel'
                      onClick={() => {
                        setSearchParams(MaterialReturnSearchModelInitValues)
                        setFieldValue('number', '')
                        setFieldValue('status', '')
                      }}
                    >
                      <span className='indicator-label'>Cancel</span>
                    </Link>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Search'
                      id='liveToastBtn'
                    >
                      <span className='indicator-label'>Search</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}
          <div className='d-flex mt-5 '>
            <div className='react-table-wrapper' style={{width: '100%'}}>
              <Table
                columns={columns}
                data={materialReturns}
                params={searchParams}
                pageCount={pages}
                fetchData={fetchData}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
