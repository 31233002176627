import React from 'react'
import {SupplierAdd} from './components/SupplierAdd'
import {Suppliers} from './components/Suppliers'
import {Route, Routes} from 'react-router-dom'
import {SupplierEdit} from './components/SupplierEdit'
import {SupplierApprove} from './components/SupplierApprove'
import {SupplierDetail} from './components/SupplierDetail'

export const SupplierPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Suppliers className='mb-5 mb-xl-8' />} />
        <Route path='/add' element={<SupplierAdd className='mb-5 mb-xl-8' />} />
        <Route path='/:id/edit' element={<SupplierEdit className='mb-5 mb-xl-8' />} />
        <Route path='/:id/approve' element={<SupplierApprove className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<SupplierDetail className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
