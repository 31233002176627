import React from 'react'
import {PurchaseOrderAdd} from './components/PurchaseOrderAdd'
import {Route, Routes} from 'react-router-dom'
import {PurchaseOrders} from './components/PurchaseOrders'
import {PurchaseOrderDetail} from './components/PurchaseOrderDetail'
import {PurchaseOrderApproval} from './components/PurchaseOrderApproval'
import {PurchaseOrderEdit} from './components/PurchaseOrderEdit'
import {PurchaseOrderReport} from './components/PurchaseOrderReport'
import {PurchaseOrderSuppliersReport} from './components/PurchaseOrderSuppliersReport'
import {PurchaseOrderItemsReport} from './components/PurchaseOrderItemsReport'

export const PurchaseOrderPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<PurchaseOrders className='mb-5 mb-xl-8' />} />
        <Route path='/add' element={<PurchaseOrderAdd className='mb-5 mb-xl-8' />} />
        <Route path='/report' element={<PurchaseOrderReport className='mb-5 mb-xl-8' />} />
        <Route
          path='/report/suppliers'
          element={<PurchaseOrderSuppliersReport className='mb-5 mb-xl-8' />}
        />
        <Route
          path='/report/items'
          element={<PurchaseOrderItemsReport className='mb-5 mb-xl-8' />}
        />
        <Route path='/:id/edit' element={<PurchaseOrderEdit className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<PurchaseOrderDetail className='mb-5 mb-xl-8' />} />
        <Route path='/:id/approve' element={<PurchaseOrderApproval className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
