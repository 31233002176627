import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PurchaseOrderModel, PurchaseOrderInitValues} from '../models/PurchaseOrderModel'

interface PurchaseOrderState {
  loading: boolean
  purchaseOrder: PurchaseOrderModel
  purchaseOrders: [PurchaseOrderModel?]
  report: any
  pagination: {total: number; pages: number}
}

const initialState: PurchaseOrderState = {
  loading: false,
  purchaseOrder: PurchaseOrderInitValues,
  purchaseOrders: [],
  report: '',
  pagination: {total: 0, pages: 0},
}

export const PurchaseOrderSlice = createSlice({
  name: 'purchaseOrder',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setPurchaseOrders: (state: {purchaseOrders: any}, action: PayloadAction<any>) => {
      state.purchaseOrders = action.payload.purchaseOrders
    },
    setPurchaseOrder: (state: {purchaseOrder: any}, action: PayloadAction<any>) => {
      state.purchaseOrder = action.payload.purchaseOrder
    },
    setPurchaseOrderReport: (state: {report: any}, action: PayloadAction<any>) => {
      state.report = action.payload.report
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
