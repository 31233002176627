import * as MaterialReturnCRUD from './MaterialReturnCRUD'
import {MaterialReturnSlice} from './MaterialReturnSlice'
import {deepCopy, formatNumber} from '../../../helper'
import {MaterialReturnTableModel} from '../models/MaterialReturnModel'
const {actions} = MaterialReturnSlice

export const fetchAll: any = (params: MaterialReturnTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return MaterialReturnCRUD.findAll(params).then((res) => {
    dispatch(actions.setMaterialReturns({materialReturns: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchOne = (id: any) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await MaterialReturnCRUD.findOne(id)
    dispatch(actions.stopLoading())
    dispatch(actions.setMaterialReturn({materialReturn: res.data.data}))
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const fetchApproved = () => (dispatch: any) => {
  return MaterialReturnCRUD.findApproved().then((res) => {
    dispatch(actions.setMaterialReturns({materialReturns: res.data.data}))
  })
}

export const create = (data: any) => async (dispatch: any) => {
  try {
    const res = await MaterialReturnCRUD.create(data)
    const successMsg = res.data.message
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const approve = (id: any) => async (dispatch: any) => {
  try {
    const res = await MaterialReturnCRUD.approve(id)

    const materialReturn = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setMaterialReturn({materialReturn: materialReturn}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const reject = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await MaterialReturnCRUD.reject(id, data)

    const materialReturn = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setMaterialReturn({materialReturn: materialReturn}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const removeMaterialReturnItem = (values: any, index: number) => (dispatch: any) => {
  let uMaterialReturn = deepCopy(values)
  const items = values?.items.filter((item: any, i: any) => i !== index)
  uMaterialReturn.items = items

  dispatch(actions.setMaterialReturn({materialReturn: uMaterialReturn}))
  dispatch(actions.setMaterialIssuance({materialIssuance: uMaterialReturn}))
}

export const onChangeMaterialIssuance =
  (e: any, values: any, materialIssuances: any) => (dispatch: any) => {
    const selectedMaterialIssuance = materialIssuances.find(
      (materialIssuance: any) => materialIssuance._id === e.value
    )
    let uMaterialReturn = JSON.parse(JSON.stringify(values))
    if (selectedMaterialIssuance) {
      uMaterialReturn.materialIssuance = selectedMaterialIssuance._id
      uMaterialReturn.items.shift()

      selectedMaterialIssuance.items.map((r: any, i: any) => {
        uMaterialReturn.items[i] = {
          product: r.product,
          qrCode: r.qrCode,
          expiryDate: r.expiryDate,
          name: r.name,
          UOM: r.UOM,
          quantity: r.quantity,
          remarks: '',
        }
      })

      dispatch(actions.setMaterialReturn({materialReturn: uMaterialReturn}))
      dispatch(actions.setMaterialIssuance({materialIssuance: selectedMaterialIssuance}))
    }
  }

export const resetMaterialReturn = (data: any) => (dispatch: any) => {
  dispatch(actions.setMaterialReturn({materialReturn: data}))
}

export const remove = (id: any, params: MaterialReturnTableModel) => async (dispatch: any) => {
  try {
    const res = await MaterialReturnCRUD.remove(id)

    const successMsg = res.data.message
    MaterialReturnCRUD.findAll(params).then((res) => {
      dispatch(actions.setMaterialReturns({materialReturns: res.data.data}))
      dispatch(actions.setPagination({pagination: res.data.pagination}))
      dispatch(actions.stopLoading())
    })

    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}
