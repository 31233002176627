export interface ProductModel {
  _id: string
  name: string
  UOM: string
  code: string
  category: string
}

export const ProductInitValues: ProductModel = {
  _id: '',
  name: '',
  UOM: '',
  code: '',
  category: '',
}

export interface ProductSearchModel {
  name: string
}

export const ProductSearchModelInitValues: ProductSearchModel = {
  name: '',
}

export interface ProductTableModel {
  page: number
  limit?: number
  sortBy: string
  sortOrder: string
  search: ProductSearchModel
}

export const ProductTableModelInitValues: ProductTableModel = {
  page: 1,
  limit: 25,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: ProductSearchModelInitValues,
}

export const ProductListingModelInitValues: ProductTableModel = {
  page: 1,
  limit: 5000,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: ProductSearchModelInitValues,
}
