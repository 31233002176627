import React, {FC, useState} from 'react'
import '../../../_metronic/assets/sass/components/cards.scss'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import * as auth from '../auth/redux/AuthRedux'
import {updateSettings} from '../auth/redux/AuthCRUD'
import {RootState} from '../../../setup'
import {DashboardItem} from './DashboardItem'
import {Can} from '../../rbac/Can'
import {toast} from 'react-toastify'

export const DashboardPage: FC = () => {
  const dispatch: any = useDispatch()
  const authUser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [colorIcon, setColorIcon] = useState(authUser?.settings?.menuIconColor)

  const path = colorIcon ? 'media/dashboard-icons/color/SVG' : 'media/dashboard-icons/classic/SVG'

  const onChangeIcons = () => {
    setColorIcon(!colorIcon)
    dispatch(
      updateSettings({
        ...authUser?.settings,
        menuIconColor: !colorIcon,
      })
    )
      .then((response: any) => {
        dispatch(auth.actions.setUser(response?.data))
      })
      .catch((error: any) => {
        toast.error(error)
      })
  }

  const dashboardItems = [
    {
      title: 'Statistics',
      route: '/statistics',
      imageSrc: `${path}/statistics.svg`,
      action: 'read',
      subject: 'statistics',
    },
    {
      title: 'Material Requisition',
      route: '/material-requisition',
      imageSrc: `${path}/material-requisition.svg`,
      action: 'read',
      subject: 'material-requisition',
    },
    {
      title: 'Material Issuance',
      route: '/material-issuance',
      imageSrc: `${path}/material-issuance.svg`,
      action: 'read',
      subject: 'material-issuance',
    },
    {
      title: 'Outward',
      route: '/outward',
      imageSrc: `${path}/outward.svg`,
      action: 'read',
      subject: 'outward',
    },
    {
      title: 'Inward',
      route: '/inward',
      imageSrc: `${path}/inward.svg`,
      action: 'read',
      subject: 'inward',
    },
    {
      title: 'Material Return',
      route: '/material-return',
      imageSrc: `${path}/material-return.svg`,
      action: 'read',
      subject: 'material-return',
    },
    {
      title: 'Purchase Orders',
      route: '/purchase-orders',
      imageSrc: `${path}/purchase-order.svg`,
      action: 'read',
      subject: 'purchase-orders',
    },

    {
      title: 'Material Inspection',
      route: '/material-inspection',
      imageSrc: `${path}/material-inspection.svg`,
      action: 'read',
      subject: 'material-inspection',
    },
    {
      title: 'Goods Received Notes',
      route: '/goods-received-note',
      imageSrc: `${path}/good-received-notes.svg`,
      action: 'read',
      subject: 'goods-received-note',
    },
    {
      title: 'Purchase Order Returns',
      route: '/purchase-order-returns',
      imageSrc: `${path}/purchase-order-return.svg`,
      action: 'read',
      subject: 'purchase-order-returns',
    },

    {
      title: 'Supplier Bills',
      route: '/supplier-bills',
      imageSrc: `${path}/supplier-bills.svg`,
      action: 'read',
      subject: 'supplier-bills',
    },
    {
      title: 'Suppliers',
      route: '/suppliers',
      imageSrc: `${path}/suppliers.svg`,
      action: 'read',
      subject: 'suppliers',
    },
    {
      title: 'Budgets',
      route: '/budgets',
      imageSrc: `${path}/budget.svg`,
      action: 'read',
      subject: 'budgets',
    },
    {
      title: 'Budget Reports',
      route: '/budgets/report',
      imageSrc: `${path}/budget-report.svg`,
      action: 'report',
      subject: 'budgets',
    },
    {
      title: 'Assets',
      route: '/assets',
      imageSrc: `${path}/assets.svg`,
      action: 'read',
      subject: 'assets',
    },
    {
      title: 'Inventory',
      route: '/inventory',
      imageSrc: `${path}/inventory.svg`,
      action: 'read',
      subject: 'inventory',
    },

    {
      title: 'Inventory Reports',
      route: '/inventory/report',
      imageSrc: `${path}/inventory-reports.svg`,
      action: 'read',
      subject: 'inventory',
    },
    {
      title: 'Stock Movement Reports',
      route: '/stock-movement-report',
      imageSrc: `${path}/inventory-reports.svg`,
      action: 'read',
      subject: 'stock-movement-report',
    },
    {
      title: 'Products',
      route: '/products',
      imageSrc: `${path}/products.svg`,
      action: 'read',
      subject: 'products',
    },
    {
      title: 'Product Categories',
      route: '/product-categories',
      imageSrc: `${path}/product-categories.svg`,
      action: 'read',
      subject: 'product-categories',
    },

    {
      title: 'Locations',
      route: '/locations',
      imageSrc: `${path}/locations.svg`,
      action: 'read',
      subject: 'locations',
    },
    {
      title: 'Departments',
      route: '/departments',
      imageSrc: `${path}/department.svg`,
      action: 'read',
      subject: 'departments',
    },

    {
      title: 'Users',
      route: '/users',
      imageSrc: `${path}/users.svg`,
      action: 'read',
      subject: 'users',
    },
    {
      title: 'Roles',
      route: '/roles',
      imageSrc: `${path}/roles.svg`,
      action: 'read',
      subject: 'roles',
    },
  ]

  return (
    <>
      <section className='cards-main mb-5 pb-5'>
        <div className='container px-0'>
          <div className='card card-custom'>
            <div className='card-body px-0'>
              <div className='tab-content'>
                <div className='tab-pane tab-pane2 active'>
                  <div className='d-flex justify-content-end me-3'>
                    <div className='btn-group  bg-light' role='group' aria-label='Basic example'>
                      <button
                        type='button'
                        className={`btn btn-sm  ${!colorIcon ? 'active' : ''}`}
                        onClick={onChangeIcons}
                      >
                        <img
                          src='media/dashboard-icons/button/classic.svg'
                          alt='SVG icon'
                          width='20'
                          height='20'
                        />
                      </button>
                      <button
                        type='button'
                        className={`btn btn-sm  ${colorIcon ? 'active' : ''}`}
                        onClick={onChangeIcons}
                      >
                        <img
                          src='media/dashboard-icons/button/colored.svg'
                          width='20'
                          height='20'
                          alt='SVG icon'
                        />
                      </button>
                    </div>
                  </div>
                  <div className='custom-grid '>
                    {dashboardItems.map((item: any, index: any) => (
                      <Can do={item.action} on={item.subject} key={index}>
                        <DashboardItem item={item} />
                      </Can>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
