import {MaterialReturnModel, MaterialReturnInitValues} from '../models/MaterialReturnModel'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface MaterialReturnState {
  loading: boolean
  materialReturn: MaterialReturnModel
  materialReturns: [MaterialReturnModel?]
  materialIssuance: MaterialReturnModel
  pagination: {total: number; pages: number}
}

const initialState: MaterialReturnState = {
  loading: false,
  materialReturn: MaterialReturnInitValues,
  materialReturns: [],
  materialIssuance: MaterialReturnInitValues,
  pagination: {total: 0, pages: 0},
}

export const MaterialReturnSlice = createSlice({
  name: 'materialReturn',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setMaterialReturns: (state, action: PayloadAction<any>) => {
      state.materialReturns = action.payload.materialReturns
    },
    setMaterialReturn: (state, action: PayloadAction<any>) => {
      state.materialReturn = action.payload.materialReturn
    },
    setMaterialIssuance: (state: {materialIssuance: any}, action: PayloadAction<any>) => {
      state.materialIssuance = action.payload.materialIssuance
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
