import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {OutwardInitValues, OutwardModel} from '../models/OutwardModel'

interface OutwardState {
  loading: boolean
  outwards: [OutwardModel?]
  outward: OutwardModel
  pagination: {total: number; pages: number}
}

const initialState: OutwardState = {
  loading: false,
  outward: OutwardInitValues,
  outwards: [],
  pagination: {total: 0, pages: 0},
}

export const OutwardSlice = createSlice({
  name: 'outward',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setOutwards: (state, action: PayloadAction<any>) => {
      state.outwards = action.payload.outwards
    },
    setOutward: (state, action: PayloadAction<any>) => {
      state.outward = action.payload.outward
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
