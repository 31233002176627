import React, {useCallback, useContext, useEffect, useState, Fragment, useMemo} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootState} from '../../../../setup/redux/RootReducer'
import {formatDateTime, formatMoney} from '../../../helper'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {ApprovalLegend} from '../../../components/ApprovalLegend'
import {
  PurchaseOrderInitValues,
  PurchaseOrderModel,
  PurchaseOrderSearchModelInitValues,
  PurchaseOrderTableModelInitValues,
} from '../models/PurchaseOrderModel'
import {PurchaseOrderCompleteConfirmModal} from './PurchaseOrderCompleteConfirmModal'
import {AbilityContext, Can} from '../../../rbac/Can'
import {fetchAll} from '../redux/PurchaseOrderAction'
import {Table} from '../../../components/ReactTable'
import {Field, Form, Formik} from 'formik'
import {DateRangePicker} from 'rsuite'
import * as Yup from 'yup'
import {PurchaseOrderDeleteConfirmModal} from './PurchaseOrderDeleteConfirmModal'

type Props = {
  className: string
}
export const defaultColumnSizing = {
  size: 150,
  minSize: 20,
  maxSize: Number.MAX_SAFE_INTEGER,
}

export const PurchaseOrders: React.FC<Props> = ({className}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [purchaseOrderToDelete, setPOToDelete] =
    useState<PurchaseOrderModel>(PurchaseOrderInitValues)

  const dispatch = useDispatch()

  const [showCompletedDialog, setShowCompletedDialog] = useState(false)
  const [purchaseOrderToCompleted, setPurchaseOrderToCompleted] =
    useState<PurchaseOrderModel>(PurchaseOrderInitValues)

  const loading: any = useSelector<RootState>(
    ({purchaseOrders}) => purchaseOrders.loading,
    shallowEqual
  )
  const purchaseOrders: any = useSelector<RootState>(
    ({purchaseOrders}) => purchaseOrders.purchaseOrders,
    shallowEqual
  )

  const pages: any = useSelector<RootState>(
    ({purchaseOrders}) => purchaseOrders.pagination?.pages,
    shallowEqual
  )
  const [searchParams, setSearchParams]: any = useState({})
  const [createdAt, setCreatedAt]: any = useState([])
  const [approvedAt, setApprovedAt]: any = useState([])
  const [show, setShow] = useState(false)
  const filterHandel = () => {
    setShow(!show)
  }
  const ability = useContext(AbilityContext)

  useEffect(() => {
    dispatch(fetchAll(PurchaseOrderTableModelInitValues))
  }, [dispatch])

  const fetchData = useCallback(
    ({pageSize, pageIndex, params, sortBy}: any) => {
      const queryParams = {
        page: pageIndex + 1,
        sortBy: sortBy[0]?.id || 'createdAt',
        sortOrder: sortBy[0]?.desc === false ? 'asc' : 'desc',
        search: params,
      }
      dispatch(fetchAll(queryParams))
    },
    [searchParams]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: (cellInfo: any) => {
          if (cellInfo?.row?.original?.createdAt) {
            return formatDateTime(cellInfo?.row?.original?.createdAt)
          } else {
            return '-'
          }
        },
        show: true,
      },
      {
        Header: 'Purchase Order',
        accessor: 'number',
        cellStyle: {textAlign: 'left'},
        Cell: (params: any) => (
          <Link to={`/purchase-orders/${params?.row?.original?._id}`}>
            {params?.row?.original?.number}
          </Link>
        ),
      },
      {
        Header: 'Material Inspection',
        accessor: 'inspection?.number',
        disableSortBy: true,
        Cell: (params: any) => {
          const inspections = params?.row?.original?.materialInspections
          return (
            <Fragment>
              {Array.isArray(inspections) &&
                inspections?.map((inspection: any, index: number) => (
                  <Fragment key={index}>
                    {ability.can('read', 'material-inspection') ? (
                      <Link to={`/material-inspection/${inspection?._id}`}>
                        {inspection?.number}&nbsp; &nbsp;
                        <br />
                      </Link>
                    ) : (
                      inspection?.number
                    )}
                  </Fragment>
                ))}
            </Fragment>
          )
        },
      },
      {
        Header: 'Goods Received Notes',
        accessor: 'grn?.number',
        disableSortBy: true,
        Cell: (params: any) => {
          const inspections = params?.row?.original?.materialInspections
          return (
            <Fragment>
              {Array.isArray(inspections) &&
                inspections?.map((inspection: any, index: number) => (
                  <Fragment key={index}>
                    {ability.can('read', 'goods-received-note') ? (
                      <Link to={`/goods-received-note/${inspection?.goodsReceivedNote?._id}`}>
                        {inspection?.goodsReceivedNote?.number} &nbsp; &nbsp;
                        <br />
                      </Link>
                    ) : (
                      inspection?.goodsReceivedNote?.number
                    )}
                  </Fragment>
                ))}
            </Fragment>
          )
        },
      },
      {Header: 'Purchase For', accessor: 'purchaseFor', disableSortBy: true},
      {Header: 'Supplier', accessor: 'supplier.name', disableSortBy: true},
      {
        Header: 'Total',
        accessor: 'totalAmount',
        Cell: (params: any) => {
          return formatMoney(params?.row?.original?.totalAmount)
        },
        show: true,
      },
      {Header: 'Status', accessor: 'status', show: true},
      {
        Header: 'Approved By',
        disableSortBy: true,
        accessor: 'approvedBy',
        Cell: (cellInfo: any) => {
          if (!cellInfo?.row?.original?.approvedBy) return '-'
          return cellInfo?.row?.original?.approvedBy?.fullName
        },
        show: true,
      },
      {
        Header: 'Approved Date',
        accessor: 'approvedAt',
        Cell: (params: any) => {
          if (!params?.row?.original?.approvedAt) return '-'
          return formatDateTime(params?.row?.original?.approvedAt)
        },
        sortable: true,
      },
      {
        Header: 'Actions',
        Cell: (params: any) => (
          <div className='action-btn-wrapper d-flex flex-shrink-0'>
            <Can do='update' on='purchase-orders'>
              {params?.row?.original?.status === 'NEW' && (
                <Link
                  to={`/purchase-orders/${params?.row?.original?._id}/edit`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  title='Edit Purchase Order'
                >
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='m-3 svg-icon-3' />
                </Link>
              )}
            </Can>
            <Can do='approve' on='purchase-orders'>
              {params?.row?.original?.status === 'NEW' ? (
                <Link to={`/purchase-orders/${params?.row?.original?._id}/approve`}>
                  <button
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    data-bs-toggle='tooltip'
                    title='Approval'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-primary svg-icon-3'
                    />
                  </button>
                </Link>
              ) : params?.row?.original?.status === 'APPROVED' ||
                params?.row?.original?.status === 'COMPLETED' ? (
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                  data-bs-toggle='tooltip'
                  title='Approved'
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen026.svg'
                    className='svg-icon-success svg-icon-3'
                  />
                </button>
              ) : (
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-default btn-sm me-1'
                  data-bs-toggle='tooltip'
                  title='Rejected'
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen026.svg'
                    className='svg-icon-default svg-icon-3'
                  />
                </button>
              )}
              <Can do='update' on='purchase-orders'>
                {params?.row?.original?.status === 'COMPLETED' ? (
                  <button
                    className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                    data-bs-toggle='tooltip'
                    title='Mark as completed'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr085.svg'
                      className='svg-icon-success svg-icon-3'
                    />
                  </button>
                ) : params?.row?.original?.status === 'REJECTED' ? (
                  <button
                    className='btn btn-icon btn-bg-light btn-active-color-secondary btn-sm me-1'
                    data-bs-toggle='tooltip'
                    title='Rejected'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr085.svg'
                      className='svg-icon-secondary svg-icon-3'
                    />
                  </button>
                ) : (
                  <button
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    data-bs-toggle='tooltip'
                    title='Mark as completed'
                    onClick={() => {
                      setShowCompletedDialog(true)
                      setPurchaseOrderToCompleted(params?.row?.original)
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr085.svg'
                      className='svg-icon-primary svg-icon-3'
                    />
                  </button>
                )}
              </Can>
            </Can>

            <Can do='delete' on='purchase-orders'>
              {params?.row?.original?.status === 'NEW' && (
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_delete_app'
                  title='Delete Purchase Order'
                  onClick={() => {
                    setPOToDelete(params?.row?.original)
                    setShowDeleteDialog(true)
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen027.svg'
                    className='m-3 svg-icon-3'
                  />
                </button>
              )}
            </Can>
          </div>
        ),
      },
    ],
    []
  )

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Purchase Orders List', url: '/purchase-orders'},
  ]

  return (
    <>
      <PurchaseOrderCompleteConfirmModal
        show={showCompletedDialog}
        handleClose={() => {
          setShowCompletedDialog(false)
        }}
        purchaseOrder={purchaseOrderToCompleted}
      />

      <PurchaseOrderDeleteConfirmModal
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        purchaseOrder={purchaseOrderToDelete}
      />

      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Purchase Orders List</span>
          </h1>
          <ApprovalLegend />
          <Can do='create' on='purchase-orders'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Add Purchase Order'
            >
              <Link
                to='/purchase-orders/add'
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                id='kt_toolbar_primary_button'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add Purchase Order
              </Link>
            </div>
          </Can>
          <Can do='report' on='purchase-orders'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Purchase Orders Report'
            >
              <Link
                to='/purchase-orders/report'
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                id='kt_toolbar_primary_button'
              >
                <KTSVG path='/media/icons/duotune/files/fil001.svg' className='svg-icon-3' />
                PO Report
              </Link>
            </div>
          </Can>
          <Can do='report' on='purchase-orders'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Purchase Order Suppliers Report'
            >
              <Link
                to='/purchase-orders/report/suppliers'
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                id='kt_toolbar_primary_button'
              >
                <KTSVG path='/media/icons/duotune/files/fil001.svg' className='svg-icon-3' />
                PO Suppliers Report
              </Link>
            </div>
          </Can>
          <Can do='report' on='purchase-orders'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Purchase Order Items Report'
            >
              <Link
                to='/purchase-orders/report/items'
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                id='kt_toolbar_primary_button'
              >
                <KTSVG path='/media/icons/duotune/files/fil001.svg' className='svg-icon-3' />
                PO Items Report
              </Link>
            </div>
          </Can>
        </div>
        <div className='card-body'>
          <div
            className={`d-flex align-items-center ${
              !show ? 'justify-content-end' : 'justify-content-between'
            }`}
          >
            <p className='my-0 me-3' style={{color: '#004B87', fontSize: '14px'}}>
              <b>Search Filters</b>
            </p>
            <span onClick={filterHandel}>
              <img src='/media/svg/icons/General/filter.svg' alt='' />
            </span>
          </div>
          {show ? (
            <Formik
              initialValues={PurchaseOrderSearchModelInitValues}
              validationSchema={Yup.object({
                number: Yup.string(),
                status: Yup.string(),
                createdAt: Yup.array(Yup.string()),
                supplier: Yup.string(),
                approvedAt: Yup.array(Yup.string()),
                approvedBy: Yup.string(),
              })}
              onSubmit={(values: any) => {
                if (values.status === 'Select Status') {
                  values.status = ''
                }

                setSearchParams(values)
              }}
            >
              {({values, setFieldValue}) => (
                <Form>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                    <div className='form-group col'>
                      <label htmlFor='createdAt' className='fw-bold'>
                        Created Date
                      </label>
                      <DateRangePicker
                        value={createdAt}
                        block
                        name='createdAt'
                        cleanable={true}
                        size='md'
                        appearance='default'
                        character='-'
                        showOneCalendar
                        placeholder='Select Date'
                        format='dd/MM/yyyy'
                        onClean={(value: any) => {
                          setFieldValue('createdAt', '')
                        }}
                        onChange={(value: any) => {
                          setFieldValue('createdAt', value)
                          setCreatedAt(value)
                        }}
                      />
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='number' className='fw-bold'>
                        Purchase Order
                      </label>
                      <Field
                        name='number'
                        placeholder='Purchase Order Number'
                        className='form-control form-control-lg'
                      />
                    </div>
                  </div>

                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5 mt-1'>
                    <div className='form-group col'>
                      <label htmlFor='supplier' className='fw-bold'>
                        Supplier
                      </label>
                      <Field
                        name='supplier'
                        placeholder='Supplier Name'
                        className='form-control form-control-lg'
                      />
                    </div>

                    <div className='form-group col'>
                      <label htmlFor='totalAmount' className='fw-bold'>
                        Total
                      </label>
                      <Field
                        name='totalAmount'
                        placeholder='Total'
                        className='form-control form-control-lg'
                      />
                    </div>
                  </div>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5 mt-1'>
                    <div className='form-group col'>
                      <label htmlFor='status' className='fw-bold'>
                        Status
                      </label>
                      <Field
                        as='select'
                        className='form-select form-select-md'
                        data-control='select2'
                        name='status'
                      >
                        <option>Status</option>
                        <option value='NEW'>NEW</option>
                        <option value='APPROVED'>APPROVED</option>
                        <option value='COMPLETED'>COMPLETED</option>
                        <option value='CANCELLED'>CANCELLED</option>
                        <option value='REJECTED'>REJECTED</option>
                      </Field>
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='approvedAt' className='fw-bold'>
                        Approved Date
                      </label>
                      <DateRangePicker
                        value={approvedAt}
                        block
                        name='approvedAt'
                        cleanable={true}
                        size='md'
                        appearance='default'
                        character='-'
                        showOneCalendar
                        placeholder='Select Date'
                        format='dd/MM/yyyy'
                        onClean={(value: any) => {
                          setFieldValue('approvedAt', '')
                        }}
                        onChange={(value: any) => {
                          setFieldValue('approvedAt', value)
                          setApprovedAt(value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5 mt-1'>
                    <div className='form-group col'>
                      <label htmlFor='number' className='fw-bold'>
                        Approved By
                      </label>
                      <Field
                        name='approvedBy'
                        placeholder='Approved By'
                        className='form-control form-control-lg'
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-end mt-10'>
                    <Link
                      to='/purchase-orders'
                      className='btn btn-sm btn-light-primary me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancel'
                      onClick={() => {
                        setSearchParams(PurchaseOrderSearchModelInitValues)
                        setFieldValue('number', '')
                        setFieldValue('status', '')
                      }}
                    >
                      <span className='indicator-label'>Cancel</span>
                    </Link>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Search'
                      id='liveToastBtn'
                    >
                      <span className='indicator-label'>Search</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}
          <div className='d-flex mt-5 '>
            <div className='ag-theme-material' style={{width: '100%'}}>
              <Table
                columns={columns}
                data={purchaseOrders}
                params={searchParams}
                pageCount={pages}
                fetchData={fetchData}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
