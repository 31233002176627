export interface InventoryReportModel {
  type: string
  reference: string
  product: string
  quantity: number
  location: string
  expiryDate: string
  qrCode?: string
  productCode?: string
}

export const InventoryReportInitValues: InventoryReportModel = {
  type: '',
  reference: '',
  product: '',
  quantity: 0,
  location: '',
  expiryDate: '',
  productCode: '',
  qrCode: '',
}

export interface InventoryReportSearchModel {
  product: string
  expiryDate: any
  productCode: string
  type: string
  quantity: string
  quantityInStock: string
}
export const InventoryReportSearchModelInitValues: InventoryReportSearchModel = {
  product: '',
  expiryDate: [],
  productCode: '',
  type: '',
  quantity: '',
  quantityInStock: '',
}

export interface InventoryReportTableModel {
  page: number
  limit?: number
  sortBy: string
  sortOrder: string
  search: InventoryReportSearchModel
}

export const InventoryReportTableModelInitValues: InventoryReportTableModel = {
  page: 1,
  limit: 25,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: InventoryReportSearchModelInitValues,
}

export const InventoryReportListingModelInitValues: InventoryReportTableModel = {
  page: 1,
  limit: 5000,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: InventoryReportSearchModelInitValues,
}
