import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {InventoryModel, InventoryInitValues} from '../models/InventoryModel'

interface InventoryState {
  loading: boolean
  inventory: InventoryModel
  inventories: [InventoryModel?]
  pagination: {total: number; pages: number}
}

const initialState: InventoryState = {
  loading: false,
  inventory: InventoryInitValues,
  inventories: [],
  pagination: {total: 0, pages: 0},
}

export const InventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setInventories: (state, action: PayloadAction<any>) => {
      const {inventories} = action.payload
      state.inventories = inventories
    },
    setInventory: (state, action) => {
      state.inventory = action.payload.inventory
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
