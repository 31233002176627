/* eslint-disable jsx-a11y/anchor-is-valid */
import {ErrorMessage, Field, Form, Formik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import makeAnimated from 'react-select/animated'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {ProductCategory} from '../../product-categories/models/ProductCategoryModel'
import {findAllParentCategories} from '../../product-categories/redux/ProductCategoryAction'
import {ProductModel, ProductTableModelInitValues} from '../models/ProductModel'
import {UOM} from '../ProductsUIHelper'
import * as actions from '../redux/ProductAction'

interface Props {
  show: boolean
  handleClose: () => void
  product: ProductModel
}

export const ProductEdit: FC<Props> = ({show, handleClose, product}) => {
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const dispatch: any = useDispatch()

  const initialValuesOnEdit = {
    name: product?.name,
    UOM: product?.UOM,
    category: product?.category,
    code: product?.code,
  }
  const animatedComponents = makeAnimated()
  const _id = product?._id

  const parentCategories: any = useSelector<RootState>(
    (state) => state.productCategories.parentCategories,
    shallowEqual
  ) as ProductCategory
  useEffect(() => {
    if (show) {
      dispatch(findAllParentCategories())
    }
  }, [show])

  const appendSubcats = (name: string, childrens: any[]) => {
    let htmlData: any = childrens?.map(function (subCat, index) {
      return (
        <React.Fragment key={subCat._id}>
          <option key={subCat._id} value={subCat._id}>
            {'-- ' + subCat.name}
          </option>
        </React.Fragment>
      )
    })
    htmlData = <optgroup label={name}>{htmlData}</optgroup>
    return htmlData
  }

  const renderSubCategories = (id: string, name: string, childrens: any[]) => {
    let subCatsData: any
    if (childrens?.length > 0) {
      subCatsData = appendSubcats(name, childrens)
    } else {
      subCatsData = (
        <option key={id} value={id}>
          {name}
        </option>
      )
    }
    return subCatsData
  }

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Edit Product</h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body flex-row-fluid py-lg-10 px-lg-10'>
          <Formik
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .trim('Name must have non-empty spaces')
                .strict(true)
                .required('Name is required')
                .label('Name'),
              UOM: Yup.string().required('UOM is required').label('UOM'),
              category: Yup.string().required('Category is required').label('Category'),
              code: Yup.string()
                .matches(/^[0-9]+$/, 'Must be only digits')
                .min(8)
                .max(13)
                .required('Code is required'),
            })}
            enableReinitialize
            initialValues={initialValuesOnEdit}
            onSubmit={(values: any) => {
              setLoading(true)
              dispatch(
                actions.update(
                  _id,
                  values.name,
                  values.UOM,
                  values.category,
                  values.code,
                  ProductTableModelInitValues
                )
              )
                .then((response: any) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/products')
                })
                .catch((error: any) => {
                  toast.error(error)
                  setLoading(false)
                })
              handleClose()
            }}
          >
            {({setFieldValue, values}) => (
              <Form className='form' noValidate id='kt_modal_create_app_form'>
                <div className='row'>
                  <div className='col-md-6 fv-row'>
                    <label className='d-flex align-items-center fs-5 fw-bold required'>
                      Category
                    </label>
                    <Field
                      as='select'
                      name='category'
                      className='form-select'
                      style={{minHeight: '41px', maxHeight: '41px'}}
                      onChange={(e: any) => {
                        setFieldValue('category', e.target.value)
                      }}
                    >
                      <option value=''>Select</option>
                      {parentCategories
                        ? parentCategories?.map(({childrens, category, _id, name}: any) => (
                            <React.Fragment key={_id}>
                              {!category && renderSubCategories(_id, name, childrens)}
                            </React.Fragment>
                          ))
                        : null}
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='category' />
                    </div>
                  </div>

                  <div className='col-md-6 fv-row'>
                    <label className='fs-6 fw-bold required'>Code</label>
                    <div className='col-12'>
                      <Field
                        type='text'
                        className='form-control form-control-lg'
                        name='code'
                        disabled={product.code ? true : false}
                      />
                      <div className='text-danger'>
                        <ErrorMessage name='code' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 fv-row'>
                    <label className='fs-6 fw-bold required'>UOM</label>
                    <div className='col-12'>
                      <Field
                        as='select'
                        name='UOM'
                        className='form-select'
                        style={{minHeight: '41px', maxHeight: '41px'}}
                      >
                        <option value=''>Select</option>
                        {UOM.map((value, i) => (
                          <option key={i} value={value}>
                            {value}
                          </option>
                        ))}
                      </Field>
                      <div className='text-danger'>
                        <ErrorMessage name='UOM' />
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6 fv-row'>
                    <label className='fs-6 fw-bold required'>Name</label>
                    <div className='col-12'>
                      <Field
                        type='text'
                        className='form-control form-control-lg'
                        name='name'
                        placeholder='Add name'
                        maxLength={255}
                      />
                      <div className='text-danger'>
                        <ErrorMessage name='name' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-stack pt-5'>
                  <div className='me-2'></div>
                  <div>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary me-3'
                      disabled={loading}
                    >
                      <span className='indicator-label'>
                        {loading ? 'Submitting...' : 'Submit'}
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
