import axios from 'axios'
import {Budget, BudgetTableModel} from '../models/BudgetModel'

const API_URL = process.env.REACT_APP_API_URL
const BUDGET_URL = `${API_URL}/budget`

export function findAll(params: BudgetTableModel) {
  return axios.get(`${BUDGET_URL}`, {params})
}

export function findOne(id: any) {
  const SINGLE_BUDGET_URL = `${BUDGET_URL}/${id}`
  return axios.get(SINGLE_BUDGET_URL)
}

export function report(year: number) {
  const REPORT_URL = `${BUDGET_URL}/report/${year}`
  return axios.get(REPORT_URL)
}

export function create(data: Budget) {
  return axios.post(BUDGET_URL, data)
}

export function update(id: any, data: Budget) {
  const UPDATE_BUDGET_URL = `${BUDGET_URL}/${id}`
  return axios.patch(UPDATE_BUDGET_URL, data)
}

export function approve(id: any) {
  const UPDATE_BUDGET_URL = `${BUDGET_URL}/${id}/approve`
  return axios.patch(UPDATE_BUDGET_URL)
}

export function reject(id: any, data: any) {
  return axios.patch(`${BUDGET_URL}/${id}/reject`, data)
}

export function remove(id: any) {
  const DELETE_BUDGET_URL = `${BUDGET_URL}/${id}`
  return axios.delete(DELETE_BUDGET_URL)
}
