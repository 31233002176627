import {ProductCategoryTableModel} from '../models/ProductCategoryModel'
import * as ProductCategoryCRUD from './ProductCategoryCRUD'
import {productCategorySlice} from './ProductCategorySlice'

const {actions} = productCategorySlice

export const findAll: any = (params: ProductCategoryTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return ProductCategoryCRUD.findAll(params).then((response) => {
    dispatch(actions.setProductCategories({productCategories: response.data.data}))
    dispatch(actions.setPagination({pagination: response.data.pagination}))
    dispatch(actions.stopLoading())
  })
}
export const findAllParentCategories = () => (dispatch: any) => {
  return ProductCategoryCRUD.findAllParentCategories().then((response) => {
    dispatch(actions.setParentCategories({parentCategories: response.data.data}))
  })
}

export const create =
  (name: string, assetType: string, parent: string, params: ProductCategoryTableModel) =>
  async (dispatch: any) => {
    try {
      const res = await ProductCategoryCRUD.create(name, assetType, parent)
      const successMsg = res.data.message
      ProductCategoryCRUD.findAll(params).then((response) => {
        dispatch(actions.setProductCategories({productCategories: response.data.data}))
        dispatch(actions.setPagination({pagination: response.data.pagination}))
      })
      return successMsg
    } catch (error: any) {
      const errors = error?.response?.data?.message
      let errorMsg
      if (typeof errors === 'string') {
        errorMsg = errors
      } else {
        errorMsg = 'Something went wrong'
      }
      throw errorMsg
    }
  }

export const update =
  (
    _id: string,
    name: string,
    assetType: string,
    parent: string,
    params: ProductCategoryTableModel
  ) =>
  async (dispatch: any) => {
    try {
      const res = await ProductCategoryCRUD.update(_id, name, assetType, parent)
      const successMsg = res.data.message
      ProductCategoryCRUD.findAll(params).then((response) => {
        dispatch(actions.setProductCategories({productCategories: response.data.data}))
        dispatch(actions.setPagination({pagination: response.data.pagination}))
      })
      return successMsg
    } catch (error: any) {
      const errors = error?.response?.data?.message
      let errorMsg
      if (typeof errors === 'string') {
        errorMsg = errors
      } else {
        errorMsg = 'Something went wrong'
      }
      throw errorMsg
    }
  }

export const remove = (_id: string, params: ProductCategoryTableModel) => async (dispatch: any) => {
  try {
    const res = await ProductCategoryCRUD.remove(_id)
    const successMsg = res.data.message
    ProductCategoryCRUD.findAll(params).then((response) => {
      dispatch(actions.setProductCategories({productCategories: response.data.data}))
      dispatch(actions.setPagination({pagination: response.data.pagination}))
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}
