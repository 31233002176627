import axios from 'axios'
import {InventoryModel, InventoryTableModel} from '../models/InventoryModel'
import {InventoryReportTableModel} from '../models/InventoryReportModel'

const API_URL = process.env.REACT_APP_API_URL
const INVENTORY_URL = `${API_URL}/inventory`
const INVENTORY_REPORT_URL = `${API_URL}/inventory/report`

export function findAll(params: InventoryTableModel) {
  return axios.get(INVENTORY_URL, {params})
}

export function fetchReport(params: InventoryReportTableModel) {
  return axios.get(INVENTORY_REPORT_URL, {params})
}

export function fetchOne(id: any) {
  const SINGLE_INVENTORY_URL = `${INVENTORY_URL}/${id}`
  return axios.get(SINGLE_INVENTORY_URL)
}

export function fetchOneByQRCode(qrcode: any) {
  const SINGLE_INVENTORY_URL = `${INVENTORY_URL}/qrcode/${qrcode}`
  return axios.get(SINGLE_INVENTORY_URL)
}

export function create(data: InventoryModel) {
  return axios.post(INVENTORY_URL, data)
}
