import React, {useCallback, useEffect, useState, Fragment, useMemo} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {Link} from 'react-router-dom'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDateTime} from '../../../helper'
import * as supplierAction from '../redux/SupplierAction'
import {Can} from '../../../rbac/Can'
import {ApprovalLegend} from '../../../components/ApprovalLegend'
import {SupplierSearchModelInitValues, SupplierTableModelInitValues} from '../models/SupplierModel'
import {Table} from '../../../components/ReactTable'
import {Field, Form, Formik} from 'formik'
import {DateRangePicker} from 'rsuite'
import * as Yup from 'yup'

type Props = {
  className: string
}

export const defaultColumnSizing = {
  size: 150,
  minSize: 20,
  maxSize: Number.MAX_SAFE_INTEGER,
}

export const Suppliers: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()

  const suppliers: any = useSelector<RootState>(({suppliers}) => suppliers.suppliers, shallowEqual)
  const loading: any = useSelector<RootState>(({suppliers}) => suppliers.loading, shallowEqual)
  const pages: any = useSelector<RootState>(
    ({suppliers}) => suppliers.pagination?.pages,
    shallowEqual
  )

  const [searchParams, setSearchParams]: any = useState({})
  const [createdAt, setCreatedAt]: any = useState([])
  const [approvedAt, setApprovedAt]: any = useState([])
  const [show, setShow] = useState(false)
  const filterHandel = () => {
    setShow(!show)
  }

  useEffect(() => {
    dispatch(supplierAction.fetchAll(SupplierTableModelInitValues))
  }, [dispatch])

  const fetchData = useCallback(
    ({pageSize, pageIndex, params, sortBy}: any) => {
      const queryParams = {
        page: pageIndex + 1,
        sortBy: sortBy[0]?.id || 'createdAt',
        sortOrder: sortBy[0]?.desc === false ? 'asc' : 'desc',
        search: params,
      }
      dispatch(supplierAction.fetchAll(queryParams))
    },
    [searchParams]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: (params: any) => {
          if (params?.row?.original?.createdAt) {
            return formatDateTime(params?.row?.original?.createdAt)
          } else {
            return '-'
          }
        },
        show: true,
      },
      {
        Header: 'Supplier Name',
        accessor: 'name',
        Cell: (params: any) => (
          <Link to={`/suppliers/${params?.row?.original?._id}`}>{params?.row?.original?.name}</Link>
        ),
        show: true,
      },
      {Header: 'Phone No.', accessor: 'phone', disableSortBy: true},
      {Header: 'Site Address', accessor: 'address', disableSortBy: true},
      {Header: 'NTN', accessor: 'NTN', disableSortBy: true},
      {Header: 'STRN', accessor: 'STRN', disableSortBy: true},
      {
        Header: 'Business Products',
        accessor: 'products',
        Cell: (params: any) => {
          const products = params?.row?.original?.products?.slice(0, 2)
          return (
            <Fragment>
              {Array.isArray(products) &&
                products?.map((items: any, index: number) => (
                  <Fragment key={index}>
                    <span className=' mt-4 '>
                      <span className=''>{items.name}</span>
                    </span>
                    {products.length > 1 ? ',' : ''}
                  </Fragment>
                ))}
            </Fragment>
          )
        },
        disableSortBy: true,
      },
      {Header: 'Status', accessor: 'status', show: true},
      {
        Header: 'Approved By',
        accessor: 'approvedBy.firstName',
        Cell: (params: any) => {
          if (!params?.row?.original?.approvedBy) return '-'
          return params?.row?.original?.approvedBy.fullName
        },
        disableSortBy: true,
      },
      {
        Header: 'Approved Date',
        accessor: 'approvedAt',
        Cell: (params: any) => {
          if (!params?.row?.original?.approvedBy) return '-'
          return formatDateTime(params?.row?.original?.approvedAt)
        },
        show: true,
      },
      {
        Header: 'Actions',
        Cell: (params: any) => (
          <div className='action-btn-wrapper d-flex flex-shrink-0 align-items-center h-100'>
            <Can do='approve' on='suppliers'>
              {params?.row?.original?.status === 'NEW' ? (
                <Link
                  to={`/suppliers/${params?.row?.original?._id}/approve`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary mt-0 btn-sm me-1'
                  data-bs-toggle='tooltip'
                  title='Approval'
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen026.svg'
                    className='svg-icon-primary svg-icon-3'
                  />
                </Link>
              ) : params?.row?.original?.status === 'APPROVED' ? (
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-success mt-0 btn-sm me-1'
                  data-bs-toggle='tooltip'
                  title='Approved'
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen026.svg'
                    className='svg-icon-success svg-icon-3'
                  />
                </button>
              ) : (
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-default mt-0 btn-sm me-1'
                  data-bs-toggle='tooltip'
                  title='Rejected'
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen026.svg'
                    className='svg-icon-default svg-icon-3'
                  />
                </button>
              )}
            </Can>
            <Can do='update' on='suppliers'>
              {!params?.row?.original?.rejectedBy ? (
                <Link
                  to={`/suppliers/${params?.row?.original?._id}/edit`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary mt-0 btn-sm me-1'
                  data-bs-toggle='tooltip'
                  title='Edit Supplier'
                >
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                </Link>
              ) : (
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-grey btn-sm'
                  title='Edit Supplier'
                >
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='m-3 svg-icon-3' />
                </button>
              )}
            </Can>
          </div>
        ),
      },
    ],
    []
  )

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Suppliers List', url: '/suppliers'},
  ]
  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Suppliers List</span>
          </h1>
          <ApprovalLegend />
          <Can do='create' on='suppliers'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Add Supplier'
            >
              <Link to='/suppliers/add' className='btn btn-sm btn-light-primary'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add Supplier
              </Link>
            </div>
          </Can>
        </div>
        <div className='card-body'>
          <div
            className={`d-flex align-items-center ${
              !show ? 'justify-content-end' : 'justify-content-between'
            }`}
          >
            <p className='my-0 me-3' style={{color: '#004B87', fontSize: '14px'}}>
              <b>Search Filters</b>
            </p>
            <span onClick={filterHandel}>
              <img src='/media/svg/icons/General/filter.svg' alt='' />
            </span>
          </div>
          {show ? (
            <Formik
              initialValues={SupplierSearchModelInitValues}
              validationSchema={Yup.object({
                name: Yup.string(),
                status: Yup.string(),
                createdAt: Yup.array(Yup.string()),
                approvedAt: Yup.array(Yup.string()),
                approvedBy: Yup.string(),
              })}
              onSubmit={(values: any) => {
                if (values.status === 'Select Status') {
                  values.status = ''
                }

                setSearchParams(values)
              }}
            >
              {({values, setFieldValue}) => (
                <Form>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                    <div className='form-group col'>
                      <label htmlFor='createdAt' className='fw-bold'>
                        Created Date
                      </label>
                      <DateRangePicker
                        value={createdAt}
                        block
                        name='createdAt'
                        cleanable={true}
                        size='md'
                        appearance='default'
                        character='-'
                        showOneCalendar
                        placeholder='Select Date'
                        format='dd/MM/yyyy'
                        onClean={(value: any) => {
                          setFieldValue('createdAt', '')
                        }}
                        onChange={(value: any) => {
                          setFieldValue('createdAt', value)
                          setCreatedAt(value)
                        }}
                      />
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='number' className='fw-bold'>
                        Supplier Name.
                      </label>
                      <Field
                        name='name'
                        placeholder='Supplier Name'
                        className='form-control form-control-lg'
                      />
                    </div>
                  </div>

                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5 mt-1'>
                    <div className='form-group col'>
                      <label htmlFor='status' className='fw-bold'>
                        Status
                      </label>
                      <Field
                        as='select'
                        className='form-select form-select-md'
                        data-control='select2'
                        name='status'
                      >
                        <option>Status</option>
                        <option value='NEW'>NEW</option>
                        <option value='APPROVED'>APPROVED</option>
                        <option value='COMPLETED'>COMPLETED</option>
                        <option value='CANCELLED'>CANCELLED</option>
                        <option value='REJECTED'>REJECTED</option>
                      </Field>
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='approvedAt' className='fw-bold'>
                        Approved Date
                      </label>
                      <DateRangePicker
                        value={approvedAt}
                        block
                        name='approvedAt'
                        cleanable={true}
                        size='md'
                        appearance='default'
                        character='-'
                        showOneCalendar
                        placeholder='Select Date'
                        format='dd/MM/yyyy'
                        onClean={(value: any) => {
                          setFieldValue('approvedAt', '')
                        }}
                        onChange={(value: any) => {
                          setFieldValue('approvedAt', value)
                          setApprovedAt(value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5 mt-1'>
                    <div className='form-group col'>
                      <label htmlFor='number' className='fw-bold'>
                        Approved By
                      </label>
                      <Field
                        name='approvedBy'
                        placeholder='Approved By'
                        className='form-control form-control-lg'
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-end mt-10'>
                    <Link
                      to='/suppliers'
                      className='btn btn-sm btn-light-primary me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancel'
                      onClick={() => {
                        setSearchParams(SupplierSearchModelInitValues)
                        setFieldValue('number', '')
                        setFieldValue('status', '')
                      }}
                    >
                      <span className='indicator-label'>Cancel</span>
                    </Link>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Search'
                      id='liveToastBtn'
                    >
                      <span className='indicator-label'>Search</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}
          <div className='d-flex mt-5 '>
            <div className='ag-theme-material' style={{width: '100%'}}>
              <Table
                columns={columns}
                data={suppliers}
                params={searchParams}
                pageCount={pages}
                fetchData={fetchData}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
