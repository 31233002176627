import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {SupplierBills} from './components/SupplierBills'
import {SupplierBillAdd} from './components/SupplierBillAdd'
import {SupplierBillApproval} from './components/SupplierBillApproval'
import {SupplierBillDetail} from './components/SupplierBillDetail'
import {SupplierBillReport} from './components/SupplierBillReport'
import {SupplierBillSuppliersReport} from './components/SupplierBillSuppliersReport'
import {SupplierBillItemsReport} from './components/SupplierBillItemsReport'
export const SupplierBillPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<SupplierBills className='mb-5 mb-xl-8' />} />
        <Route path='/report' element={<SupplierBillReport className='mb-5 mb-xl-8' />} />
        <Route
          path='/report/suppliers'
          element={<SupplierBillSuppliersReport className='mb-5 mb-xl-8' />}
        />
        <Route
          path='/report/items'
          element={<SupplierBillItemsReport className='mb-5 mb-xl-8' />}
        />
        <Route path='/add' element={<SupplierBillAdd className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<SupplierBillDetail className='mb-5 mb-xl-8' />} />
        <Route path='/:id/approve' element={<SupplierBillApproval className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
