import {MaterialRequisitionTableModel} from '../models/MaterialRequisitionModel'
import * as MaterialRequisitionCRUD from './MaterialRequisitionCRUD'
import {MaterialRequisitionSlice} from './MaterialRequisitionSlice'

const {actions} = MaterialRequisitionSlice

export const fetchAll: any = (params: MaterialRequisitionTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return MaterialRequisitionCRUD.findAll(params).then((res) => {
    dispatch(actions.setRequisitions({materialRequisitions: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchOne = (id: any) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await MaterialRequisitionCRUD.findOne(id)
    let newMaterialRequisition = JSON.parse(JSON.stringify(res.data.data))
    res.data.data.items.map((r: any, i: any) => {
      newMaterialRequisition.items[i] = {
        product: r.product,
        name: r.name,
        UOM: r.UOM,
        quantity: r.quantity,
        status: r.status,
      }
    })

    dispatch(actions.setRequisition({materialRequisition: newMaterialRequisition}))
    dispatch(actions.stopLoading())
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const fetchReport = (year: number) => (dispatch: any) => {
  return MaterialRequisitionCRUD.report(year).then((response) => {
    dispatch(actions.setMaterialRequisitionReport({report: response.data.data}))
  })
}

export const fetchDepartmentReport = (year: number, month: number) => (dispatch: any) => {
  return MaterialRequisitionCRUD.departmentsReport(year, month).then((response) => {
    dispatch(actions.setMaterialRequisitionReport({report: response.data.data}))
  })
}

export const fetchItemsReport = (year: number, month: number) => (dispatch: any) => {
  return MaterialRequisitionCRUD.itemsReport(year, month).then((response) => {
    dispatch(actions.setMaterialRequisitionReport({report: response.data.data}))
  })
}

export const fetchApproved = () => (dispatch: any) => {
  return MaterialRequisitionCRUD.findApproved().then((res) => {
    dispatch(actions.setRequisitions({materialRequisitions: res.data.data}))
  })
}

export const setMaterialRequisition = (data: any) => (dispatch: any) => {
  dispatch(actions.setRequisition({materialRequisition: data}))
}

export const create = (data: any) => async (dispatch: any) => {
  try {
    const res = await MaterialRequisitionCRUD.create(data)
    const materialRequisition = res.data.data
    const successMsg = res.data.message
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const approve = (id: any) => async (dispatch: any) => {
  try {
    const res = await MaterialRequisitionCRUD.approve(id)

    const materialRequisition = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setRequisition({materialRequisition: materialRequisition}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const reject = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await MaterialRequisitionCRUD.reject(id, data)

    const materialRequisition = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setRequisition({materialRequisition: materialRequisition}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const complete = (id: any) => async (dispatch: any) => {
  try {
    const res = await MaterialRequisitionCRUD.complete(id)

    const materialRequisition = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setRequisition({materialRequisition: materialRequisition}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const cancel = (id: any) => async (dispatch: any) => {
  try {
    const res = await MaterialRequisitionCRUD.cancel(id)

    const materialRequisition = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setRequisition({materialRequisition: materialRequisition}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const remove = (id: any, params: MaterialRequisitionTableModel) => async (dispatch: any) => {
  try {
    const res = await MaterialRequisitionCRUD.remove(id)

    const successMsg = res.data.message
    MaterialRequisitionCRUD.findAll(params).then((res) => {
      dispatch(actions.setRequisitions({materialRequisitions: res.data.data}))
      dispatch(actions.setPagination({pagination: res.data.pagination}))
      dispatch(actions.stopLoading())
    })

    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}
