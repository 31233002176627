import {Field, FieldArray, ErrorMessage, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {SupplierInitValues} from '../models/SupplierModel'
import * as supplierAction from '../redux/SupplierAction'

type Props = {
  className: string
}

export const SupplierEdit: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(false)
  const supplier: any = useSelector<RootState>(({suppliers}) => suppliers.supplier, shallowEqual)

  const dispatch: any = useDispatch()
  const navigate = useNavigate()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(supplierAction.fetchOne(id))
  }, [dispatch, id])

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Suppliers List', url: '/suppliers'},
    {name: 'Edit Supplier', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3'> Edit Supplier</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          <Formik
            initialValues={supplier || SupplierInitValues}
            enableReinitialize
            validationSchema={Yup.object({
              name: Yup.string()
                .trim('Name must have non-empty spaces')
                .strict(true)
                .required('Name is required'),
              address: Yup.string()
                .trim('Address must have non-empty spaces')
                .strict(true)
                .required('Address is required'),
              city: Yup.string()
                .trim('City must have non-empty spaces')
                .strict(true)
                .required('City is required'),
              phone: Yup.string()
                .required('Phone No is required')
                .matches(/^[0-9]+$/, 'Must be only digits'),
              NTN: Yup.string()
                .matches(/^[0-9]+$/, 'Must be only digits')
                .min(7)
                .max(20)
                .required('NTN is required'),
              STRN: Yup.string()
                .matches(/^[0-9]+$/, 'Must be only digits')
                .min(7)
                .max(20)
                .required('STRN is required'),
              evaluation: Yup.object().shape({
                productionCapactiy: Yup.string()
                  .trim('Production Capactiy must have non-empty spaces')
                  .strict(true)
                  .required('Production Capactiy is required'),
                fbrRegistrationStatus: Yup.string()
                  .trim('FBR Registration Status must have non-empty spaces')
                  .strict(true)
                  .required('FBR Registration Status is required'),
                orderCapacity: Yup.string()
                  .trim('Order Capacity must have non-empty spaces')
                  .strict(true)
                  .required('Order Capacity is required'),
                marketPR: Yup.string().required('Market PR is required'),
                customerRelationManagement: Yup.string().required(
                  'Customer relation management is required'
                ),
                paymentsTerms: Yup.string()
                  .trim('Payments Terms must have non-empty spaces')
                  .strict(true)
                  .required('Payments Terms is required'),
              }),
              bank: Yup.object().shape({
                name: Yup.string()
                  .trim('Bank Name must have non-empty spaces')
                  .strict(true)
                  .required('Bank Name is required'),
                accountTitle: Yup.string()
                  .trim('Account Title must have non-empty spaces')
                  .strict(true)
                  .required('Account Title is required'),
                IBAN: Yup.string()
                  .trim('IBAN No must have non-empty spaces')
                  .strict(true)
                  .required('IBAN No is required'),
              }),
              products: Yup.array().of(
                Yup.object().shape({
                  name: Yup.string()
                    .trim('Name must have non-empty spaces')
                    .strict(true)
                    .required('Name is required'),
                  description: Yup.string()
                    .trim('Description must have non-empty spaces')
                    .strict(true)
                    .required('Description is required'),
                })
              ),
            })}
            onSubmit={(values: any) => {
              setLoading(true)
              dispatch(supplierAction.update(id, values))
                .then((response: any) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/suppliers')
                })
                .catch((errorMsg: any) => {
                  toast.error(errorMsg)
                  setLoading(false)
                })
            }}
          >
            {({values}) => (
              <Form>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='form-group col'>
                    <label htmlFor='name' className='fw-bold required'>
                      Name
                    </label>
                    <Field
                      name='name'
                      placeholder='Name'
                      className='form-control form-control-lg'
                      type='text'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='name' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='address' className='fw-bold required'>
                      Address
                    </label>
                    <Field
                      name='address'
                      placeholder='Address'
                      className='form-control form-control-lg'
                      type='text'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='address' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='city' className='fw-bold required'>
                      City
                    </label>
                    <Field
                      name='city'
                      placeholder='City'
                      className='form-control form-control-lg'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='city' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='Phone No.' className='fw-bold required'>
                      Phone No.
                    </label>
                    <Field
                      name='phone'
                      placeholder='Phone No.'
                      className='form-control form-control-lg'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='phone' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='NTN' className='fw-bold required'>
                      NTN
                    </label>
                    <Field
                      name='NTN'
                      placeholder='NTN'
                      className='form-control form-control-lg'
                      type='text'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='NTN' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='STRN' className='fw-bold required'>
                      STRN
                    </label>
                    <Field
                      name='STRN'
                      placeholder='STRN'
                      className='form-control form-control-lg'
                      type='text'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='STRN' />
                    </div>
                  </div>
                </div>

                <div className='mt-10'></div>
                <h4>Bank Details</h4>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='bank.name' className='fw-bold required'>
                        Bank Name
                      </label>
                      <Field
                        name='bank.name'
                        placeholder='Bank Name'
                        className='form-control form-control-lg'
                        type='text'
                        maxLength={255}
                      />
                      <div className='text-danger'>
                        <ErrorMessage name='bank.name' />
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='accountTitle' className='fw-bold required'>
                        Account Title
                      </label>
                      <Field
                        name='bank.accountTitle'
                        placeholder='Account Title'
                        className='form-control form-control-lg'
                        maxLength={255}
                      />
                      <div className='text-danger'>
                        <ErrorMessage name='bank.accountTitle' />
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='IBAN No.' className='fw-bold required'>
                        IBAN No.
                      </label>
                      <Field
                        name='bank.IBAN'
                        placeholder='IBAN No.'
                        className='form-control form-control-lg'
                        type='text'
                        maxLength={255}
                      />
                      <div className='text-danger'>
                        <ErrorMessage name='bank.IBAN' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-10'></div>
                <h4>Evaluation</h4>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='form-group col'>
                    <label htmlFor='productionCapactiy' className='fw-bold required'>
                      Production Capactiy
                    </label>
                    <Field
                      name='evaluation.productionCapactiy'
                      placeholder='Production Capactiy'
                      className='form-control form-control-lg'
                      type='text'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='evaluation.productionCapactiy' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='fbrRegistrationStatus' className='fw-bold required'>
                      FBR Registration Status
                    </label>
                    <Field
                      name='evaluation.fbrRegistrationStatus'
                      placeholder='FBR Registration Status'
                      className='form-control form-control-lg'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='evaluation.fbrRegistrationStatus' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='orderCapacity' className='fw-bold required'>
                      Order Capacity
                    </label>
                    <Field
                      name='evaluation.orderCapacity'
                      placeholder='Order Capacity'
                      className='form-control form-control-lg'
                      type='text'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='evaluation.orderCapacity' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='Market PR.' className='fw-bold required'>
                      Market PR.
                    </label>
                    <Field
                      as='select'
                      className='form-select form-select-md'
                      data-control='select2'
                      name='evaluation.marketPR'
                    >
                      <option>Select</option>
                      <option value='Very Good'>Very Good</option>
                      <option value='Good'>Good</option>
                      <option value='Poor'>Poor</option>
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='evaluation.marketPR' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='Customer Relation Management' className='fw-bold required'>
                      Customer Relation Management
                    </label>
                    <Field
                      as='select'
                      className='form-select form-select-md'
                      data-control='select2'
                      name='evaluation.customerRelationManagement'
                    >
                      <option>Select</option>
                      <option value='Very Good'>Very Good</option>
                      <option value='Good'>Good</option>
                      <option value='Poor'>Poor</option>
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='evaluation.customerRelationManagement' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='paymentsTerms' className='fw-bold required'>
                      Payments Terms
                    </label>
                    <Field
                      name='evaluation.paymentsTerms'
                      placeholder='Payments Terms'
                      className='form-control form-control-lg'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='evaluation.paymentsTerms' />
                    </div>
                  </div>
                </div>

                <FieldArray
                  name='products'
                  render={(arrayHelpers) => (
                    <div className='mt-15'>
                      <table className='table tbl-md-responsivness'>
                        <thead>
                          <tr>
                            <th>
                              <label className='form-label required'>Name</label>
                            </th>
                            <th>
                              <label className='form-label required'>Description</label>
                            </th>
                            <th>
                              <label className='form-label'></label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.products?.length > 0 &&
                            values.products?.map((materialItem: any, index: any) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td data-label='Name'>
                                    <Field
                                      name={`products.${index}.name`}
                                      placeholder='Name'
                                      className='form-control form-control-md'
                                      maxLength={255}
                                    />
                                    <div className='text-danger'>
                                      <ErrorMessage name={`products.${index}.name`} />
                                    </div>
                                  </td>

                                  <td data-label='Description'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`products.${index}.description`}
                                        placeholder='description'
                                        className='form-control form-control-md'
                                        maxLength={255}
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`products.${index}.description`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <div className='d-flex flex-row w-100'>
                                      <button
                                        type='button'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                        data-bs-toggle='tooltip'
                                        data-bs-placement='top'
                                        title='Add New Item'
                                        onClick={() =>
                                          arrayHelpers.push({
                                            name: '',
                                            description: '',
                                          })
                                        }
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/arrows/arr013.svg'
                                          className='svg-icon  svg-icon-3'
                                        />
                                      </button>

                                      <button
                                        type='button'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                        onClick={() => arrayHelpers.remove(index)}
                                        data-bs-toggle='tooltip'
                                        data-bs-placement='top'
                                        title='Remove Product'
                                        disabled={values.products.length > 1 ? false : true}
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen027.svg'
                                          className='svg-icon svg-icon-3'
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                />

                <div className='d-flex justify-content-end mt-10'>
                  <Link to='/suppliers'>
                    <button
                      type='submit'
                      className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancel'
                    >
                      <span className='indicator-label'>Cancel</span>
                    </button>
                  </Link>
                  <button
                    type='submit'
                    className='btn btn-lg  btn-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Submit'
                    id='liveToastBtn'
                    disabled={loading}
                  >
                    <span className='indicator-label'>{loading ? 'Submitting...' : 'Submit'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
