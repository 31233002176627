/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {Budget, BudgetTableModelInitValues} from '../models/BudgetModel'
import {toast} from 'react-toastify'
import * as actions from '../redux/BudgetAction'

interface Props {
  show: boolean
  handleClose: () => void
  budget: Budget
}

const BudgetDelete: FC<Props> = ({show, handleClose, budget}) => {
  let navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const dispatch: any = useDispatch()
  const deleteBudget = () => {
    setLoading(true)
    dispatch(actions.remove(budget?._id, BudgetTableModelInitValues))
      .then((response: any) => {
        toast.success(response)
        setLoading(false)
        navigate('/budgets')
      })
      .catch((error: any) => {
        toast.error(error)
        setLoading(false)
      })
    handleClose()
  }

  return (
    <Modal
      id='kt_modal_delete_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Delete Budget</h2>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <div className='current' data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      Are you sure you want to delete this budget ({budget?.title})?
                    </label>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack pt-'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary me-3'
                    onClick={() => {
                      deleteBudget()
                    }}
                    disabled={loading}
                  >
                    <span className='indicator-label'>{loading ? 'Deleting...' : 'Delete'}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {BudgetDelete}
