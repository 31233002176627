import React from 'react'
import {Assets} from './components/Assets'
import {Route, Routes} from 'react-router-dom'
import {AssetAdd} from './components/AssetAdd'
import {AssetEdit} from './components/AssetEdit'
import {AssetDetail} from './components/AssetDetail'

export const AssetsPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Assets className='mb-5 mb-xl-8' />} />
        <Route path='/add' element={<AssetAdd className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<AssetDetail className='mb-5 mb-xl-8' />} />
        <Route path='/:id/edit' element={<AssetEdit className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
