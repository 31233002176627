import {Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {SupplierBillInitValues, SupplierBillModel} from '../models/SupplierBillModel'
import * as SupplierBillAction from '../redux/SupplierBillAction'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDateTime, formatNumber} from '../../../helper'
import {SupplierBillRejectConfirmModal} from './SupplierBillRejectConfirmModal'
import {SupplierBillApproveConfirmModal} from './SupplierBillApproveConfirmModal'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const SupplierBillApproval: React.FC<Props> = ({className}) => {
  const supplierBill: any = useSelector<RootState>(
    ({supplierBills}) => supplierBills.supplierBill,
    shallowEqual
  )
  const loading: any = useSelector<RootState>(
    ({supplierBills}) => supplierBills.loading,
    shallowEqual
  )

  const items = supplierBill?.items?.map((item: any, index: number) => {
    const itemsData = {
      rate: formatNumber(item.rate),
      tax: formatNumber(item.tax),
      UOM: item?.UOM,
      name: item?.name,
      price: formatNumber(item?.price),
      priceInclusiveTax: formatNumber(item?.priceInclusiveTax),
      quantity: item?.quantity,
      product: item?.product,
      discount: formatNumber(item?.discount),
      total: formatNumber(item?.total),
      _id: item?._id,
    }
    return itemsData
  })

  const supplierBillUpdatedValues = {
    ...supplierBill,
    items,
    createdAt: formatDateTime(supplierBill?.createdAt),
    totalPrice: formatNumber(supplierBill?.totalPrice),
    totalTax: formatNumber(supplierBill?.totalTax),
    totalDiscount: formatNumber(supplierBill?.totalDiscount),
    totalAmount: formatNumber(supplierBill?.totalAmount),
  }

  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [supplierBillToApprove, setsupplierBillToApprove] =
    useState<SupplierBillModel>(SupplierBillInitValues)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [supplierBillToReject, setSupplierBillToReject] =
    useState<SupplierBillModel>(SupplierBillInitValues)

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(SupplierBillAction.fetchOne(id))
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Supplier Bills List', url: '/supplier-bills'},
    {name: 'Supplier Bill Approval Form', url: ''},
  ]

  return (
    <>
      <SupplierBillRejectConfirmModal
        show={showRejectDialog}
        handleClose={() => {
          setShowRejectDialog(false)
        }}
        supplierBill={supplierBillToReject}
      />
      <SupplierBillApproveConfirmModal
        show={showApproveDialog}
        handleClose={() => {
          setShowApproveDialog(false)
        }}
        supplierBill={supplierBillToApprove}
      />
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3'>Supplier Bill Approval Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            supplierBill && supplierBill?._id !== '' ? (
              <Formik
                enableReinitialize
                initialValues={supplierBillUpdatedValues || SupplierBillInitValues}
                onSubmit={(values: any) => {
                  setShowApproveDialog(true)
                  setsupplierBillToApprove(supplierBillUpdatedValues)
                }}
              >
                {({values}) => (
                  <Form>
                    <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                      <div className='form-group col'>
                        <label htmlFor='number' className='fw-bold  '>
                          Supplier Bill No.
                        </label>
                        <Field
                          name='number'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='createdAt' className='fw-bold'>
                          Created Date
                        </label>
                        <Field
                          name='createdAt'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>

                      <div className='form-group col'>
                        <label htmlFor='goodsReceivedNoteNo.' className='fw-bold'>
                          Good Received Node No.
                        </label>

                        <Field
                          className='form-control form-control-md'
                          name='goodsReceivedNote.number'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='purchaseOrderNo.' className='fw-bold'>
                          Purchase Order No.
                        </label>

                        <Field
                          className='form-control form-control-md'
                          name='goodsReceivedNote.materialInspection.purchaseOrder.number'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='supplierName' className='fw-bold'>
                          Supplier Name
                        </label>
                        <Field
                          className='form-control form-control-md'
                          name='goodsReceivedNote.materialInspection.purchaseOrder.supplier.name'
                          placeholder='Supplier Name'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='deliveryChallanNumber' className='fw-bold'>
                          Delivery Challan No.
                        </label>
                        <Field
                          className='form-control form-control-md'
                          name='goodsReceivedNote.deliveryChallanNumber'
                          placeholder='Delivery Challan No.'
                          disabled={true}
                        />
                      </div>
                    </div>
                    <FieldArray
                      name='items'
                      render={(arrayHelpers) => (
                        <div className='mt-10'>
                          <table className='table tbl-md-responsivness'>
                            <thead>
                              <tr>
                                <th className='ps-0'>
                                  <label className='form-label mb-0'>Product</label>
                                </th>
                                <th className='ps-0'>
                                  <label className='form-label mb-0'>UOM</label>
                                </th>
                                <th className='ps-0'>
                                  <label className='form-label mb-0'>Quantity</label>
                                </th>
                                <th className='ps-0'>
                                  <label className='form-label mb-0'>Rate</label>
                                </th>
                                <th className='ps-0'>
                                  <label className='form-label mb-0'>Price</label>
                                </th>
                                <th className='ps-0'>
                                  <label className='form-label mb-0'>Tax</label>
                                </th>
                                <th className='ps-0'>
                                  <label className='form-label mb-0'>Price Inclusive Tax</label>
                                </th>
                                <th>
                                  <label className='form-label'>Discount</label>
                                </th>
                                <th>
                                  <label className='form-label'>Total</label>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.items?.length > 0 &&
                                values?.items?.map((materialItem: any, index: any) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td data-label='product'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field name={`items.${index}.name`} type='hidden' />
                                          <div className='col'>
                                            <Field
                                              name={`items.${index}.name`}
                                              placeholder='Product'
                                              disabled={true}
                                              className='form-control form-control-md'
                                            />
                                          </div>
                                        </div>
                                      </td>
                                      <td data-label='UOM'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.UOM`}
                                            placeholder='UOM'
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='quantity'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.quantity`}
                                            placeholder='Quantity'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='rate'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.rate`}
                                            placeholder='Rate'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Price'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.price`}
                                            placeholder='Price'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Tax'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.tax`}
                                            placeholder='Tax'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='priceInclusiveTax'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.priceInclusiveTax`}
                                            placeholder='Price Inclusive Tax'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>
                                      <td data-label='Discount'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.discount`}
                                            placeholder='Discount'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>
                                      <td data-label='Total'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.total`}
                                            placeholder='Total'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              <tr>
                                <td colSpan={8} className='ps-0 dl' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Price
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Price'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalPrice'
                                      placeholder='Total Price'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={8} className='ps-0 dl' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Tax
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Tax'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalTax'
                                      placeholder='Total Tax'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={8} className='ps-0 dl' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Discount
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Discount'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalDiscount'
                                      placeholder='Total Discount'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={8} className='ps-0' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label required d-flex justify-content-end align-items-end'>
                                      Total Amount
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Amount'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalAmount'
                                      placeholder='Total Amount'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    />

                    <div className='d-flex justify-content-end mt-10'>
                      <button
                        type='button'
                        className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Reject'
                        onClick={() => {
                          setShowRejectDialog(true)
                          setSupplierBillToReject(supplierBillUpdatedValues)
                        }}
                      >
                        <span className='indicator-label'>Reject</span>
                      </button>

                      <button
                        type='submit'
                        className='btn btn-lg  btn-primary me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Approve'
                        id='liveToastBtn'
                      >
                        <span className='indicator-label'>Approve</span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
