/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {remove} from '../redux/DepartmentAction'
import {DepartmentModel, DepartmentTableModelInitValues} from '../models/DepartmentModel'
import {toast} from 'react-toastify'

interface Props {
  show: boolean
  handleClose: () => void
  department: DepartmentModel
}

export const DepartmentDelete: FC<Props> = ({show, handleClose, department}) => {
  const [loading, setLoading] = useState(false)
  const dispatch: any = useDispatch()

  const deleteDepartment = () => {
    setLoading(true)
    dispatch(remove(department?._id, DepartmentTableModelInitValues))
      .then((response: any) => {
        toast.success(response)
        setLoading(false)
        handleClose()
      })
      .catch((error: any) => {
        toast.error(error)
        setLoading(false)
      })
  }

  return (
    <Modal
      id='kt_modal_delete_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Delete Department </h2>
        </div>

        <div className='modal-body flex-row-fluid py-lg-10 px-lg-10'>
          <p className='d-flex align-items-center fs-5 fw-bold'>
            Are you sure you want to delete this department ({department?.name})?
          </p>
          <div className='d-flex flex-stack mt-5'>
            <button
              type='button'
              className='btn btn-lg btn-light-primary float-left'
              onClick={() => {
                handleClose()
              }}
            >
              Cancel
            </button>
            <button
              title='Delete'
              type='submit'
              className='btn btn-lg btn-primary float-left'
              onClick={() => {
                deleteDepartment()
              }}
              disabled={loading}
            >
              <span className='indicator-label'>Delete</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
