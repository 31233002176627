import React from 'react'

export function Loader() {
  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='spinner-border text-secondary' role='status' aria-hidden='true'></div>
        <div className='text-muted fs-6 fw-semibold p-2'> Loading...</div>
      </div>
    </>
  )
}
