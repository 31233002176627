import {MaterialIssuanceModel, MaterialIssuanceInitValues} from '../models/MaterialIssuanceModel'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
interface MaterialIssuanceState {
  loading: boolean
  materialIssuance: MaterialIssuanceModel
  materialIssuances: [MaterialIssuanceModel?]
  pagination: {total: number; pages: number}
}

const initialState: MaterialIssuanceState = {
  loading: false,
  materialIssuance: MaterialIssuanceInitValues,
  materialIssuances: [],
  pagination: {total: 0, pages: 0},
}

export const MaterialIssuanceSlice = createSlice({
  name: 'materialissuance',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setMaterialIssuances: (state: {materialIssuances: any}, action: PayloadAction<any>) => {
      state.materialIssuances = action.payload.materialIssuances
    },
    setMaterialIssuance: (state: {materialIssuance: any}, action: PayloadAction<any>) => {
      state.materialIssuance = action.payload.materialIssuance
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
