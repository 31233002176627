import {Field, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Bar} from 'react-chartjs-2'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {NoItemFound} from '../../../components/NoItemFound'
import {PrintButton} from '../../../components/PrintButton'
import {formatMoney, getMaxValueArrays, getYears} from '../../../helper/index'
import * as actions from '../redux/SupplierBillAction'
import {Loader} from '../../../components/Loader'

type Props = {
  className: string
}

export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const SupplierBillReport: React.FC<Props> = ({className}) => {
  const dispatch: any = useDispatch()

  const [showChart, setShowChart] = useState('table')
  const [view, setView] = useState('table')

  const reports: any = useSelector<RootState>((state) => state.supplierBills.report, shallowEqual)

  const totalYearlyData =
    reports.length &&
    reports?.reduce(
      (acc: any, curr: any) => {
        acc.totalSB += curr.totalSB
        acc.totalSbAmount += curr.totalSbAmount
        acc.totalNew += curr.totalNew
        acc.totalApproved += curr.totalApproved
        acc.totalRejected += curr.totalRejected
        acc.totalGrnAmount += curr.totalGrnAmount
        acc.totalPOR += curr.totalPOR
        acc.totalPorAmount += curr.totalPorAmount
        return acc
      },
      {
        totalSB: 0,
        totalSbAmount: 0,
        totalNew: 0,
        totalApproved: 0,
        totalRejected: 0,
        totalGrnAmount: 0,
        totalPOR: 0,
        totalPorAmount: 0,
      }
    )

  const date = new Date().getFullYear()
  const yearList = getYears(2022)
  const [initValues] = useState<any>({year: date})

  const [currentYear, setCurrentYear] = useState(initValues.year)
  const handleUpdate = (e: any) => {
    setCurrentYear(e.target.value)
  }

  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const totalSbAmount =
    reports.length &&
    labels.map(
      (label: string, index: number) =>
        reports?.find((report: any) => report?.month === index + 1)?.totalSbAmount || ''
    )

  const totalGrnAmount =
    reports.length &&
    labels.map(
      (label: string, index: number) =>
        reports?.find((report: any) => report?.month === index + 1)?.totalGrnAmount || ''
    )

  const totalPorAmount =
    reports.length &&
    labels.map(
      (label: string, index: number) =>
        reports?.find((report: any) => report?.month === index + 1)?.totalPorAmount || ''
    )

  const maxAmountArray = [totalSbAmount, totalGrnAmount, totalPorAmount]
  const maxAmount = totalSbAmount && getMaxValueArrays(maxAmountArray)

  useEffect(() => {
    dispatch(actions.fetchReport(currentYear))
  }, [dispatch, currentYear])

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        title: {
          display: true,
          text: 'Supplier Bills Report ' + currentYear,
          font: {
            family: 'Arial',
            size: 16,
            weight: 'bold',
          },
          color: '#333',
          padding: 10,
        },
      },
    },

    scales: {
      y: {
        ticks: {
          callback: function (amount: any) {
            return 'PKR-' + formatMoney(amount)
          },
        },
        min: 0,
        max: Math.ceil(maxAmount / 10) * 10,
      },
    },
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'SB Amount',
        data: totalSbAmount,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 1.5)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
      {
        label: 'GRN Amount',
        data: totalGrnAmount,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(230, 90, 9, 0.8)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
      {
        label: 'POR Amount',
        data: totalPorAmount,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(175, 95, 240, 0.97)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
    ],
  }

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Supplier Bills List', url: '/supplier-bills'},
    {name: 'Supplier Bills Report', url: '/supplier-bills/report'},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Supplier Bills Report</span>
          </h1>
          <PrintButton />
        </div>
        <Formik
          validationSchema={Yup.object().shape({
            year: Yup.string(),
          })}
          initialValues={initValues}
          onSubmit={(values: any) => {}}
        >
          {({values}) => (
            <Form className='form' noValidate id='kt_modal_create_app_form'>
              <div className='row ms-5'>
                <div className='col-md-4 print-view-none fv-row '>
                  <div className='d-flex report-row'>
                    <label className='fs-6 fw-bold m-2'>Year</label>
                    <div className='col-6 '>
                      <Field
                        as='select'
                        name='year'
                        className='form-select'
                        style={{minHeight: '41px', maxHeight: '41px'}}
                        value={currentYear}
                        onChange={handleUpdate}
                      >
                        <option value=''>Select</option>
                        {yearList?.map((year, i) => (
                          <option key={i} value={year}>
                            {year}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className='report-btn'>
                      <div className='btn-group ms-5' role='group' aria-label='Basic example'>
                        <button
                          type='button'
                          className={`btn btn-sm btn-secondary ${view == 'table' ? 'active' : ''}`}
                          onClick={() => {
                            setShowChart('table')
                            setView('table')
                          }}
                        >
                          Table
                        </button>
                        <button
                          type='button'
                          className={`btn btn-sm btn-secondary ${view == 'chart' ? 'active' : ''}`}
                          onClick={() => {
                            setShowChart('chart')
                            setView('chart')
                          }}
                        >
                          Chart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <div className='card-body py-10'>
          {reports ? (
            showChart == 'chart' && reports?.length !== 0 ? (
              <>
                <div className='bar-chart-container'>
                  <Bar options={options} data={data} />
                </div>
              </>
            ) : reports?.length !== 0 ? (
              <table className='table tbl-border tbl-md-responsivness table-striped'>
                <thead>
                  <tr>
                    <th>
                      <b>Month </b>
                    </th>
                    <th>
                      <b> Bills </b>
                    </th>
                    <th>
                      <b> POR </b>
                    </th>
                    <th>
                      <b> POR Amount </b>
                    </th>
                    <th>
                      <b> GRN Amount </b>
                    </th>
                    <th>
                      <b>Bill Amount </b>
                    </th>
                    <th>
                      <b> New </b>
                    </th>
                    <th>
                      <b> Approved </b>
                    </th>
                    <th>
                      <b> Rejected </b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reports &&
                    reports?.map(
                      (report: any, index: any) =>
                        report?.totalGrnAmount > 0 && (
                          <tr key={index}>
                            <td data-label='Month'>
                              {labels[report?.month - 1]} - {report?.year}
                            </td>
                            <td data-label='Total Supplier Bill'>{report?.totalSB}</td>
                            <td data-label='Total POR'>{report?.totalPOR}</td>
                            <td data-label='Total POR Amount'>
                              {formatMoney(report?.totalPorAmount)}
                            </td>
                            <td data-label='Total GRN Amount'>
                              {formatMoney(report?.totalGrnAmount)}
                            </td>
                            <td data-label='Total Supplier Bill Amount'>
                              {formatMoney(report?.totalSbAmount)}
                            </td>
                            <td data-label='Total New'>{report?.totalNew}</td>
                            <td data-label='Total Approved'>{report?.totalApproved}</td>
                            <td data-label='Total Rejected'>{report?.totalRejected}</td>
                          </tr>
                        )
                    )}
                  <tr>
                    <td>
                      <b>Overall Total</b>
                    </td>
                    <td data-label='Total SB'>
                      <b>{totalYearlyData?.totalSB}</b>
                    </td>
                    <td data-label='Total POR'>
                      <b>{totalYearlyData?.totalPOR}</b>
                    </td>
                    <td data-label='Total POR Amount'>
                      <b>{formatMoney(totalYearlyData?.totalPorAmount)}</b>
                    </td>
                    <td data-label='Total GRN Amount'>
                      <b>{formatMoney(totalYearlyData?.totalGrnAmount)}</b>
                    </td>
                    <td data-label='Total SB Amount'>
                      <b>{formatMoney(totalYearlyData?.totalSbAmount)}</b>
                    </td>
                    <td data-label='Total New'>
                      <b>{totalYearlyData?.totalNew}</b>
                    </td>
                    <td data-label='Total Approved'>
                      <b>{totalYearlyData?.totalApproved}</b>
                    </td>
                    <td data-label='Total Rejected'>
                      <b>{totalYearlyData?.totalRejected}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
