import axios from 'axios'
import {SupplierBillModel, SupplierBillTableModel} from '../models/SupplierBillModel'

const API_URL = process.env.REACT_APP_API_URL
const SUPPLIER_BILL_URL = `${API_URL}/supplier-bills`

export function findAll(params: SupplierBillTableModel) {
  return axios.get(`${SUPPLIER_BILL_URL}`, {params})
}

export function report(year: number) {
  return axios.get(`${SUPPLIER_BILL_URL}/report/${year}`)
}

export function suppliersReport(year: number, month: number) {
  const REPORT_URL = `${SUPPLIER_BILL_URL}/report/suppliers/${year}/${month}`
  return axios.get(REPORT_URL)
}

export function itemsReport(year: number, month: number) {
  const REPORT_URL = `${SUPPLIER_BILL_URL}/report/items/${year}/${month}`
  return axios.get(REPORT_URL)
}

export function findOne(id: any) {
  return axios.get(`${SUPPLIER_BILL_URL}/${id}`)
}

export function findApproved() {
  return axios.get(`${SUPPLIER_BILL_URL}/approved`)
}

export function create(data: SupplierBillModel) {
  return axios.post(SUPPLIER_BILL_URL, data)
}

export function approve(id: any) {
  return axios.patch(`${SUPPLIER_BILL_URL}/${id}/approve`)
}

export function reject(id: any, data: any) {
  return axios.patch(`${SUPPLIER_BILL_URL}/${id}/reject`, data)
}

export function remove(id: any) {
  return axios.delete(`${SUPPLIER_BILL_URL}/${id}`)
}
