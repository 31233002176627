import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {formatDateTime} from '../../../helper'
import {SupplierInitValues, SupplierModel} from '../models/SupplierModel'
import * as SupplierAction from '../redux/SupplierAction'
import {SupplierApproveConfirmModal} from '../components/SupplierApproveConfirmModal'
import {SupplierRejectConfirmModal} from '../components/SupplierRejectConfirmModal'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const SupplierApprove: React.FC<Props> = ({className}) => {
  const supplier: any = useSelector<RootState>(({suppliers}) => suppliers.supplier, shallowEqual)
  const loading: any = useSelector<RootState>(({suppliers}) => suppliers.loading, shallowEqual)

  const supplierUpdatedValues = {
    ...supplier,
    requestedDate: formatDateTime(supplier?.requestedDate),
    approvedAt: formatDateTime(supplier?.approvedAt),
  }

  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [supplierToApprove, setSupplierToApprove] = useState<SupplierModel>(SupplierInitValues)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [supplierToReject, setSupplierToReject] = useState<SupplierModel>(SupplierInitValues)

  const dispatch: any = useDispatch()

  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(SupplierAction.fetchOne(id))
  }, [dispatch, id])

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Suppliers List', url: '/suppliers'},
    {name: 'Supplier Approval Form', url: ''},
  ]

  return (
    <>
      <SupplierRejectConfirmModal
        show={showRejectDialog}
        handleClose={() => {
          setShowRejectDialog(false)
        }}
        supplier={supplierToReject}
      />
      <SupplierApproveConfirmModal
        show={showApproveDialog}
        handleClose={() => {
          setShowApproveDialog(false)
        }}
        supplier={supplierToApprove}
      />
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Supplier Approval Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            supplier && supplier?._id !== '' ? (
              <Formik
                initialValues={supplierUpdatedValues || SupplierInitValues}
                enableReinitialize
                onSubmit={(values: any) => {
                  setShowApproveDialog(true)
                  setSupplierToApprove(supplierUpdatedValues)
                }}
              >
                {({values}) => (
                  <Form>
                    <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                      <div className='form-group col'>
                        <label htmlFor='name' className='fw-bold '>
                          Name
                        </label>
                        <Field
                          name='name'
                          placeholder='Name'
                          className='form-control form-control-lg'
                          type='text'
                          maxLength={255}
                          disabled={true}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='name' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='address' className='fw-bold '>
                          Address
                        </label>
                        <Field
                          name='address'
                          placeholder='Address'
                          className='form-control form-control-lg'
                          type='text'
                          disabled={true}
                          maxLength={255}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='address' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='city' className='fw-bold '>
                          City
                        </label>
                        <Field
                          name='city'
                          placeholder='City'
                          className='form-control form-control-lg'
                          disabled={true}
                          maxLength={255}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='city' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='Phone No.' className='fw-bold '>
                          Phone No.
                        </label>
                        <Field
                          name='phone'
                          placeholder='Phone No.'
                          className='form-control form-control-lg'
                          disabled={true}
                          maxLength={255}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='phone' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='NTN' className='fw-bold '>
                          NTN
                        </label>
                        <Field
                          name='NTN'
                          placeholder='NTN'
                          className='form-control form-control-lg'
                          disabled={true}
                          type='text'
                          maxLength={255}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='NTN' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='STRN' className='fw-bold '>
                          STRN
                        </label>
                        <Field
                          name='STRN'
                          placeholder='STRN'
                          className='form-control form-control-lg'
                          disabled={true}
                          type='text'
                          maxLength={255}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='STRN' />
                        </div>
                      </div>
                    </div>

                    <div className='mt-10'></div>
                    <h4>Bank Details</h4>
                    <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                      <div className='col'>
                        <div className='form-group'>
                          <label htmlFor='bank.name' className='fw-bold '>
                            Bank Name
                          </label>
                          <Field
                            name='bank.name'
                            placeholder='Bank Name'
                            className='form-control form-control-lg'
                            type='text'
                            disabled={true}
                            maxLength={255}
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='bank.name' />
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='form-group'>
                          <label htmlFor='accountTitle' className='fw-bold '>
                            Account Title
                          </label>
                          <Field
                            name='bank.accountTitle'
                            placeholder='Account Title'
                            className='form-control form-control-lg'
                            disabled={true}
                            maxLength={255}
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='bank.accountTitle' />
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='form-group'>
                          <label htmlFor='IBAN No.' className='fw-bold '>
                            IBAN No.
                          </label>
                          <Field
                            name='bank.IBAN'
                            placeholder='IBAN No.'
                            className='form-control form-control-lg'
                            type='text'
                            disabled={true}
                            maxLength={255}
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='bank.IBAN' />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='mt-10'></div>
                    <h4>Evaluation</h4>
                    <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                      <div className='form-group col'>
                        <label htmlFor='productionCapactiy' className='fw-bold '>
                          Production Capactiy
                        </label>
                        <Field
                          name='evaluation.productionCapactiy'
                          placeholder='Production Capactiy'
                          className='form-control form-control-lg'
                          type='text'
                          disabled={true}
                          maxLength={255}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='evaluation.productionCapactiy' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='fbrRegistrationStatus' className='fw-bold '>
                          FBR Registration Status
                        </label>
                        <Field
                          name='evaluation.fbrRegistrationStatus'
                          placeholder='FBR Registration Status'
                          className='form-control form-control-lg'
                          disabled={true}
                          maxLength={255}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='evaluation.fbrRegistrationStatus' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='orderCapacity' className='fw-bold '>
                          Order Capacity
                        </label>
                        <Field
                          name='evaluation.orderCapacity'
                          placeholder='Order Capacity'
                          className='form-control form-control-lg'
                          type='text'
                          disabled={true}
                          maxLength={255}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='evaluation.orderCapacity' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='Market PR.' className='fw-bold '>
                          Market PR.
                        </label>
                        <Field
                          className='form-control form-control-md'
                          data-control='control'
                          disabled={true}
                          name='evaluation.marketPR'
                        ></Field>
                        <div className='text-danger'>
                          <ErrorMessage name='evaluation.marketPR' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='Customer Relation Management' className='fw-bold '>
                          Customer Relation Management
                        </label>
                        <Field
                          className='form-control form-control-md'
                          data-control='control'
                          disabled={true}
                          name='evaluation.customerRelationManagement'
                        ></Field>
                        <div className='text-danger'>
                          <ErrorMessage name='evaluation.customerRelationManagement' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='paymentsTerms' className='fw-bold '>
                          Payments Terms
                        </label>
                        <Field
                          name='evaluation.paymentsTerms'
                          placeholder='Payments Terms'
                          className='form-control form-control-lg'
                          disabled={true}
                          maxLength={255}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='evaluation.paymentsTerms' />
                        </div>
                      </div>
                    </div>

                    <FieldArray
                      name='products'
                      render={(arrayHelpers) => (
                        <div className='mt-15'>
                          <table className='table tbl-md-responsivness'>
                            <thead>
                              <tr>
                                <th>
                                  <label className='form-label '>Name</label>
                                </th>
                                <th>
                                  <label className='form-label '>Description</label>
                                </th>
                                <th>
                                  <label className='form-label'></label>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.products?.length > 0 &&
                                values.products?.map((materialItem: any, index: any) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td data-label='Name'>
                                        <Field
                                          name={`products.${index}.name`}
                                          placeholder='Name'
                                          className='form-control form-control-md'
                                          disabled={true}
                                          maxLength={255}
                                        />
                                        <div className='text-danger'>
                                          <ErrorMessage name={`products.${index}.name`} />
                                        </div>
                                      </td>

                                      <td data-label='Description'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`products.${index}.description`}
                                            placeholder='description'
                                            className='form-control form-control-md'
                                            disabled={true}
                                            maxLength={255}
                                          />
                                          <div className='text-danger'>
                                            <ErrorMessage name={`products.${index}.description`} />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    />

                    <div className='d-flex justify-content-end mt-10'>
                      <button
                        type='button'
                        className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Reject'
                        onClick={() => {
                          setShowRejectDialog(true)
                          setSupplierToReject(supplierUpdatedValues)
                        }}
                      >
                        <span className='indicator-label'>Reject</span>
                      </button>

                      <button
                        type='submit'
                        className='btn btn-lg  btn-primary me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Approve'
                        id='liveToastBtn'
                      >
                        <span className='indicator-label'>Approve</span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
