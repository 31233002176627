import React from 'react'
import {MaterialReturnAdd} from './components/MaterialReturnAdd'
import {Route, Routes} from 'react-router-dom'
import {MaterialReturns} from './components/MaterialReturns'
import {MaterialReturnApproval} from './components/MaterialReturnApproval'
import {MaterialReturnDetail} from './components/MaterialReturnDetail'

export const MaterialReturnPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<MaterialReturns className='mb-5 mb-xl-8' />} />
        <Route path='/add' element={<MaterialReturnAdd className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<MaterialReturnDetail className='mb-5 mb-xl-8' />} />
        <Route path='/:id/approve' element={<MaterialReturnApproval className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
