import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {Outwards} from './components/Outwards'
import {OutwardAdd} from './components/OutwardAdd'
import {OutwardApproval} from './components/OutwardApproval'
import {OutwardDetail} from './components/OutwardDetail'

export const OutwardFormPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Outwards className='mb-5 mb-xl-8' />} />
        <Route path='/add' element={<OutwardAdd className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<OutwardDetail className='mb-5 mb-xl-8' />} />
        <Route path='/:id/approve' element={<OutwardApproval className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
