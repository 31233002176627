import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {fetchReport} from '../redux/InventoryAction'
import {Link} from 'react-router-dom'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {
  InventoryReportSearchModelInitValues,
  InventoryReportTableModelInitValues,
} from '../models/InventoryReportModel'
import {Table} from '../../../components/ReactTable'
import {formatDate, formatDateTime, formatNumber} from '../../../helper/index'
import {Field, Form, Formik} from 'formik'
import QRCode from 'qrcode.react'
import {PDFDownloadLink} from '@react-pdf/renderer'
import QRCodeDocument from '../../../components/QRCodeDocument'
import moment from 'moment'
import {DateRangePicker} from 'rsuite'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_metronic/helpers'
import 'rsuite/dist/rsuite.css'

type Props = {
  className: string
}

export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

function addDays(date: any, days: any) {
  var today = new Date(date)
  var priorDate = new Date(new Date().setDate(today.getDate() + days))
  return priorDate
}

export const InventoryReport: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()

  const inventories: any = useSelector<RootState>(
    ({inventories}) => inventories?.inventories,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(({inventories}) => inventories.loading, shallowEqual)
  const pages: any = useSelector<RootState>(
    ({inventories}) => inventories.pagination?.pages,
    shallowEqual
  )
  const [searchParams, setSearchParams]: any = useState({})
  const [expiryDate, setExpiryDate]: any = useState([])

  const [show, setShow] = useState(false)
  const filterHandel = () => {
    setShow(!show)
  }

  const [selectedItemId, setSelectedItemId] = useState<any>(null)
  const [selectedRowData, setSelectedRowData] = useState<any>(null)
  const [showDownloadButton, setShowDownloadButton] = useState<boolean>(false)

  const handlePrintQR = (id: any, rowData: any) => {
    setSelectedItemId(id)
    setSelectedRowData(rowData)
    setShowDownloadButton(true)
  }

  useEffect(() => {
    dispatch(fetchReport(InventoryReportTableModelInitValues))
  }, [dispatch])

  const fetchData = useCallback(
    ({pageSize, pageIndex, params, sortBy}: any) => {
      const queryParams = {
        page: pageIndex + 1,
        sortBy: sortBy[0]?.id || 'createdAt',
        sortOrder: sortBy[0]?.desc === false ? 'asc' : 'desc',
        search: params,
      }
      dispatch(fetchReport(queryParams))
    },
    [searchParams]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: (cellInfo: any) => {
          if (cellInfo?.row?.original?.createdAt) {
            return formatDateTime(cellInfo?.row?.original?.createdAt)
          } else {
            return '-'
          }
        },
        show: true,
      },
      {Header: 'Inventory Type', accessor: 'type', show: true},
      {
        Header: 'Reference',
        accessor: 'reference',
        cellStyle: {textAlign: 'left'},
        show: true,
      },
      {
        Header: 'Location',
        accessor: 'location.name',
        disableSortBy: true,
        show: true,
      },
      {
        Header: 'Product',
        accessor: 'product.name',
        disableSortBy: true,
        show: true,
      },
      {
        Header: 'Product Code',
        accessor: 'product.code',
        disableSortBy: true,
        show: true,
      },
      {
        Header: 'Expiry Date',
        accessor: 'expiryDate',
        Cell: (cellInfo: any) => {
          if (cellInfo?.row?.original?.expiryDate) {
            return (
              <div
                className={
                  cellInfo?.row?.original?.expiryDate
                    ? addDays(cellInfo?.row?.original?.createdAt, 30) >=
                      new Date(cellInfo?.row?.original?.expiryDate)
                      ? 'expiry-date'
                      : ''
                    : ''
                }
              >
                {formatDate(cellInfo?.row?.original?.expiryDate)}
              </div>
            )
          } else {
            return '-'
          }
        },
        show: true,
      },
      {
        Header: 'UOM',
        accessor: 'product.UOM',
        disableSortBy: true,

        show: true,
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: (cellInfo: any) => {
          if (cellInfo?.row?.original?.quantity) {
            return formatNumber(cellInfo?.row?.original?.quantity)
          } else {
            return '0.00'
          }
        },
        show: true,
      },
      {
        Header: 'In Stock',
        accessor: 'quantityInStock',
        Cell: (cellInfo: any) => {
          if (cellInfo?.row?.original?.quantityInStock) {
            return formatNumber(cellInfo?.row?.original?.quantityInStock)
          } else {
            return '0.00'
          }
        },
        show: true,
      },

      {
        Header: 'Qr Code',
        disableSortBy: true,
        accessor: 'qrCode',
        Cell: (cellInfo: any) => {
          if (cellInfo?.row?.original?.qrCode && cellInfo?.row?.original?.productCode) {
            return (
              <div
                style={{
                  height: 'auto',
                  margin: '0 auto',
                  maxWidth: 64,
                  width: '100%',
                }}
              >
                <QRCode
                  id={`${cellInfo?.row?.original?.qrCode}`}
                  size={144}
                  bgColor='#FFF'
                  fgColor='#000'
                  includeMargin
                  level={'H'}
                  style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                  value={cellInfo?.row?.original?.qrCode}
                />
              </div>
            )
          } else {
            return '-'
          }
        },
        show: true,
      },
      {
        Header: 'Print Qr Code',
        disableSortBy: true,
        accessor: 'download qrCode',
        Cell: (cellInfo: any) => {
          if (cellInfo?.row?.original?.qrCode && cellInfo?.row?.original?.productCode) {
            const isCurrentRowSelected = cellInfo?.row?.original?._id === selectedItemId

            return (
              <button
                id={`${cellInfo?.row?.original?.qrCode}`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Download PDF'
                onClick={() => handlePrintQR(cellInfo?.row?.original?._id, cellInfo?.row?.original)}
              >
                {isCurrentRowSelected && showDownloadButton ? (
                  <PDFDownloadLink
                    document={
                      <QRCodeDocument qrCodeData={[selectedRowData] ?? []} comp='inventoryReport' />
                    }
                    fileName={`InventoryReport-${moment(new Date()).format('YYYYMMDD')}.pdf`}
                  >
                    {({loading}) =>
                      loading ? (
                        <KTSVG
                          path='/media/icons/duotune/art/loadingicon.svg'
                          className='inventory-report-svg-icon'
                        />
                      ) : (
                        <KTSVG
                          path='/media/icons/duotune/art/downloadicon.svg'
                          className='inventory-report-svg-icon'
                        />
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <KTSVG
                    path='/media/icons/duotune/art/printer.svg'
                    className='inventory-report-svg-icon'
                  />
                )}
              </button>
            )
          } else {
            return '-'
          }
        },
        show: true,
      },
    ],
    [showDownloadButton, selectedRowData, selectedItemId]
  )

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Inventory Report', url: '/inventory'},
  ]

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Inventory Report </span>
          </h1>
        </div>
        <div className='card-body'>
          <div
            className={`d-flex align-items-center ${
              !show ? 'justify-content-end' : 'justify-content-between'
            }`}
          >
            <p className='my-0 me-3' style={{color: '#004B87', fontSize: '14px'}}>
              <b>Search Filters</b>
            </p>
            <span onClick={filterHandel}>
              <img src='/media/svg/icons/General/filter.svg' alt='' />
            </span>
          </div>
          {show ? (
            <Formik
              initialValues={InventoryReportSearchModelInitValues}
              validationSchema={Yup.object({
                product: Yup.string(),
                expiryDate: Yup.array(Yup.string()),
                type: Yup.string(),
                quantity: Yup.string(),
                quantityInStock: Yup.string(),
              })}
              onSubmit={(values: any) => {
                if (values.type === 'Inventory Type') {
                  values.type = ''
                }
                setSearchParams(values)
              }}
            >
              {({values, setFieldValue}) => (
                <Form>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5 mt-1'>
                    <div className='form-group col'>
                      <label htmlFor='product' className='fw-bold'>
                        Product
                      </label>
                      <Field
                        name='product'
                        placeholder='Product'
                        className='form-control form-control-lg'
                      />
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='productCode' className='fw-bold'>
                        Product Code
                      </label>
                      <Field
                        name='productCode'
                        placeholder='Product Code'
                        className='form-control form-control-lg'
                      />
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='type' className='fw-bold'>
                        Inventory Type
                      </label>
                      <Field
                        as='select'
                        className='form-select form-select-md'
                        data-control='select2'
                        name='type'
                      >
                        <option>Inventory Type</option>
                        <option value='INCREASE ADJUSTMENT'>INCREASE ADJUSTMENT</option>
                        <option value='DECREASE ADJUSTMENT'>DECREASE ADJUSTMENT</option>
                        <option value='MATERIAL ISSUANCE'>MATERIAL ISSUANCE</option>
                        <option value='GOODS RECEIVED'>GOODS RECEIVED</option>
                        <option value='MATERIAL RETURN'>MATERIAL RETURN</option>
                        <option value='PURCHASE RETURN'>PURCHASE RETURN</option>
                      </Field>
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='quantity' className='fw-bold'>
                        Quantity
                      </label>
                      <Field
                        name='quantity'
                        placeholder='Quantity'
                        className='form-control form-control-lg'
                      />
                    </div>
                  </div>

                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5 mt-1'>
                    <div className='form-group col'>
                      <label htmlFor='quantityInStock' className='fw-bold'>
                        Quantity In Stock
                      </label>
                      <Field
                        name='quantityInStock'
                        placeholder='Quantity In Stock'
                        className='form-control form-control-lg'
                      />
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='expiryDate' className='fw-bold'>
                        Expiry Date
                      </label>
                      <DateRangePicker
                        value={expiryDate}
                        block
                        name='expiryDate'
                        cleanable={true}
                        size='md'
                        appearance='default'
                        character='-'
                        showOneCalendar
                        placeholder='Expiry Date'
                        format='dd/MM/yyyy'
                        onClean={(value: any) => {
                          setFieldValue('expiryDate', '')
                        }}
                        onChange={(value: any) => {
                          setFieldValue('expiryDate', value)
                          setExpiryDate(value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-end mt-10'>
                    <Link
                      to='/inventory/report'
                      className='btn btn-sm btn-light-primary me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancel'
                      onClick={() => {
                        setSearchParams(InventoryReportSearchModelInitValues)
                        setExpiryDate([])
                        setFieldValue('product', '')
                        setFieldValue('productCode', '')
                        setFieldValue('type', '')
                        setFieldValue('quantity', '')
                        setFieldValue('quantityInStock', '')
                      }}
                    >
                      <span className='indicator-label'>Cancel</span>
                    </Link>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Search'
                      id='liveToastBtn'
                    >
                      <span className='indicator-label'>Search</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}
          <div className='d-flex mt-5 '>
            <div className='react-table-wrapper' style={{width: '100%'}}>
              <Table
                columns={columns}
                data={inventories}
                params={searchParams}
                pageCount={pages}
                fetchData={fetchData}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
