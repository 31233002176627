/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import * as actions from '../redux/BudgetAction'
import {Budget} from '../models/BudgetModel'
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {deepCopy} from '../../../helper'

interface Props {
  show: boolean
  handleClose: () => void
  budget: Budget
}

export const BudgetRejectConfirmModal: FC<Props> = ({show, handleClose, budget}) => {
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const dispatch: any = useDispatch()

  const rejectBudget = (values: any) => {
    setLoading(true)
    dispatch(actions.reject(budget?._id, values))
      .then((response: any) => {
        toast.success(response)
        setLoading(false)
        navigate('/budgets')
      })
      .catch((error: any) => {
        toast.error(error)
        setLoading(false)
      })
    handleClose()
  }
  const createBudgetSchema = Yup.object({
    reason: Yup.string().required('Reason is required'),
  })

  const initialValuesofReason: any = {
    reason: '',
  }
  return (
    <>
      <Modal
        id='kt_modal_delete_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered mw-500px h-auto'
        show={show}
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Reject Budget</h2>
          </div>

          <div className='modal-body flex-row-fluid py-lg-10 px-lg-10'>
            <p className='d-flex align-items-center fs-5 fw-bold'>
              Are you sure you want to reject this budget ({budget?.title})?
            </p>

            <Formik
              validationSchema={createBudgetSchema}
              initialValues={initialValuesofReason}
              onSubmit={(values: any) => {
                let form: any = deepCopy(budget)
                form.reason = values.reason
                rejectBudget(form)
              }}
            >
              {({values}) => (
                <Form className='form' noValidate id='kt_modal_create_app_form'>
                  <div className='d-flex flex-stack pt-5'>
                    <div className='w-100'>
                      <div className='form-group'>
                        <Field
                          name='reason'
                          className='form-control form-control-lg'
                          placeholder='Reason to reject'
                          maxLength={255}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='reason' />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-stack mt-5'>
                    <div className='me-2'>
                      <button
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        onClick={() => {
                          handleClose()
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button type='submit' className='btn btn-lg btn-primary' disabled={loading}>
                        <span className='indicator-label'>
                          {loading ? 'Rejecting...' : 'Reject'}
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  )
}
