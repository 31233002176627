import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify-token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const FORGOT_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const UPDATE_PROFILE_URL = `${API_URL}/users/update`
export const UPDATE_SETTINGS_URL = `${API_URL}/users/update-settings`

export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

export function register(email: string, firstName: string, lastName: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstName,
    lastName,
    password,
  })
}

export function forgotPassword(email: string) {
  return axios.post<{success: boolean; message: string}>(FORGOT_PASSWORD_URL, {
    email: email,
  })
}

export function resetPassword(password: string, token: string) {
  return axios.post<{success: boolean; message: string}>(`${RESET_PASSWORD_URL}`, {
    password,
    token,
  })
}

export function getUserByToken() {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function updateUser(data: UserModel) {
  return axios.patch<UserModel>(UPDATE_PROFILE_URL, data)
}

export const updateSettings = (data: any) => async () => {
  try {
    const res = await axios.patch(UPDATE_SETTINGS_URL, data)
    return res
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const generateAppToken = (user: any, id: string) => async () => {
  try {
    const data = {
      application: user.application,
    }
    const res = await axios.post(`${API_URL}/auth/${id}/application-token`, data)
    return res
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}
