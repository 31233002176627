export interface AssetModel {
  _id: string
  number: string
  grn?: string | null
  product: string
  quantity: number
  location: string
  marketValue: number | string
}

export const AssetInitValues: AssetModel = {
  _id: '',
  number: '',
  grn: null,
  product: '',
  quantity: 1,
  location: '',
  marketValue: '',
}

export interface AssetSearchModel {
  number: string
  quantity: string
  disposal: any
  marketValue: string
}
export const AssetSearchModelInitValues: AssetSearchModel = {
  number: '',
  quantity: '',
  disposal: '',
  marketValue: '',
}

export interface AssetTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: AssetSearchModel
}

export const AssetTableModelInitValues: AssetTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: AssetSearchModelInitValues,
}
