import axios from 'axios'
import {UserModel, UserTableModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/users`
const ROLE_URL = `${API_URL}/roles`

export function findAll(params: UserTableModel) {
  return axios.get(USER_URL, {params})
}

export function findOne(id: string) {
  return axios.get(`${USER_URL}/${id}`)
}

export function create(data: UserModel) {
  return axios.post(USER_URL, data)
}

export function updateAccess(_id: string, values: string) {
  return axios.patch(`${USER_URL}/${_id}/update-access`, values)
}

export function findAllRole() {
  return axios.get(ROLE_URL)
}
