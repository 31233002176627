import clsx from 'clsx'
import moment from 'moment'
import {FC, useEffect, useRef, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {RootState} from '../../../../setup'
import * as actions from '../../../../app/modules/notification/redux/NotificationAction'

const NotificationUserMenu: FC = () => {
  const dispatch = useDispatch()

  const notification: any = useSelector<RootState>(
    ({notification}) => notification.notification,
    shallowEqual
  )

  const auth: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  useEffect(() => {
    let notificationInterval: any
    if (auth && auth?.settings?.appNotification) {
      getNotifications()
      notificationInterval = setInterval(() => {
        getNotifications()
      }, 10000)
    } else {
      clearInterval(notificationInterval)
    }

    return () => {
      clearInterval(notificationInterval)
      dispatch(actions.clearNotification())
    }
  }, [auth])

  const getNotifications = () => {
    if (auth) {
      dispatch(actions.fetchAll())
    }
  }
  const markAsRead = (notification: any) => {
    const unSeenNotification = notification?.filter((noti: any) => !noti.read)
    const ids = unSeenNotification.map((noti: any) => noti._id)
    dispatch(actions.markAsRead(ids))
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div className='d-flex flex-column rounded-top' style={{backgroundColor: '#004b87'}}>
        <h3 className='text-white fw-bold px-9 mt-5 mb-5'>
          Notifications{' '}
          <span className='fs-4'>
            {notification?.unSeenCount > 0 && `(${notification?.unSeenCount})`}
          </span>
          {/* {notification?.data?.length > 0 && (
            <span className='fs-8 opacity-75 ps-3'>{notification?.length} New *</span>
          )} */}
          {notification?.unSeenCount > 0 && (
            <Link
              className='fs-8  ps-3 text-white opacity-75 text-hover-white text-hover-opacity-100'
              onClick={() => {
                markAsRead(notification?.data)
              }}
              style={{float: 'right', marginTop: '5px'}}
              to='#'
            >
              Mark all as read
            </Link>
          )}
        </h3>
      </div>

      <div className='tab-content'>
        <div className={'tab-pane fade show active'} id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px my-2 px-2'>
            {notification?.data?.length > 0 ? (
              notification?.data?.map((notification: any, index: number) => {
                return (
                  <Link
                    key={`alert${index}`}
                    className='mt-3 d-flex flex-stack py-2 px-2 my-1 '
                    style={{
                      backgroundColor: notification.read
                        ? 'rgba(235, 235, 235,0.9)'
                        : 'rgba(200, 247, 197)',
                      borderRadius: 5,
                    }}
                    to={notification?.actionUrl}
                  >
                    <div
                      className='mt-1 d-flex align-items-center'
                      onClick={() => {
                        dispatch(actions.markAsRead(notification))
                      }}
                    >
                      <div title='Profile' className={'cursor-pointer symbol pe-3'}>
                        <img
                          className='rounded-circle'
                          src={toAbsoluteUrl('/media/avatars/blank.png')}
                          alt='profile_picture'
                          style={{height: 40, width: 40}}
                        />
                      </div>

                      <div className='mb-0 me-2' style={{lineHeight: 1.2}}>
                        <div className='text-gray-700 fs-7'>{notification?.subject}</div>
                      </div>

                      <span
                        className={clsx(
                          'm-1 badge fs-8 ',
                          `${!notification.seen ? 'badge-light-primary' : 'badge-light'}`
                        )}
                      >
                        {moment(notification.createdAt).fromNow()}
                      </span>
                    </div>
                  </Link>
                )
              })
            ) : (
              <div className='d-flex flex-column align-items-center'>
                <h4 className='text-black fw-bold mt-15 mb-15'>No notifications</h4>
              </div>
            )}
          </div>
          <div className='d-none py-1 text-center border-top'>
            <Link
              to='/crafted/pages/profile'
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              View All{' '}
              <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export {NotificationUserMenu}
