import {LocationModel, LocationInitValues} from './../models/LocationModel'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface LocationState {
  loading: boolean
  location: LocationModel
  locations: [LocationModel?]
  pagination: {total: number; pages: number}
}

const initialState: LocationState = {
  loading: false,
  location: LocationInitValues,
  locations: [],
  pagination: {total: 0, pages: 0},
}

export const LocationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setLocations: (state: {locations: any}, action: PayloadAction<any>) => {
      state.locations = action.payload.locations
    },
    setLocation: (state: {location: any}, action: PayloadAction<any>) => {
      state.location = action.payload.location
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
