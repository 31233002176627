/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik} from 'formik'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {toast} from 'react-toastify'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {UserModel, UserTableModelInitValues} from '../models/UserModel'
import {updateAccess} from '../redux/UserAction'

interface Props {
  show: boolean
  handleClose: () => void
  user: UserModel
}

export const UserEdit: FC<Props> = ({show, handleClose, user}) => {
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const roles: any = useSelector<RootState>(({roles}) => roles.roles, shallowEqual)
  const dispatch: any = useDispatch()
  let roleOptions = roles?.map((role: any) => {
    return {
      value: role._id,
      label: role.name,
    }
  })

  const animatedComponents = makeAnimated()

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Edit User Role</h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body flex-row py-lg-10 px-lg-10'>
          <Formik
            initialValues={user}
            enableReinitialize
            onSubmit={(values: any) => {
              setLoading(true)
              dispatch(updateAccess(user?._id, values, UserTableModelInitValues))
                .then((response: any) => {
                  toast.success(response)
                  navigate('/users')
                  setLoading(false)
                  handleClose()
                })
                .catch((error: any) => {
                  toast.error(error)
                  setLoading(false)
                })
            }}
          >
            {({values, setFieldValue, errors}) => (
              <Form className='form' noValidate id='kt_modal_create_app_form'>
                <div className='row'>
                  <div className='form-group col-6 mt-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className=''>First Name</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg'
                      name='firstName'
                      disabled={true}
                    />
                  </div>

                  <div className='form-group col-6 mt-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className=''>Last Name</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg'
                      name='lastName'
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='form-group col-12 mt-5'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className=''>Email</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-lg'
                    name='email'
                    disabled={1 ? true : false}
                  />
                </div>
                <div className='form-group col-12 mt-5'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span>Role</span>
                  </label>
                  <Field name='role._id' type='hidden' />
                  <Select
                    options={roleOptions}
                    placeholder='Select'
                    name='roles'
                    className='multi-select-container w-100'
                    classNamePrefix='multi-select'
                    isMulti={false}
                    defaultValue={values.roles.map((role: any) => {
                      return {label: role.name, value: role._id}
                    })}
                    onChange={(e: any) => {
                      {
                        setFieldValue(`roles`, [e.value])
                      }
                    }}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                  />
                </div>

                <div className='mt-5 col-md-4 pb-5'>
                  <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                    <label className='fs-5 fw-bold mb-2' title='Disable'>
                      Disable
                    </label>
                    <Field
                      title='Disable'
                      className='form-check-input mx-5 border border-dark'
                      type='checkbox'
                      name='disable'
                    />
                  </div>
                </div>

                <div className='d-flex flex-stack pt-5'>
                  <div className='me-2'></div>
                  <div>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary me-3'
                      disabled={loading}
                    >
                      <span className='indicator-label'>
                        Submit
                        <KTSVG
                          path='/media/icons/duotune/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
