interface MaterialInspectionItem {
  _id: string
  product: string
  name: string
  UOM: string
  quantity: number | string
  rate: number | string
  price: number | string
  tax: number | string
  priceInclusiveTax: number | string
  discount: number | string
  total: number | string
  remarks: string
}

export interface MaterialInspectionModel {
  _id: string
  invoiceNo: string
  number: string
  location: string
  createdBy: string
  createdAt: string
  approvedAt: string
  approvedBy: string
  rejectedBy: string
  rejectedAt: string
  rejectionReason: string
  purchaseOrder: string
  totalPrice: number | string
  totalTax: number | string
  totalDiscount: number | string
  totalAmount: number | string
  items: [MaterialInspectionItem]
}

export const MaterialInspectionInitValues: MaterialInspectionModel = {
  _id: '',
  invoiceNo: '',
  number: '',
  location: '',
  createdBy: '',
  createdAt: '',
  approvedAt: '',
  approvedBy: '',
  rejectedBy: '',
  rejectedAt: '',
  rejectionReason: '',
  purchaseOrder: '',
  totalPrice: '',
  totalTax: '',
  totalDiscount: '',
  totalAmount: '',
  items: [
    {
      _id: '',
      product: '',
      name: '',
      UOM: '',
      quantity: '',
      rate: '',
      price: '',
      tax: '',
      priceInclusiveTax: '',
      discount: '',
      total: '',
      remarks: '',
    },
  ],
}

export interface MaterialInspectionSearchModel {
  number: string
  status: string
  createdAt: any
  approvedBy: string
  approvedAt: any
}
export const MaterialInspectionSearchModelInitValues: MaterialInspectionSearchModel = {
  number: '',
  status: '',
  createdAt: [],
  approvedBy: '',
  approvedAt: [],
}

export interface MaterialInspectionTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: MaterialInspectionSearchModel
}

export const MaterialInspectionTableModelInitValues: MaterialInspectionTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: MaterialInspectionSearchModelInitValues,
}
