import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState: any = {
  data: {},
}

export const SettingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSetting: (state, action: PayloadAction<{name: string; setting: object}>) => {
      const {name, setting} = action.payload
      state.data[name] = setting
    },
  },
})
