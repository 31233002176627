import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {GoodsReceivedNotes} from './components/GoodsReceivedNotes'
import {GoodsReceivedNoteAdd} from './components/GoodsReceivedNoteAdd'
import {GoodsReceivedNoteApproval} from './components/GoodsReceivedNoteApproval'
import {GoodsReceivedNoteDetail} from './components/GoodsReceivedNoteDetail'

export const GoodsReceivedNotePage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<GoodsReceivedNotes className='mb-5 mb-xl-8' />} />
        <Route path='/add' element={<GoodsReceivedNoteAdd className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<GoodsReceivedNoteDetail className='mb-5 mb-xl-8' />} />
        <Route
          path='/:id/approve'
          element={<GoodsReceivedNoteApproval className='mb-5 mb-xl-8' />}
        />
      </Routes>
    </>
  )
}
