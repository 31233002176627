import React, {Fragment, useMemo, useEffect, useState} from 'react'
import {useTable, usePagination, useExpanded, useSortBy} from 'react-table'
import {Loader} from './Loader'
import {NoItemFound} from './NoItemFound'

type Props = {
  columns: any
  data: any
  params: any
  fetchData: any
  pageCount: any
  loading: any
  isPaginated?: any
}
export const Table: React.FC<Props> = ({
  columns,
  data,
  params,
  fetchData,
  pageCount: controlledPageCount,
  loading,
}) => {
  const defaultColumn = useMemo(() => ({}), [])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex, pageSize, sortBy},
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
        hiddenColumns: columns
          ?.filter((column: any) => !column?.show)
          ?.map((column: any) => column?.id),
      },
      manualPagination: true,
      autoResetPage: true,
      pageCount: controlledPageCount,
      manualSortBy: true,
    },
    useSortBy,
    useExpanded,
    usePagination
  )

  useEffect(() => {
    fetchData && fetchData({pageIndex, pageSize, params, sortBy})
  }, [fetchData, pageIndex, pageSize, sortBy, params])

  useEffect(() => {
    gotoPage(0)
  }, [gotoPage, params])

  return (
    <Fragment>
      <div className='table-responsive'>
        {loading ? (
          <Loader />
        ) : (
          <table
            {...getTableProps()}
            className='table table-striped'
            style={{width: 'unset', minWidth: '100%'}}
          >
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr
                  style={{borderBottom: '1px solid #E2E2E2'}}
                  {...headerGroup?.getHeaderGroupProps()}
                >
                  {headerGroup?.headers?.map((column) => (
                    <th
                      className='whiteSpacesClass'
                      {...column.getHeaderProps(column?.getSortByToggleProps())}
                    >
                      <b className='pe-2'>{column?.render('Header')}</b>

                      <span className='pl-5'>
                        {column?.isSorted ? (
                          column?.isSortedDesc ? (
                            <i className='bi bi-chevron-up'></i>
                          ) : (
                            <i className='bi bi-chevron-down'></i>
                          )
                        ) : column?.canSort ? (
                          <i className='bi bi-chevron-expand'></i>
                        ) : null}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {data?.length > 0 ? (
              <tbody {...getTableBodyProps()}>
                {page?.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr className='tableHover' {...row?.getRowProps()}>
                      {row?.cells?.map((cell, i) => {
                        return <td {...cell?.getCellProps()}>{cell?.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()}>
                <tr>
                  <td colSpan={columns?.length}>
                    <NoItemFound />
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        )}
        <div className='mt-10 mb-5'>
          <div className='d-flex align-items-center'>
            <span>
              Page {'   '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <div className='col-5 ms-5'>
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {'<<'}
              </button>
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {'<'}
              </button>
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {'>'}
              </button>
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {'>>'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
