import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {formatDateTime, formatNumber} from '../../../helper'
import {PrintButton} from '../../../components/PrintButton'
import * as InwardAction from '../../inward/redux/InwardAction'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const InwardDetail: React.FC<Props> = ({className}) => {
  const inward: any = useSelector<RootState>(({inwards}) => inwards.inward, shallowEqual)

  const loading: any = useSelector<RootState>(({inwards}) => inwards.loading, shallowEqual)

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(InwardAction.fetchOne(id))
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Inwards List', url: '/inward'},
    {name: 'Inward Approval', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Inward Detail</span>
          </h1>
          <PrintButton />
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            inward && inward?._id !== '' ? (
              <>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Inward No.</b>
                      </td>
                      <td>{inward?.number}</td>
                      <td>
                        <b>Created Date</b>
                      </td>
                      <td>{formatDateTime(inward?.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Outward No.</b>
                      </td>
                      <td>{inward?.outward?.number}</td>
                      <td>
                        <b>Provided to Employee Name</b>
                      </td>
                      <td>{inward?.providedToEmployee?.fullName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Provided to Department</b>
                      </td>
                      <td>{inward?.providedToDepartment?.name}</td>
                      <td>
                        <b>Location</b>
                      </td>
                      <td>{inward?.location?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Status</b>
                      </td>
                      <td>{inward?.status}</td>
                      <td colSpan={2}></td>
                    </tr>
                    {inward?.approvedAt && inward?.approvedBy && (
                      <tr>
                        <td>
                          <b>Approved At</b>
                        </td>
                        <td>{formatDateTime(inward?.approvedAt)}</td>
                        <td>
                          <b>Approved By</b>
                        </td>
                        <td>{inward?.approvedBy?.fullName}</td>
                      </tr>
                    )}
                    {inward?.rejectedAt && inward?.rejectedBy && (
                      <tr>
                        <td>
                          <b>Rejected At</b>
                        </td>
                        <td>{formatDateTime(inward?.rejectedAt)}</td>
                        <td>
                          <b>Rejected By</b>
                        </td>
                        <td>{inward?.rejectedBy?.firstName}</td>
                      </tr>
                    )}
                    {inward?.rejectionReason && (
                      <tr>
                        <td>
                          <b>Reason to Reject</b>
                        </td>
                        <td colSpan={3}>{inward?.rejectionReason}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                  <thead>
                    <tr>
                      <th style={{width: 40}}>
                        <b>#</b>
                      </th>
                      <th>
                        <b>Product</b>
                      </th>
                      <th>
                        <b>UOM</b>
                      </th>
                      <th>
                        <b>Quantity</b>
                      </th>
                      <th>
                        <b>Asset No.</b>
                      </th>
                      <th>
                        <b>Remarks</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inward.items.map((materialItem: any, index: any) => (
                      <tr key={index}>
                        <td data-label='#'>{index + 1}</td>
                        <td data-label='Name'>{materialItem?.name}</td>
                        <td data-label='UOM'>{materialItem?.UOM}</td>
                        <td data-label='Quantity'>{formatNumber(materialItem?.quantity)}</td>
                        <td data-label='Asset No.'>{materialItem?.assetNumber}</td>
                        <td data-label='Remarks '>{materialItem?.remarks}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
