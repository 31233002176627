import axios from 'axios'
import {
  PurchaseOrderReturnModel,
  PurchaseOrderReturnTableModel,
} from '../models/PurchaseOrderReturnModel'

const API_URL = process.env.REACT_APP_API_URL
const PURCHASE_ORDER_Return_URL = `${API_URL}/purchase-order-returns`

export function findAll(params: PurchaseOrderReturnTableModel) {
  return axios.get(`${PURCHASE_ORDER_Return_URL}`, {params})
}

export function findOne(id: any) {
  return axios.get(`${PURCHASE_ORDER_Return_URL}/${id}`)
}

export function create(data: PurchaseOrderReturnModel) {
  return axios.post(PURCHASE_ORDER_Return_URL, data)
}

export function approve(id: any) {
  return axios.patch(`${PURCHASE_ORDER_Return_URL}/${id}/approve`)
}

export function reject(id: any, data: any) {
  return axios.patch(`${PURCHASE_ORDER_Return_URL}/${id}/reject`, data)
}

export function remove(id: any) {
  return axios.delete(`${PURCHASE_ORDER_Return_URL}/${id}`)
}

export function upload(id: any, data: any) {
  const formData = new FormData()
  data?.map((file: any, index: number) => {
    formData.append('attachments', file)
  })
  formData.append('purchaseOrderReturn', id)

  return axios({
    method: 'POST',
    url: `${PURCHASE_ORDER_Return_URL}/upload`,
    data: formData,
    headers: {'Content-Type': 'multipart/form-data'},
  })
}
