import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  UserInitValues,
  UserModel,
  UserSearchModelInitValues,
  UserTableModelInitValues,
} from '../models/UserModel'
import {UserEdit} from './UserEdit'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import * as actions from '../../roles/redux/RoleAction'
import {fetchAll} from '../redux/UserAction'
import {Can} from '../../../rbac/Can'
import {Table} from '../../../components/ReactTable'
import {formatDateTime} from '../../../helper/index'
import {Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import 'rsuite/dist/rsuite.css'
import {Link} from 'react-router-dom'
import {RoleTableModelInitValues} from '../../roles/models/RoleModel'
import {ApplicationToken} from './ApplicationToken'

type Props = {
  className: string
}
export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const Users: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [userToEdit, setUserToEdit] = useState<UserModel>(UserInitValues)

  const [showApplicationTokenDialog, setShowApplicationTokenDialog] = useState(false)
  const [generateAppToken, setGenerateAppToken] = useState<UserModel>(UserInitValues)

  const users: any = useSelector<RootState>(({users}) => users.users, shallowEqual)
  const pages: any = useSelector<RootState>(({users}) => users.pagination?.pages, shallowEqual)

  const [searchParams, setSearchParams]: any = useState({})

  const [show, setShow] = useState(false)
  const filterHandel = () => {
    setShow(!show)
  }
  useEffect(() => {
    dispatch(fetchAll(UserTableModelInitValues))
    dispatch(actions.findAll(RoleTableModelInitValues))
  }, [dispatch])

  const loading: any = useSelector<RootState>(({users}) => users.loading, shallowEqual)

  const fetchData = useCallback(
    ({pageSize, pageIndex, params, sortBy}: any) => {
      const queryParams = {
        page: pageIndex + 1,
        sortBy: sortBy[0]?.id || 'createdAt',
        sortOrder: sortBy[0]?.desc === false ? 'asc' : 'desc',
        search: params,
      }
      dispatch(fetchAll(queryParams))
    },
    [searchParams]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: (cellInfo: any) => {
          if (cellInfo?.row?.original?.createdAt) {
            return formatDateTime(cellInfo?.row?.original?.createdAt)
          } else {
            return '-'
          }
        },
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'roles.name',
        Cell: (params: any) => {
          if (!params?.row?.original?.roles[0]?.name) return '-'
          return params?.row?.original?.roles[0]?.name
        },
        disableSortBy: true,
      },
      {
        Header: 'Actions',
        Cell: (params: any) => (
          <Can do='update' on='users'>
            <div className='action-btn-wrapper d-flex flex-shrink-0'>
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title='Edit User'
                onClick={(e) => {
                  setUserToEdit(params?.row?.original)
                  setShowEditDialog(true)
                }}
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button>

              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title='Generate Token'
                onClick={(e) => {
                  setGenerateAppToken(params?.row?.original)
                  setShowApplicationTokenDialog(true)
                }}
              >
                <KTSVG path='/media/icons/duotune/general/gen058.svg' className='svg-icon-3' />
              </button>
            </div>
          </Can>
        ),
      },
    ],
    []
  )

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Users List', url: '/users'},
  ]

  return (
    <>
      <UserEdit
        show={showEditDialog}
        handleClose={() => {
          setShowEditDialog(false)
        }}
        user={userToEdit}
      />

      <ApplicationToken
        show={showApplicationTokenDialog}
        handleClose={() => {
          setShowApplicationTokenDialog(false)
        }}
        user={generateAppToken}
      />

      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Users List</span>
          </h1>
        </div>
        <div className='card-body'>
          <div
            className={`d-flex align-items-center ${
              !show ? 'justify-content-end' : 'justify-content-between'
            }`}
          >
            <p className='my-0 me-3' style={{color: '#004B87', fontSize: '14px'}}>
              <b>Search Filters</b>
            </p>
            <span onClick={filterHandel}>
              <img src='/media/svg/icons/General/filter.svg' alt='' />
            </span>
          </div>
          {show ? (
            <Formik
              initialValues={UserSearchModelInitValues}
              validationSchema={Yup.object({
                firstName: Yup.string(),
                lastName: Yup.string(),
                email: Yup.string(),
              })}
              onSubmit={(values: any) => {
                setSearchParams(values)
              }}
            >
              {({values, setFieldValue}) => (
                <Form>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                    <div className='form-group col'>
                      <label htmlFor='firstName' className='fw-bold'>
                        FirstName
                      </label>
                      <Field
                        name='firstName'
                        placeholder='First Name'
                        className='form-control form-control-lg'
                      />
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='lastName' className='fw-bold'>
                        Last Name
                      </label>
                      <Field
                        name='lastName'
                        placeholder='Last Name'
                        className='form-control form-control-lg'
                      />
                    </div>
                  </div>

                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5 mt-1'>
                    <div className='form-group col'>
                      <label htmlFor='email' className='fw-bold'>
                        Email
                      </label>
                      <Field
                        name='email'
                        placeholder='Email'
                        className='form-control form-control-lg'
                      />
                    </div>
                  </div>

                  <div className='d-flex justify-content-end mt-10'>
                    <Link
                      to='/users'
                      className='btn btn-sm btn-light-primary me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancel'
                      onClick={() => {
                        setSearchParams(UserSearchModelInitValues)
                        setFieldValue('firstName', '')
                        setFieldValue('lastName', '')
                        setFieldValue('email', '')
                      }}
                    >
                      <span className='indicator-label'>Cancel</span>
                    </Link>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Search'
                      id='liveToastBtn'
                    >
                      <span className='indicator-label'>Search</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}
          <div className='d-flex mt-5 '>
            <div className='react-table-wrapper' style={{width: '100%'}}>
              <Table
                columns={columns}
                data={users}
                params={searchParams}
                pageCount={pages}
                fetchData={fetchData}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
