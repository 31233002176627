import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'
import {RootState} from '../../../../setup'
import {formatDateTime, formatMoney, formatNumber} from '../../../helper/index'
import * as SupplierBillAction from '../redux/SupplierBillAction'
import * as GoodsReceivedNoteAction from '../../goods-received-note/redux/GoodsReceivedNoteAction'
import {SupplierBillInitValues, SupplierBillModel} from '../models/SupplierBillModel'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'

type Props = {
  className: string
}

export const SupplierBillAdd: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(false)
  const goodsReceivedNotes: any = useSelector<RootState>(
    ({goodsReceivedNotes}) => goodsReceivedNotes.goodsReceivedNotes,
    shallowEqual
  )

  const animatedComponents = makeAnimated()
  const [supplierBill, setSupplierBill] = useState<SupplierBillModel>(SupplierBillInitValues)

  const [grn, setGRN]: any = useState({})
  const [por, setPOR]: any = useState({})

  const dispatch: any = useDispatch()
  let navigate = useNavigate()
  useEffect(() => {
    dispatch(GoodsReceivedNoteAction.fetchApproved())
  }, [dispatch])

  const onChangeGoodsReceivedNote = (e: any, values: any) => {
    const selectedGRN: any = goodsReceivedNotes.find((r: any) => r._id === e.value)
    setGRN(selectedGRN)

    let uSupplierBill = JSON.parse(JSON.stringify(values))

    if (selectedGRN) {
      const selectedPOR = selectedGRN?.purchaseOrderReturn[0]
        ? selectedGRN?.purchaseOrderReturn[0]
        : {
            number: '',
            totalDiscount: '',
            totalAmount: '',
            totalTax: '',
            totalPrice: '',
            items: [],
          }

      setPOR(selectedPOR)
      uSupplierBill.goodsReceivedNote = selectedGRN._id
      uSupplierBill.purchaseOrder = selectedGRN.purchaseOrder
      uSupplierBill.purchaseOrderReturn = selectedPOR
      uSupplierBill.deliveryChallanNumber = selectedGRN.deliveryChallanNumber

      uSupplierBill.totalPrice = formatNumber(selectedGRN?.totalPrice - selectedPOR?.totalPrice)
      uSupplierBill.totalTax = formatNumber(selectedGRN?.totalTax - selectedPOR?.totalTax)
      uSupplierBill.totalDiscount = formatNumber(
        selectedGRN?.totalDiscount - selectedPOR?.totalDiscount
      )
      uSupplierBill.totalAmount = formatMoney(selectedGRN?.totalAmount - selectedPOR?.totalAmount)
      uSupplierBill.items = []

      selectedGRN?.items.forEach((grnItem: any, i: any) => {
        const matchedItem = selectedPOR?.items.find((porItem: any) => porItem._id === grnItem._id)

        const porItem = matchedItem
          ? matchedItem
          : {
              quantity: '',
              price: '',
              tax: '',
              priceInclusiveTax: '',
              discount: '',
              total: '',
            }

        uSupplierBill.items[i] = {
          product: grnItem.product,
          _id: grnItem._id,
          name: grnItem.name,
          UOM: grnItem.UOM,
          quantity: formatNumber(grnItem.quantity - porItem?.quantity),
          rate: formatNumber(grnItem.rate),
          price: formatNumber(grnItem.price - porItem?.price),
          tax: formatNumber(grnItem.tax - porItem?.tax),
          priceInclusiveTax: formatNumber(grnItem.priceInclusiveTax - porItem?.priceInclusiveTax),
          discount: formatNumber(grnItem.discount - porItem?.discount),
          total: formatNumber(grnItem.total - porItem?.total),
        }
      })

      setSupplierBill(uSupplierBill)
    }
  }

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Supplier Bills List', url: '/supplier-bills'},
    {name: 'Supplier Bill Form', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3'>Supplier Bill Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          <Formik
            enableReinitialize
            initialValues={supplierBill}
            validationSchema={Yup.object({
              goodsReceivedNote: Yup.string().required('Goods Received Note No is required'),
            })}
            onSubmit={(values: any) => {
              setLoading(true)
              let purchaseOrder = values.purchaseOrder._id
              let purchaseOrderReturn = values?.purchaseOrderReturn._id
              let totalAmount = formatNumber(grn?.totalAmount - por?.totalAmount)

              const data = {
                ...values,
                purchaseOrder,
                purchaseOrderReturn,
                totalAmount,
              }

              dispatch(SupplierBillAction.create(data))
                .then((response: string) => {
                  toast.success(response)
                  setLoading(true)
                  navigate('/supplier-bills')
                })
                .catch((errorMsg: any) => {
                  toast.error(errorMsg)
                  setLoading(true)
                })
            }}
          >
            {({setFieldValue, values}) => (
              <Form>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='form-group col'>
                    <label htmlFor='goodsReceivedNoteNo.' className='fw-bold required'>
                      Goods Received Note No.
                    </label>
                    &nbsp;
                    <label className='fs-8'>
                      {` ${
                        grn.createdAt
                          ? `(GRN - ${formatDateTime(grn?.createdAt)} - ${formatMoney(
                              grn?.totalAmount - por?.totalAmount
                            )})`
                          : ' (GRN - Created Date - Total Amount)'
                      }`}
                    </label>
                    <Select
                      options={
                        Array.isArray(goodsReceivedNotes)
                          ? goodsReceivedNotes.map((goodsReceivedNote: any, index: any) => {
                              const porTotalAmount =
                                goodsReceivedNote?.purchaseOrderReturn?.[0]?.totalAmount ?? 0

                              return {
                                value: goodsReceivedNote._id,
                                label: `${goodsReceivedNote.number} - ${formatDateTime(
                                  goodsReceivedNote.createdAt
                                )} -  ${formatMoney(
                                  goodsReceivedNote.totalAmount - porTotalAmount
                                )}`,
                                key: index,
                              }
                            })
                          : []
                      }
                      placeholder='Select'
                      className='multi-select-container'
                      classNamePrefix='multi-select'
                      name='goodsReceivedNote'
                      onChange={(e: any) => {
                        if (e?.value) {
                          setFieldValue('goodsReceivedNote', e.value)
                          onChangeGoodsReceivedNote(e, values)
                        } else {
                          setFieldValue('goodsReceivedNote', '')
                        }
                      }}
                      closeMenuOnSelect={true}
                      isClearable={true}
                      components={animatedComponents}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='goodsReceivedNote' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='purchaseOrder' className='fw-bold'>
                      Purchase Order No.
                    </label>
                    <Field
                      className='form-control form-control-md'
                      name='purchaseOrder.number'
                      placeholder='Purchase Order No.'
                      disabled={true}
                    />
                  </div>

                  <div className='form-group col'>
                    <label htmlFor='purchaseOrderReturn' className='fw-bold'>
                      Purchase Order Return No.
                    </label>
                    <Field
                      className='form-control form-control-md'
                      name='purchaseOrderReturn.number'
                      placeholder='Purchase Order Return No.'
                      disabled={true}
                    />
                  </div>

                  <div className='form-group col'>
                    <label htmlFor='supplierName' className='fw-bold'>
                      Supplier Name
                    </label>
                    <Field
                      className='form-control form-control-md'
                      name='purchaseOrder.supplier.name'
                      placeholder='Supplier Name'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='deliveryChallanNumber' className='fw-bold'>
                      Delivery Challan No.
                    </label>
                    <Field
                      className='form-control form-control-md'
                      name='deliveryChallanNumber'
                      placeholder='Delivery Challan No.'
                      disabled={true}
                    />
                  </div>
                </div>
                <FieldArray
                  name='items'
                  render={(arrayHelpers) => (
                    <div className='mt-10'>
                      <table className='table tbl-xxl-responsivness'>
                        <thead>
                          <tr>
                            <th className='ps-0'>
                              <label className='form-label mb-0'>Product</label>
                            </th>
                            <th className='ps-0'>
                              <label className='form-label mb-0'>UOM</label>
                            </th>
                            <th className='ps-0'>
                              <label className='form-label mb-0'>Quantity</label>
                            </th>
                            <th className='ps-0'>
                              <label className='form-label mb-0'>Rate</label>
                            </th>
                            <th className='ps-0'>
                              <label className='form-label mb-0'>Price</label>
                            </th>
                            <th className='ps-0'>
                              <label className='form-label mb-0'>Tax</label>
                            </th>
                            <th className='ps-0'>
                              <label className='form-label mb-0'>Price Inclusive Tax</label>
                            </th>
                            <th>
                              <label className='form-label'>Discount</label>
                            </th>
                            <th>
                              <label className='form-label'>Total</label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.items.length > 0 &&
                            values.items.map((materialItem: any, index: any) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td className='ps-0' data-label='Product'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.name`}
                                        placeholder='Product'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                    </div>
                                  </td>
                                  <td className='ps-0' data-label='UOM'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.UOM`}
                                        placeholder='UOM'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                    </div>
                                  </td>

                                  <td className='ps-0' data-label='Quantity'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.quantity`}
                                        placeholder='Quantity'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td className='ps-0' data-label='Rate'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.rate`}
                                        placeholder='Rate'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td className='ps-0' data-label='Price'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.price`}
                                        placeholder='Price'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td className='ps-0' data-label='Tax'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.tax`}
                                        placeholder='Tax'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td className='ps-0' data-label='Price Inclusive Tax'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.priceInclusiveTax`}
                                        placeholder='Price Inclusive Tax'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>
                                  <td data-label='Discount'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.discount`}
                                        placeholder='Discount'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>
                                  <td data-label='Total'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.total`}
                                        placeholder='Total'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Price
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Price'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalPrice'
                                  placeholder='Total Price'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Tax
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Tax'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalTax'
                                  placeholder='Total Tax'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Discount
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Discount'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalDiscount'
                                  placeholder='Total Discount'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Amount
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Amount'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalAmount'
                                  placeholder='Total Amount'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                />

                <div className='d-flex justify-content-end mt-10'>
                  <Link
                    to='/supplier-bills'
                    className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Cancel'
                  >
                    <span className='indicator-label'>Cancel</span>
                  </Link>

                  <button
                    type='submit'
                    className='btn btn-lg  btn-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Submit'
                    disabled={loading}
                  >
                    <span className='indicator-label'>{loading ? 'Submitting...' : 'Submit'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
