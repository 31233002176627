import {DepartmentTableModel} from '../models/DepartmentModel'
import * as DepartmentCRUD from './DepartmentCRUD'
import {DepartmentSlice} from './DepartmentSlice'

const {actions} = DepartmentSlice

export const fetchAll: any = (params: DepartmentTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return DepartmentCRUD.findAll(params).then((response) => {
    dispatch(actions.setDepartments({departments: response.data.data}))
    dispatch(actions.setPagination({pagination: response.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchOne = (id: string) => (dispatch: any) => {
  return DepartmentCRUD.findOne(id).then((response) => {
    dispatch(actions.setDepartment({department: response.data.data}))
  })
}
export const create = (data: any, params: DepartmentTableModel) => async (dispatch: any) => {
  try {
    const res = await DepartmentCRUD.create(data)
    const successMsg = res.data.message
    DepartmentCRUD.findAll(params).then((response) => {
      dispatch(actions.setDepartments({departments: response.data.data}))
      dispatch(actions.setPagination({pagination: response.data.pagination}))
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const update =
  (_id: string, name: string, params: DepartmentTableModel) => async (dispatch: any) => {
    try {
      const res = await DepartmentCRUD.update(_id, name)
      const successMsg = res.data.message
      DepartmentCRUD.findAll(params).then((response) => {
        dispatch(actions.setDepartments({departments: response.data.data}))
        dispatch(actions.setPagination({pagination: response.data.pagination}))
      })
      return successMsg
    } catch (error: any) {
      const errors = error?.response?.data?.message
      let errorMsg
      if (typeof errors === 'string') {
        errorMsg = errors
      } else {
        errorMsg = 'Something went wrong'
      }
      throw errorMsg
    }
  }

export const remove = (_id: string, params: DepartmentTableModel) => async (dispatch: any) => {
  try {
    const res = await DepartmentCRUD.remove(_id)
    const successMsg = res.data.message
    DepartmentCRUD.findAll(params).then((response) => {
      dispatch(actions.setDepartments({departments: response.data.data}))
      dispatch(actions.setPagination({pagination: response.data.pagination}))
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}
