import {lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {AssetsPage} from '../modules/assets/AssetsPage'
import {BudgetPage} from '../modules/budget/BudgetsPage'
import {Departments} from '../modules/departments/DepartmentsPage'
import {GoodsReceivedNotePage} from '../modules/goods-received-note/GoodsReceivedNotePage'
import {InventoryPage} from '../modules/inventory/inventoryPage'
import {InwardPage} from '../modules/inward/InwardPage'
import {LocationPage} from '../modules/locations/LocationPage'
import {MaterialInspectionPage} from '../modules/material-inspection/MaterialInspectionPage'
import {MaterialIssuancePage} from '../modules/material-issuance/MaterialIssuancePage'
import {MaterialRequisitionPage} from '../modules/material-requisition/MaterialRequisitionPage'
import {MaterialReturnPage} from '../modules/material-return/MaterialReturnPage'
import {OutwardFormPage} from '../modules/outward/OutwardPage'
import {ProductCategoryPage} from '../modules/product-categories/ProductCategoriesPage'
import {ProductPage} from '../modules/products/ProductsPage'
import {PurchaseOrderPage} from '../modules/purchase-orders/PurchaseOrdersPage'
import {PurchaseOrderReturnPage} from '../modules/purchase-order-returns/PurchaseOrderReturnsPage'
import {SupplierPage} from '../modules/suppliers/SuppliersPage'
import {DashboardPage} from '../modules/dashboard/DashboardPage'
import {SupplierBillPage} from '../modules/supplier-bills/SupplierBillPage'
import {UserPage} from '../modules/users/UserPage'
import {RolePage} from '../modules/roles/RolePage'
import {StatisticsPage} from '../modules/statistics/StatisticsPage'
import {StockMovementReportPage} from '../modules/stock-movement-report/StockMovementReportPage'

const AccountPage = lazy(() => import('../modules/account/AccountPage'))

export function PrivateRoutes() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Routes>
        <Route path='/dashboard' element={<DashboardPage />} />
        <Route path='/material-requisition/*' element={<MaterialRequisitionPage />} />
        <Route path='/assets/*' element={<AssetsPage />} />
        <Route path='/suppliers/*' element={<SupplierPage />} />
        <Route path='/inventory/*' element={<InventoryPage />} />
        <Route path='/material-return/*' element={<MaterialReturnPage />} />
        <Route path='/purchase-orders/*' element={<PurchaseOrderPage />} />
        <Route path='/purchase-order-returns/*' element={<PurchaseOrderReturnPage />} />
        <Route path='/material-inspection/*' element={<MaterialInspectionPage />} />
        <Route path='/material-issuance/*' element={<MaterialIssuancePage />} />
        <Route path='/product-categories/*' element={<ProductCategoryPage />} />
        <Route path='/products/*' element={<ProductPage />} />
        <Route path='/outward/*' element={<OutwardFormPage />} />
        <Route path='/inward/*' element={<InwardPage />} />
        <Route path='/departments/*' element={<Departments />} />
        <Route path='/locations/*' element={<LocationPage />} />
        <Route path='/budgets/*' element={<BudgetPage />} />
        <Route path='/account/*' element={<AccountPage />} />
        <Route path='/goods-received-note/*' element={<GoodsReceivedNotePage />} />
        <Route path='/supplier-bills/*' element={<SupplierBillPage />} />
        <Route path='/users/*' element={<UserPage />} />
        <Route path='/roles/*' element={<RolePage />} />
        <Route path='/statistics/*' element={<StatisticsPage />} />
        <Route path='/stock-movement-report/*' element={<StockMovementReportPage />} />
        <Route path='/' element={<Navigate to='/dashboard' />} />
        <Route path='*' element={<Navigate to='/dashboard' replace />} />
      </Routes>
    </Suspense>
  )
}
