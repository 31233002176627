import React from 'react'
import '../assets/sass/legend.scss'

export const ApprovalLegend: React.FC = () => {
  return (
    <div className='legend-body card-body py-3'>
      <h3>Approval </h3>
      <div className='periority-not-approved d-flex flex-column flex-center'>
        <div>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-bold'>Pending</span>
          </div>
          <div>
            <div className='progress h-6px w-100'>
              <div className='progress-bar bg-primary' role='progressbar'></div>
            </div>
          </div>
        </div>
      </div>

      <div className='periority-approved d-flex flex-column flex-center'>
        <div>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-bold'>Approved</span>
          </div>
          <div>
            <div className='progress h-6px w-100'>
              <div className='progress-bar bg-success' role='progressbar'></div>
            </div>
          </div>
        </div>
      </div>

      <div className='periority-rejected d-flex flex-column flex-center'>
        <div>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-bold'>Rejected</span>
          </div>
          <div>
            <div className='progress h-6px w-100'>
              <div className='progress-bar bg-secondary' role='progressbar'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
