import axios from 'axios'
import {GoodsReceivedNoteModel, GoodsReceivedNoteTableModel} from '../models/GoodsReceivedNoteModel'

const API_URL = process.env.REACT_APP_API_URL
const GOODS_RECEIVED_NOTE_URL = `${API_URL}/goods-received-note`

export function findAll(params: GoodsReceivedNoteTableModel) {
  return axios.get(`${GOODS_RECEIVED_NOTE_URL}`, {params})
}

export function findOne(id: any) {
  return axios.get(`${GOODS_RECEIVED_NOTE_URL}/${id}`)
}

export function findApproved() {
  return axios.get(`${GOODS_RECEIVED_NOTE_URL}/approved`)
}

export function findReturns() {
  return axios.get(`${GOODS_RECEIVED_NOTE_URL}/returns`)
}

export function create(data: GoodsReceivedNoteModel) {
  return axios.post(GOODS_RECEIVED_NOTE_URL, data)
}

export function upload(id: any, data: any) {
  const formData = new FormData()
  data?.map((file: any, index: number) => {
    formData.append('attachments', file)
  })
  formData.append('goodsReceivedNote', id)

  return axios({
    method: 'POST',
    url: `${GOODS_RECEIVED_NOTE_URL}/upload`,
    data: formData,
    headers: {'Content-Type': 'multipart/form-data'},
  })
}
export function approve(id: any) {
  return axios.patch(`${GOODS_RECEIVED_NOTE_URL}/${id}/approve`)
}

export function reject(id: any, data: any) {
  return axios.patch(`${GOODS_RECEIVED_NOTE_URL}/${id}/reject`, data)
}

export function remove(id: any) {
  return axios.delete(`${GOODS_RECEIVED_NOTE_URL}/${id}`)
}
