import axios from 'axios'
import {InwardModel, InwardTableModel} from '../models/InwardModel'

const API_URL = process.env.REACT_APP_API_URL
const INWARD_URL = `${API_URL}/inward`

export function findAll(params: InwardTableModel) {
  return axios.get(INWARD_URL, {params})
}

export function findOne(id: any) {
  return axios.get(`${INWARD_URL}/${id}`)
}

export function findApproved() {
  return axios.get(`${INWARD_URL}/approved`)
}

export function create(data: InwardModel) {
  return axios.post(INWARD_URL, data)
}

export function approve(id: any) {
  return axios.patch(`${INWARD_URL}/${id}/approve`)
}

export function reject(id: any, data: any) {
  return axios.patch(`${INWARD_URL}/${id}/reject`, data)
}

export function remove(id: any) {
  return axios.delete(`${INWARD_URL}/${id}`)
}
