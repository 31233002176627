import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AssetModel, AssetInitValues} from '../models/AssetModel'

interface AssetState {
  loading: boolean
  asset: AssetModel
  assets: [AssetModel?]
  pagination: {total: number; pages: number}
}
const initialState: AssetState = {
  loading: false,
  asset: AssetInitValues,
  assets: [],
  pagination: {total: 0, pages: 0},
}

export const AssetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setAssets: (state, action: PayloadAction<any>) => {
      const {assets} = action.payload

      state.assets = assets
    },
    setAsset: (state, action) => {
      state.asset = action.payload.asset
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
