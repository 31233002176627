import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {PrintButton} from '../../../components/PrintButton'
import {formatDateTime} from '../../../helper'
import * as MaterialRequestAction from '../redux/MaterialRequisitionAction'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const MaterialRequisitionDetail: React.FC<Props> = ({className}) => {
  const materialRequisition: any = useSelector<RootState>(
    ({materialRequisition}) => materialRequisition.materialRequisition,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({materialRequisition}) => materialRequisition.loading,
    shallowEqual
  )

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(MaterialRequestAction.fetchOne(id))
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Requisitions List', url: '/material-requisition'},
    {name: 'Material Requisition Detail', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Material Requisition Detail</span>
          </h1>
          <PrintButton />
        </div>
        <div className='card-body'>
          {!loading ? (
            materialRequisition && materialRequisition?._id !== '' ? (
              <>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Material Requisition No.</b>
                      </td>
                      <td>{materialRequisition?.number}</td>
                      <td>
                        <b>Created Date</b>
                      </td>
                      <td>{formatDateTime(materialRequisition?.requestedDate)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Request by Employee Name</b>
                      </td>
                      <td>{materialRequisition?.requestedBy?.fullName}</td>
                      <td>
                        <b>Request by Department</b>
                      </td>
                      <td>{materialRequisition?.department?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Location</b>
                      </td>
                      <td>{materialRequisition?.location?.name}</td>
                      <td>
                        <b>Status</b>
                      </td>
                      <td>{materialRequisition?.status}</td>
                    </tr>
                    {materialRequisition?.approvedAt && materialRequisition?.approvedBy && (
                      <tr>
                        <td>
                          <b>Approved At</b>
                        </td>
                        <td>{formatDateTime(materialRequisition?.approvedAt)}</td>
                        <td>
                          <b>Approved By</b>
                        </td>
                        <td>{materialRequisition?.approvedBy?.fullName}</td>
                      </tr>
                    )}
                    {materialRequisition?.rejectedAt && materialRequisition?.rejectedBy && (
                      <tr>
                        <td>
                          <b>Rejected At</b>
                        </td>
                        <td>{formatDateTime(materialRequisition?.rejectedAt)}</td>
                        <td>
                          <b>Rejected By</b>
                        </td>
                        <td>{materialRequisition?.rejectedBy?.fullName}</td>
                      </tr>
                    )}
                    {materialRequisition?.rejectionReason && (
                      <tr>
                        <td>
                          <b>Reason to Reject</b>
                        </td>
                        <td colSpan={3}>{materialRequisition?.rejectionReason}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                  <thead>
                    <tr>
                      <th style={{width: 40}}>
                        <b>#</b>
                      </th>
                      <th>
                        <b>Product</b>
                      </th>
                      <th>
                        <b>UOM</b>
                      </th>
                      <th>
                        <b>Quantity</b>
                      </th>
                      <th>
                        <b>Status</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {materialRequisition?.items.map((materialItem: any, index: any) => (
                      <tr key={index}>
                        <td data-label='#'>{index + 1}</td>
                        <td data-label='Name'>{materialItem.name}</td>
                        <td data-label='UOM'>{materialItem.UOM}</td>
                        <td data-label='Quantity'>{materialItem.quantity}</td>
                        <td data-label='Status'>{materialItem.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
