import * as NotificationCRUD from './NotificationCRUD'
import {NotificationSlice} from './NotificationSlice'

const {actions} = NotificationSlice

export const fetchAll: any = () => (dispatch: any) => {
  return NotificationCRUD.fetchAll().then((response) => {
    dispatch(actions.setNotification({notification: response.data.data}))
  })
}

export const markAsRead: any = (ids: any) => async (dispatch: any) => {
  const response = await NotificationCRUD.markAsRead(ids)
  NotificationCRUD.fetchAll().then((response) => {
    dispatch(actions.setNotification({notification: response.data.data}))
  })
}

export const clearNotification: any = () => (dispatch: any) => {
  dispatch(actions.setNotification({notification: []}))
}
