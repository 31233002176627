import {Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {Bar} from 'react-chartjs-2'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {NoItemFound} from '../../../components/NoItemFound'
import {PrintButton} from '../../../components/PrintButton'
import * as actions from '../redux/MaterialRequisitionAction'
import {DatePicker} from 'rsuite'
import {getMaxValueArrays} from '../../../helper/index'
import {Loader} from '../../../components/Loader'
import moment from 'moment'
import 'rsuite/dist/rsuite.css'

type Props = {
  className: string
}

export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const MaterialRequisitionDepartmentReport: React.FC<Props> = ({className}) => {
  const dispatch: any = useDispatch()

  const [showChart, setShowChart] = useState('table')
  const [view, setView] = useState('table')

  const reports: any = useSelector<RootState>(
    (state) => state.materialRequisition.report,
    shallowEqual
  )

  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1

  const [initValues] = useState<any>({year: year, month: month})
  const [currentYear, setCurrentYear] = useState(initValues?.year)
  const [currentMonth, setCurrentMonth] = useState(initValues.month)

  const onDatePickerChange = (value: any) => {
    if (value) {
      setCurrentYear(value.getFullYear())
      setCurrentMonth(value.getMonth() + 1)
    }
  }

  useEffect(() => {
    dispatch(actions.fetchDepartmentReport(currentYear, currentMonth))
  }, [dispatch, currentYear, currentMonth])

  const labels = reports.length && reports?.map((report: any) => report?.department)

  const totalRequisition =
    reports.length &&
    labels.map(
      (label: string) =>
        reports?.find((report: any) => report?.department === label)?.totalRequisition || ''
    )

  const totalIssuance =
    reports.length &&
    labels.map(
      (label: string) =>
        reports?.find((report: any) => report?.department === label)?.totalIssuance || ''
    )

  const totalOutward =
    reports.length &&
    labels.map(
      (label: string) =>
        reports?.find((report: any) => report?.department === label)?.totalOutward || ''
    )

  const totalInward =
    reports.length &&
    labels.map(
      (label: string) =>
        reports?.find((report: any) => report?.department === label)?.totalInward || ''
    )

  const maxValuesArray = [totalRequisition, totalIssuance, totalOutward, totalInward]

  const overallMax = totalRequisition && getMaxValueArrays(maxValuesArray)

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        title: {
          display: true,
          text:
            'Material Requisition Records ' +
            moment(`${currentYear}-${currentMonth}-01`).format('MMM YYYY'),
          font: {
            family: 'Arial',
            size: 16,
            weight: 'bold',
          },
          color: '#333',
          padding: 10,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (count: any) {
            return count
          },
        },
        min: 0,
        max: Math.ceil(overallMax / 10) * 10,
      },
    },
  }

  const departments = labels
  const data = {
    labels: departments,
    datasets: [
      {
        label: 'Requisitions',
        data: totalRequisition,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 1.5)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
      {
        label: 'Issuances',
        data: totalIssuance,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(230, 90, 9, 0.8)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
      {
        label: 'Outwards',
        data: totalOutward,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(175, 95, 240, 0.97)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
      {
        label: 'Inwards',
        data: totalInward,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(194, 163, 37, 1)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
    ],
  }

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Requisitions List', url: '/material-requisition'},
    {
      name: 'Material Requisition Departments Report',
      url: '/material-requisition/report/department',
    },
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              Material Requisition Departments Report
            </span>
          </h1>
          <PrintButton />
        </div>
        <Formik
          validationSchema={Yup.object().shape({
            year: Yup.string(),
          })}
          initialValues={initValues}
          onSubmit={(values: any) => {}}
        >
          {({values}) => (
            <Form className='form' noValidate id='kt_modal_create_app_form'>
              <div className='row ms-5'>
                <div className='col-md-4 print-view-none fv-row'>
                  <div className='d-flex report-row'>
                    <label htmlFor='Market PR' className='fs-6 fw-bold m-2'>
                      Month
                    </label>
                    <div className='col-6 '>
                      <DatePicker
                        placeholder='Month & Year'
                        format='MMM yyyy'
                        ranges={[]}
                        style={{width: 300}}
                        shouldDisableDate={(date: any) => {
                          return date.getFullYear() < 2022 || date.getFullYear() > year
                        }}
                        onChange={onDatePickerChange}
                        defaultValue={date}
                      />
                    </div>
                    <div className='report-btn'>
                      <div className='btn-group ms-5' role='group' aria-label='Basic example'>
                        <button
                          type='button'
                          className={`btn btn-sm btn-secondary ${view == 'table' ? 'active' : ''}`}
                          onClick={() => {
                            setShowChart('table')
                            setView('table')
                          }}
                        >
                          Table
                        </button>
                        <button
                          type='button'
                          className={`btn btn-sm btn-secondary ${view == 'chart' ? 'active' : ''}`}
                          onClick={() => {
                            setShowChart('chart')
                            setView('chart')
                          }}
                        >
                          Chart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <div className='card-body py-10'>
          {reports ? (
            showChart == 'chart' && reports?.length !== 0 ? (
              <>
                <div className='doughnut-chart-container'>
                  {/* <Doughnut data={dataDoughnut} options={optionsDoughnut} /> */}
                </div>
                <br />
                <div className='bar-chart-container'>
                  <Bar options={options} data={data} />
                </div>
              </>
            ) : reports?.length !== 0 ? (
              <table className='table tbl-border tbl-md-responsivness table-striped'>
                <thead>
                  <tr>
                    <th>
                      <b>Department </b>
                    </th>
                    <th>
                      <b> Requisitions </b>
                    </th>
                    <th>
                      <b> Issuances </b>
                    </th>
                    <th>
                      <b> Outwards </b>
                    </th>
                    <th>
                      <b> Inwards </b>
                    </th>
                    <th>
                      <b>New </b>
                    </th>
                    <th>
                      <b> Approved </b>
                    </th>
                    <th>
                      <b> Rejected </b>
                    </th>
                    <th>
                      <b> Completed </b>
                    </th>
                    <th>
                      <b> Cancelled </b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reports?.map((report: any, index: any) => (
                    <tr key={index}>
                      <td data-label='Department'>{report?.department}</td>
                      <td data-label='Total Requisition'>{report?.totalRequisition}</td>
                      <td data-label='Total Issuance'>{report?.totalIssuance}</td>
                      <td data-label='Total Outward'>{report?.totalOutward}</td>
                      <td data-label='Total Inward'>{report?.totalInward}</td>
                      <td data-label='Total New'>{report?.totalNew}</td>
                      <td data-label='Total Approved'>{report?.totalApproved}</td>
                      <td data-label='Total Rejected'>{report?.totalRejected}</td>
                      <td data-label='Total Completed'>{report?.totalCompleted}</td>
                      <td data-label='Total Cancelled'>{report?.totalCancelled}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
