import {useEffect} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {ResetPassword} from './components/ResetPassword'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='Login-page d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.jpg')})`,
      }}
    >
      <div className='d-flex flex-centerr position-x-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <a className='mb-10 w-auto mx-auto' href='/login'>
          <img
            alt='Logo'
            src='/media/logos/logo-4.png'
            className='h-75px d-flex position-x-center mx-auto'
          />
        </a>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/registration' element={<Registration />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password/:token' element={<ResetPassword />} />
            <Route path='/nscm' element={<Navigate to='/login' replace />} />
            <Route path='*' element={<Navigate to='/login' replace />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}
