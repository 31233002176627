import axios from 'axios'
import {ProductCategoryTableModel} from '../models/ProductCategoryModel'

const API_URL = process.env.REACT_APP_API_URL
const PRODUCT_CATEGORIES_URL = `${API_URL}/product-categories`

export function findAll(params: ProductCategoryTableModel) {
  return axios.get(PRODUCT_CATEGORIES_URL, {params})
}
export function findAllParentCategories() {
  return axios.get(`${PRODUCT_CATEGORIES_URL}/parent`)
}
export function create(name: string, assetType: string, parent: string) {
  return axios.post(PRODUCT_CATEGORIES_URL, {name, assetType, parent})
}
export function update(_id: string, name: string, assetType: string, parent: string) {
  return axios.patch(`${PRODUCT_CATEGORIES_URL}/${_id}`, {name, assetType, parent})
}
export function remove(_id: string) {
  return axios.delete(`${PRODUCT_CATEGORIES_URL}/${_id}`)
}
