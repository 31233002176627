import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SupplierModel, SupplierInitValues} from '../models/SupplierModel'

interface supplierState {
  loading: boolean
  supplier: SupplierModel
  suppliers: [SupplierModel?]
  pagination: {total: number; pages: number}
}

const initialState: supplierState = {
  loading: false,
  supplier: SupplierInitValues,
  suppliers: [],
  pagination: {total: 0, pages: 0},
}

export const supplierSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setSuppliers: (state, action: PayloadAction<any>) => {
      const {suppliers} = action.payload
      state.suppliers = suppliers
    },
    setSupplier: (state, action) => {
      state.supplier = action.payload.supplier
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
