import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import * as MaterialReturnAction from '../redux/MaterialReturnAction'
import {toast} from 'react-toastify'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDate, formatDateTime, formatNumber} from '../../../helper'
import {PrintButton} from '../../../components/PrintButton'
import QRCode from 'qrcode.react'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const MaterialReturnDetail: React.FC<Props> = ({className}) => {
  const materialReturn: any = useSelector<RootState>(
    ({materialReturn}) => materialReturn.materialReturn,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({materialReturn}) => materialReturn.loading,
    shallowEqual
  )

  const dispatch: any = useDispatch()
  let navigate = useNavigate()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(MaterialReturnAction.fetchOne(id))
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Returns List', url: '/material-return'},
    {name: 'Material Return Detail', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Material Return Detail</span>
          </h1>
          <PrintButton />
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            materialReturn && materialReturn?._id !== '' ? (
              <>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Material Return No.</b>
                      </td>
                      <td>{materialReturn?.number}</td>
                      <td>
                        <b>Created Date</b>
                      </td>
                      <td>{formatDateTime(materialReturn?.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Material Issuance No.</b>
                      </td>
                      <td>{materialReturn?.materialIssuance?.number}</td>

                      <td>
                        <b>Location</b>
                      </td>
                      <td>{materialReturn?.location?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b> Provided To Department</b>
                      </td>
                      <td>{materialReturn?.providedToDepartment?.name}</td>
                      <td>
                        <b> Received By Department</b>
                      </td>
                      <td>{materialReturn?.receivedByDepartment?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Status</b>
                      </td>
                      <td>{materialReturn?.status}</td>
                    </tr>
                    {materialReturn?.approvedAt && materialReturn?.approvedBy && (
                      <tr>
                        <td>
                          <b>Approved At</b>
                        </td>
                        <td>{formatDateTime(materialReturn?.approvedAt)}</td>
                        <td>
                          <b>Approved By</b>
                        </td>
                        <td>{materialReturn?.approvedBy?.firstName}</td>
                      </tr>
                    )}
                    {materialReturn?.rejectedAt && materialReturn?.rejectedBy && (
                      <tr>
                        <td>
                          <b>Rejected At</b>
                        </td>
                        <td>{formatDateTime(materialReturn?.rejectedAt)}</td>
                        <td>
                          <b>Rejected By</b>
                        </td>
                        <td>{materialReturn?.rejectedBy?.firstName}</td>
                      </tr>
                    )}
                    {materialReturn?.rejectionReason && (
                      <tr>
                        <td>
                          <b>Reason to Reject</b>
                        </td>
                        <td colSpan={3}>{materialReturn?.rejectionReason}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                  <thead>
                    <tr>
                      <th style={{width: 40}}>
                        <b>#</b>
                      </th>
                      <th>
                        <b>Product</b>
                      </th>
                      <th>
                        <b>UOM</b>
                      </th>
                      <th>
                        <b>Quantity</b>
                      </th>
                      <th>
                        <b>Expiry Date</b>
                      </th>
                      <th>
                        <b>QR Code</b>
                      </th>
                      <th>
                        <b>Remarks</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {materialReturn?.items.map((materialReturnItem: any, index: any) => (
                      <tr key={index}>
                        <td data-label='#'>{index + 1}</td>
                        <td data-label='Name'>{materialReturnItem.name}</td>
                        <td data-label='UOM'>{materialReturnItem.UOM}</td>
                        <td data-label='Quantity'>{formatNumber(materialReturnItem.quantity)}</td>
                        <td data-label='Expiry Date'>
                          {materialReturnItem.expiryDate
                            ? formatDate(materialReturnItem.expiryDate)
                            : '-'}
                        </td>
                        <td data-label='QR Code'>
                          {materialReturnItem?.qrCode && (
                            <div
                              style={{
                                height: 'auto',
                                margin: '0 auto',
                                maxWidth: 64,
                                width: '100%',
                              }}
                            >
                              <QRCode
                                id={`${materialReturnItem.qrCode}`}
                                size={144}
                                bgColor='#FFF'
                                fgColor='#000'
                                includeMargin
                                level={'H'}
                                style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                                value={materialReturnItem.qrCode.toString()}
                              />
                            </div>
                          )}
                        </td>
                        <td data-label='Remarks'>{materialReturnItem.remarks}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
