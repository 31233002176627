import {SupplierInitValues, SupplierModel} from '../../suppliers/models/SupplierModel'

interface PurchaseOrderReturnItem {
  _id: string
  product: string
  qrCode: string
  expiryDate: string
  name: string
  UOM: string
  quantity: number | string
  rate: number | string
  price: number | string
  tax: number | string
  priceInclusiveTax: number | string
  discount: number | string
  total: number | string
  status: string
}

export interface PurchaseOrderReturnModel {
  _id: string
  purchaseOrder: string
  goodsReceivedNote: string
  purchaseFor: string
  number: string
  totalPrice: number | string
  totalTax: number | string
  totalDiscount: number | string
  totalAmount: number | string
  status: string
  approvedAt?: string
  approvedBy?: string
  rejectedBy?: string
  rejectedAt?: string
  rejectionReason?: string
  supplier: SupplierModel
  items: [PurchaseOrderReturnItem]
}

export const PurchaseOrderReturnInitValues: PurchaseOrderReturnModel = {
  _id: '',
  number: '',
  purchaseOrder: '',
  goodsReceivedNote: '',
  purchaseFor: '',
  totalPrice: '',
  totalTax: '',
  totalDiscount: '',
  totalAmount: '',
  status: '',
  approvedAt: '',
  approvedBy: '',
  rejectedBy: '',
  rejectedAt: '',
  rejectionReason: '',
  supplier: SupplierInitValues,
  items: [
    {
      _id: '',
      product: '',
      qrCode: '',
      expiryDate: '',
      name: '',
      UOM: '',
      quantity: '',
      rate: '',
      price: '',
      tax: '',
      priceInclusiveTax: '',
      discount: '',
      total: '',
      status: '',
    },
  ],
}

export interface PurchaseOrderReturnSearchModel {
  number: string
  status: string
  createdAt: any
  approvedBy: string
  approvedAt: any
}
export const PurchaseOrderReturnSearchModelInitValues: PurchaseOrderReturnSearchModel = {
  number: '',
  status: '',
  createdAt: [],
  approvedBy: '',
  approvedAt: [],
}

export interface PurchaseOrderReturnTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: PurchaseOrderReturnSearchModel
}

export const PurchaseOrderReturnTableModelInitValues: PurchaseOrderReturnTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: PurchaseOrderReturnSearchModelInitValues,
}
