/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import * as MaterialRequisitionAction from '../redux/MaterialRequisitionAction'
import {
  MaterialRequisitionTableModelInitValues,
  MaterialRequisitionModel,
} from '../models/MaterialRequisitionModel'

interface Props {
  show: boolean
  handleClose: () => void
  materialRequisition: MaterialRequisitionModel
}

export const MaterialRequisitionCompleteConfirmModal: FC<Props> = ({
  show,
  handleClose,
  materialRequisition,
}) => {
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const dispatch: any = useDispatch()

  const completedMaterialRequisition = () => {
    setLoading(true)
    dispatch(MaterialRequisitionAction.complete(materialRequisition?._id))
      .then((response: any) => {
        toast.success(response)
        setLoading(false)
        navigate('/material-requisition')
        dispatch(MaterialRequisitionAction.fetchAll(MaterialRequisitionTableModelInitValues))
      })
      .catch((error: any) => {
        toast.error(error)
        setLoading(false)
      })
    handleClose()
  }

  return (
    <>
      <Modal
        id='kt_modal_delete_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered mw-500px h-auto'
        show={show}
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Complete Material Requisition</h2>
          </div>

          <div className='modal-body flex-row-fluid py-lg-10 px-lg-10'>
            <p className='d-flex align-items-center fs-5 fw-bold'>
              Are you sure you want to complete this material requisition (
              {materialRequisition?.number})?
            </p>
            <div className='d-flex flex-stack mt-5'>
              <button
                type='button'
                className='btn btn-lg btn-light-primary float-left'
                onClick={() => {
                  handleClose()
                }}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='btn btn-lg btn-primary float-right'
                disabled={loading}
                onClick={() => {
                  completedMaterialRequisition()
                }}
              >
                <span className='indicator-label'>{loading ? 'Completing...' : 'Complete'}</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
