import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {StepNav2} from '../../../components/step-nav2'
import * as GoodsReceivedNoteAction from '../redux/GoodsReceivedNoteAction'
import * as MaterialInspectionAction from '../../material-inspection/redux/MaterialInspectionAction'
import {UserModel} from '../../auth/models/UserModel'
import {GoodsReceivedNoteInitValues, GoodsReceivedNoteModel} from '../models/GoodsReceivedNoteModel'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {formatNumber} from '../../../helper'
import {DatePicker} from 'rsuite'
import * as ProductAction from '../../products/redux/ProductAction'
import {ProductListingModelInitValues} from '../../products/models/ProductModel'
import moment from 'moment'

type Props = {
  className: string
}

export const GoodsReceivedNoteAdd: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(false)
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const materialInspections: any = useSelector<RootState>(
    ({materialInspections}) => materialInspections.materialInspections,
    shallowEqual
  )
  const animatedComponents = makeAnimated()

  const [goodsReceivedNote, setGoodsReceivedNote] = useState<GoodsReceivedNoteModel>(
    GoodsReceivedNoteInitValues
  )
  const [attachments, setAttachments] = useState<any>([])
  const products: any = useSelector<RootState>((state) => state.products.products, shallowEqual)

  const dispatch: any = useDispatch()
  let navigate = useNavigate()

  useEffect(() => {
    dispatch(ProductAction.findAll(ProductListingModelInitValues))
    dispatch(MaterialInspectionAction.fetchApproved())
    dispatch(GoodsReceivedNoteAction.reset())
  }, [dispatch])

  const onChangeMaterialInspection = (e: any, values: any) => {
    const selectedMaterialInspection = materialInspections.find((r: any) => r._id === e.value)
    let uGoodsReceivedNote = JSON.parse(JSON.stringify(values))
    if (selectedMaterialInspection) {
      uGoodsReceivedNote.materialInspection = selectedMaterialInspection._id
      uGoodsReceivedNote.purchaseOrder = selectedMaterialInspection.purchaseOrder
      uGoodsReceivedNote.location = selectedMaterialInspection?.location?.id
      uGoodsReceivedNote.locationName = selectedMaterialInspection?.location?.name
      uGoodsReceivedNote.totalPrice = formatNumber(selectedMaterialInspection?.totalPrice)
      uGoodsReceivedNote.totalTax = formatNumber(selectedMaterialInspection?.totalTax)
      uGoodsReceivedNote.totalDiscount = formatNumber(selectedMaterialInspection?.totalDiscount)
      uGoodsReceivedNote.totalAmount = formatNumber(selectedMaterialInspection?.totalAmount)

      uGoodsReceivedNote.items = []
      selectedMaterialInspection.items.map((r: any, i: any) => {
        uGoodsReceivedNote.items[i] = {
          _id: r._id,
          product: r.product,
          name: r.name,
          UOM: r.UOM,
          quantity: formatNumber(r.quantity),
          rate: formatNumber(r.rate),
          price: formatNumber(r.price),
          tax: formatNumber(r.tax),
          priceInclusiveTax: formatNumber(r.priceInclusiveTax),
          discount: formatNumber(r.discount),
          expiry: '',
          total: formatNumber(r.total),
        }
      })

      setGoodsReceivedNote(uGoodsReceivedNote)
    }
  }

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Goods Received Note List', url: '/goods-received-note'},
    {name: 'Goods Received Note Form', url: ''},
  ]
  const onChangeAttachments = (e: any) => {
    const documents: any = Array.from(e.target.files)

    setAttachments(documents)
    if (documents?.length > 0) {
      documents?.map((doc: any) => {
        const MB = doc.size / (1024 * 1024)
        if (MB > 2) {
          toast.error('File too large')
          setAttachments([])
          e.target.value = null
          return
        }
        if (!['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].includes(doc.type)) {
          toast.error('Kindly select valid input file')
          setAttachments([])
          e.target.value = null
          return
        }
      })
      if (documents?.length > 5) {
        toast.error('Cannot add more than 5 files')
        setAttachments([])
        e.target.value = null
        return
      }
    }
  }

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='border-0 pt-5 p-5'>
          <StepNav2 itemNumber={3} />
        </div>
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Goods Received Note Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          <Formik
            enableReinitialize
            initialValues={goodsReceivedNote}
            validationSchema={Yup.object({
              materialInspection: Yup.string().required('Material Inspection No is required'),
              deliveryChallanNumber: Yup.string()
                .trim('Delivery Challan No must have non-empty spaces')
                .strict(true)
                .required('Delivery Challan No is required'),
            })}
            onSubmit={(values: any) => {
              setLoading(true)
              values.items.map((item: any, index: number) => {
                const getProduct =
                  products.length > 0 &&
                  products?.filter((product: any) => product?._id == item?.product)
                const expiryDate = item?.expiryDate
                  ? moment(item?.expiryDate).format('YYYYMMDD')
                  : '00000000'
                item.qrCode = getProduct[0]?.code + '-' + expiryDate
                item.productCode = getProduct[0]?.code
              })

              dispatch(GoodsReceivedNoteAction.create(values, attachments))
                .then((response: any) => {
                  toast.success(response.message)
                  setLoading(false)
                  navigate('/goods-received-note')
                })
                .catch((errorMsg: any) => {
                  toast.error(errorMsg)
                  setLoading(false)
                })
            }}
          >
            {({setFieldValue, values}) => (
              <Form>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='form-group col'>
                    <label htmlFor='materialInspection' className='fw-bold required'>
                      Material Inspection No.
                    </label>

                    <Select
                      options={
                        Array.isArray(materialInspections)
                          ? materialInspections?.map((materialInspection: any, index: any) => {
                              return {
                                value: materialInspection._id,
                                label: `${materialInspection.number}`,
                                key: index,
                              }
                            })
                          : []
                      }
                      placeholder='Select'
                      className='multi-select-container'
                      classNamePrefix='multi-select'
                      name='materialInspection'
                      onChange={(e: any) => {
                        if (e?.value) {
                          setFieldValue('materialInspection', e.value)
                          onChangeMaterialInspection(e, values)
                        } else {
                          setFieldValue('materialInspection', '')
                        }
                      }}
                      closeMenuOnSelect={true}
                      isClearable={true}
                      components={animatedComponents}
                    />

                    <div className='text-danger'>
                      <ErrorMessage name='materialInspection' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='requestedBy' className='fw-bold'>
                      Employee Name
                    </label>
                    <Field
                      name='requestedBy'
                      value={user.fullName}
                      className='form-control form-control-lg'
                      type='text'
                      disabled={true}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='requestedBy' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='purchaseOrder' className='fw-bold'>
                      Purchase Order No.
                    </label>
                    <Field
                      className='form-control form-control-md'
                      name='purchaseOrder.number'
                      placeholder='Purchase Order No.'
                      disabled={true}
                    ></Field>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='deliveryChallanNumber' className='fw-bold required'>
                      Delivery Challan No.
                    </label>
                    <Field
                      name='deliveryChallanNumber'
                      className='form-control form-control-lg form-control'
                      type='text'
                      placeholder='Delivery Challan No.'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='deliveryChallanNumber' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='supplier' className='fw-bold'>
                      Supplier Name
                    </label>
                    <Field
                      className='form-control form-control-md'
                      name='purchaseOrder.supplier.name'
                      placeholder='Supplier Name'
                      disabled={true}
                    ></Field>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='attachments' className='fw-bold'>
                      Upload Files (Allowed Extensions: jpeg, jpg, png, pdf)
                    </label>
                    <Field
                      className='form-control form-control-md'
                      name='attachments'
                      type='file'
                      onChange={(e: any) => onChangeAttachments(e)}
                      accept='image/png, image/jpg, image/jpeg ,application/pdf'
                      multiple
                    ></Field>
                    {attachments?.map((attachment: any, i: number) => (
                      <React.Fragment key={i}>
                        {attachment.name} {'  '}
                      </React.Fragment>
                    ))}
                  </div>
                  <div className='form-group col' hidden>
                    <label htmlFor='location' className='fw-bold required'>
                      Location
                    </label>
                    <Field
                      name='location'
                      placeholder='Location'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='locationName' className='fw-bold'>
                      Location
                    </label>
                    <Field
                      name='locationName'
                      placeholder='Location'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                </div>

                <FieldArray
                  name='items'
                  render={(arrayHelpers) => (
                    <div className='mt-15'>
                      <table className='table tbl-lg-responsivness'>
                        <thead>
                          <tr>
                            <th>
                              <label className='form-label'>Product</label>
                            </th>
                            <th>
                              <label className='form-label'>UOM</label>
                            </th>
                            <th>
                              <label className='form-label'>Quantity</label>
                            </th>
                            <th>
                              <label className='form-label'>Rate</label>
                            </th>
                            <th>
                              <label className='form-label'>Price</label>
                            </th>
                            <th>
                              <label className='form-label'>Tax</label>
                            </th>
                            <th>
                              <label className='form-label'>Price Inclusive Tax</label>
                            </th>
                            <th>
                              <label className='form-label'>Discount</label>
                            </th>
                            <th>
                              <label className='form-label'>Expiry Date</label>
                            </th>
                            <th>
                              <label className='form-label'>Total</label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.items?.length > 0 &&
                            values.items?.map((materialItem: any, index: any) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td data-label='Product'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.name`}
                                        placeholder='Product'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                    </div>
                                  </td>

                                  <td data-label='UOM'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.UOM`}
                                        placeholder='UOM'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Quantity'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.quantity`}
                                        placeholder='Quantity'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Rate'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.rate`}
                                        placeholder='Rate'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Price'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.price`}
                                        placeholder='Price'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Tax'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.tax`}
                                        placeholder='Tax'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Price Inclusive Tax'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.priceInclusiveTax`}
                                        placeholder='Price Inclusive Tax'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>
                                  <td data-label='Discount'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.discount`}
                                        placeholder='Discount'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>
                                  <td data-label='Expiry Date'>
                                    <div className='d-flex flex-column w-100'>
                                      <DatePicker
                                        name='expiryDate'
                                        cleanable={true}
                                        format='dd/MM/yyyy'
                                        placeholder='Select Date'
                                        onChange={(value: any) => {
                                          setFieldValue(`items.${index}.expiryDate`, value)
                                        }}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Total'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.total`}
                                        placeholder='Total'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Price
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Price'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalPrice'
                                  placeholder='Total Price'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Tax
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Tax'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalTax'
                                  placeholder='Total Tax'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Discount
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Discount'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalDiscount'
                                  placeholder='Total Discount'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Amount
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Amount'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalAmount'
                                  placeholder='Total Amount'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                />
                <div className='d-flex justify-content-end mt-10'>
                  <Link
                    to='/goods-received-note'
                    className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Cancel'
                  >
                    <span className='indicator-label'>Cancel</span>
                  </Link>

                  <button
                    type='submit'
                    className='btn btn-lg  btn-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Submit'
                    disabled={loading}
                  >
                    <span className='indicator-label'>{loading ? 'Submitting...' : 'Submit'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
