/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  descriptionTitle: any
  description: string
  descriptionTitle2: string
  description2: string
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  descriptionTitle,
  description,
  descriptionTitle2,
  description2,
}) => {
  const cardStyle = {
    backgroundColor: color,
  }
  return (
    <div className={`card   ${className}`} style={cardStyle}>
      <div className='card-body'>
        {/* <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-4x ms-n2`} /> */}
        <img src={svgIcon} width={50} height={50} />
        <div className={`text-white fw-bolder fs-2 mb-2 mt-5`}>{title}</div>
        <div className='d-flex space-between justify-content-between'>
          <div className={`fw-bold text-white fs-5`}>{descriptionTitle}</div>
          <div className={`fw-bold text-white fs-5`}>{description}</div>
        </div>
        <div className='d-flex space-between justify-content-between'>
          <div className={`fw-bold text-white fs-5`}>{descriptionTitle2}</div>
          <div className={`fw-bold text-white fs-5`}>{description2}</div>
        </div>
      </div>
    </div>
  )
}

export {StatisticsWidget5}
