import {Form, Formik, Field} from 'formik'
import {FC, useState} from 'react'
import {useDispatch} from 'react-redux'
import {generateAppToken} from '../../auth/redux/AuthCRUD'
import {toast} from 'react-toastify'
import CopyToClipboard from 'react-copy-to-clipboard'
import {KTSVG} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import {UserModel} from '../models/UserModel'
import {fetchAll} from '../redux/UserAction'
import {UserTableModelInitValues} from '../models/UserModel'
import {formatDateTime} from '../../../helper'

interface Props {
  show: boolean
  handleClose: () => void
  user: UserModel
}
export const ApplicationToken: FC<Props> = ({show, handleClose, user}) => {
  const [loading, setLoading] = useState(false)
  const dispatch: any = useDispatch()
  const [copied, setCopied] = useState('')

  const currentUser = {
    ...user,
    application: {
      ...user.application,
      expiry:
        user?.application?.expiry?.toString() !== ''
          ? formatDateTime(user?.application?.expiry?.toString() ?? '')
          : '',
    },
  }

  setTimeout(() => {
    setCopied('')
  }, 4000)

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Generate New Token</h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body flex-row py-lg-10 px-lg-10'>
          <Formik
            initialValues={currentUser}
            onSubmit={(values: any, {setSubmitting, setFieldValue}) => {
              setLoading(true)
              dispatch(generateAppToken(values, user._id))
                .then((response: any) => {
                  const user = response?.data?.data
                  setFieldValue('application.token', user?.application?.token)
                  setFieldValue('application.expiry', formatDateTime(user?.application?.expiry))
                  setFieldValue('application.refreshToken', user?.application?.refreshToken)
                  dispatch(fetchAll(UserTableModelInitValues))
                  toast.success(response.data.message)
                  setLoading(false)
                })
                .catch((error: any) => {
                  toast.error(error)
                  setLoading(false)
                })
            }}
            enableReinitialize={true}
          >
            {(values: any) => (
              <Form className='form' noValidate id='kt_modal_create_app_form'>
                <>
                  <div className='form-group position-relative'>
                    <label htmlFor='application token' className='fw-bold'>
                      Application Token
                    </label>
                    <Field
                      name='application.token'
                      as='textarea'
                      className='form-control form-control-lg w'
                      disabled={true}
                    />
                    {copied == 'token' ? (
                      <button
                        type='button'
                        className='btn btn-icon btn-bg-light btn-active-color-default btn-sm position-absolute end-0 top-0 me-7 mt-4 x'
                        data-bs-toggle='tooltip'
                        title='Copy'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen059.svg'
                          className='svg-icon svg-icon-muted svg-icon-2hx'
                        />
                        <span className='text-muted'>Copied!</span>
                      </button>
                    ) : (
                      <CopyToClipboard
                        text={values?.values?.application?.token ?? ''}
                        onCopy={() => setCopied('token')}
                      >
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm position-absolute end-0 top-0 me-5 mt-4 x'
                          data-bs-toggle='tooltip'
                          title='Copy'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen057.svg'
                            className='svg-icon svg-icon-muted svg-icon-2hx'
                          />
                        </button>
                      </CopyToClipboard>
                    )}
                  </div>

                  <div className='form-group mt-5 position-relative'>
                    <label htmlFor='expiry' className='fw-bold'>
                      Expiry
                    </label>
                    <Field
                      name='application.expiry'
                      as='textarea'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                    {copied == 'expiry' ? (
                      <button
                        type='button'
                        className='btn btn-icon btn-bg-light btn-active-color-default btn-sm position-absolute end-0 top-0 me-7 mt-4 x'
                        data-bs-toggle='tooltip'
                        title='Copy'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen059.svg'
                          className='svg-icon svg-icon-muted svg-icon-2hx'
                        />
                        <span className='text-muted'>Copied!</span>
                      </button>
                    ) : (
                      <CopyToClipboard
                        text={values?.values?.application?.expiry ?? ''}
                        onCopy={() => setCopied('expiry')}
                      >
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm position-absolute end-0 top-0 me-5 mt-4 x'
                          data-bs-toggle='tooltip'
                          title='Copy'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen057.svg'
                            className='svg-icon svg-icon-muted svg-icon-2hx'
                          />
                        </button>
                      </CopyToClipboard>
                    )}
                  </div>

                  <div className='form-group mt-5 position-relative'>
                    <label htmlFor='refreshToken' className='fw-bold'>
                      Refresh Token
                    </label>
                    <Field
                      name='application.refreshToken'
                      as='textarea'
                      className='form-control form-control-lg w'
                      disabled={true}
                    />
                    {copied == 'refreshToken' ? (
                      <button
                        type='button'
                        className='btn btn-icon btn-bg-light btn-active-color-default btn-sm position-absolute end-0 top-0 me-7 mt-4 x'
                        data-bs-toggle='tooltip'
                        title='Copy'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen059.svg'
                          className='svg-icon svg-icon-muted svg-icon-2hx'
                        />
                        <span className='text-muted'>Copied!</span>
                      </button>
                    ) : (
                      <CopyToClipboard
                        text={values?.values?.application?.refreshToken ?? ''}
                        onCopy={() => setCopied('refreshToken')}
                      >
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm position-absolute end-0 top-0 me-5 mt-4 x'
                          data-bs-toggle='tooltip'
                          title='Copy'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen057.svg'
                            className='svg-icon svg-icon-muted svg-icon-2hx'
                          />
                        </button>
                      </CopyToClipboard>
                    )}
                  </div>
                  <div className='d-flex flex-stack pt-5'>
                    <div className='me-2'></div>
                    <div>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary me-3'
                        title='Generate Token'
                        disabled={loading}
                      >
                        <span className='indicator-label'>
                          Generate New Token
                          <KTSVG
                            path='/media/icons/duotune/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
