import React, {useEffect} from 'react'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {KTSVG} from '../../../../_metronic/helpers'
import {RootState} from '../../../../setup'
import {Link, useNavigate} from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import * as Yup from 'yup'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {InventoryInitValues} from '../models/InventoryModel'
import * as InventoryAction from '../redux/InventoryAction'
import {toast} from 'react-toastify'
import * as ProductAction from '../../products/redux/ProductAction'
import * as LocationAction from '../../locations/redux/LocationAction'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {LocationTableModelInitValues} from '../../locations/models/LocationModel'
import {ProductListingModelInitValues} from '../../products/models/ProductModel'
import {DatePicker} from 'rsuite'
import {formatNumber} from '../../../helper'

type Props = {
  className: string
}

export const InventoryAdd: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = React.useState(false)
  const products: any = useSelector<RootState>((state) => state.products.products, shallowEqual)
  const locations: any = useSelector<RootState>(({location}) => location.locations, shallowEqual)

  let productsOptions =
    products &&
    products
      .filter((product: any) => {
        return product?.category?.assetType === 'CURRENT'
      })
      .map((product: any) => {
        return {
          value: product._id,
          label: product.name,
        }
      })

  const animatedComponents = makeAnimated()

  const dispatch: any = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(ProductAction.findAll(ProductListingModelInitValues))
    dispatch(LocationAction.fetchAll(LocationTableModelInitValues))
  }, [dispatch])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Inventories List', url: '/inventory'},
    {name: 'Add Inventory', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3'>Add Inventory</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          <Formik
            enableReinitialize
            initialValues={InventoryInitValues}
            validationSchema={Yup.object({
              type: Yup.string().required('Inventory type is required'),
              location: Yup.string().required('Location is required'),
              reference: Yup.string()
                .trim('Reference must have non-empty spaces')
                .strict(true)
                .required('Reference is required'),
              product: Yup.string().required('Product is required'),
              quantity: Yup.number()
                .required('Quantity is required')
                .typeError('Quantity is invalid')
                .nullable()
                .positive('Quantity must be more than 0'),
            })}
            onSubmit={(values: any) => {
              setLoading(true)
              dispatch(InventoryAction?.create(values))
                .then((response: string) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/inventory')
                })
                .catch((errorMsg: any) => {
                  setLoading(false)
                  toast.error(errorMsg)
                })
            }}
          >
            {({setFieldValue}) => (
              <Form>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='form-group col'>
                    <label htmlFor='InventoryType' className='fw-bold required'>
                      Inventory Type
                    </label>
                    <Field
                      as='select'
                      className='form-select form-select-md '
                      data-control='select2'
                      name='type'
                    >
                      <option>Select</option>
                      <option value='INCREASE ADJUSTMENT'>INCREASE ADJUSTMENT</option>
                      <option value='DECREASE ADJUSTMENT'>DECREASE ADJUSTMENT</option>
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='type' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='name' className='fw-bold required'>
                      Reference
                    </label>
                    <Field
                      name='reference'
                      placeholder='Reference'
                      className='form-control form-control-lg '
                      type='text'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='reference' />
                    </div>
                  </div>
                  <div className='col fv-row'>
                    <label className='fw-bold required'>
                      <span>Product</span>
                    </label>

                    <div className='row fv-row'>
                      <div className='col multisel'>
                        <Select
                          options={productsOptions}
                          placeholder='Select'
                          name='product'
                          className='multi-select-container'
                          classNamePrefix='multi-select'
                          onChange={(e: any) => {
                            if (e?.value) {
                              setFieldValue('product', e.value)
                            } else {
                              setFieldValue('product', '')
                            }
                          }}
                          closeMenuOnSelect={true}
                          isClearable={true}
                          components={animatedComponents}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='product' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='location' className='fw-bold required'>
                      Location
                    </label>
                    <Field as='select' className='form-select form-select-md ' name='location'>
                      <option> Select</option>
                      {locations &&
                        locations.map((location: any, index: any) => (
                          <option key={index} value={location._id || ''}>
                            {location.name}
                          </option>
                        ))}
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='location' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='quantity' className='fw-bold required'>
                      Quantity
                    </label>
                    <Field
                      name='quantity'
                      placeholder='Quantity'
                      className='form-control form-control-lg '
                      onBlur={(e: any) => {
                        const value: number = e.target.value
                        const roundedValue = formatNumber(value)
                        setFieldValue('quantity', roundedValue)
                      }}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='quantity' />
                    </div>
                  </div>
                  <div className='col '>
                    <label htmlFor='expiryDate' className='fw-bold'>
                      Expiry Date
                    </label>
                    <div className='row fv-row'>
                      <DatePicker
                        name='expiryDate'
                        cleanable={true}
                        format='dd/MM/yyyy'
                        placeholder='Select Date'
                        onChange={(value: any) => {
                          setFieldValue(`expiryDate`, value)
                        }}
                      />
                      <div className='text-danger'>
                        <ErrorMessage name='expiryDate' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex justify-content-end mt-10'>
                  <Link
                    to='/inventory'
                    type='submit'
                    className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Cancel'
                  >
                    <span className='indicator-label'>Cancel</span>
                  </Link>
                  <button
                    type='submit'
                    className='btn btn-lg  btn-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Submit'
                    id='liveToastBtn'
                    disabled={loading}
                  >
                    <span className='indicator-label'> {loading ? 'Submitting...' : 'Submit'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
