import React from 'react'
import {Department} from './components/Departments'
import {Route, Routes} from 'react-router-dom'

const Departments: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Department className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}

export {Departments}
