import * as LocationCRUD from './LocationCRUD'
import {LocationSlice} from './LocationSlice'
import {LocationTableModel} from '../models/LocationModel'

const {actions} = LocationSlice

export const fetchAll: any = (params: LocationTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return LocationCRUD.findAll(params).then((response) => {
    dispatch(actions.setLocations({locations: response.data.data}))
    dispatch(actions.setPagination({pagination: response.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchOne = (id: string) => (dispatch: any) => {
  return LocationCRUD.findOne(id).then((response) => {
    dispatch(actions.setLocation({location: response.data}))
  })
}

export const create = (data: any, params: LocationTableModel) => async (dispatch: any) => {
  try {
    const res = await LocationCRUD.create(data)
    const successMsg = res.data.message

    LocationCRUD.findAll(params).then((response) => {
      dispatch(actions.setLocations({locations: response.data.data}))
      dispatch(actions.setPagination({pagination: response.data.pagination}))
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const update =
  (_id: string, name: string, params: LocationTableModel) => async (dispatch: any) => {
    try {
      const res = await LocationCRUD.update(_id, name)
      const successMsg = res.data.message
      LocationCRUD.findAll(params).then((response) => {
        dispatch(actions.setLocations({locations: response.data.data}))
        dispatch(actions.setPagination({pagination: response.data.pagination}))
      })
      return successMsg
    } catch (error: any) {
      const errors = error?.response?.data?.message
      let errorMsg
      if (typeof errors === 'string') {
        errorMsg = errors
      } else {
        errorMsg = 'Something went wrong'
      }
      throw errorMsg
    }
  }

export const remove = (_id: string, params: LocationTableModel) => async (dispatch: any) => {
  try {
    const res = await LocationCRUD.remove(_id)
    const successMsg = res.data.message
    LocationCRUD.findAll(params).then((response) => {
      dispatch(actions.setLocations({locations: response.data.data}))
      dispatch(actions.setPagination({pagination: response.data.pagination}))
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}
