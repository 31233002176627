import {Field, ErrorMessage, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {AssetInitValues} from '../models/AssetModel'
import * as LocationAction from '../../locations/redux/LocationAction'
import * as ProductAction from '../../products/redux/ProductAction'
import * as AssetAction from '../redux/AssetAction'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import * as actions from '../redux/AssetAction'
import {LocationTableModelInitValues} from '../../locations/models/LocationModel'
import {ProductListingModelInitValues} from '../../products/models/ProductModel'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'
import {formatNumber} from '../../../helper'

type Props = {
  className: string
}

export const AssetEdit: React.FC<Props> = ({className}) => {
  const [loadingBtn, setLoading] = useState(false)
  const asset: any = useSelector<RootState>(({assets}) => assets.asset, shallowEqual)

  const initialAssetValues = {
    ...asset,
    product: asset?.product?._id || '',
    location: asset?.location?._id || '',
  }

  const loading: any = useSelector<RootState>(({assets}) => assets.loading, shallowEqual)
  const products: any = useSelector<RootState>((state) => state.products.products, shallowEqual)
  const locations: any = useSelector<RootState>(({location}) => location.locations, shallowEqual)

  let fixedProducts =
    products &&
    products.filter((product: any) => {
      return product?.category?.assetType === 'FIXED'
    })

  const dispatch: any = useDispatch()
  const navigate = useNavigate()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(ProductAction.findAll(ProductListingModelInitValues))
    dispatch(AssetAction.fetchOne(id))
    dispatch(LocationAction.fetchAll(LocationTableModelInitValues))

    return () => {
      dispatch(actions.resetAsset(AssetInitValues))
    }
  }, [dispatch, id])

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Assets List', url: '/assets'},
    {name: 'Edit Asset', url: ''},
  ]

  return (
    <>
      <div className={`card ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3'>Edit Asset</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            asset && asset?._id !== '' ? (
              <Formik
                initialValues={initialAssetValues}
                enableReinitialize
                validationSchema={Yup.object({
                  number: Yup.string()
                    .trim('Asset No must have non-empty spaces')
                    .strict(true)
                    .required('Asset Number is required'),
                  grn: Yup.string()
                    .trim('Goods Received Note must have non-empty spaces')
                    .strict(true)
                    .nullable(),
                  product: Yup.string().required('Product is required'),
                  location: Yup.string().required('Product is required'),
                  marketValue: Yup.number()
                    .typeError('Market Value is invalid')
                    .nullable()
                    .positive('Market Value must be more than 0'),
                  notes: Yup.string().trim('Notes must have non-empty spaces'),
                })}
                onSubmit={(values: any) => {
                  setLoading(true)
                  const valuesChanged =
                    JSON.stringify(initialAssetValues) !== JSON.stringify(values)

                  if (valuesChanged) {
                    dispatch(AssetAction.update(id, values))
                      .then((response: string) => {
                        toast.success(response)
                        setLoading(false)
                        navigate('/assets')
                      })
                      .catch((errorMsg: any) => {
                        toast.error(errorMsg)
                        setLoading(false)
                      })
                  } else {
                    toast.info('No changes made.')
                    setLoading(false)
                  }
                }}
              >
                {({values, setFieldValue}) => (
                  <Form>
                    <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                      <div className='form-group col'>
                        <label htmlFor='name' className='fw-bold required'>
                          Asset No.
                        </label>
                        <Field
                          name='number'
                          placeholder='Asset No.'
                          className='form-control form-control-lg'
                          type='text'
                          maxLength={255}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='number' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='GRN' className='fw-bold '>
                          Goods Received Note No.
                        </label>
                        <Field
                          name='grn'
                          placeholder='Enter a valid GRN number'
                          className='form-control form-control-lg'
                          type='text'
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='product' className='fw-bold required'>
                          Product
                        </label>
                        <Field
                          as='select'
                          className='form-select form-select-md'
                          name='product'
                          onChange={(e: any) => {
                            console.log('value :', e.target.value)
                            if (e.target.value && e.target.value !== 'Select') {
                              setFieldValue('product', e.target.value)
                            } else {
                              setFieldValue('product', '')
                            }
                          }}
                        >
                          <option> Select</option>
                          {fixedProducts.map((product: any, index: any) => (
                            <option
                              key={index}
                              value={product._id || ''}
                              selected={product._id === initialAssetValues.product}
                            >
                              {product.name}
                            </option>
                          ))}
                        </Field>
                        <div className='text-danger'>
                          <ErrorMessage name='product' />
                        </div>
                      </div>

                      <div className='form-group col'>
                        <label htmlFor='quantity' className='fw-bold'>
                          Quantity
                        </label>
                        <Field
                          name='quantity'
                          placeholder='Quantity'
                          className='form-control form-control-lg'
                          disabled
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='location' className='fw-bold required'>
                          Location
                        </label>
                        <Field
                          as='select'
                          className='form-select form-select-md'
                          name='location'
                          onChange={(e: any) => {
                            if (e.target.value && e.target.value !== 'Select') {
                              setFieldValue('location', e.target.value)
                            } else {
                              setFieldValue('location', '')
                            }
                          }}
                        >
                          <option> Select</option>
                          {locations.map((location: any, index: any) => (
                            <option
                              key={index}
                              value={location._id || ''}
                              selected={location._id === asset.location}
                            >
                              {location.name}
                            </option>
                          ))}
                        </Field>
                        <div className='text-danger'>
                          <ErrorMessage name='location' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='disposal' className='fw-bold'>
                          Disposal
                        </label>
                        <div className='d-flex flex-row'>
                          <div className='p-2 bd-highlight'>
                            <label>
                              <Field type='radio' name='disposal' value='NO' />
                              &nbsp; No
                            </label>
                          </div>
                          <div className='p-2 bd-highlight'>
                            <label>
                              <Field type='radio' name='disposal' value='YES' />
                              &nbsp; Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='marketValue' className='fw-bold'>
                          Market Value
                        </label>
                        <Field
                          name='marketValue'
                          placeholder='Market Value'
                          className='form-control form-control-lg'
                          onBlur={(e: any) => {
                            const value: number = e.target.value
                            const roundedValue = formatNumber(value)
                            setFieldValue('marketValue', roundedValue)
                          }}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='marketValue' />
                        </div>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='notes' className='fw-bold '>
                          Notes
                        </label>
                        <Field
                          name='notes'
                          placeholder='write a note'
                          className='form-control form-control-lg'
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-end mt-10'>
                      <Link
                        to='/assets'
                        type='submit'
                        className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Cancel'
                      >
                        <span className='indicator-label'>Cancel</span>
                      </Link>
                      <button
                        type='submit'
                        className='btn btn-lg  btn-primary me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Submit'
                        id='liveToastBtn'
                        disabled={loadingBtn}
                      >
                        <span className='indicator-label'>
                          {loadingBtn ? 'Submitting...' : 'Submit'}
                        </span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
