import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {formatDate, formatDateTime, formatMoney, formatFileName} from '../../../helper'
import Base64HeaderImage from '../../../components/LogoBase64'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default function BudgetDetailPdf({budget}: any) {
  const generatePDF = () => {
    const documentDefinition: any = {
      defaultStyle: {
        fontSize: 9,
      },
      content: [
        {
          image: Base64HeaderImage(),
          fit: [150, 150],
          margin: [180, 10, 180, 30],
        },

        {text: 'Budget Detail', fontSize: 14, margin: [0, 0, 0, 20]},

        {
          table: {
            widths: ['*', '*', '*', '*'],
            heights: 15,
            body: [
              [
                {text: 'Title', bold: true},
                budget.title,
                {text: 'Budget No', bold: true},
                budget.number,
              ],
              [
                {text: 'Created Date', bold: true},
                formatDateTime(budget.createdAt),
                {text: 'Status', bold: true},
                budget.status,
              ],
              [
                {text: 'Start Date', bold: true},
                formatDate(budget.startDate),
                {text: 'End Date', bold: true},
                formatDate(budget.endDate),
              ],
              [
                {text: 'Grand Total', bold: true},
                formatMoney(budget?.total, true),
                {text: 'Budget Type', bold: true},
                budget.type,
              ],
              [
                {text: 'Request by Employee Name', bold: true},
                {text: budget?.createdBy?.fullName, colSpan: 3},
                '',
                '',
              ],

              ...(budget.approvedAt && budget.approvedBy
                ? [
                    [
                      {text: 'Approved At', bold: true},
                      formatDateTime(budget.approvedAt),
                      {text: 'Approved By', bold: true},
                      budget.approvedBy.fullName,
                    ],
                  ]
                : []),

              ...(budget.rejectedAt && budget.rejectedBy
                ? [
                    [
                      {text: 'Rejected At', bold: true},
                      formatDateTime(budget.rejectedAt),
                      {text: 'Rejected By', bold: true},
                      budget.rejectedBy.fullName,
                    ],
                  ]
                : []),

              ...(budget?.reason
                ? [
                    [
                      {text: 'Reason to Reject', bold: true},
                      {text: budget?.reason, colSpan: 3},
                      '',
                      '',
                    ],
                  ]
                : []),
            ],
          },
          margin: [0, 0, 0, 30],
        },

        // Iterate through sections
        ...budget.sections.map((section: any) => {
          const sectionContent = [
            {
              table: {
                widths: [150, '*', '*', '*', '*'],
                body: [
                  [
                    {text: 'Section Title', fontSize: 10, bold: true, colSpan: 2},
                    '',
                    {text: section.title, fontSize: 10, bold: true},
                    {text: 'Section Total', fontSize: 10, bold: true},
                    {text: 'PKR ' + section.total, fontSize: 10, bold: true},
                  ],
                  // Table header row
                  [
                    {text: 'Product', bold: true},
                    {text: 'UOM', bold: true},
                    {text: 'Quantity', bold: true},
                    {text: 'Rate', bold: true},
                    {text: 'Amount', bold: true},
                  ],
                  // Iterate through items in the section
                  ...section.items.map((item: any, index: any) => [
                    index + 1 + '. ' + item.name,
                    item.UOM,
                    item.quantity,
                    item.rate,
                    item.amount,
                  ]),
                ],
              },
              layout: {
                fillColor: function (rowIndex: any) {
                  if (rowIndex > 1) {
                    return rowIndex % 2 === 0 ? '#CCCCCC' : null
                  }
                  return null
                },
              },
              margin: [0, 10, 0, 10],
            },
          ]

          return sectionContent
        }),
        {
          table: {
            widths: [350, '*'],
            heights: 15,
            body: [
              [
                {text: 'Grand Total', fontSize: 12, bold: true, alignment: 'right'},
                {
                  text: formatMoney(budget?.total, true),
                  fontSize: 12,
                  bold: true,
                  alignment: 'right',
                },
              ],
            ],
          },
          margin: [0, 10, 0, 10],
        },
      ],

      footer: function (currentPage: any, pageCount: any) {
        return [
          {
            columns: [
              {
                text: 'Ref : ' + budget.number,
                fontSize: 8,
                alignment: 'left',
                margin: [8, 0, 0, 0],
              },
              {
                text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                fontSize: 8,
                alignment: 'center',
              },
              {
                text: `${new Date().getFullYear()} \u00A9 ${process.env.REACT_APP_NAME}`,
                fontSize: 8,
                alignment: 'right',
                margin: [0, 0, 8, 0],
              },
            ],
            absolutePosition: {x: 0, y: 25},
          },
        ]
      },
    }

    pdfMake.createPdf(documentDefinition).download(formatFileName(`FnB-${budget.number}`))
  }

  return (
    <div>
      <button
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        data-bs-trigger='hover'
        title='Download PDF'
        onClick={generatePDF}
      >
        <KTSVG
          path='/media/svg/icons/Devices/DownloadPdf.svg'
          className='svg-icon svg-icon-2x svg-icon-danger '
        />
      </button>
    </div>
  )
}
