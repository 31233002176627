import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  PurchaseOrderReturnModel,
  PurchaseOrderReturnInitValues,
} from '../models/PurchaseOrderReturnModel'

interface PurchaseOrderReturnState {
  loading: boolean
  purchaseOrderReturn: PurchaseOrderReturnModel
  purchaseOrderReturns: [PurchaseOrderReturnModel?]
  goodsReceivedNote: PurchaseOrderReturnModel
  pagination: {total: number; pages: number}
}

const initialState: PurchaseOrderReturnState = {
  loading: false,
  purchaseOrderReturn: PurchaseOrderReturnInitValues,
  purchaseOrderReturns: [],
  goodsReceivedNote: PurchaseOrderReturnInitValues,
  pagination: {total: 0, pages: 0},
}

export const PurchaseOrderReturnSlice = createSlice({
  name: 'purchaseOrderReturn',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setPurchaseOrdersReturn: (state: {purchaseOrderReturns: any}, action: PayloadAction<any>) => {
      state.purchaseOrderReturns = action.payload.purchaseOrderReturns
    },
    setPurchaseOrderReturn: (state: {purchaseOrderReturn: any}, action: PayloadAction<any>) => {
      state.purchaseOrderReturn = action.payload.purchaseOrderReturn
    },
    setGoodsReceivedNote: (state: {goodsReceivedNote: any}, action: PayloadAction<any>) => {
      state.goodsReceivedNote = action.payload.goodsReceivedNote
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
