import {SupplierBillTableModel} from '../models/SupplierBillModel'
import * as SupplierBillCRUD from './SupplierBillCRUD'
import {SupplierBillSlice} from './SupplierBillSlice'

const {actions} = SupplierBillSlice

export const fetchAll: any = (params: SupplierBillTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return SupplierBillCRUD.findAll(params).then((res) => {
    dispatch(actions.setSupplierBills({supplierBills: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchReport = (year: number) => (dispatch: any) => {
  return SupplierBillCRUD.report(year).then((response) => {
    dispatch(actions.setSupplierBillReport({report: response.data.data}))
  })
}

export const fetchSuppliersReport = (year: number, month: number) => (dispatch: any) => {
  return SupplierBillCRUD.suppliersReport(year, month).then((response) => {
    dispatch(actions.setSupplierBillReport({report: response.data.data}))
  })
}

export const fetchItemsReport = (year: number, month: number) => (dispatch: any) => {
  return SupplierBillCRUD.itemsReport(year, month).then((response) => {
    dispatch(actions.setSupplierBillReport({report: response.data.data}))
  })
}

export const fetchOne = (id: any) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await SupplierBillCRUD.findOne(id)
    dispatch(actions.setSupplierBill({supplierBill: res.data.data}))
    dispatch(actions.stopLoading())
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const fetchApproved = () => (dispatch: any) => {
  return SupplierBillCRUD.findApproved().then((res) => {
    dispatch(actions.setSupplierBills({supplierBills: res.data.data}))
  })
}

export const create = (data: any) => async (dispatch: any) => {
  try {
    const res = await SupplierBillCRUD.create(data)
    const supplierBill = res.data.data
    const successMsg = res.data.message
    dispatch(actions.setSupplierBill({supplierBill: supplierBill}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const approve = (id: any) => async (dispatch: any) => {
  try {
    const res = await SupplierBillCRUD.approve(id)

    const supplierBill = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setSupplierBill({supplierBill: supplierBill}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const reject = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await SupplierBillCRUD.reject(id, data)
    const supplierBill = res.data.data
    const successMsg = res.data.message
    dispatch(actions.setSupplierBill({supplierBill: supplierBill}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const remove = (id: any, params: SupplierBillTableModel) => async (dispatch: any) => {
  try {
    const res = await SupplierBillCRUD.remove(id)
    const successMsg = res.data.message

    SupplierBillCRUD.findAll(params).then((res) => {
      dispatch(actions.setSupplierBills({supplierBills: res.data.data}))
      dispatch(actions.setPagination({pagination: res.data.pagination}))
      dispatch(actions.stopLoading())
    })
    return successMsg
  } catch (error: any) {
    console.log(error)
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}
