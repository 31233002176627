import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import * as OutwardAction from '../redux/OutwardAction'
import {toast} from 'react-toastify'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDateTime, formatNumber} from '../../../helper'
import {PrintButton} from '../../../components/PrintButton'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const OutwardDetail: React.FC<Props> = ({className}) => {
  const outward: any = useSelector<RootState>(({outwards}) => outwards.outward, shallowEqual)

  const loading: any = useSelector<RootState>(({outwards}) => outwards.loading, shallowEqual)

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(OutwardAction.fetchOne(id))
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Outwards List', url: '/outward'},
    {name: 'Outward Detail', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Outward Detail</span>
          </h1>
          <PrintButton />
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            outward && outward?._id !== '' ? (
              <>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Outward No.</b>
                      </td>
                      <td>{outward?.number}</td>
                      <td>
                        <b>Created Date</b>
                      </td>
                      <td>{formatDateTime(outward?.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Material Issuance No.</b>
                      </td>
                      <td>{outward?.materialIssuance?.number}</td>
                      <td>
                        <b>Provided by Employee Name</b>
                      </td>
                      <td>{outward?.providedToEmployee?.fullName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Provided by Employee Name</b>
                      </td>
                      <td>{outward?.providedToDepartment?.name}</td>
                      <td>
                        <b>Location</b>
                      </td>
                      <td>{outward?.location?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Status</b>
                      </td>
                      <td>{outward?.status}</td>
                      <td colSpan={2}></td>
                    </tr>
                    {outward?.approvedAt && outward?.approvedBy && (
                      <tr>
                        <td>
                          <b>Approved At</b>
                        </td>
                        <td>{formatDateTime(outward?.approvedAt)}</td>
                        <td>
                          <b>Approved By</b>
                        </td>
                        <td>{outward.approvedBy.fullName}</td>
                      </tr>
                    )}
                    {outward?.rejectedAt && outward?.rejectedBy && (
                      <tr>
                        <td>
                          <b>Rejected At</b>
                        </td>
                        <td>{formatDateTime(outward?.rejectedAt)}</td>
                        <td>
                          <b>Rejected By</b>
                        </td>
                        <td>{outward?.rejectedBy?.firstName}</td>
                      </tr>
                    )}
                    {outward?.rejectionReason && (
                      <tr>
                        <td>
                          <b>Reason to Reject</b>
                        </td>
                        <td colSpan={3}>{outward?.rejectionReason}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                  <thead>
                    <tr>
                      <th style={{width: 40}}>
                        <b>#</b>
                      </th>
                      <th>
                        <b>Product</b>
                      </th>
                      <th>
                        <b>UOM</b>
                      </th>
                      <th>
                        <b>Quantity</b>
                      </th>
                      <th>
                        <b>Asset No.</b>
                      </th>
                      <th>
                        <b>Remarks</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {outward.items.map((materialItem: any, index: any) => (
                      <tr key={index}>
                        <td data-label='#'>{index + 1}</td>
                        <td data-label='Name'>{materialItem?.name}</td>
                        <td data-label='UOM'>{materialItem?.UOM}</td>
                        <td data-label='Quantity'>{formatNumber(materialItem?.quantity)}</td>
                        <td data-label='Asset No.'>{materialItem?.assetNumber}</td>
                        <td data-label='Remarks'>{materialItem?.remarks}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
