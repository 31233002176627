interface MaterialIssuanceItem {
  _id: string
  qrCode: string
  expiryDate: string
  product: string
  name: string
  UOM: string
  quantity: number | string
  assetNumber: number | string
  remarks: string
}

export interface MaterialIssuanceModel {
  _id: string
  createdAt?: string
  approvedAt?: string
  approvedBy?: string
  rejectedBy?: string
  rejectedAt?: string
  rejectionReason?: string
  number: string
  materialRequisition: string
  providedToEmployee: string
  providedToDepartment: string
  location: string
  providedToEmployeeName: string
  providedToDepartmentName: string
  locationName: string
  items: [MaterialIssuanceItem]
}

export const MaterialIssuanceInitValues: MaterialIssuanceModel = {
  _id: '',
  createdAt: '',
  approvedAt: '',
  approvedBy: '',
  rejectedBy: '',
  rejectedAt: '',
  rejectionReason: '',
  number: '',
  materialRequisition: '',
  providedToEmployee: '',
  providedToDepartment: '',
  location: '',
  providedToEmployeeName: '',
  providedToDepartmentName: '',
  locationName: '',

  items: [
    {
      _id: '',
      qrCode: '',
      expiryDate: '',
      product: '',
      name: '',
      UOM: '',
      quantity: 0,
      assetNumber: '',
      remarks: '',
    },
  ],
}

export interface MaterialIssuanceSearchModel {
  number: string
  status: string
  createdAt: any
  approvedBy: string
  approvedAt: any
}
export const MaterialIssuanceSearchModelInitValues: MaterialIssuanceSearchModel = {
  number: '',
  status: '',
  createdAt: [],
  approvedBy: '',
  approvedAt: [],
}

export interface MaterialIssuanceTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: MaterialIssuanceSearchModel
}

export const MaterialIssuanceTableModelInitValues: MaterialIssuanceTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: MaterialIssuanceSearchModelInitValues,
}
