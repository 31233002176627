import axios from 'axios'
import {SupplierModel, SupplierTableModel} from '../models/SupplierModel'

const API_URL = process.env.REACT_APP_API_URL
const SUPPLIER_URL = `${API_URL}/suppliers`

export function fetchAll(params: SupplierTableModel) {
  return axios.get(`${SUPPLIER_URL}`, {params})
}

export function fetchOne(id: any) {
  return axios.get(`${SUPPLIER_URL}/${id}`)
}

export function findApproved() {
  return axios.get(`${SUPPLIER_URL}/approved`)
}

export function create(data: SupplierModel) {
  return axios.post(SUPPLIER_URL, data)
}

export function update(id: any, data: any) {
  return axios.put(`${SUPPLIER_URL}/${id}`, data)
}

export function approve(id: any) {
  return axios.patch(`${SUPPLIER_URL}/${id}/approve`)
}

export function reject(id: any, data: any) {
  return axios.patch(`${SUPPLIER_URL}/${id}/reject`, data)
}
