import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/users`
const NOTIFICATION_URL = `${API_URL}/notifications`

export function fetchAll() {
  return axios.get(NOTIFICATION_URL)
}

export function markAsRead(data: any) {
  return axios.patch(NOTIFICATION_URL, data)
}

export function update(data: any) {
  return axios.patch(`${USER_URL}/update-notification`, data)
}
