import React, {useEffect} from 'react'
import {StatisticsWidget5} from '../../../../_metronic/partials/widgets'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {fetchAll} from '../redux/StatisticsAction'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/RootReducer'
import {Loader} from '../../../components/Loader'

type Props = {
  className?: string
}

export const Statistics: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()

  const loading: any = useSelector<RootState>(({statistics}) => statistics.loading, shallowEqual)
  const statistics: any = useSelector<RootState>(
    ({statistics}) => statistics.statistics,
    shallowEqual
  )

  useEffect(() => {
    dispatch(fetchAll())
  }, [dispatch])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Statistics', url: '/statistics'},
  ]

  return (
    <div className={`card card-custom ${className}`}>
      <Breadcrumbs routes={routes} />
      <div className='card-header border-0 pt-5'>
        <h1 className='card-title'>
          <span className='card-label fw-bolder fs-3 mb-1'>Statistics</span>
        </h1>
      </div>
      <div className='card-body'>
        {!loading ? (
          <>
            {/* begin::Row */}
            <div className='row g-5 g-xl-8  mb-2'>
              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/users.svg'
                  color='#26402d'
                  iconColor='primary'
                  title='Users'
                  descriptionTitle='Total'
                  description={statistics && statistics?.users?.total}
                  descriptionTitle2=''
                  description2=''
                />
              </div>

              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/roles.svg'
                  color='#2b3e50'
                  iconColor='dark'
                  title='Roles'
                  descriptionTitle='Total'
                  description={statistics && statistics?.roles?.total}
                  descriptionTitle2=''
                  description2=''
                />
              </div>

              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/locations.svg'
                  color='#bf262b'
                  iconColor='success'
                  title='Locations'
                  descriptionTitle='Total'
                  description={statistics && statistics?.locations?.total}
                  descriptionTitle2=''
                  description2=''
                />
              </div>

              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/departments.svg'
                  color='#78281f'
                  iconColor='warning'
                  title='Departments'
                  descriptionTitle='Total'
                  description={statistics && statistics?.departments?.total}
                  descriptionTitle2=''
                  description2=''
                />
              </div>
            </div>
            {/* end row */}

            {/* begin::Row */}
            <div className='row g-5 g-xl-8  mb-2'>
              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/products.svg'
                  color='#512e5f'
                  iconColor='danger'
                  title='Products'
                  descriptionTitle='Total'
                  description={statistics && statistics?.products.total}
                  descriptionTitle2=''
                  description2=''
                />
              </div>

              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/suppliers.svg'
                  color='#d14f1b'
                  iconColor='primary'
                  title='Suppliers'
                  descriptionTitle='Total'
                  description={statistics && statistics?.suppliers?.total}
                  descriptionTitle2=''
                  description2=''
                />
              </div>
              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/budgets.svg'
                  color='#154360'
                  iconColor='dark'
                  title='Budgets'
                  descriptionTitle="Month's"
                  description={statistics && statistics?.budgets?.monthly}
                  descriptionTitle2=''
                  description2=''
                />
              </div>

              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/material-requisitions.svg'
                  color='#6e2c00'
                  iconColor='success'
                  title='Material Requisitions'
                  descriptionTitle="Today's"
                  description={statistics && statistics?.requisitions?.daily}
                  descriptionTitle2="Week's"
                  description2={statistics && statistics?.requisitions?.weekly}
                />
              </div>
            </div>
            {/* end row */}

            {/* begin::Row */}
            <div className='row g-5 g-xl-8  mb-2'>
              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/material-issuances.svg'
                  color='#d69b1a'
                  iconColor='success'
                  title='Material Issuances'
                  descriptionTitle="Today's"
                  description={statistics && statistics?.issuances?.daily}
                  descriptionTitle2="Week's"
                  description2={statistics && statistics?.issuances?.weekly}
                />
              </div>

              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/outwards.svg'
                  color='#7e5109'
                  iconColor='success'
                  title='Outwards'
                  descriptionTitle="Today's"
                  description={statistics && statistics?.outwards?.daily}
                  descriptionTitle2="Week's"
                  description2={statistics && statistics?.outwards?.weekly}
                />
              </div>
              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/inwards.svg'
                  color='#6a1b9a'
                  iconColor='success'
                  title='Inwards'
                  descriptionTitle="Today's"
                  description={statistics && statistics?.inwards?.daily}
                  descriptionTitle2="Week's"
                  description2={statistics && statistics?.inwards?.weekly}
                />
              </div>

              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/purchase-orders.svg'
                  color='#05965a'
                  iconColor='success'
                  title='Purchase Orders'
                  descriptionTitle="Today's"
                  description={statistics && statistics?.purchaseOrders?.daily}
                  descriptionTitle2="Week's"
                  description2={statistics && statistics?.purchaseOrders?.weekly}
                />
              </div>
            </div>
            {/* end row */}

            {/* begin::Row */}
            <div className='row g-5 g-xl-8 mb-2'>
              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/material-inspections.svg'
                  color='#943126'
                  iconColor='success'
                  title='Material Inspections'
                  descriptionTitle="Today's"
                  description={statistics && statistics?.inspections?.daily}
                  descriptionTitle2="Week's"
                  description2={statistics && statistics?.inspections?.weekly}
                />
              </div>

              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/good-received-notes.svg'
                  color='#2e4053'
                  iconColor='success'
                  title='Goods Received Notes'
                  descriptionTitle="Today's"
                  description={statistics && statistics?.goodsReceivedNotes?.daily}
                  descriptionTitle2="Week's"
                  description2={statistics && statistics?.goodsReceivedNotes?.weekly}
                />
              </div>
              <div className='col-xl-3 col-lg-6 col-12'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/statistics/supplier-bills.svg'
                  color='#2980b9'
                  iconColor='success'
                  title='Supplier Bills'
                  descriptionTitle="Today's"
                  description={statistics && statistics?.supplierBills?.daily}
                  descriptionTitle2="Week's"
                  description2={statistics && statistics?.supplierBills?.weekly}
                />
              </div>
            </div>
            {/* end row */}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  )
}
