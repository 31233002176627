import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {StepNav1} from '../../../components/step-nav1'
import {UserModel} from '../../auth/models/UserModel'
import * as LocationAction from '../../locations/redux/LocationAction'
import * as DepartmentAction from '../../departments/redux/DepartmentAction'
import * as ProductAction from '../../products/redux/ProductAction'
import * as InventoryAction from '../../inventory/redux/InventoryAction'
import {MaterialRequisitionInitValues} from '../models/MaterialRequisitionModel'
import {create} from '../redux/MaterialRequisitionAction'
import {LocationTableModelInitValues} from '../../locations/models/LocationModel'
import {DepartmentTableModelInitValues} from '../../departments/models/DepartmentModel'
import {ProductListingModelInitValues} from '../../products/models/ProductModel'
import {InventoryReportListingModelInitValues} from '../../inventory/models/InventoryReportModel'
import {formatNumber} from '../../../helper'

type Props = {
  className: string
}

export const MaterialRequisitionAdd: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = React.useState(false)
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const locations: any = useSelector<RootState>(({location}) => location.locations, shallowEqual)
  const departments: any = useSelector<RootState>(
    ({department}) => department.departments,
    shallowEqual
  )
  const inventories: any = useSelector<RootState>(
    ({inventories}) => inventories.inventories,
    shallowEqual
  )
  const products: any = useSelector<RootState>((state) => state.products.products, shallowEqual)
  let productsOptions = products.map((product: any) => {
    return {
      value: product._id,
      label: product.name,
      UOM: product.UOM,
      quantityInStock: product.quantityInStock,
    }
  })

  let filteredProductOption = productsOptions.filter((product: any) => product.quantityInStock > 0)

  const animatedComponents = makeAnimated()

  let navigate = useNavigate()
  const dispatch: any = useDispatch()
  useEffect(() => {
    dispatch(ProductAction.findAll(ProductListingModelInitValues))
    dispatch(LocationAction.fetchAll(LocationTableModelInitValues))
    dispatch(DepartmentAction.fetchAll(DepartmentTableModelInitValues))
    dispatch(InventoryAction.fetchReport(InventoryReportListingModelInitValues))
  }, [dispatch])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Requisitions List', url: '/material-requisition'},
    {name: 'Material Requisition Form', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='border-0 pt-5 p-5'>
          <StepNav1 itemNumber={1} />
        </div>
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Material Requisition Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          <Formik
            initialValues={MaterialRequisitionInitValues}
            validationSchema={Yup.object({
              location: Yup.string().required('Location is required'),
              department: Yup.string().required('Department is required'),
              items: Yup.array().of(
                Yup.object().shape({
                  product: Yup.string().required('Product is required'),
                  UOM: Yup.string().required('Packing details is required'),
                  quantity: Yup.number()
                    .required('Quantity is required')
                    .typeError('Quantity is invalid')
                    .nullable()
                    .positive('Quantity must be more than 0')
                    .test(
                      'quantity',
                      'You cannot add more quantity than present in stock',
                      function (value: any, el: any) {
                        const product = el.parent.product
                        const itemInStock = filteredProductOption?.find(
                          (item: any) => item.value == product
                        )

                        return !(value > +formatNumber(itemInStock?.quantityInStock))
                      }
                    ),
                })
              ),
            })}
            onSubmit={(values: any) => {
              setLoading(true)
              dispatch(create(values))
                .then((response: any) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/material-requisition')
                })
                .catch((error: any) => {
                  toast.error(error)
                  setLoading(false)
                })
            }}
          >
            {({values, setFieldValue}) => (
              <Form>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='form-group col'>
                    <label htmlFor='name' className='fw-bold required'>
                      Request by Employee Name
                    </label>
                    <Field
                      name='requestedBy'
                      value={user.fullName}
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='requestedBy' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='department' className='fw-bold required'>
                      Request by Department
                    </label>
                    <Field as='select' className='form-select form-select-md ' name='department'>
                      <option> Select</option>
                      {Array.isArray(departments) &&
                        departments.map((department: any, index: any) => (
                          <option key={index} value={department._id || ''}>
                            {department.name}
                          </option>
                        ))}
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='department' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='location' className='fw-bold required'>
                      Location
                    </label>
                    <Field as='select' className='form-select form-select-md ' name='location'>
                      <option> Select</option>
                      {Array.isArray(locations) &&
                        locations.map((location: any, index: any) => (
                          <option key={index} value={location._id || ''}>
                            {location.name}
                          </option>
                        ))}
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='location' />
                    </div>
                  </div>
                </div>
                <FieldArray
                  name='items'
                  render={(arrayHelpers) => (
                    <div className='mt-15'>
                      <table className='table tbl-md-responsivness'>
                        <thead>
                          <tr>
                            <th>
                              <label className='form-label required'>Product</label>
                            </th>
                            <th>
                              <label className='form-label required'>UOM</label>
                            </th>
                            <th>
                              <label className='form-label required'>Quantity</label>
                            </th>
                            <th>
                              <label className='form-label'></label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.items.length > 0 &&
                            values.items.map((materialItem: any, index: any) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td data-label='Product'>
                                    <Field name={`values.items.${index}.name`} type='hidden' />

                                    <div className='multisel w-100 d-flex flex-column'>
                                      <Select
                                        options={filteredProductOption}
                                        placeholder='Select'
                                        name={`items.${index}.product`}
                                        className='multi-select-container w-100'
                                        classNamePrefix='multi-select'
                                        value={filteredProductOption.filter(function (option: any) {
                                          return option.value == values.items[index].product
                                        })}
                                        onChange={(e: any) => {
                                          if (e?.value) {
                                            const found = values?.items?.find(
                                              (item: any, index: any) => {
                                                return item.product == e?.value
                                              }
                                            )
                                            if (found) {
                                              toast.error(
                                                'You cannot add multiple products with the same name!'
                                              )
                                              return
                                            } else {
                                              setFieldValue(`items.${index}.product`, e.value)
                                              setFieldValue(`items.${index}.name`, e.label)
                                              setFieldValue(`items.${index}.UOM`, e.UOM)
                                            }
                                          } else {
                                            setFieldValue(`items.${index}.product`, '')
                                            setFieldValue(`items.${index}.name`, '')
                                            setFieldValue(`items.${index}.UOM`, '')
                                          }
                                        }}
                                        closeMenuOnSelect={true}
                                        isClearable={true}
                                        components={animatedComponents}
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.product`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label='UOM'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.UOM`}
                                        placeholder='UOM'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />

                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.UOM`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label='Quantity'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.quantity`}
                                        placeholder='Quantity'
                                        className='form-control form-control-md'
                                        onBlur={(e: any) => {
                                          const value: number = e.target.value
                                          const roundedValue = formatNumber(value)
                                          setFieldValue(`items.${index}.quantity`, roundedValue)
                                        }}
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.quantity`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <div className='d-flex flex-row w-100'>
                                      <button
                                        type='button'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                        data-bs-toggle='tooltip'
                                        data-bs-placement='top'
                                        title='Add New Item'
                                        onClick={() =>
                                          arrayHelpers.push({
                                            product: '',
                                            name: '',
                                            UOM: '',
                                            quantity: '',
                                          })
                                        }
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/arrows/arr013.svg'
                                          className='svg-icon  svg-icon-3'
                                        />
                                      </button>

                                      <button
                                        type='button'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                        onClick={() => arrayHelpers.remove(index)}
                                        data-bs-toggle='tooltip'
                                        data-bs-placement='top'
                                        title='Remove Item'
                                        disabled={values.items.length > 1 ? false : true}
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen027.svg'
                                          className='svg-icon svg-icon-3'
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                />

                <div className='d-flex justify-content-end mt-10'>
                  <Link
                    to='/material-requisition'
                    className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Cancel'
                  >
                    <span className='indicator-label'>Cancel</span>
                  </Link>

                  <button
                    type='submit'
                    className='btn btn-lg btn-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Submit'
                    id='liveToastBtn'
                    disabled={loading}
                  >
                    <span className='indicator-label'>{loading ? 'Submitting...' : 'Submit'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
