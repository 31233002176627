import * as PurchaseOrderReturnCRUD from './PurchaseOrderReturnCRUD'
import {PurchaseOrderReturnSlice} from './PurchaseOrderReturnSlice'
import {deepCopy, formatNumber} from '../../../helper'
import {PurchaseOrderReturnTableModel} from '../models/PurchaseOrderReturnModel'

const {actions} = PurchaseOrderReturnSlice

export const fetchAll: any = (params: PurchaseOrderReturnTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return PurchaseOrderReturnCRUD.findAll(params).then((res) => {
    dispatch(actions.setPurchaseOrdersReturn({purchaseOrderReturns: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const removePurchaseOrderReturnItem = (values: any, index: number) => (dispatch: any) => {
  let uPurchaseOrderReturn = deepCopy(values)
  const items = values?.items.filter((item: any, i: any) => i !== index)
  uPurchaseOrderReturn.items = items
  dispatch(actions.setPurchaseOrderReturn({purchaseOrderReturn: uPurchaseOrderReturn}))
  dispatch(onChangeItem(uPurchaseOrderReturn))
}

export const onChangeItem = (values: any) => (dispatch: any) => {
  let uPurchaseOrderReturn = JSON.parse(JSON.stringify(values))
  const totalAmount = uPurchaseOrderReturn.items.reduce((prev: any, item: any) => {
    item.price = +formatNumber(item.quantity || 0) * +formatNumber(item.rate || 0)
    let itemExclusiveTax = +formatNumber(item.quantity || 0) * +formatNumber(item.rate || 0)
    item.price = formatNumber(itemExclusiveTax)
    const itemPriceInclusiveTax = +formatNumber(item.price) + +formatNumber(item.tax)
    item.priceInclusiveTax = formatNumber(itemPriceInclusiveTax)
    const total = +formatNumber(item.priceInclusiveTax) - +formatNumber(item.discount)
    item.total = formatNumber(total)
    return prev + total
  }, 0)
  const totalPrice = uPurchaseOrderReturn.items.reduce((prev: any, item: any) => {
    return prev + +formatNumber(item.price)
  }, 0)
  const totalTax = uPurchaseOrderReturn.items.reduce((prev: any, item: any) => {
    return prev + +formatNumber(item.tax)
  }, 0)
  const totalDiscount = uPurchaseOrderReturn.items.reduce((prev: any, item: any) => {
    return prev + +formatNumber(item.discount)
  }, 0)
  uPurchaseOrderReturn.totalAmount = formatNumber(totalAmount)
  uPurchaseOrderReturn.totalPrice = formatNumber(totalPrice)
  uPurchaseOrderReturn.totalTax = formatNumber(totalTax)
  uPurchaseOrderReturn.totalDiscount = formatNumber(totalDiscount)
  dispatch(actions.setPurchaseOrderReturn({purchaseOrderReturn: uPurchaseOrderReturn}))
}

export const onChangeGoodsReceivedNote =
  (e: any, values: any, goodsReceivedNotes: any) => (dispatch: any) => {
    const selectedGoodsReceivedNote = goodsReceivedNotes.find(
      (goodsReceivedNote: any) => goodsReceivedNote._id === e.value
    )

    let uPurchaseOrderReturn = JSON.parse(JSON.stringify(values))

    if (selectedGoodsReceivedNote) {
      uPurchaseOrderReturn.goodsReceivedNote = selectedGoodsReceivedNote._id
      uPurchaseOrderReturn.purchaseOrder = selectedGoodsReceivedNote?.purchaseOrder?._id
      uPurchaseOrderReturn.purchaseOrderNumber = selectedGoodsReceivedNote?.purchaseOrder?.number
      uPurchaseOrderReturn.supplier = selectedGoodsReceivedNote?.purchaseOrder?.supplier
      uPurchaseOrderReturn.purchaseFor = selectedGoodsReceivedNote?.purchaseOrder?.purchaseFor
      uPurchaseOrderReturn.totalPrice = formatNumber(selectedGoodsReceivedNote.totalPrice)
      uPurchaseOrderReturn.totalTax = formatNumber(selectedGoodsReceivedNote.totalTax)
      uPurchaseOrderReturn.totalDiscount = formatNumber(selectedGoodsReceivedNote.totalDiscount)
      uPurchaseOrderReturn.totalAmount = formatNumber(selectedGoodsReceivedNote.totalAmount)

      uPurchaseOrderReturn.items = []

      selectedGoodsReceivedNote?.items.map((item: any, i: any) => {
        uPurchaseOrderReturn.items[i] = {
          _id: item._id,
          qrCode: item.qrCode,
          expiryDate: item.expiryDate,
          product: item.product,
          name: item.name,
          UOM: item.UOM,
          quantity: item.quantity,
          rate: formatNumber(item.rate),
          price: formatNumber(item.price),
          tax: formatNumber(item.tax),
          priceInclusiveTax: formatNumber(item.priceInclusiveTax),
          discount: formatNumber(item.discount),
          total: formatNumber(item.total),
          remarks: '',
        }
      })
      dispatch(actions.setPurchaseOrderReturn({purchaseOrderReturn: uPurchaseOrderReturn}))
      dispatch(onChangeItem(uPurchaseOrderReturn))
      dispatch(actions.setGoodsReceivedNote({goodsReceivedNote: selectedGoodsReceivedNote}))
    }
  }

export const fetchOne = (id: any) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await PurchaseOrderReturnCRUD.findOne(id)
    dispatch(actions.setPurchaseOrderReturn({purchaseOrderReturn: res.data.data}))
    dispatch(actions.stopLoading())
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const create = (data: any, attachments?: any) => async (dispatch: any) => {
  try {
    const res = await PurchaseOrderReturnCRUD.create(data)
    const upload = await PurchaseOrderReturnCRUD.upload(res.data.data.id, attachments)
    const successMsg = res.data.message
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const approve = (id: any) => async (dispatch: any) => {
  try {
    const res = await PurchaseOrderReturnCRUD.approve(id)

    const purchaseOrderReturn = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setPurchaseOrderReturn({purchaseOrderReturn: purchaseOrderReturn}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const reject = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await PurchaseOrderReturnCRUD.reject(id, data)

    const purchaseOrderReturn = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setPurchaseOrderReturn({purchaseOrderReturn: purchaseOrderReturn}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const remove = (id: any, params: PurchaseOrderReturnTableModel) => async (dispatch: any) => {
  try {
    const res = await PurchaseOrderReturnCRUD.remove(id)
    const successMsg = res.data.message

    PurchaseOrderReturnCRUD.findAll(params).then((res) => {
      dispatch(actions.setPurchaseOrdersReturn({purchaseOrderReturns: res.data.data}))
      dispatch(actions.setPagination({pagination: res.data.pagination}))
      dispatch(actions.stopLoading())
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const resetPurchaseOrderReturn = (data: any) => (dispatch: any) => {
  dispatch(actions.setPurchaseOrderReturn({purchaseOrderReturn: data}))
}
