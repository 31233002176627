/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import * as PurchaseOrderReturnAction from '../redux/PurchaseOrderReturnAction'
import {PurchaseOrderReturnModel} from '../models/PurchaseOrderReturnModel'
import {toast} from 'react-toastify'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {deepCopy} from '../../../helper'
import * as Yup from 'yup'

interface Props {
  show: boolean
  handleClose: () => void
  purchaseOrderReturn: PurchaseOrderReturnModel
}

export const PurchaseOrderReturnRejectConfirmModal: FC<Props> = ({
  show,
  handleClose,
  purchaseOrderReturn,
}) => {
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const dispatch: any = useDispatch()

  const rejectPurchaseOrderReturn = (values: any) => {
    setLoading(true)
    dispatch(PurchaseOrderReturnAction.reject(purchaseOrderReturn?._id, values))
      .then((response: any) => {
        toast.success(response)
        setLoading(false)
        navigate('/purchase-order-returns')
      })
      .catch((error: any) => {
        toast.error(error)
        setLoading(false)
      })
    handleClose()
  }

  const createPurchaseOrderReturnSchema = Yup.object({
    rejectionReason: Yup.string().required('Reason is required'),
  })

  const initialValuesofReason: any = {
    rejectionReason: '',
  }

  return (
    <>
      <Modal
        id='kt_modal_delete_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered mw-500px h-auto'
        show={show}
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Reject Purchase Order Return</h2>
          </div>

          <div className='modal-body flex-row-fluid py-lg-10 px-lg-10'>
            <p className='d-flex align-items-center fs-5 fw-bold'>
              Are you sure you want to reject this purchase Order Return (
              {purchaseOrderReturn?.number})?
            </p>
            <Formik
              validationSchema={createPurchaseOrderReturnSchema}
              initialValues={initialValuesofReason}
              onSubmit={(values: any) => {
                let form: any = deepCopy(purchaseOrderReturn)
                form.rejectionReason = values.rejectionReason
                rejectPurchaseOrderReturn(form)
              }}
            >
              {({values}) => (
                <Form className='form' noValidate id='kt_modal_create_app_form'>
                  <div className='d-flex flex-stack mt-5'>
                    <div className='form-group col-12 '>
                      <Field
                        name='rejectionReason'
                        className='form-control form-control-lg'
                        placeholder='Reason for rejection'
                        maxLength={255}
                      />
                      <div className='text-danger'>
                        <ErrorMessage name='rejectionReason' />
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-stack mt-5'>
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary float-left'
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary float-right'
                      disabled={loading}
                    >
                      <span className='indicator-label'>{loading ? 'Rejecting...' : 'Reject'}</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  )
}
