// @ts-nocheck
import {Document, Page, Image, View, StyleSheet, Text} from '@react-pdf/renderer'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {formatDate} from '../helper/index'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },

  view1: {
    width: 50,
    height: 50,
  },

  view2: {
    flexDirection: 'column',
    width: 80,
  },

  text1: {
    fontSize: 7,
  },

  text2: {
    fontSize: 7,
  },

  qrImage: {
    width: '100%',
    height: '100%',
  },
})

export const QRCodePage = ({qrCode, comp}) => {
  const dataUrl = document.getElementById(qrCode?.qrCode)?.toDataURL()
  return (
    <>
      {[...Array(comp == 'inventoryReport' ? 1 : Math.floor(qrCode.quantity))]?.map((x, index) => (
        <Page
          key={`page_${qrCode.qrCode}-${index}`}
          size={{
            width: '2in',
            height: '1in',
          }}
          style={styles.page}
        >
          <View style={styles.view1}>
            <Image allowDangerousPaths src={dataUrl} style={styles.qrImage} />
          </View>
          <View style={styles.view2}>
            <Text style={styles.text1}>
              {comp == 'inventoryReport' ? qrCode.product.name : qrCode.name}
            </Text>
            <Text style={styles.text2}>
              {qrCode.expiryDate ? formatDate(qrCode.expiryDate) : '---'}
            </Text>
            <View style={styles.logo}>
              <Image
                allowDangerousPaths
                style={{width: '20px', height: '11px'}}
                src={toAbsoluteUrl('/media/logos/logo-2.png')}
              />
            </View>
          </View>
        </Page>
      ))}
    </>
  )
}

function QRCodeDocument({qrCodeData, comp}) {
  return (
    <Document>
      {qrCodeData?.map((qrCode: any, i: any) => <QRCodePage key={i} qrCode={qrCode} comp={comp} />)}
    </Document>
  )
}

export default QRCodeDocument
