import axios from 'axios'
import {OutwardModel, OutwardTableModel} from '../models/OutwardModel'

const API_URL = process.env.REACT_APP_API_URL
const OUTWARD_URL = `${API_URL}/outward`

export function findAll(params: OutwardTableModel) {
  return axios.get(`${OUTWARD_URL}`, {params})
}

export function findOne(id: any) {
  return axios.get(`${OUTWARD_URL}/${id}`)
}

export function findApproved() {
  return axios.get(`${OUTWARD_URL}/approved`)
}

export function create(data: OutwardModel) {
  return axios.post(OUTWARD_URL, data)
}

export function approve(id: any) {
  return axios.patch(`${OUTWARD_URL}/${id}/approve`)
}

export function reject(id: any, data: any) {
  return axios.patch(`${OUTWARD_URL}/${id}/reject`, data)
}

export function remove(id: any) {
  return axios.delete(`${OUTWARD_URL}/${id}`)
}
