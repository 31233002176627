export const UOM = [
  'KG',
  'PKT',
  'BOX',
  'JAR',
  'LTR',
  'Gram',
  'PCS',
  'Length',
  'Width',
  'Height',
  'Feet',
  'Meter',
  'Yard',
  'Inch',
]
