import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Budget, BudgetInitValues} from '../models/BudgetModel'

interface BudgetState {
  loading: boolean
  budget: Budget
  budgets: [Budget?]
  report: any
  pagination: {total: number; pages: number}
}

const initialState: BudgetState = {
  loading: false,
  budget: BudgetInitValues,
  budgets: [],
  report: '',
  pagination: {total: 0, pages: 0},
}

export const BudgetSlice = createSlice({
  name: 'Budgets',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setBudgets: (state: {budgets: any}, action: PayloadAction<any>) => {
      state.budgets = action.payload.budgets
    },
    setBudget: (state: {budget: any}, action: PayloadAction<any>) => {
      state.budget = action.payload.budget
    },
    setBudgetsReport: (state: {report: any}, action: PayloadAction<any>) => {
      state.report = action.payload.report
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
