import {Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import * as MaterialReturnAction from '../redux/MaterialReturnAction'
import {MaterialReturnInitValues, MaterialReturnModel} from '../models/MaterialReturnModel'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDateTime} from '../../../helper'
import {MaterialReturnRejectConfirmModal} from './MaterialReturnRejectConfirmModal'
import {MaterialReturnApproveConfirmModal} from './MaterialReturnApproveConfirmModal'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const MaterialReturnApproval: React.FC<Props> = ({className}) => {
  const materialReturn: any = useSelector<RootState>(
    ({materialReturn}) => materialReturn.materialReturn,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({materialReturn}) => materialReturn.loading,
    shallowEqual
  )

  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [materialReturnToApprove, setMaterialReturnToApprove] =
    useState<MaterialReturnModel>(MaterialReturnInitValues)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [materialReturnToReject, setMaterialReturnToReject] =
    useState<MaterialReturnModel>(MaterialReturnInitValues)

  const materialReturnUpdatedValues = {
    ...materialReturn,
    createdAt: formatDateTime(materialReturn.createdAt),
  }

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(MaterialReturnAction.fetchOne(id))
    return () => {
      dispatch(MaterialReturnAction.resetMaterialReturn(MaterialReturnInitValues))
    }
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Returns List', url: '/material-return'},
    {name: 'Material Return Approval Form', url: ''},
  ]

  return (
    <>
      <MaterialReturnRejectConfirmModal
        show={showRejectDialog}
        handleClose={() => {
          setShowRejectDialog(false)
        }}
        materialReturn={materialReturnToReject}
      />
      <MaterialReturnApproveConfirmModal
        show={showApproveDialog}
        handleClose={() => {
          setShowApproveDialog(false)
        }}
        materialReturn={materialReturnToApprove}
      />
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3'>Material Return Approval Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            materialReturn && materialReturn?._id !== '' ? (
              <>
                <Formik
                  enableReinitialize
                  initialValues={materialReturnUpdatedValues || MaterialReturnInitValues}
                  onSubmit={(values: any) => {
                    setShowApproveDialog(true)
                    setMaterialReturnToApprove(materialReturnUpdatedValues)
                  }}
                >
                  {({values}) => (
                    <Form>
                      <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                        <div className='form-group col'>
                          <label htmlFor='number' className='fw-bold  '>
                            Material Return No.
                          </label>
                          <Field
                            name='number'
                            className='form-control form-control-lg'
                            disabled={true}
                          />
                        </div>
                        <div className='form-group col'>
                          <label htmlFor='createdAt' className='fw-bold'>
                            Created Date
                          </label>
                          <Field
                            name='createdAt'
                            className='form-control form-control-lg'
                            disabled={true}
                          />
                        </div>
                        <div className='form-group col'>
                          <label htmlFor='materialIssuance' className='fw-bold'>
                            Material Issuance No.
                          </label>

                          <Field
                            className='form-control form-control-md'
                            name='materialIssuance.number'
                            disabled={true}
                          />
                        </div>
                        <div className='form-group col'>
                          <label htmlFor='location' className='fw-bold'>
                            Location
                          </label>
                          <Field
                            name='location.name'
                            className='form-control form-control-lg'
                            disabled={true}
                          />
                        </div>
                        <div className='form-group col'>
                          <label htmlFor='providedToDepartment' className='fw-bold'>
                            Provided To Department
                          </label>

                          <Field
                            className='form-control form-control-md'
                            name='providedToDepartment.name'
                            disabled={true}
                          />
                        </div>
                        <div className='form-group col'>
                          <label htmlFor='receivedByDepartment' className='fw-bold'>
                            Received By Department
                          </label>
                          <Field
                            name='receivedByDepartment.name'
                            className='form-control form-control-lg'
                            disabled={true}
                          />
                        </div>
                      </div>

                      <FieldArray
                        name='items'
                        render={(arrayHelpers) => (
                          <div className='mt-15'>
                            <table className='table tbl-md-responsivness'>
                              <thead>
                                <tr>
                                  <th>
                                    <label className='form-label '>Product</label>
                                  </th>
                                  <th>
                                    <label className='form-label '>UOM</label>
                                  </th>
                                  <th>
                                    <label className='form-label '>Quantity Return</label>
                                  </th>
                                  <th>
                                    <label className='form-label '>Remarks</label>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {values.items?.length > 0 &&
                                  values.items?.map((materialItem: any, index: any) => (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td data-label='Product'>
                                          <Field
                                            name={`items.${index}.name`}
                                            placeholder='Product'
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </td>

                                        <td data-label='UOM'>
                                          <div className='d-flex flex-column w-100'>
                                            <Field
                                              name={`items.${index}.UOM`}
                                              placeholder='UOM'
                                              disabled={true}
                                              className='form-control form-control-md'
                                            />
                                          </div>
                                        </td>

                                        <td data-label='Quantity'>
                                          <div className='d-flex flex-column w-100'>
                                            <Field
                                              name={`items.${index}.quantity`}
                                              placeholder='Quantity Return'
                                              disabled={true}
                                              className='form-control form-control-md'
                                            />
                                          </div>
                                        </td>

                                        <td data-label='Remarks'>
                                          <div className='d-flex flex-column w-100'>
                                            <Field
                                              name={`items.${index}.remarks`}
                                              placeholder='Remarks'
                                              disabled={true}
                                              className='form-control form-control-md'
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      />

                      <div className='d-flex justify-content-end mt-10'>
                        <button
                          type='button'
                          className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title='Reject'
                          onClick={() => {
                            setShowRejectDialog(true)
                            setMaterialReturnToReject(materialReturnUpdatedValues)
                          }}
                        >
                          <span className='indicator-label'>Reject</span>
                        </button>

                        <button
                          type='submit'
                          className='btn btn-lg  btn-primary me-3'
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title='Approve'
                          id='liveToastBtn'
                        >
                          <span className='indicator-label'>Approve</span>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
