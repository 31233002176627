/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import {GoodsReceivedNoteModel} from '../models/GoodsReceivedNoteModel'
import * as GoodsReceivedNoteAction from '../redux/GoodsReceivedNoteAction'

interface Props {
  show: boolean
  handleClose: () => void
  goodsReceivedNote: GoodsReceivedNoteModel
}

export const GoodsReceivedNoteApproveConfirmModal: FC<Props> = ({
  show,
  handleClose,
  goodsReceivedNote,
}) => {
  let navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const dispatch: any = useDispatch()

  const approveGoodsReceivedNote = () => {
    setLoading(true)
    dispatch(GoodsReceivedNoteAction.approve(goodsReceivedNote?._id))
      .then((response: any) => {
        toast.success(response)
        navigate('/goods-received-note')
      })
      .catch((error: any) => {
        toast.error(error)
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
        handleClose()
      })
  }

  return (
    <>
      <Modal
        id='kt_modal_delete_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered mw-500px h-auto'
        show={show}
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Approve Goods Received Note</h2>
          </div>

          <div className='modal-body flex-row-fluid py-lg-10 px-lg-10'>
            <p className='d-flex align-items-center fs-5 fw-bold'>
              Are you sure you want to approve this goods received note ({goodsReceivedNote?.number}
              )?
            </p>
            <div className='d-flex flex-stack mt-5'>
              <button
                type='button'
                className='btn btn-lg btn-light-primary float-left'
                onClick={() => {
                  handleClose()
                }}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='btn btn-lg btn-primary float-right'
                onClick={() => {
                  approveGoodsReceivedNote()
                }}
                disabled={loading}
              >
                <span className='indicator-label'> {loading ? 'Approving...' : 'Approve'}</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
