import axios from 'axios'
import {PurchaseOrderModel, PurchaseOrderTableModel} from '../models/PurchaseOrderModel'

const API_URL = process.env.REACT_APP_API_URL
const PURCHASE_ORDER_URL = `${API_URL}/purchase-orders`

export function findAll(params: PurchaseOrderTableModel) {
  return axios.get(`${PURCHASE_ORDER_URL}`, {params})
}

export function findOne(id: any) {
  return axios.get(`${PURCHASE_ORDER_URL}/${id}`)
}

export function report(year: number) {
  const REPORT_URL = `${PURCHASE_ORDER_URL}/report/${year}`
  return axios.get(REPORT_URL)
}

export function suppliersReport(year: number, month: number) {
  const REPORT_URL = `${PURCHASE_ORDER_URL}/report/suppliers/${year}/${month}`
  return axios.get(REPORT_URL)
}

export function itemsReport(year: number, month: number) {
  const REPORT_URL = `${PURCHASE_ORDER_URL}/report/items/${year}/${month}`
  return axios.get(REPORT_URL)
}

export function findApproved() {
  return axios.get(`${PURCHASE_ORDER_URL}/approved`)
}

export function findReturns() {
  return axios.get(`${PURCHASE_ORDER_URL}/returns`)
}

export function create(data: PurchaseOrderModel) {
  return axios.post(PURCHASE_ORDER_URL, data)
}

export function update(id: any, data: any) {
  const UPDATE_PURCHASE_ORDER_URL = `${PURCHASE_ORDER_URL}/${id}`
  return axios.patch(UPDATE_PURCHASE_ORDER_URL, data)
}

export function approve(id: any) {
  return axios.patch(`${PURCHASE_ORDER_URL}/${id}/approve`)
}

export function reject(id: any, data: any) {
  return axios.patch(`${PURCHASE_ORDER_URL}/${id}/reject`, data)
}

export function complete(id: any) {
  return axios.patch(`${PURCHASE_ORDER_URL}/${id}/complete`)
}

export function remove(id: any) {
  return axios.delete(`${PURCHASE_ORDER_URL}/${id}`)
}
