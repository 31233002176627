import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {fetchAll} from '../redux/StockAction'
import {Link} from 'react-router-dom'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {
  StockMovementReportSearchModelInitValues,
  StockMovementReportTableModelInitValues,
} from '../models/StockMovementReportModel'
import {Table} from '../../../components/ReactTableForStockReport'
import {formatDate, formatFileName, formatStockMoney} from '../../../helper/index'
import {Field, Form, Formik} from 'formik'
import {DateRangePicker} from 'rsuite'
import {CSVLink} from 'react-csv'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_metronic/helpers'
import 'rsuite/dist/rsuite.css'

type Props = {
  className: string
}

export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const StockMovementReport: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()

  const stocks: any = useSelector<RootState>(({stocks}) => stocks?.stocks, shallowEqual)

  const dateRange: any = useSelector<RootState>(({stocks}) => stocks?.dateRange, shallowEqual)

  const loading: any = useSelector<RootState>(({stocks}) => stocks.loading, shallowEqual)

  const [searchParams, setSearchParams]: any = useState({})
  const [stockDate, setStockDate]: any = useState([])

  const [show, setShow] = useState(false)
  const filterHandel = () => {
    setShow(!show)
  }

  useEffect(() => {
    dispatch(fetchAll(StockMovementReportTableModelInitValues))
  }, [dispatch])

  const fetchData = useCallback(
    ({params}: any) => {
      const queryParams = {
        search: params,
      }
      dispatch(fetchAll(queryParams))
    },
    [searchParams]
  )

  const extractCSVData = (stocks: any) => {
    const openingStockDate = formatDate(dateRange?.openingStockDate)
    const closingStockDate = formatDate(dateRange?.closingStockDate)

    const csvData = stocks?.map((stock: any) => ({
      'Product Name': stock.name,
      UOM: stock.UOM,
      'Opening Stock - Quantity': stock?.openingStock?.quantity || 0,
      'Opening Stock - Rate': stock?.openingStock?.rate || 0,
      'Opening Stock - Value': stock?.openingStock?.value || 0,

      'Purchases - Quantity': stock?.purchases?.quantity || 0,
      'Purchases - Rate': stock?.purchases?.rate || 0,
      'Purchases - Value': stock?.purchases?.value || 0,

      'Purchase Return - Quantity': stock?.purchaseReturn?.quantity || 0,
      'Purchase Return - Rate': stock?.purchaseReturn?.rate || 0,
      'Purchase Return - Value': stock?.purchaseReturn?.value || 0,

      'Inventory Adjustment - Quantity': stock?.inventoryAdjustment?.quantity || 0,
      'Inventory Adjustment - Rate': stock?.inventoryAdjustment?.rate || 0,
      'Inventory Adjustment - Value': stock?.inventoryAdjustment?.value || 0,

      'Product Issuance - Quantity': stock?.productIssuance?.quantity || 0,
      'Product Issuance - Rate': stock?.productIssuance?.rate || 0,
      'Product Issuance - Value': stock?.productIssuance?.value || 0,

      'Material Return - Quantity': stock?.productReturn?.quantity || 0,
      'Material Return - Rate': stock?.productReturn?.rate || 0,
      'Material Return - Value': stock?.productReturn?.value || 0,

      'Closing Stock - Quantity': stock?.closingStock?.quantity || 0,
      'Closing Stock - Rate': stock?.closingStock?.rate || 0,
      'Closing Stock - Value': stock?.closingStock?.value || 0,
    }))

    const Period = {Period: `${openingStockDate} - ${closingStockDate}`}

    const finalCsvData = [Period, ...csvData]

    return finalCsvData
  }

  const columns = useMemo(
    () => [
      {
        Header: 'No.',
        columns: [
          {
            accessor: 'index',
            Cell: ({row}: any) => row.index + 1,
          },
        ],
      },
      {
        Header: 'Products',
        columns: [
          {
            accessor: 'name',
            show: true,
          },
        ],
      },
      {
        Header: 'UOM',
        columns: [
          {
            accessor: 'UOM',
            show: true,
          },
        ],
      },
      {
        Header: 'Opening Stock',
        columns: [
          {
            Header: 'Qty',
            accessor: 'openingStock.quantity',
            Cell: (cellInfo: any) => {
              return cellInfo?.row?.original?.openingStock?.quantity || 0
            },
            show: true,
          },
          {
            Header: 'Rate',
            accessor: 'openingStock.rate',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.openingStock?.rate || 0)
            },
            show: true,
          },
          {
            Header: 'Value',
            accessor: 'openingStock.value',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.openingStock?.value || 0)
            },
            show: true,
          },
        ],
      },
      {
        Header: 'Purchases',
        columns: [
          {
            Header: 'Qty',
            accessor: 'purchases.quantity',
            Cell: (cellInfo: any) => {
              return cellInfo?.row?.original?.purchases?.quantity || 0
            },
            show: true,
          },
          {
            Header: 'Rate',
            accessor: 'purchases.rate',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.purchases?.rate || 0)
            },
            show: true,
          },
          {
            Header: 'Value',
            accessor: 'purchases.value',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.purchases?.value || 0)
            },
            show: true,
          },
        ],
      },
      {
        Header: 'Purchase Return',
        columns: [
          {
            Header: 'Qty',
            accessor: 'purchaseReturn.quantity',
            Cell: (cellInfo: any) => {
              return cellInfo?.row?.original?.purchaseReturn?.quantity || 0
            },
            show: true,
          },
          {
            Header: 'Rate',
            accessor: 'purchaseReturn.rate',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.purchaseReturn?.rate || 0)
            },
            show: true,
          },
          {
            Header: 'Value',
            accessor: 'purchaseReturn.value',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.purchaseReturn?.value || 0)
            },
            show: true,
          },
        ],
      },
      {
        Header: 'Inventory Adjustment',
        columns: [
          {
            Header: 'Qty',
            accessor: 'inventoryAdjustment.quantity',
            Cell: (cellInfo: any) => {
              return cellInfo?.row?.original?.inventoryAdjustment?.quantity || 0
            },
            show: true,
          },
          {
            Header: 'Rate',
            accessor: 'inventoryAdjustment.rate',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.inventoryAdjustment?.rate || 0)
            },
            show: true,
          },
          {
            Header: 'Value',
            accessor: 'inventoryAdjustment.value',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.inventoryAdjustment?.value || 0)
            },
            show: true,
          },
        ],
      },
      {
        Header: 'Material Issuance',
        columns: [
          {
            Header: 'Qty',
            accessor: 'productIssuance.quantity',
            Cell: (cellInfo: any) => {
              return cellInfo?.row?.original?.productIssuance?.quantity || 0
            },
            show: true,
          },
          {
            Header: 'Rate',
            accessor: 'productIssuance.rate',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.productIssuance?.rate || 0)
            },
            show: true,
          },
          {
            Header: 'Value',
            accessor: 'productIssuance.value',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.productIssuance?.value || 0)
            },
            show: true,
          },
        ],
      },
      {
        Header: 'Material Return',
        columns: [
          {
            Header: 'Qty',
            accessor: 'productReturn.quantity',
            Cell: (cellInfo: any) => {
              return cellInfo?.row?.original?.productReturn?.quantity || 0
            },
            show: true,
          },
          {
            Header: 'Rate',
            accessor: 'productReturn.rate',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.productReturn?.rate || 0)
            },
            show: true,
          },
          {
            Header: 'Value',
            accessor: 'productReturn.value',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.productReturn?.value || 0)
            },
            show: true,
          },
        ],
      },
      {
        Header: 'Closing Stock',
        columns: [
          {
            Header: 'Qty',
            accessor: 'closingStock.quantity',
            Cell: (cellInfo: any) => {
              return cellInfo?.row?.original?.closingStock?.quantity || 0
            },
            show: true,
          },
          {
            Header: 'Rate',
            accessor: 'closingStock.rate',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.closingStock?.rate || 0)
            },
            show: true,
          },
          {
            Header: 'Value',
            accessor: 'closingStock.value',
            Cell: (cellInfo: any) => {
              return formatStockMoney(cellInfo?.row?.original?.closingStock?.value || 0)
            },
            show: true,
          },
        ],
      },
    ],
    []
  )

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Product wise Stock Movement Report', url: '/Product wise Stock Movement Report'},
  ]

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Products Stock Movement Report </span>
          </h1>
          <div className='d-flex'>
            <div className='m-1'>
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Print PDF'
              >
                <CSVLink
                  data={extractCSVData(stocks)}
                  filename={formatFileName('Stock-Movement-Report')}
                  target='_blank'
                >
                  <KTSVG
                    path='/media/svg/icons/Devices/DownloadCSV.svg'
                    className='svg-icon svg-icon-2x svg-icon-danger '
                  />
                </CSVLink>
              </button>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div
            className={`d-flex align-items-center ${
              !show ? 'justify-content-end' : 'justify-content-between'
            }`}
          >
            <p className='my-0 me-3' style={{color: '#004B87', fontSize: '14px'}}>
              <b>Search Filters</b>
            </p>
            <span onClick={filterHandel}>
              <img src='/media/svg/icons/General/filter.svg' alt='' />
            </span>
          </div>
          {show ? (
            <Formik
              initialValues={StockMovementReportSearchModelInitValues}
              validationSchema={Yup.object({
                name: Yup.string(),
                stockDate: Yup.array(Yup.string()),
              })}
              onSubmit={(values: any) => {
                setSearchParams(values)
              }}
            >
              {({values, setFieldValue}) => (
                <Form>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5 mt-1'>
                    <div className='form-group col'>
                      <label htmlFor='name' className='fw-bold'>
                        Name
                      </label>
                      <Field
                        name='name'
                        placeholder='Product Name'
                        className='form-control form-control-lg'
                      />
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='stockDate' className='fw-bold'>
                        Select Range of Opening & Closing Stock
                      </label>
                      <DateRangePicker
                        value={stockDate}
                        block
                        name='stockDate'
                        cleanable={true}
                        size='md'
                        appearance='default'
                        character='-'
                        showOneCalendar
                        placeholder='Stock Date'
                        format='dd/MM/yyyy'
                        onClean={(value: any) => {
                          setFieldValue('stockDate', '')
                        }}
                        onChange={(value: any) => {
                          setFieldValue('stockDate', value)
                          setStockDate(value)
                        }}
                      />
                    </div>
                  </div>

                  <div className='d-flex justify-content-end mt-10'>
                    <Link
                      to='/stock-movement-report'
                      className='btn btn-sm btn-light-primary me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancel'
                      onClick={() => {
                        setSearchParams(StockMovementReportSearchModelInitValues)
                        setStockDate([])
                        setFieldValue('name', '')
                      }}
                    >
                      <span className='indicator-label'>Cancel</span>
                    </Link>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Search'
                      id='liveToastBtn'
                    >
                      <span className='indicator-label'>Search</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}

          <span className='mb-1'>
            <b>Period: </b>{' '}
            {formatDate(dateRange.openingStockDate ? dateRange?.openingStockDate : new Date())}{' '}
            <b>to</b>{' '}
            {formatDate(dateRange?.closingStockDate ? dateRange?.closingStockDate : new Date())}
          </span>

          <div className='d-flex mt-5 '>
            <div className='react-table-wrapper' style={{width: '100%'}}>
              <Table
                columns={columns}
                data={stocks}
                params={searchParams}
                fetchData={fetchData}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
