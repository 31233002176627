import {Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {formatDateTime} from '../../../helper'
import * as InwardAction from '../../inward/redux/InwardAction'
import {InwardInitValues, InwardModel} from '../models/InwardModel'
import {InwardApproveConfirmModal} from './InwardApproveConfirmModal'
import {InwardRejectConfirmModal} from './InwardRejectConfirmModal'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

interface ILabels {
  [key: string]: string
}

const inwardLabels: ILabels = {
  description: 'Description',
  UOM: 'UOM',
  quantity: 'Quantity Issued',
  assetNumber: 'Asset No.',
  remarks: 'Remarks',
}

export const InwardApproval: React.FC<Props> = ({className}) => {
  const inward: any = useSelector<RootState>(({inwards}) => inwards.inward, shallowEqual)
  const loading: any = useSelector<RootState>(({inwards}) => inwards.loading, shallowEqual)

  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [inwardToApprove, setInwardToApprove] = useState<InwardModel>(InwardInitValues)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [inwardToReject, setInwardToReject] = useState<InwardModel>(InwardInitValues)

  const inwardUpdatedValues = {
    ...inward,
    createdAt: formatDateTime(inward.createdAt),
  }

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(InwardAction.fetchOne(id))
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Inwards List', url: '/inward'},
    {name: 'Inward Approval Form', url: ''},
  ]

  return (
    <>
      <InwardRejectConfirmModal
        show={showRejectDialog}
        handleClose={() => {
          setShowRejectDialog(false)
        }}
        inward={inwardToReject}
      />
      <InwardApproveConfirmModal
        show={showApproveDialog}
        handleClose={() => {
          setShowApproveDialog(false)
        }}
        inward={inwardToApprove}
      />
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Inward Approval Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            inward && inward?._id !== '' ? (
              <Formik
                enableReinitialize
                initialValues={inwardUpdatedValues || InwardInitValues}
                onSubmit={(values: any) => {
                  setShowApproveDialog(true)
                  setInwardToApprove(inward)
                }}
              >
                {({values}) => (
                  <Form>
                    <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                      <div className='form-group col'>
                        <label htmlFor='number' className='fw-bold  '>
                          Inward No.
                        </label>
                        <Field
                          name='number'
                          className='form-control form-control-lg form-control-solid '
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='createdAt' className='fw-bold'>
                          Created Date
                        </label>
                        <Field
                          name='createdAt'
                          className='form-control form-control-lg form-control-solid '
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='outward.number' className='fw-bold'>
                          Outward No.
                        </label>

                        <Field
                          className='form-control form-control-md form-control-solid  '
                          name='outward.number'
                          disabled={true}
                        ></Field>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='providedToEmployee' className='fw-bold'>
                          Provided To Employee Name
                        </label>
                        <Field
                          name='providedToEmployee.fullName'
                          className='form-control form-control-lg form-control-solid '
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='providedToDepartment' className='fw-bold'>
                          Provided To Department
                        </label>
                        <Field
                          name='providedToDepartment.name'
                          className='form-control form-control-lg form-control-solid '
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='location' className='fw-bold'>
                          Location
                        </label>
                        <Field
                          name='location.name'
                          className='form-control form-control-lg form-control-solid '
                          disabled={true}
                        />
                      </div>
                    </div>

                    <FieldArray
                      name='items'
                      render={(arrayHelpers) => (
                        <div className='mt-15'>
                          <table className='table tbl-md-responsivness'>
                            <thead>
                              <tr>
                                <th>
                                  <label className='form-label'>Product</label>
                                </th>
                                <th>
                                  <label className='form-label'>UOM</label>
                                </th>
                                <th>
                                  <label className='form-label'>Quantity</label>
                                </th>
                                <th>
                                  <label className='form-label'>Asset No.</label>
                                </th>
                                <th>
                                  <label className='form-label'>Remarks</label>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.items?.length > 0 &&
                                values.items?.map((materialItem: any, index: any) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td data-label='Product'>
                                        <Field
                                          name={`items.${index}.name`}
                                          disabled={true}
                                          className='form-control form-control-md'
                                        />
                                      </td>

                                      <td data-label='UOM'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.UOM`}
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Quantity'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.quantity`}
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Asset No.'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.assetNumber`}
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Remarks'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.remarks`}
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    />

                    <div className='d-flex justify-content-end mt-10'>
                      <button
                        type='button'
                        className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Reject'
                        onClick={() => {
                          setShowRejectDialog(true)
                          setInwardToReject(inward)
                        }}
                      >
                        <span className='indicator-label'>Reject</span>
                      </button>

                      <button
                        type='submit'
                        className='btn btn-lg  btn-primary me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Approve'
                        id='liveToastBtn'
                      >
                        <span className='indicator-label'>Approve</span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
