import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {GoodsReceivedNoteInitValues, GoodsReceivedNoteModel} from '../models/GoodsReceivedNoteModel'

interface GoodsReceivedNoteState {
  loading: boolean
  goodsReceivedNotes: [GoodsReceivedNoteModel?]
  goodsReceivedNote: GoodsReceivedNoteModel
  pagination: {total: number; pages: number}
}

const initialState: GoodsReceivedNoteState = {
  loading: false,
  goodsReceivedNote: GoodsReceivedNoteInitValues,
  goodsReceivedNotes: [],
  pagination: {total: 0, pages: 0},
}

export const GoodsReceivedNoteSlice = createSlice({
  name: 'goodsReceivedNote',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setGoodsReceivedNotes: (state, action: PayloadAction<any>) => {
      state.goodsReceivedNotes = action.payload.goodsReceivedNotes
    },
    setGoodsReceivedNote: (state, action: PayloadAction<any>) => {
      state.goodsReceivedNote = action.payload.goodsReceivedNote
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
