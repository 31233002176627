/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import * as actions from '../redux/ProductCategoryAction'
import {ProductCategory, ProductCategoryTableModelInitValues} from '../models/ProductCategoryModel'
import {toast} from 'react-toastify'
interface Props {
  show: boolean
  handleClose: () => void
  category: ProductCategory
}

const ProductCategoryDelete: FC<Props> = ({show, handleClose, category}) => {
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const dispatch: any = useDispatch()
  const deleteProductCategory = () => {
    setLoading(true)
    dispatch(actions.remove(category?._id, ProductCategoryTableModelInitValues))
      .then((response: any) => {
        toast.success(response)
        setLoading(false)
        navigate('/product-categories')
      })
      .catch((error: any) => {
        toast.error(error)
        setLoading(false)
      })
    handleClose()
  }

  return (
    <Modal
      id='kt_modal_delete_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Delete Product Category</h2>
        </div>

        <div className='modal-body flex-row-fluid py-lg-10 px-lg-10'>
          <p className='d-flex align-items-center fs-5 fw-bold'>
            Are you sure you want to delete this product category ({category?.name})?
          </p>
          <div className='d-flex flex-stack mt-5'>
            <button
              type='button'
              className='btn btn-lg btn-light-primary float-left'
              onClick={() => {
                handleClose()
              }}
            >
              Cancel
            </button>
            <button
              title='Delete'
              type='submit'
              className='btn btn-lg btn-primary float-left'
              onClick={() => {
                deleteProductCategory()
              }}
              disabled={loading}
            >
              <span className='indicator-label'>{loading ? 'Deleting...' : 'Delete'}</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {ProductCategoryDelete}
