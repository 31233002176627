import {
  MaterialInspectionInitValues,
  MaterialInspectionModel,
} from '../../material-inspection/models/MaterialInspectionModel'
import {
  PurchaseOrderInitValues,
  PurchaseOrderModel,
} from '../../purchase-orders/models/PurchaseOrderModel'
interface GoodsReceivedNoteItem {
  _id: string
  product: string
  qrCode:string
  name: string
  UOM: string
  quantity: number | string
  rate: number | string
  price: number | string
  tax: number | string
  priceInclusiveTax: number | string
  discount: number | string
  expiryDate: string
  total: number | string

}

export interface GoodsReceivedNoteModel {
  _id: string
  deliveryChallanNumber: string
  requestedBy: string
  createdAt: string
  number: string
  approvedAt: string
  approvedBy: string
  rejectedBy: string
  rejectedAt: string
  rejectionReason: string
  materialInspection: string
  purchaseOrder: PurchaseOrderModel
  location: string
  locationName: string
  totalPrice: number | string
  totalTax: number | string
  totalDiscount: number | string
  totalAmount: number | string
  items: [GoodsReceivedNoteItem]
}

export const GoodsReceivedNoteInitValues: GoodsReceivedNoteModel = {
  _id: '',
  deliveryChallanNumber: '',
  createdAt: '',
  requestedBy: '',
  number: '',
  approvedAt: '',
  approvedBy: '',
  rejectedBy: '',
  rejectedAt: '',
  rejectionReason: '',
  materialInspection: '',
  location: '',
  locationName: '',
  totalPrice: '',
  totalTax: '',
  totalDiscount: '',
  totalAmount: '',
  purchaseOrder: PurchaseOrderInitValues,
  items: [
    {
      _id: '',
      product: '',
      qrCode:'',
      name: '',
      UOM: '',
      quantity: '',
      rate: '',
      price: '',
      tax: '',
      priceInclusiveTax: '',
      discount: '',
      expiryDate: '',
      total: '', 
    },
  ],
}

export interface GoodsReceivedNoteSearchModel {
  number: string
  status: string
  createdAt: any
  approvedBy: string
  approvedAt: any
}
export const GoodsReceivedNoteSearchModelInitValues: GoodsReceivedNoteSearchModel = {
  number: '',
  status: '',
  createdAt: [],
  approvedBy: '',
  approvedAt: [],
}

export interface GoodsReceivedNoteTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: GoodsReceivedNoteSearchModel
}

export const GoodsReceivedNoteTableModelInitValues: GoodsReceivedNoteTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: GoodsReceivedNoteSearchModelInitValues,
}
