export interface StatisticsModel {
  users: number
  roles: number
  locations: number
  departments: number
  suppliers: number
  products: number
  budgets: number
  requisitions: {
    weekly: number
    daily: number
  }
  issuances: {
    weekly: number
    daily: number
  }
  outwards: {
    weekly: number
    daily: number
  }
  inwards: {
    weekly: number
    daily: number
  }
  purchaseOrders: {
    weekly: number
    daily: number
  }
  inspections: {
    weekly: number
    daily: number
  }
  goodsReceivedNotes: {
    weekly: number
    daily: number
  }
  supplierBills: {
    weekly: number
    daily: number
  }
}

export const StatisticsInitValues: StatisticsModel = {
  users: 0,
  roles: 0,
  locations: 0,
  departments: 0,
  suppliers: 0,
  products: 0,
  budgets: 0,
  requisitions: {
    weekly: 0,
    daily: 0,
  },
  issuances: {
    weekly: 0,
    daily: 0,
  },
  outwards: {
    weekly: 0,
    daily: 0,
  },
  inwards: {
    weekly: 0,
    daily: 0,
  },
  purchaseOrders: {
    weekly: 0,
    daily: 0,
  },
  inspections: {
    weekly: 0,
    daily: 0,
  },
  goodsReceivedNotes: {
    weekly: 0,
    daily: 0,
  },
  supplierBills: {
    weekly: 0,
    daily: 0,
  },
}
