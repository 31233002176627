import {Field, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Bar} from 'react-chartjs-2'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {NoItemFound} from '../../../components/NoItemFound'
import {PrintButton} from '../../../components/PrintButton'
import {formatMoney, getMaxValueArrays, getYears} from '../../../helper/index'
import {Loader} from '../../../components/Loader'
import * as actions from '../redux/BudgetAction'

type Props = {
  className: string
}

export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const BudgetReport: React.FC<Props> = ({className}) => {
  const dispatch: any = useDispatch()

  const [showChart, setShowChart] = useState('table')
  const [view, setView] = useState('table')

  const reports: any = useSelector<RootState>((state) => state.budget.report, shallowEqual)

  const estimated =
    reports.length &&
    reports
      ?.map((item: any) => item.actualTotal > 0 && item.estimatedTotal)
      .reduce((prev: any, curr: any) => prev + curr, 0)

  const actual =
    reports.length &&
    reports?.map((item: any) => item.actualTotal).reduce((prev: any, curr: any) => prev + curr, 0)

  const date = new Date().getFullYear()
  const yearList = getYears(2022)
  const [initValues] = useState<any>({year: date})

  const [currentYear, setCurrentYear] = useState(initValues.year)
  const handleUpdate = (e: any) => {
    setCurrentYear(e.target.value)
  }

  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const actualData =
    reports.length &&
    labels.map((label: string, index: number) => {
      return reports?.find((report: any) => report?.month === index + 1)?.actualTotal || ''
    })

  const estimatedData =
    reports.length &&
    labels.map(
      (label: string, index: number) =>
        reports?.find((report: any) => report?.month === index + 1)?.estimatedTotal || ''
    )

  const dataArrays = [actualData, estimatedData]
  const maxValue = actualData && getMaxValueArrays(dataArrays)

  useEffect(() => {
    dispatch(actions.fetchReport(currentYear))
  }, [dispatch, currentYear])

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        title: {
          display: true,
          text: 'Budget Report ' + currentYear,
          font: {
            family: 'Arial',
            size: 16,
            weight: 'bold',
          },
          color: '#333',
          padding: 10,
        },
      },
    },

    scales: {
      y: {
        ticks: {
          callback: function (amount: any) {
            return 'PKR-' + formatMoney(amount)
          },
        },
        min: 0,
        max: Math.ceil(maxValue / 10) * 10,
      },
    },
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Estimated Budget',
        data: estimatedData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 1.5)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
      {
        label: 'Actual Budget',
        data: actualData,
        borderColor: 'rgb(198, 255, 196 )',
        backgroundColor: 'rgba(0, 197, 95, 0.8)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
    ],
  }

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Budget Reports', url: '/budgets/reports'},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Budget Reports</span>
          </h1>
          <PrintButton />
        </div>

        <Formik
          validationSchema={Yup.object().shape({
            year: Yup.string(),
          })}
          initialValues={initValues}
          onSubmit={(values: any) => {}}
        >
          {({values}) => (
            <Form className='form' noValidate id='kt_modal_create_app_form'>
              <div className='row ms-5'>
                <div className='col-md-4 print-view-none fv-row'>
                  <div className='d-flex report-row'>
                    <label className='fs-6 fw-bold m-2'>Year</label>
                    <div className='form-group col-6 '>
                      <Field
                        as='select'
                        name='year'
                        className='form-select'
                        style={{minHeight: '41px', maxHeight: '41px'}}
                        value={currentYear}
                        onChange={handleUpdate}
                      >
                        <option value=''>Select</option>
                        {yearList?.map((year, i) => (
                          <option key={i} value={year}>
                            {year}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className='report-btn'>
                      <div className='btn-group ms-5' role='group' aria-label='Basic example'>
                        <button
                          type='button'
                          className={`btn btn-sm btn-secondary ${view == 'table' ? 'active' : ''}`}
                          onClick={() => {
                            setShowChart('table')
                            setView('table')
                          }}
                        >
                          Table
                        </button>
                        <button
                          type='button'
                          className={`btn btn-sm btn-secondary ${view == 'chart' ? 'active' : ''}`}
                          onClick={() => {
                            setShowChart('chart')
                            setView('chart')
                          }}
                        >
                          Chart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <div className='card-body py-10'>
          {reports ? (
            showChart == 'chart' && reports?.length !== 0 && estimated > 0 ? (
              <div className='bar-chart-container'>
                <Bar options={options} data={data} />
              </div>
            ) : (
              <>
                {reports?.length !== 0 && estimated > 0 ? (
                  <table className='table tbl-border tbl-md-responsivness table-striped'>
                    <thead>
                      <tr>
                        <th>
                          <b>Month </b>
                        </th>
                        <th>
                          <b>Estimated Budget </b>
                        </th>
                        <th>
                          <b>Actual Budget </b>
                        </th>
                        <th>
                          <b>Difference </b>
                        </th>
                        <th>
                          <b>% Difference </b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports?.map(
                        (report: any, index: any) =>
                          report?.actualTotal > 0 && (
                            <tr key={index}>
                              <td data-label='Month'>
                                {labels[report?.month - 1]} - {report?.year}
                              </td>

                              <td data-label='Estimated Budget'>
                                {formatMoney(report?.estimatedTotal)}
                              </td>
                              <td data-label='Actual Budget'>{formatMoney(report?.actualTotal)}</td>
                              <td data-label='Difference'>
                                {formatMoney(report?.actualTotal - report?.estimatedTotal)}
                              </td>
                              <td data-label='% Difference'>
                                {(
                                  ((report?.actualTotal - report?.estimatedTotal) /
                                    report?.estimatedTotal) *
                                  100
                                ).toFixed(0)}
                                %
                              </td>
                            </tr>
                          )
                      )}
                      <tr>
                        <td>
                          <b>Overall Total</b>
                        </td>
                        <td data-label='Estimated Budget'>
                          <b>{formatMoney(estimated)}</b>
                        </td>
                        <td data-label='Actual Budget'>
                          <b>{formatMoney(actual)}</b>
                        </td>
                        <td data-label='Difference'>
                          <b>{formatMoney(actual - estimated)}</b>
                        </td>
                        <td data-label='% Difference'>
                          <b>{(((actual - estimated) / estimated) * 100).toFixed(0)}%</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <NoItemFound />
                )}
              </>
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
