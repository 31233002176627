import React from 'react'
import {Locations} from './components/Locations'
import {Route, Routes} from 'react-router-dom'

export const LocationPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Locations className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
