import * as PurchaseOrderCRUD from './PurchaseOrderCRUD'
import {PurchaseOrderSlice} from './PurchaseOrderSlice'
import {deepCopy, formatNumber} from '../../../helper'
import {PurchaseOrderTableModel} from '../models/PurchaseOrderModel'

const {actions} = PurchaseOrderSlice

export const fetchAll: any = (params: PurchaseOrderTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return PurchaseOrderCRUD.findAll(params).then((res) => {
    dispatch(actions.setPurchaseOrders({purchaseOrders: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchReport = (year: number) => (dispatch: any) => {
  return PurchaseOrderCRUD.report(year).then((response) => {
    dispatch(actions.setPurchaseOrderReport({report: response.data.data}))
  })
}

export const fetchSuppliersReport = (year: number, month: number) => (dispatch: any) => {
  return PurchaseOrderCRUD.suppliersReport(year, month).then((response) => {
    dispatch(actions.setPurchaseOrderReport({report: response.data.data}))
  })
}

export const fetchItemsReport = (year: number, month: number) => (dispatch: any) => {
  return PurchaseOrderCRUD.itemsReport(year, month).then((response) => {
    dispatch(actions.setPurchaseOrderReport({report: response.data.data}))
  })
}

export const resetPurchaseOrder = (data: any) => (dispatch: any) => {
  dispatch(actions.setPurchaseOrder({purchaseOrder: data}))
}

export const onChangeSupplier = (e: any, values: any, suppliers: any) => (dispatch: any) => {
  const selectedSupplier = suppliers.find((supplier: any) => supplier._id === e.target.value)
  let uPurchaseOrder = deepCopy(values)
  if (selectedSupplier) {
    uPurchaseOrder.supplier = selectedSupplier
    dispatch(actions.setPurchaseOrder({purchaseOrder: uPurchaseOrder}))
  }
}

export const addPurchaseOrderItem = (values: any, item: any) => (dispatch: any) => {
  let uPurchaseOrder = deepCopy(values)
  uPurchaseOrder.items.push(item)
  dispatch(actions.setPurchaseOrder({purchaseOrder: uPurchaseOrder}))
  dispatch(onChangeItem(uPurchaseOrder))
}

export const removePurchaseOrderItem = (values: any, index: number) => (dispatch: any) => {
  let uPurchaseOrder = deepCopy(values)
  const items = values?.items.filter((item: any, i: any) => i !== index)
  uPurchaseOrder.items = items
  dispatch(actions.setPurchaseOrder({purchaseOrder: uPurchaseOrder}))
  dispatch(onChangeItem(uPurchaseOrder))
}

export const onChangeItem = (values: any) => (dispatch: any) => {
  let uPurchaseOrder = JSON.parse(JSON.stringify(values))
  const totalAmount = uPurchaseOrder.items.reduce((prev: any, item: any) => {
    item.price = +formatNumber(item.quantity || 0) * +formatNumber(item.rate || 0)
    let itemExclusiveTax = +formatNumber(item.quantity || 0) * +formatNumber(item.rate || 0)
    item.price = formatNumber(itemExclusiveTax)
    const itemPriceInclusiveTax = +formatNumber(item.price) + +formatNumber(item.tax)
    item.priceInclusiveTax = formatNumber(itemPriceInclusiveTax)
    const total = +formatNumber(item.priceInclusiveTax) - +formatNumber(item.discount)
    item.total = formatNumber(total)
    return prev + total
  }, 0)
  const totalPrice = uPurchaseOrder.items.reduce((prev: any, item: any) => {
    return prev + +formatNumber(item.price)
  }, 0)
  const totalTax = uPurchaseOrder.items.reduce((prev: any, item: any) => {
    return prev + +formatNumber(item.tax)
  }, 0)
  const totalDiscount = uPurchaseOrder.items.reduce((prev: any, item: any) => {
    return prev + +formatNumber(item.discount)
  }, 0)
  uPurchaseOrder.totalAmount = formatNumber(totalAmount)
  uPurchaseOrder.totalPrice = formatNumber(totalPrice)
  uPurchaseOrder.totalTax = formatNumber(totalTax)
  uPurchaseOrder.totalDiscount = formatNumber(totalDiscount)
  dispatch(actions.setPurchaseOrder({purchaseOrder: uPurchaseOrder}))
}

export const fetchOne = (id: any) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await PurchaseOrderCRUD.findOne(id)
    let newPurchaseOrder = JSON.parse(JSON.stringify(res.data.data))
    res.data.data.items.map((r: any, i: any) => {
      newPurchaseOrder.items[i] = {
        product: r.product,
        name: r.name,
        UOM: r.UOM,
        quantity: r.quantity,
        rate: r.rate,
        price: r.price,
        tax: r.tax,
        priceInclusiveTax: r.priceInclusiveTax,
        discount: r.discount,
        total: r.total,
        status: r.status,
      }
    })
    dispatch(actions.stopLoading())
    dispatch(actions.setPurchaseOrder({purchaseOrder: newPurchaseOrder}))
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const fetchApproved = () => (dispatch: any) => {
  return PurchaseOrderCRUD.findApproved().then((res) => {
    dispatch(actions.setPurchaseOrders({purchaseOrders: res.data.data}))
  })
}

export const fetchReturns = () => (dispatch: any) => {
  return PurchaseOrderCRUD.findReturns().then((res) => {
    dispatch(actions.setPurchaseOrders({purchaseOrders: res.data.data}))
  })
}

export const create = (data: any) => async (dispatch: any) => {
  try {
    const res = await PurchaseOrderCRUD.create(data)
    const purchaseOrder = res.data.data
    const successMsg = res.data.message
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const update =
  (_id: string, data: any, params: PurchaseOrderTableModel) => async (dispatch: any) => {
    try {
      const res = await PurchaseOrderCRUD.update(_id, data)
      const successMsg = res.data.message

      PurchaseOrderCRUD.findAll(params).then((response) => {
        dispatch(actions.setPurchaseOrders({purchaseOrders: response.data.data}))
      })
      return successMsg
    } catch (error: any) {
      const errors = error?.response?.data?.message
      let errorMsg
      if (typeof errors === 'string') {
        errorMsg = errors
      } else {
        errorMsg = 'Something went wrong'
      }
      throw errorMsg
    }
  }

export const approve = (id: any) => async (dispatch: any) => {
  try {
    const res = await PurchaseOrderCRUD.approve(id)

    const purchaseOrder = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setPurchaseOrder({purchaseOrder: purchaseOrder}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const reject = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await PurchaseOrderCRUD.reject(id, data)

    const purchaseOrder = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setPurchaseOrder({purchaseOrder: purchaseOrder}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const complete = (id: any) => async (dispatch: any) => {
  try {
    const res = await PurchaseOrderCRUD.complete(id)

    const purchaseOrder = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setPurchaseOrder({purchaseOrder: purchaseOrder}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const remove = (id: any, params: PurchaseOrderTableModel) => async (dispatch: any) => {
  try {
    const res = await PurchaseOrderCRUD.remove(id)
    const successMsg = res.data.message

    PurchaseOrderCRUD.findAll(params).then((res) => {
      dispatch(actions.setPurchaseOrders({purchaseOrders: res.data.data}))
      dispatch(actions.setPagination({pagination: res.data.pagination}))
      dispatch(actions.stopLoading())
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}
