import React from 'react'
import {MaterialInspections} from './components/MaterialInspections'
import {Route, Routes} from 'react-router-dom'
import {MaterialInspectionAdd} from './components/MaterialInspectionAdd'
import {MaterialInspectionDetail} from './components/MaterialInspectionDetail'
import {MaterialInspectionApprove} from './components/MaterialInspectionApproval'

export const MaterialInspectionPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/add' element={<MaterialInspectionAdd className='mb-5 mb-xl-8' />} />
        <Route path='/' element={<MaterialInspections className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<MaterialInspectionDetail className='mb-5 mb-xl-8' />} />
        <Route
          path='/:id/approve'
          element={<MaterialInspectionApprove className='mb-5 mb-xl-8' />}
        />
      </Routes>
    </>
  )
}
