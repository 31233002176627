/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom'
import {Error500} from './components/Error500'
import {Error404} from './components/Error404'
import {Error403} from './components/Error403'

import {toAbsoluteUrl} from '../../../_metronic/helpers'

const ErrorsPage: React.FC = () => {
  const navigate = useNavigate()
  const NavigateToDashboard = () => {
    navigate('/')
  }

  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`}}
      >
        <div className='d-flex flex-centerr position-x-center flex-column flex-column-fluid p-10 pb-lg-20'>
          <a href='/dashboard' className='mb-10 w-auto mx-auto'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-4.png')}
              className='h-50px mb-5'
            />
          </a>
          <div className='w-lg-900px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <div className='pt-lg-10 mb-10'>
              <Routes>
                <Route path='/' element={<Navigate to='/error/404' />} />
                <Route path='/403' element={<Error403 />} />
                <Route path='/404' element={<Error404 />} />
                <Route path='/500' element={<Error500 />} />
                <Route path='*' element={<Navigate to='/error/404' replace />} />
              </Routes>

              <div className='text-center'>
                <a onClick={NavigateToDashboard} className='btn btn-lg btn-primary fw-bolder'>
                  Go to dashboard
                </a>
              </div>
            </div>
          </div>
          <div
            className='
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          min-h-100px min-h-lg-350px
        '
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export {ErrorsPage}
