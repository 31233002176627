import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {Budgets} from './components/Budgets'
import {BudgetAdd} from './components/BudgetAdd'
import {BudgetEdit} from './components/BudgetEdit'
import {BudgetApprove} from './components/BudgetApprove'
import {BudgetDetail} from './components/BudgetDetail'
import {BudgetReport} from './components/BudgetReport'

const BudgetPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Budgets className='mb-5 mb-xl-8' />} />
        <Route path='/report' element={<BudgetReport className='mb-5 mb-xl-8' />} />
        <Route path='/add' element={<BudgetAdd className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<BudgetDetail className='mb-5 mb-xl-8' />} />
        <Route path='/:id/edit' element={<BudgetEdit className='mb-5 mb-xl-8' />} />
        <Route path='/:id/approve' element={<BudgetApprove className='mb-5 mb-xl-8' />} />
        <Route path='/:id/:type' element={<BudgetAdd className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}

export {BudgetPage}
