import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {PrintButton} from '../../../components/PrintButton'
import {formatDateTime, formatNumber, formatMoney} from '../../../helper'
import {PurchaseOrderInitValues} from '../models/PurchaseOrderModel'
import * as PurchaseOrderAction from '../redux/PurchaseOrderAction'
import * as actions from '../redux/PurchaseOrderAction'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const PurchaseOrderDetail: React.FC<Props> = ({className}) => {
  const purchaseOrder: any = useSelector<RootState>(
    ({purchaseOrders}) => purchaseOrders.purchaseOrder,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({purchaseOrders}) => purchaseOrders.loading,
    shallowEqual
  )

  const dispatch: any = useDispatch()

  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(PurchaseOrderAction.fetchOne(id))

    return () => {
      dispatch(actions.resetPurchaseOrder(PurchaseOrderInitValues))
    }
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Purchase Orders List', url: '/purchase-orders'},
    {name: 'Purchase Order Detail', url: ''},
  ]
  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Purchase Order Detail</span>
          </h1>
          <PrintButton />
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            purchaseOrder && purchaseOrder?._id !== '' ? (
              <>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Purchase Order No.</b>
                      </td>
                      <td>{purchaseOrder?.number}</td>
                      <td>
                        <b>Created Date</b>
                      </td>
                      <td>{formatDateTime(purchaseOrder?.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Purchase For</b>
                      </td>
                      <td>{purchaseOrder?.purchaseFor}</td>
                      <td>
                        <b>Status</b>
                      </td>
                      <td>{purchaseOrder?.status}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Supplier</b>
                      </td>
                      <td>{purchaseOrder?.supplier?.name}</td>
                      <td>
                        <b>Address</b>
                      </td>
                      <td>{purchaseOrder?.supplier?.city}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>NTN No.</b>
                      </td>
                      <td>{purchaseOrder?.supplier?.NTN}</td>
                      <td>
                        <b>Phone No.</b>
                      </td>
                      <td>{purchaseOrder?.supplier?.phone}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>STRN No.</b>
                      </td>
                      <td>{purchaseOrder?.supplier?.STRN}</td>
                      <td>
                        <b>Account Title</b>
                      </td>
                      <td>{purchaseOrder?.supplier?.bank?.accountTitle}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Bank Name</b>
                      </td>
                      <td>{purchaseOrder?.supplier?.bank?.name}</td>
                      <td>
                        <b>Status</b>
                      </td>
                      <td>{purchaseOrder?.status}</td>
                    </tr>
                    {purchaseOrder?.approvedAt && purchaseOrder?.approvedBy && (
                      <tr>
                        <td>
                          <b>Approved At</b>
                        </td>
                        <td>{formatDateTime(purchaseOrder?.approvedAt)}</td>
                        <td>
                          <b>Approved By</b>
                        </td>
                        <td>{purchaseOrder?.approvedBy?.fullName}</td>
                      </tr>
                    )}
                    {purchaseOrder?.rejectedAt && purchaseOrder?.rejectedBy && (
                      <tr>
                        <td>
                          <b>Rejected At</b>
                        </td>
                        <td>{formatDateTime(purchaseOrder?.rejectedAt)}</td>
                        <td>
                          <b>Rejected By</b>
                        </td>
                        <td>{purchaseOrder?.rejectedBy?.fullName}</td>
                      </tr>
                    )}
                    {purchaseOrder?.rejectionReason && (
                      <tr>
                        <td>
                          <b>Reason to Reject</b>
                        </td>
                        <td colSpan={3}>{purchaseOrder?.rejectionReason}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                  <thead>
                    <tr>
                      <th style={{width: 40}}>
                        <b>#</b>
                      </th>
                      <th>
                        <b>Product</b>
                      </th>
                      <th>
                        <b>UOM</b>
                      </th>
                      <th>
                        <b>Status</b>
                      </th>
                      <th style={{width: 80}}>
                        <b>Quantity</b>
                      </th>
                      <th>
                        <b>Rate</b>
                      </th>
                      <th>
                        <b>Price</b>
                      </th>
                      <th style={{width: 100}}>
                        <b>Tax</b>
                      </th>
                      <th>
                        <b>Price Inclusive Tax</b>
                      </th>
                      <th>
                        <b>Discount/Return</b>
                      </th>
                      <th>
                        <b>Total</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseOrder?.items.map((materialItem: any, index: any) => (
                      <tr key={index}>
                        <td data-label='#'>{index + 1}</td>
                        <td data-label='Name'>{materialItem.name}</td>
                        <td data-label='UOM'>{materialItem.UOM}</td>
                        <td data-label='Status'>{materialItem.status}</td>
                        <td data-label='Quantity'>{formatNumber(materialItem.quantity)}</td>
                        <td data-label='Rate'>{formatMoney(materialItem.rate)}</td>
                        <td data-label='Price'>{formatMoney(materialItem.price)}</td>
                        <td data-label='Tax'>{formatMoney(materialItem.tax)}</td>
                        <td data-label='Price Inclusive Tax'>
                          {formatMoney(materialItem?.priceInclusiveTax)}
                        </td>
                        <td data-label='Discount/Return'>{formatMoney(materialItem?.discount)}</td>
                        <td data-label='Total'>{formatMoney(materialItem?.total)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={9}></td>
                      <td>
                        <b>Total Price</b>
                      </td>
                      <td data-label='Total Price'>{formatMoney(purchaseOrder?.totalPrice)}</td>
                    </tr>
                    <tr>
                      <td colSpan={9}></td>
                      <td>
                        <b>Total Tax</b>
                      </td>
                      <td data-label='Total Tax'>{formatMoney(purchaseOrder?.totalTax)}</td>
                    </tr>
                    <tr>
                      <td colSpan={9}></td>
                      <td>
                        <b>Total Discount</b>
                      </td>
                      <td data-label='Total Discount'>
                        {formatMoney(purchaseOrder?.totalDiscount)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}></td>
                      <td>
                        <b>Total Amount</b>
                      </td>
                      <td data-label='Total Amount'>{formatMoney(purchaseOrder?.totalAmount)}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
