import {FC} from 'react'

const Error403: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-3x text-gray-700 mb-10 text-center'> You are not authorized</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15 text-center'>
        You have no permissions to see this page!
      </div>
    </>
  )
}

export {Error403}
