export interface BudgetSectionItem {
  product: string
  name: string
  UOM: string
  quantity: string
  rate: string
  amount: string
}

export interface BudgetSection {
  title: string
  total: string
  items: [BudgetSectionItem]
}
export interface Budget {
  _id: string
  title: string
  total: string
  startDate: Date | null
  endDate: Date | null
  number: string
  type: string
  parentId: string | null
  approvedAt?: string
  approvedBy?: string
  sections: [BudgetSection]
}

export const BudgetSectionItemInitValues: BudgetSectionItem = {
  product: '',
  name: '',
  UOM: '',
  quantity: '',
  rate: '',
  amount: '',
}

export const BudgetSectionInitValues: BudgetSection = {
  title: '',
  total: '',
  items: [BudgetSectionItemInitValues],
}
export const BudgetInitValues: Budget = {
  _id: '',
  title: '',
  total: '',
  startDate: new Date(),
  endDate: new Date(),
  number: '',
  type: 'ESTIMATE',
  parentId: null,
  approvedAt: '',
  approvedBy: '',

  sections: [BudgetSectionInitValues],
}

export interface BudgetSearchModel {
  number: string
  status: string
  createdAt: any
  approvedBy: string
  approvedAt: any
}
export const BudgetSearchModelInitValues: BudgetSearchModel = {
  number: '',
  status: '',
  createdAt: [],
  approvedBy: '',
  approvedAt: [],
}

export interface BudgetTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: BudgetSearchModel
}

export const BudgetTableModelInitValues: BudgetTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: BudgetSearchModelInitValues,
}
