import React from 'react'
import {KTSVG} from '../../_metronic/helpers'

export const PrintButton: React.FC = () => {
  return (
    <>
      <a
        href='#'
        title='Print'
        onClick={() => {
          window.print()
        }}
      >
        <KTSVG
          path='/media/svg/icons/Devices/Printer.svg'
          className='svg-icon svg-icon-2x svg-icon-danger '
        />
      </a>
    </>
  )
}
