import {MaterialInspectionSlice} from './MaterialInspectionSlice'
import * as MaterialInspectionCRUD from './MaterialInspectionCRUD'
import {deepCopy, formatNumber} from '../../../helper'
import {
  MaterialInspectionInitValues,
  MaterialInspectionTableModel,
} from '../models/MaterialInspectionModel'

const {actions} = MaterialInspectionSlice

export const fetchAll: any = (params: MaterialInspectionTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return MaterialInspectionCRUD.findAll(params).then((res) => {
    dispatch(actions.setMaterialInspections({materialInspections: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchOne = (id: any) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await MaterialInspectionCRUD.findOne(id)
    let newMaterialInspection = JSON.parse(JSON.stringify(res.data.data))
    res.data.data.items.map((r: any, i: any) => {
      newMaterialInspection.items[i] = {
        _id: r._id,
        product: r.product,
        name: r.name,
        UOM: r.UOM,
        quantity: r.quantity,
        rate: r.rate,
        price: r.price,
        tax: r.tax,
        priceInclusiveTax: r.priceInclusiveTax,
        discount: r.discount,
        total: r.total,
        remarks: r.remarks,
      }
    })
    dispatch(actions.setMaterialInspection({materialInspection: newMaterialInspection}))
    dispatch(actions.stopLoading())
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const fetchApproved = () => (dispatch: any) => {
  return MaterialInspectionCRUD.findApproved().then((res) => {
    dispatch(actions.setMaterialInspections({materialInspections: res.data.data}))
  })
}

export const create = (data: any) => async (dispatch: any) => {
  try {
    const res = await MaterialInspectionCRUD.create(data)
    const successMsg = res.data.message
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const approve = (id: any) => async (dispatch: any) => {
  try {
    const res = await MaterialInspectionCRUD.approve(id)

    const materialInspection = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setMaterialInspection({materialInspection: materialInspection}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const reject = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await MaterialInspectionCRUD.reject(id, data)

    const materialInspection = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setMaterialInspection({materialInspection: materialInspection}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const onChangePurchaseOrder =
  (e: any, values: any, purchaseOrders: any) => (dispatch: any) => {
    const selectedPurchaseOrder = purchaseOrders.find(
      (purchaseOrder: any) => purchaseOrder._id === e.value
    )

    let uMaterialInspection = JSON.parse(JSON.stringify(values))

    if (selectedPurchaseOrder) {
      uMaterialInspection.purchaseOrder = selectedPurchaseOrder._id
      uMaterialInspection.totalPrice = formatNumber(selectedPurchaseOrder.totalPrice)
      uMaterialInspection.totalTax = formatNumber(selectedPurchaseOrder.totalTax)
      uMaterialInspection.totalDiscount = formatNumber(selectedPurchaseOrder.totalDiscount)
      uMaterialInspection.totalAmount = formatNumber(selectedPurchaseOrder.totalAmount)

      uMaterialInspection.items = []

      const filteredItems = selectedPurchaseOrder?.items.filter(
        (item: any) => item.status === 'APPROVED'
      )

      filteredItems.map((r: any, i: any) => {
        uMaterialInspection.items[i] = {
          _id: r._id,
          product: r.product,
          name: r.name,
          UOM: r.UOM,
          quantity: r.quantity,
          rate: formatNumber(r.rate),
          price: formatNumber(r.price),
          tax: formatNumber(r.tax),
          priceInclusiveTax: formatNumber(r.priceInclusiveTax),
          discount: formatNumber(r.discount),
          total: formatNumber(r.total),
          remarks: '',
        }
      })
      dispatch(actions.setMaterialInspection({materialInspection: uMaterialInspection}))
      dispatch(onChangeItem(uMaterialInspection))
    }
  }

export const removeMaterialInspectionItem = (values: any, index: number) => (dispatch: any) => {
  let uMaterialInspection = deepCopy(values)
  const items = values?.items.filter((item: any, i: any) => i !== index)
  uMaterialInspection.items = items
  dispatch(actions.setMaterialInspection({materialInspection: uMaterialInspection}))
  dispatch(onChangeItem(uMaterialInspection))
}

export const onChangeItem = (values: any) => (dispatch: any) => {
  let uMaterialInspection = deepCopy(values)
  const totalAmount = uMaterialInspection.items.reduce((prev: any, item: any) => {
    const total = +formatNumber(item.priceInclusiveTax) - +formatNumber(item.discount)
    item.total = formatNumber(total)
    return prev + total
  }, 0)
  const totalPrice = uMaterialInspection.items.reduce((prev: any, item: any) => {
    return prev + +formatNumber(item.price)
  }, 0)
  const totalTax = uMaterialInspection.items.reduce((prev: any, item: any) => {
    return prev + +formatNumber(item.tax)
  }, 0)
  const totalDiscount = uMaterialInspection.items.reduce((prev: any, item: any) => {
    return prev + +formatNumber(item.discount)
  }, 0)
  uMaterialInspection.totalAmount = formatNumber(totalAmount)
  uMaterialInspection.totalPrice = formatNumber(totalPrice)
  uMaterialInspection.totalTax = formatNumber(totalTax)
  uMaterialInspection.totalDiscount = formatNumber(totalDiscount)
  dispatch(actions.setMaterialInspection({materialInspection: uMaterialInspection}))
}
export const resetMaterialInspection = (data: any) => (dispatch: any) => {
  dispatch(actions.setMaterialInspection({materialInspection: data}))
}

export const remove = (id: any, params: MaterialInspectionTableModel) => async (dispatch: any) => {
  try {
    const res = await MaterialInspectionCRUD.remove(id)
    const successMsg = res.data.message

    MaterialInspectionCRUD.findAll(params).then((res) => {
      dispatch(actions.setMaterialInspections({materialInspections: res.data.data}))
      dispatch(actions.setPagination({pagination: res.data.pagination}))
      dispatch(actions.stopLoading())
    })

    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}
