import {AbilityBuilder} from '@casl/ability'
import {defineAbility} from '@casl/ability'

export function updateAbility(ability: any, user: any) {
  const {can, rules} = new AbilityBuilder(user)
  const userRoles = user?.roles

  userRoles &&
    userRoles?.map((role: any) => {
      Object?.entries(role?.permissions)?.forEach(([subject, value]: any) => {
        Object?.entries(value)?.forEach(([i, action]) => {
          let cond = ''
          can(action, subject, cond)
        })
      })
    })
  ability.update(rules)
}

export default defineAbility((can, cannot) => {
  can('read', 'Dashboard')
})
