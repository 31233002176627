/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {formatDate, formatDateTime, formatMoney} from '../../../helper'
import {Budget, BudgetInitValues} from '../models/BudgetModel'
import * as actions from '../redux/BudgetAction'
import {BudgetApproveConfirmModal} from './BudgetApproveConfirmModal'
import {BudgetRejectConfirmModal} from './BudgetRejectConfirmModal'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const BudgetApprove: FC<Props> = ({className}) => {
  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()
  const [isPkr, setPkr] = useState(true)

  const budget: any = useSelector<RootState>((state) => state.budget.budget, shallowEqual)
  const loading: any = useSelector<RootState>((state) => state.budget.loading, shallowEqual)

  useEffect(() => {
    if (id) {
      dispatch(actions.findOne(id))
    } else {
      dispatch(actions.resetBudget(BudgetInitValues))
    }
  }, [dispatch, id])

  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [budgetToApprove, setBudgetToApprove] = useState<Budget>(BudgetInitValues)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [budgetToReject, setBudgetToReject] = useState<Budget>(BudgetInitValues)

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Budgets List', url: '/budgets'},
    {name: 'Budget Approval Form', url: ''},
  ]

  return (
    <>
      <BudgetApproveConfirmModal
        show={showApproveDialog}
        handleClose={() => {
          setShowApproveDialog(false)
        }}
        budget={budgetToApprove}
      />

      <BudgetRejectConfirmModal
        show={showRejectDialog}
        handleClose={() => {
          setShowRejectDialog(false)
        }}
        budget={budgetToReject}
      />

      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-body py-10'>
          <h3 className='card-title'>
            <span className='card-label fw-bolder fs-3 mt-15'>Budget Approval Form</span>
          </h3>
          {!loading ? (
            budget && budget?._id !== '' ? (
              <>
                <table className='mt-10 table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Title </b>
                      </td>
                      <td>{budget?.title}</td>
                      <td>
                        <b>Budget No</b>
                      </td>
                      <td>{budget?.number}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Created Date</b>
                      </td>
                      <td>{formatDateTime(budget?.createdAt)}</td>
                      <td>
                        <b>Status</b>
                      </td>
                      <td>{budget.status}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Start Date </b>
                      </td>
                      <td>{formatDate(budget?.startDate)}</td>
                      <td>
                        <b>End Date</b>
                      </td>
                      <td>{formatDate(budget?.endDate)}</td>
                    </tr>

                    <tr>
                      <td>
                        <b>Grand Total</b>
                      </td>
                      <td>
                        <b>{formatMoney(budget?.total, isPkr)}</b>
                      </td>
                      <td>
                        <b>Budget Type</b>
                      </td>
                      <td>{budget.type}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Request by Employee Name</b>
                      </td>
                      <td>{budget?.createdBy?.fullName}</td>
                      <td></td> <td></td>
                    </tr>

                    {budget?.approvedAt && budget?.approvedBy && (
                      <tr>
                        <td>
                          <b>Approved At</b>
                        </td>
                        <td>{formatDateTime(budget?.approvedAt)}</td>
                        <td>
                          <b>Approved By</b>
                        </td>
                        <td>{budget?.approvedBy.firstName}</td>
                      </tr>
                    )}
                    {budget?.rejectedAt && budget?.rejectedBy && (
                      <tr>
                        <td>
                          <b>Rejected At</b>
                        </td>
                        <td>{formatDateTime(budget.rejectedAt)}</td>
                        <td>
                          <b>Rejected By</b>
                        </td>
                        <td>{budget?.rejectedBy.firstName}</td>
                      </tr>
                    )}
                    {budget?.reason && (
                      <tr>
                        <td>
                          <b>Reason to Reject</b>
                        </td>
                        <td colSpan={3}>{budget.reason}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {budget?.sections?.map((section: any, index: any) => (
                  <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                    <thead>
                      <tr>
                        <th colSpan={3}>
                          <h3>Section Title</h3>
                        </th>
                        <th>
                          <h3>{section.title}</h3>
                        </th>
                        <th>
                          <h3>Section Total</h3>
                        </th>
                        <th>
                          <h3>{formatMoney(section.total, isPkr)}</h3>
                        </th>
                      </tr>
                      <tr>
                        <th colSpan={2}>
                          <b>Product</b>
                        </th>
                        <th>
                          <b>UOM</b>
                        </th>
                        <th>
                          <b>Quantity</b>
                        </th>
                        <th>
                          <b>Rate</b>
                        </th>
                        <th>
                          <b>Amount</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {section?.items?.map((sectionItem: any, index: any) => (
                        <tr key={index}>
                          <td data-label='Product' colSpan={2}>
                            {index + 1}. {sectionItem.name}
                          </td>
                          <td data-label='UOM'>{sectionItem.UOM}</td>
                          <td data-label='Quantity'>{sectionItem.quantity}</td>
                          <td data-label='Rate'>{formatMoney(sectionItem.rate)}</td>
                          <td data-label='Amount'>{formatMoney(sectionItem.amount)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))}
                <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                  <tbody>
                    <tr>
                      <td className='text-end' colSpan={4}>
                        <h2>Grand Total</h2>
                      </td>
                      <td>
                        <h2>{formatMoney(budget?.total, isPkr)}</h2>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className='mt-10'></div>
                <div className='d-flex flex-stack pt-5'>
                  <div className='me-2'></div>

                  <div className='d-flex justify-content-end mt-5'>
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Reject'
                      onClick={() => {
                        setShowRejectDialog(true)
                        setBudgetToReject(budget)
                      }}
                    >
                      <span className='indicator-label'>Reject</span>
                    </button>

                    <button
                      type='button'
                      className='btn btn-lg  btn-primary me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Approve'
                      id='liveToastBtn'
                      onClick={() => {
                        setShowApproveDialog(true)
                        setBudgetToApprove(budget)
                      }}
                    >
                      <span className='indicator-label'>Approve</span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
