import React from 'react'
import {InventoryAdd} from './components/InventoryAdd'
import {Route, Routes} from 'react-router-dom'
import {Inventories} from './components/Inventories'
import {InventoryReport} from './components/InventoryReport'

export const InventoryPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Inventories className='mb-5 mb-xl-8' />} />
        <Route path='/add' element={<InventoryAdd className='mb-5 mb-xl-8' />} />
        <Route path='/report' element={<InventoryReport className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
