import {Role, initRoleValues} from '../../roles/models/RoleModel'

export interface settings {
  appNotification: boolean
  emailNotification: boolean
}

export const initSettingsValues: settings = {
  appNotification: false,
  emailNotification: false,
}

export interface application {
  token: string
  expiry: Date
  refreshToken: string
}

export const initApplicationValues: application = {
  token: '',
  expiry: new Date(),
  refreshToken: '',
}

export interface UserModel {
  _id: string
  firstName: string
  lastName: string
  email: string
  password: string
  roles: [Role]
  setting?: settings
  disable: boolean
  application?: application
}

export const UserInitValues: UserModel = {
  _id: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  roles: [initRoleValues],
  setting: initSettingsValues,
  disable: false,
  application: initApplicationValues,
}

export interface UserSearchModel {
  firstName: string
  lastName: string
  email: string
}
export const UserSearchModelInitValues: UserSearchModel = {
  firstName: '',
  lastName: '',
  email: '',
}

export interface UserTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: UserSearchModel
}

export const UserTableModelInitValues: UserTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: UserSearchModelInitValues,
}
