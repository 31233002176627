import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  DepartmentInitValues,
  DepartmentModel,
  DepartmentSearchModelInitValues,
  DepartmentTableModelInitValues,
} from '../models/DepartmentModel'
import {fetchAll} from '../redux/DepartmentAction'
import {DepartmentAdd} from './DepartmentAdd'
import {DepartmentEdit} from './DepartmentEdit'
import {DepartmentDelete} from './DepartmentDelete'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDateTime} from '../../../helper'
import {Can} from '../../../rbac/Can'
import {Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import 'rsuite/dist/rsuite.css'
import {Link} from 'react-router-dom'
import {Table} from '../../../components/ReactTable'

type Props = {
  className: string
}
export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const Department: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [departmentToDelete, setDepartmentToDelete] =
    useState<DepartmentModel>(DepartmentInitValues)
  const [departmentToEdit, setDepartmentToEdit] = useState<DepartmentModel>(DepartmentInitValues)
  const loading: any = useSelector<RootState>(({department}) => department.loading, shallowEqual)

  const departments: any = useSelector<RootState>(
    ({department}) => department.departments,
    shallowEqual
  )

  const pages: any = useSelector<RootState>(
    ({department}) => department.pagination?.pages,
    shallowEqual
  )

  const [searchParams, setSearchParams]: any = useState({})

  const [show, setShow] = useState(false)
  const filterHandel = () => {
    setShow(!show)
  }

  const fetchData = useCallback(
    ({pageSize, pageIndex, params, sortBy}: any) => {
      const queryParams = {
        page: pageIndex + 1,
        sortBy: sortBy[0]?.id || 'createdAt',
        sortOrder: sortBy[0]?.desc === false ? 'asc' : 'desc',
        search: params,
      }
      dispatch(fetchAll(queryParams))
    },
    [searchParams]
  )

  useEffect(() => {
    dispatch(fetchAll(DepartmentTableModelInitValues))
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: (cellInfo: any) => {
          if (cellInfo?.row?.original?.createdAt) {
            return formatDateTime(cellInfo?.row?.original?.createdAt)
          } else {
            return '-'
          }
        },
        show: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Actions',

        Cell: (params: any) => (
          <div className='action-btn-wrapper d-flex flex-shrink-0'>
            <Can do='update' on='departments'>
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title='Edit Department'
                onClick={(e) => {
                  setDepartmentToEdit(params?.row?.original)
                  setShowEditDialog(true)
                }}
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button>
            </Can>
            <Can do='delete' on='departments'>
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title='Delete Department'
                onClick={(e) => {
                  setDepartmentToDelete(params?.row?.original)

                  setShowDeleteDialog(true)
                }}
              >
                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
              </button>
            </Can>
          </div>
        ),
      },
    ],
    []
  )

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Departments List', url: '/departments'},
  ]

  return (
    <>
      <DepartmentAdd
        show={showAddDialog}
        handleClose={() => {
          setShowAddDialog(false)
        }}
      />

      <DepartmentEdit
        show={showEditDialog}
        handleClose={() => {
          setShowEditDialog(false)
        }}
        department={departmentToEdit}
      />

      <DepartmentDelete
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        department={departmentToDelete}
      />

      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Departments List</span>
          </h1>
          <Can do='create' on='departments'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Add Department'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                id='kt_toolbar_primary_button'
                onClick={() => {
                  setShowAddDialog(true)
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add Department
              </a>
            </div>
          </Can>
        </div>
        <div className='card-body'>
          <div
            className={`d-flex align-items-center ${
              !show ? 'justify-content-end' : 'justify-content-between'
            }`}
          >
            <p className='my-0 me-3' style={{color: '#004B87', fontSize: '14px'}}>
              <b>Search Filters</b>
            </p>
            <span onClick={filterHandel}>
              <img src='/media/svg/icons/General/filter.svg' alt='' />
            </span>
          </div>
          {show ? (
            <Formik
              initialValues={DepartmentSearchModelInitValues}
              validationSchema={Yup.object({
                name: Yup.string(),
              })}
              onSubmit={(values: any) => {
                setSearchParams(values)
              }}
            >
              {({values, setFieldValue}) => (
                <Form>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                    <div className='form-group col'>
                      <label htmlFor='number' className='fw-bold'>
                        Name
                      </label>
                      <Field
                        name='name'
                        placeholder='Name'
                        className='form-control form-control-lg'
                      />
                    </div>
                  </div>

                  <div className='d-flex justify-content-end mt-10'>
                    <Link
                      to='/departments'
                      className='btn btn-sm btn-light-primary me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancel'
                      onClick={() => {
                        setSearchParams(DepartmentSearchModelInitValues)
                        setFieldValue('name', '')
                      }}
                    >
                      <span className='indicator-label'>Cancel</span>
                    </Link>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Search'
                      id='liveToastBtn'
                    >
                      <span className='indicator-label'>Search</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}
          <div className='d-flex mt-5 '>
            <div className='react-table-wrapper' style={{width: '50%'}}>
              <Table
                columns={columns}
                data={departments}
                params={searchParams}
                pageCount={pages}
                fetchData={fetchData}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
