interface MaterialRequisitionItem {
  _id: string
  product: string
  name: string
  UOM: string
  quantity: number | string
  status: string
}

export interface MaterialRequisitionModel {
  _id: string
  number: string
  requestedDate?: string
  requestedBy?: string
  approvedAt?: string
  approvedBy?: string
  rejectedBy?: string
  rejectedAt?: string
  rejectionReason: string
  department: string
  location: string
  status: string
  items: [MaterialRequisitionItem]
}

export const MaterialRequisitionInitValues: MaterialRequisitionModel = {
  _id: '',
  department: '',
  number: '',
  requestedDate: '',
  requestedBy: '',
  approvedAt: '',
  approvedBy: '',
  rejectedBy: '',
  rejectedAt: '',
  rejectionReason: '',
  location: '',
  status: '',
  items: [{_id: '', product: '', name: '', UOM: '', quantity: '', status: ''}],
}

export interface MaterialRequisitionSearchModel {
  number: string
  status: string
  createdAt: any
  approvedBy: string
  approvedAt: any
}
export const MaterialRequisitionSearchModelInitValues: MaterialRequisitionSearchModel = {
  number: '',
  status: '',
  createdAt: [],
  approvedBy: '',
  approvedAt: [],
}

export interface MaterialRequisitionTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: MaterialRequisitionSearchModel
}

export const MaterialRequisitionTableModelInitValues: MaterialRequisitionTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: MaterialRequisitionSearchModelInitValues,
}
