/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import '../../../_metronic/assets/sass/components/cards.scss'
import {Link} from 'react-router-dom'

type Props = {
  item: any
  index?: any
}

export const DashboardItem: FC<Props> = ({index, item}) => {
  return (
    <div key={index} className='col rounded-2 dashboard-icon'>
      <Link to={item.route} className='card-item mx-4 my-4 rounded-2'>
        <div className='icon'>
          <img src={`${item.imageSrc}`} alt='icon' />
        </div>
        <div className='title'>
          <h6>{item.title}</h6>
        </div>
      </Link>
    </div>
  )
}
