interface products {
  name: string
  description: string
}

interface evaluation {
  productionCapactiy: string
  fbrRegistrationStatus: string
  orderCapacity: string
  marketPR: string
  customerRelationManagement: string
  paymentsTerms: string
}

export interface SupplierBankModel {
  name: string
  accountTitle: string
  IBAN: string
}

export interface SupplierModel {
  _id: string
  approvedAt?: string
  approvedBy?: string
  rejectedBy?: string
  rejectedAt?: string
  name: string
  address: string
  city: string
  phone: string
  NTN: string
  STRN: string
  rejectionReason: string
  products: [products]
  evaluation: evaluation
  bank: SupplierBankModel
}

export const SupplierInitValues: SupplierModel = {
  _id: '',
  name: '',
  address: '',
  city: '',
  phone: '',
  NTN: '',
  STRN: '',
  approvedAt: '',
  approvedBy: '',
  rejectedBy: '',
  rejectedAt: '',
  rejectionReason: '',
  products: [{name: '', description: ''}],
  evaluation: {
    productionCapactiy: '',
    fbrRegistrationStatus: '',
    orderCapacity: '',
    marketPR: '',
    customerRelationManagement: '',
    paymentsTerms: '',
  },
  bank: {
    name: '',
    accountTitle: '',
    IBAN: '',
  },
}

export interface SupplierSearchModel {
  name: string
  status: string
  createdAt: any
  approvedBy: string
  approvedAt: any
}
export const SupplierSearchModelInitValues: SupplierSearchModel = {
  name: '',
  status: '',
  createdAt: [],
  approvedBy: '',
  approvedAt: [],
}

export interface SupplierTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: SupplierSearchModel
}

export const SupplierTableModelInitValues: SupplierTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: SupplierSearchModelInitValues,
}
