import {
  MaterialIssuanceInitValues,
  MaterialIssuanceTableModel,
} from '../models/MaterialIssuanceModel'
import * as MaterialIssuanceCRUD from './MaterialIssuanceCRUD'
import {MaterialIssuanceSlice} from './MaterialIssuanceSlice'
import * as InventoryCrud from '../../inventory/redux/InventoryCRUD'
import {InventorySlice} from '../../inventory/redux/InventorySlice'
import {toast} from 'react-toastify'

const {actions} = MaterialIssuanceSlice

export const fetchAll: any = (params: MaterialIssuanceTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return MaterialIssuanceCRUD.findAll(params).then((res) => {
    dispatch(actions.setMaterialIssuances({materialIssuances: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchOne = (id: any) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await MaterialIssuanceCRUD.findOne(id)
    let newMaterialIssuance = JSON.parse(JSON.stringify(res.data.data))
    res.data.data.items.map((r: any, i: any) => {
      newMaterialIssuance.items[i] = {
        _id: r._id,
        product: r.product,
        qrCode: r.qrCode,
        expiryDate: r.expiryDate,
        name: r.name,
        UOM: r.UOM,
        quantity: r.quantity,
        assetNumber: r.assetNumber,
        remarks: r.remarks,
      }
    })
    dispatch(actions.stopLoading())
    dispatch(actions.setMaterialIssuance({materialIssuance: newMaterialIssuance}))
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const fetchByQRCode = (values: any, index: any, mr: any) => async (dispatch: any) => {
  try {
    dispatch(actions.startLoading())
    let uMaterialIssuance = JSON.parse(JSON.stringify(values))
    //item found
    const itemFound = uMaterialIssuance.items.filter((item: any, ind: any) => ind == index)

    //call api
    let result = await InventoryCrud.fetchOneByQRCode(itemFound[0].qrCode)
    //set data return from api
    if (result?.data?.data != null) {
      itemFound[0]._id = result?.data?.data?.product?._id
      itemFound[0].product = result?.data?.data?.product?._id
      itemFound[0].UOM = result?.data?.data?.product?.UOM
      itemFound[0].name = result?.data?.data?.product?.name
      itemFound[0].expiryDate = result?.data?.data?.expiryDate

      if (itemFound[0]) {
        const existInMR = mr?.items?.filter((item: any, i: any) => item.name == itemFound[0]?.name)
        if (existInMR.length == 0) {
          toast.error('Please Scan Valid Items that exist in Material Requisition')
          dispatch(actions.stopLoading())
        } else {
          // make that item updated
          const items = uMaterialIssuance?.items.map((item: any, j: any) => {
            let uItem = JSON.parse(JSON.stringify(item))
            if (j == index) {
              uItem = itemFound[0]
              return uItem
            }
            return uItem
          })

          items.push({
            _id: '',
            qrCode: '',
            product: '',
            name: '',
            UOM: '',
            quantity: 0,
            assetNumber: '',
            remarks: '',
          })

          uMaterialIssuance.items = items
          dispatch(actions.setMaterialIssuance({materialIssuance: uMaterialIssuance}))
          dispatch(actions.stopLoading())
        }
      }
    }
  } catch (error: any) {
    const errors = error?.response?.data?.message
    toast.error(errors)
    dispatch(actions.stopLoading())
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const resetMaterialIssuance = (data: any) => (dispatch: any) => {
  dispatch(actions.setMaterialIssuance({materialIssuance: data}))
}

export const fetchApproved = () => (dispatch: any) => {
  return MaterialIssuanceCRUD.findApproved().then((res) => {
    dispatch(actions.setMaterialIssuances({materialIssuances: res.data.data}))
  })
}

export const fetchReturn = () => (dispatch: any) => {
  return MaterialIssuanceCRUD.findReturn().then((res) => {
    dispatch(actions.setMaterialIssuances({materialIssuances: res.data.data}))
  })
}

export const create = (data: any) => async (dispatch: any) => {
  try {
    const res = await MaterialIssuanceCRUD.create(data)
    const successMsg = res.data.message
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const approve = (id: any) => async (dispatch: any) => {
  try {
    const res = await MaterialIssuanceCRUD.approve(id)
    const materialIssuance = res.data.data
    const successMsg = res.data.message
    dispatch(actions.setMaterialIssuance({materialIssuance: materialIssuance}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const reject = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await MaterialIssuanceCRUD.reject(id, data)

    const materialIssuance = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setMaterialIssuance({materialIssuance: materialIssuance}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const remove = (id: any, params: MaterialIssuanceTableModel) => async (dispatch: any) => {
  try {
    const res = await MaterialIssuanceCRUD.remove(id)

    const successMsg = res.data.message
    MaterialIssuanceCRUD.findAll(params).then((res) => {
      dispatch(actions.setMaterialIssuances({materialIssuances: res.data.data}))
      dispatch(actions.setPagination({pagination: res.data.pagination}))
      dispatch(actions.stopLoading())
    })

    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message

    let errorMsg

    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}
