import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {StockMovementReport} from './components/StockMovementReport'

export const StockMovementReportPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<StockMovementReport className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
