import React from 'react'
import {RoleTable} from './components/Roles'
import {Route, Routes} from 'react-router-dom'
import {RoleEdit} from './components/RoleEdit'

export const RolePage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<RoleTable className='mb-5 mb-xl-8' />} />
        <Route path='/create' element={<RoleEdit className='mb-5 mb-xl-8' />} />
        <Route path='/:id/edit' element={<RoleEdit className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
