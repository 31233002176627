import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import * as DepartmentAction from '../../departments/redux/DepartmentAction'
import * as MaterialIssuanceAction from '../../material-issuance/redux/MaterialIssuanceAction'
import * as LocationAction from '../../locations/redux/LocationAction'
import {toast} from 'react-toastify'
import * as MaterialReturnAction from '../redux/MaterialReturnAction'
import {MaterialReturnInitValues} from '../models/MaterialReturnModel'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {KTSVG} from '../../../../_metronic/helpers'
import {LocationTableModelInitValues} from '../../locations/models/LocationModel'
import {DepartmentTableModelInitValues} from '../../departments/models/DepartmentModel'
import {formatNumber} from '../../../helper'

type Props = {
  className: string
}

export const MaterialReturnAdd: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(false)
  const dispatch: any = useDispatch()
  let navigate = useNavigate()

  const materialReturn: any = useSelector<RootState>(
    (state) => state.materialReturn.materialReturn,
    shallowEqual
  )

  const materialIssuance: any = useSelector<RootState>(
    (state) => state.materialReturn.materialIssuance,
    shallowEqual
  )

  const materialIssuances: any = useSelector<RootState>(
    ({materialIssuance}) => materialIssuance.materialIssuances,
    shallowEqual
  )

  const animatedComponents = makeAnimated()

  const departments: any = useSelector<RootState>(
    ({department}) => department.departments,
    shallowEqual
  )

  const locations: any = useSelector<RootState>(({location}) => location.locations, shallowEqual)

  useEffect(() => {
    dispatch(MaterialIssuanceAction.fetchReturn())
    dispatch(DepartmentAction.fetchAll(DepartmentTableModelInitValues))
    dispatch(LocationAction.fetchAll(LocationTableModelInitValues))
    return () => {
      dispatch(MaterialReturnAction.resetMaterialReturn(MaterialReturnInitValues))
    }
  }, [dispatch])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Returns List', url: '/material-return'},
    {name: 'Material Return Form', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3'> Material Return Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          <Formik
            enableReinitialize
            initialValues={materialReturn}
            validationSchema={Yup.object({
              materialIssuance: Yup.string().required('Material Issuance is required'),
              receivedByDepartment: Yup.string().required('Received By Department is required'),
              providedToDepartment: Yup.string().required('Provided To Department is required'),
              location: Yup.string().required('Location is required'),
              items: Yup.array().of(
                Yup.object().shape({
                  qrCode: Yup.string().required('QR Code is required'),
                  name: Yup.string().required('Product is required'),
                  UOM: Yup.string().required('Packing details is required'),
                  quantity: Yup.number()
                    .required('Quantity is required')
                    .typeError('Quantity is invalid')
                    .nullable()
                    .positive('Quantity must be more than 0')
                    .test(
                      'quantity',
                      'Quantity must be smaller then the previous quantity',
                      function (value: any, el: any) {
                        const product = el.parent.product
                        const oldItem =
                          materialIssuance &&
                          materialIssuance?.items?.find((item: any) => item.product == product)

                        return !(value > oldItem?.quantity)
                      }
                    ),
                  remarks: Yup.string()
                    .required('Remarks is required')
                    .trim('Remarks must have non-empty spaces')
                    .strict(true),
                })
              ),
            })}
            onSubmit={(values: any) => {
              setLoading(true)
              dispatch(MaterialReturnAction.create(values))
                .then((response: string) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/material-return')
                })
                .catch((errorMsg: any) => {
                  toast.error(errorMsg)
                  setLoading(false)
                })
            }}
          >
            {({setFieldValue, values}) => (
              <Form>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='form-group col'>
                    <label htmlFor='materialIssuance' className='fw-bold required'>
                      Material Issuance No.
                    </label>
                    <Select
                      options={
                        Array.isArray(materialIssuances)
                          ? materialIssuances?.map((materialIssuance: any, index: any) => {
                              return {
                                value: materialIssuance._id,
                                label: `${materialIssuance.number}`,
                                key: index,
                              }
                            })
                          : []
                      }
                      placeholder='Select'
                      className='multi-select-container'
                      classNamePrefix='multi-select'
                      name='materialIssuance'
                      onChange={(e: any) => {
                        if (e?.value) {
                          setFieldValue('materialIssuance', e.value)
                          dispatch(
                            MaterialReturnAction.onChangeMaterialIssuance(
                              e,
                              values,
                              materialIssuances
                            )
                          )
                        } else {
                          setFieldValue('materialIssuance', '')
                        }
                      }}
                      closeMenuOnSelect={true}
                      isClearable={true}
                      components={animatedComponents}
                    />

                    <div className='text-danger'>
                      <ErrorMessage name='materialIssuance' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='location' className='fw-bold required'>
                      Location
                    </label>
                    <Field as='select' className='form-select form-select-md mt-1' name='location'>
                      <option> Select</option>
                      {Array.isArray(locations) &&
                        locations.map((location: any, index: any) => (
                          <option key={index} value={location._id || ''}>
                            {location.name}
                          </option>
                        ))}
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='location' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='providedToDepartment' className='fw-bold required'>
                      Provided To Department
                    </label>
                    <Field
                      as='select'
                      className='form-select form-select-md'
                      data-control='select2'
                      name='providedToDepartment'
                    >
                      <option>Select</option>
                      {Array.isArray(departments) &&
                        departments.map((department: any, index: any) => (
                          <option value={department._id} key={index}>
                            {department.name}
                          </option>
                        ))}
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='providedToDepartment' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='receivedByDepartment' className='fw-bold required'>
                      Received By Department
                    </label>
                    <Field
                      as='select'
                      className='form-select form-select-md'
                      data-control='select2'
                      name='receivedByDepartment'
                    >
                      <option>Select</option>
                      {Array.isArray(departments) &&
                        departments.map((department: any, index: any) => (
                          <option value={department._id} key={index}>
                            {department.name}
                          </option>
                        ))}
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='receivedByDepartment' />
                    </div>
                  </div>
                </div>

                <FieldArray
                  name='items'
                  render={(arrayHelpers) => (
                    <div className='mt-15'>
                      <table className='table tbl-md-responsivness'>
                        <thead>
                          <tr>
                            <th>
                              <label className='form-label required '>QR Code</label>
                            </th>
                            <th>
                              <label className='form-label required '>Product</label>
                            </th>
                            <th>
                              <label className='form-label required '>UOM</label>
                            </th>
                            <th>
                              <label className='form-label required '>Quantity Return</label>
                            </th>
                            <th>
                              <label className='form-label required '>Remarks</label>
                            </th>
                            <th>
                              <label className='form-label'></label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.items?.length > 0 &&
                            values.items?.map((materialItem: any, index: any) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td data-label='QR Code'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.qrCode`}
                                        placeholder='QR Code'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.qrCode`} />
                                      </div>
                                    </div>
                                  </td>
                                  <td data-label='Product'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.name`}
                                        placeholder='Product'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.name`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label='UOM'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.UOM`}
                                        placeholder='UOM'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.UOM`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label='Quantity'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.quantity`}
                                        placeholder='Quantity Return'
                                        className='form-control form-control-md'
                                        onBlur={(e: any) => {
                                          const value: number = e.target.value
                                          const roundedValue = formatNumber(value)
                                          setFieldValue(`items.${index}.quantity`, roundedValue)
                                        }}
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.quantity`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label='Remarks'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.remarks`}
                                        placeholder='Remarks'
                                        className='form-control form-control-md'
                                        maxLength={255}
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.remarks`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <div className='d-flex flex-column w-100'>
                                      <button
                                        type='button'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                        onClick={() => {
                                          dispatch(
                                            MaterialReturnAction.removeMaterialReturnItem(
                                              values,
                                              index
                                            )
                                          )
                                        }}
                                        data-bs-toggle='tooltip'
                                        data-bs-placement='top'
                                        title='Remove'
                                        disabled={values.items.length > 1 ? false : true}
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen027.svg'
                                          className='svg-icon  svg-icon-3'
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                />

                <div className='d-flex justify-content-end mt-10'>
                  <Link
                    to='/material-return'
                    className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Cancel'
                  >
                    <span className='indicator-label'>Cancel</span>
                  </Link>

                  <button
                    type='submit'
                    className='btn btn-lg  btn-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Submit'
                    id='liveToastBtn'
                    disabled={loading}
                  >
                    <span className='indicator-label'>{loading ? 'Submitting...' : 'Submit'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
