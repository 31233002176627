import React, {Fragment, useMemo, useEffect, useState} from 'react'
import {useTable, usePagination, useExpanded, useSortBy} from 'react-table'
import {Loader} from './Loader'
import {NoItemFound} from './NoItemFound'

type Props = {
  columns: any
  data: any
  params: any
  fetchData: any
  loading: any
}
export const Table: React.FC<Props> = ({columns, data, params, fetchData, loading}) => {
  const defaultColumn = useMemo(() => ({}), [])
  const {getTableProps, getTableBodyProps, headerGroups, prepareRow, page} = useTable(
    {
      columns,
      data,
      defaultColumn,

      manualPagination: true,
    },
    useSortBy,
    useExpanded,
    usePagination
  )

  useEffect(() => {
    fetchData && fetchData({params})
  }, [fetchData, params])

  // Calculate the total number of columns including subheaders
  const totalColumns = headerGroups.reduce((total, headerGroup) => {
    return total + headerGroup.headers.length
  }, 0)

  return (
    <Fragment>
      <div className='table-responsive'>
        {loading ? (
          <Loader />
        ) : (
          <table
            {...getTableProps()}
            className='table table-striped'
            style={{width: 'unset', minWidth: '100%', border: '1px solid #E2E2E2'}}
          >
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr
                  style={{border: '1px solid #E2E2E2', textAlign: 'center'}}
                  {...headerGroup?.getHeaderGroupProps()}
                >
                  {headerGroup?.headers?.map((column) => (
                    <th
                      className='whiteSpacesClass'
                      style={{border: '1px solid #E2E2E2'}}
                      {...column.getHeaderProps(column?.getSortByToggleProps())}
                    >
                      <b>{column?.render('Header')}</b>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {data?.length > 0 ? (
              <tbody {...getTableBodyProps()}>
                {page?.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr
                      className='tableHover'
                      {...row?.getRowProps()}
                      style={{
                        border: '1px solid #ddd',
                        textAlign: 'center',
                      }}
                    >
                      {row?.cells?.map((cell, i) => {
                        return (
                          <td
                            {...cell?.getCellProps()}
                            style={{width: 'fit-content', maxWidth: '100px'}}
                          >
                            {cell?.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()}>
                <tr>
                  <td colSpan={totalColumns}>
                    <NoItemFound />
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        )}
      </div>
    </Fragment>
  )
}
