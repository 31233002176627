import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SupplierBillInitValues, SupplierBillModel} from '../models/SupplierBillModel'

interface SupplierBillState {
  loading: boolean
  supplierBill: SupplierBillModel
  supplierBills: [SupplierBillModel?]
  report: any
  pagination: {total: number; pages: number}
}

const initialState: SupplierBillState = {
  loading: false,
  supplierBill: SupplierBillInitValues,
  supplierBills: [],
  report: '',
  pagination: {total: 0, pages: 0},
}

export const SupplierBillSlice = createSlice({
  name: 'supplierBill',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setSupplierBills: (state, action: PayloadAction<any>) => {
      state.supplierBills = action.payload.supplierBills
    },
    setSupplierBill: (state, action: PayloadAction<any>) => {
      state.supplierBill = action.payload.supplierBill
    },
    setSupplierBillReport: (state: {report: any}, action: PayloadAction<any>) => {
      state.report = action.payload.report
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
