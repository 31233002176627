import {
  GoodsReceivedNoteInitValues,
  GoodsReceivedNoteTableModel,
} from '../models/GoodsReceivedNoteModel'
import * as GoodsReceivedNoteCRUD from './GoodsReceivedNoteCRUD'
import {GoodsReceivedNoteSlice} from './GoodsReceivedNoteSlice'

const {actions} = GoodsReceivedNoteSlice

export const fetchAll: any = (params: GoodsReceivedNoteTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return GoodsReceivedNoteCRUD.findAll(params).then((res) => {
    dispatch(actions.setGoodsReceivedNotes({goodsReceivedNotes: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const reset = () => (dispatch: any) => {
  dispatch(actions.setGoodsReceivedNote({goodsReceivedNote: GoodsReceivedNoteInitValues}))
}

export const fetchOne = (id: any) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await GoodsReceivedNoteCRUD.findOne(id)
    dispatch(actions.setGoodsReceivedNote({goodsReceivedNote: res.data.data}))
    dispatch(actions.stopLoading())
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const fetchApproved = () => (dispatch: any) => {
  return GoodsReceivedNoteCRUD.findApproved().then((res) => {
    dispatch(actions.setGoodsReceivedNotes({goodsReceivedNotes: res.data.data}))
  })
}

export const fetchReturns = () => (dispatch: any) => {
  return GoodsReceivedNoteCRUD.findReturns().then((res) => {
    dispatch(actions.setGoodsReceivedNotes({goodsReceivedNotes: res.data.data}))
  })
}

export const create = (data: any, attachments?: any) => async (dispatch: any) => {
  try {
    const res = await GoodsReceivedNoteCRUD.create(data)

    const upload = await GoodsReceivedNoteCRUD.upload(res.data.data.id, attachments)
    const goodsReceivedNote = res.data.data
    const successMsg = res.data.message
    dispatch(actions.setGoodsReceivedNote({goodsReceivedNote: goodsReceivedNote}))
    return res.data
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const approve = (id: any) => async (dispatch: any) => {
  try {
    const res = await GoodsReceivedNoteCRUD.approve(id)

    const goodsReceivedNote = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setGoodsReceivedNote({goodsReceivedNote: goodsReceivedNote}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const reject = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await GoodsReceivedNoteCRUD.reject(id, data)

    const goodsReceivedNote = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setGoodsReceivedNote({goodsReceivedNote: goodsReceivedNote}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const remove = (id: any, params: GoodsReceivedNoteTableModel) => async (dispatch: any) => {
  try {
    const res = await GoodsReceivedNoteCRUD.remove(id)
    const successMsg = res.data.message
    GoodsReceivedNoteCRUD.findAll(params).then((res) => {
      dispatch(actions.setGoodsReceivedNotes({goodsReceivedNotes: res.data.data}))
      dispatch(actions.setPagination({pagination: res.data.pagination}))
      dispatch(actions.stopLoading())
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}
