import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {StepNav1} from '../../../components/step-nav1'
import * as OutwardAction from '../../outward/redux/OutwardAction'
import {InwardInitValues, InwardModel} from '../models/InwardModel'
import * as InwardAction from '../redux/InwardAction'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
type Props = {
  className: string
}

export const InwardAdd: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(false)
  const outwards: any = useSelector<RootState>(({outwards}) => outwards.outwards, shallowEqual)

  const [inward, setInward] = useState<InwardModel>(InwardInitValues)
  const dispatch: any = useDispatch()
  let navigate = useNavigate()
  const animatedComponents = makeAnimated()

  const onChangeOutward = (e: any, values: any) => {
    const selectedOutward = outwards && outwards.find((outward: any) => outward._id === e.value)
    let uInward = JSON.parse(JSON.stringify(values))
    if (selectedOutward) {
      uInward.outward = selectedOutward._id
      uInward.providedToEmployee = selectedOutward.providedToEmployee?._id
      uInward.providedToDepartment = selectedOutward.providedToDepartment?._id
      uInward.location = selectedOutward.location?._id
      uInward.providedToEmployeeName = selectedOutward.providedToEmployee?.fullName
      uInward.providedToDepartmentName = selectedOutward.providedToDepartment?.name
      uInward.locationName = selectedOutward.location?.name
      uInward.items.shift()
      selectedOutward.items.map((r: any, i: any) => {
        uInward.items[i] = {
          _id: r._id,
          product: r.product,
          name: r.name,
          UOM: r.UOM,
          quantity: r.quantity,
          assetNumber: r.assetNumber,
          remarks: '',
        }
      })
      setInward(uInward)
    }
  }

  useEffect(() => {
    dispatch(OutwardAction.fetchApproved())
  }, [dispatch])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Inwards List', url: '/inward'},
    {name: 'Inward Form', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='border-0 pt-5 p-5'>
          <StepNav1 itemNumber={4} />
        </div>

        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Inward Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          <Formik
            enableReinitialize
            initialValues={inward}
            validationSchema={Yup.object({
              outward: Yup.string().required('Outward No is required'),
              items: Yup.array().of(
                Yup.object().shape({
                  name: Yup.string().required('Product is required'),
                  UOM: Yup.string().required('UOM is required'),
                  quantity: Yup.number()
                    .required('Quantity  is required')
                    .typeError('Quantity is invalid')
                    .nullable()
                    .positive('Quantity must be more than 0'),
                  assetNumber: Yup.string().nullable(),
                  remarks: Yup.string()
                    .trim('Remarks must have non-empty spaces')
                    .strict(true)
                    .required('Remarks is required'),
                })
              ),
            })}
            onSubmit={(values: any) => {
              setLoading(true)
              dispatch(InwardAction.create(values))
                .then((response: string) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/inward')
                })
                .catch((errorMsg: any) => {
                  toast.error(errorMsg)
                  setLoading(false)
                })
            }}
          >
            {({setFieldValue, values}) => (
              <Form>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='form-group col' hidden>
                    <label htmlFor='providedToEmployee' className='fw-bold required'>
                      Provided To Employee Name
                    </label>
                    <Field
                      name='providedToEmployee'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col' hidden>
                    <label htmlFor='providedToDepartment' className='fw-bold required'>
                      Provided To Department
                    </label>
                    <Field
                      name='providedToDepartment'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col' hidden>
                    <label htmlFor='location' className='fw-bold required'>
                      Location
                    </label>
                    <Field
                      name='location'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='outward' className='fw-bold required'>
                      Outward No.
                    </label>
                    <Select
                      options={
                        Array.isArray(outwards)
                          ? outwards?.map((outward: any, index: any) => {
                              return {
                                value: outward._id,
                                label: `${outward.number}`,
                                key: index,
                              }
                            })
                          : []
                      }
                      placeholder='Select'
                      className='multi-select-container'
                      classNamePrefix='multi-select'
                      name='outward'
                      onChange={(e: any) => {
                        if (e?.value) {
                          setFieldValue('outward', e.value)
                          onChangeOutward(e, values)
                        } else {
                          setFieldValue('outward', '')
                        }
                      }}
                      closeMenuOnSelect={true}
                      isClearable={true}
                      components={animatedComponents}
                    />
                    {/* <Field
                      as='select'
                      className='form-select form-select-md form-select '
                      data-control='select2'
                      name='outward'
                      onClick={(e: any) => onChangeOutward(e, values)}
                    >
                      <option>Select</option>
                      {outwards.map((outward: any, index: any) => (
                        <option value={outward._id} key={index}>
                          {outward.number}
                        </option>
                      ))}
                    </Field> */}
                    <div className='text-danger'>
                      <ErrorMessage name='outward' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='providedToEmployeeName' className='fw-bold'>
                      Provided To Employee Name
                    </label>
                    <Field
                      name='providedToEmployeeName'
                      placeholder='Provided By Employee'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='providedToDepartmentName' className='fw-bold'>
                      Provided To Department
                    </label>
                    <Field
                      name='providedToDepartmentName'
                      placeholder='Provided By Department'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='locationName' className='fw-bold'>
                      Location
                    </label>
                    <Field
                      name='locationName'
                      placeholder='Location'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                </div>

                <FieldArray
                  name='items'
                  render={(arrayHelpers) => (
                    <div className='mt-15'>
                      <table className='table tbl-md-responsivness'>
                        <thead>
                          <tr>
                            <th>
                              <label className='form-label required '>Product</label>
                            </th>
                            <th>
                              <label className='form-label required '>UOM</label>
                            </th>
                            <th>
                              <label className='form-label required '>Quantity</label>
                            </th>
                            <th>
                              <label className='form-label'>Asset No.</label>
                            </th>
                            <th>
                              <label className='form-label required '>Remarks</label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.items?.length > 0 &&
                            values.items?.map((materialItem: any, index: any) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td data-label='Product'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.name`}
                                        placeholder='Product'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.name`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label='UOM'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.UOM`}
                                        placeholder='UOM'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.UOM`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label='Quantity'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.quantity`}
                                        placeholder='Quantity'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.quantity`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label='Asset No.'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.assetNumber`}
                                        placeholder='Asset No.'
                                        disabled={true}
                                        className='form-control form-control-md'
                                        maxLength={255}
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.assetNumber`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label='Remarks'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.remarks`}
                                        placeholder='Remarks'
                                        className='form-control form-control-md'
                                        maxLength={255}
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.remarks`} />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                />

                <div className='d-flex justify-content-end mt-10'>
                  <Link
                    to='/inward'
                    className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Cancel'
                  >
                    <span className='indicator-label'>Cancel</span>
                  </Link>

                  <button
                    type='submit'
                    className='btn btn-lg  btn-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Submit'
                    id='liveToastBtn'
                    disabled={loading}
                  >
                    <span className='indicator-label'>{loading ? 'Submitting...' : 'Submit'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
