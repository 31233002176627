import {ErrorMessage, Field, FieldArray, Form, Formik, useField, useFormikContext} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {PurchaseOrderReturnInitValues} from '../models/PurchaseOrderReturnModel'
import * as PurchaseOrderReturnAction from '../redux/PurchaseOrderReturnAction'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {InventoryReportListingModelInitValues} from '../../inventory/models/InventoryReportModel'
import {fetchReport} from '../../inventory/redux/InventoryAction'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import * as actions from '../redux/PurchaseOrderReturnAction'
import * as GoodsReceivedNoteAction from '../../goods-received-note/redux/GoodsReceivedNoteAction'
import {KTSVG} from '../../../../_metronic/helpers'
import {formatNumber} from '../../../helper'

type Props = {
  className: string
}

export const PurchaseOrderReturnAdd: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(false)
  const dispatch: any = useDispatch()

  const goodsReceivedNotes: any = useSelector<RootState>(
    ({goodsReceivedNotes}) => goodsReceivedNotes.goodsReceivedNotes,
    shallowEqual
  )

  const goodsReceivedNote: any = useSelector<RootState>(
    ({purchaseOrderReturns}) => purchaseOrderReturns.goodsReceivedNote,
    shallowEqual
  )

  const purchaseOrderReturn: any = useSelector<RootState>(
    (state) => state.purchaseOrderReturns.purchaseOrderReturn,
    shallowEqual
  )

  const [attachments, setAttachments] = useState<any>([])

  const animatedComponents = makeAnimated()

  const inventories: any = useSelector<RootState>(
    ({inventories}) => inventories?.inventories,
    shallowEqual
  )

  useEffect(() => {
    dispatch(fetchReport(InventoryReportListingModelInitValues))
    dispatch(GoodsReceivedNoteAction.fetchReturns())
    return () => {
      dispatch(actions.resetPurchaseOrderReturn(PurchaseOrderReturnInitValues))
    }
  }, [dispatch])

  let navigate = useNavigate()

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Purchase Order Returns List', url: '/purchase-order-returns'},
    {name: 'Purchase Order Return Form', url: ''},
  ]

  const onChangeAttachments = (e: any) => {
    const documents: any = Array.from(e.target.files)

    setAttachments(documents)
    if (documents?.length > 0) {
      documents?.map((doc: any) => {
        const MB = doc.size / (1024 * 1024)
        if (MB > 2) {
          toast.error('File too large')
          setAttachments([])
          e.target.value = null
          return
        }
        if (!['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].includes(doc.type)) {
          toast.error('Kindly select valid input file')
          setAttachments([])
          e.target.value = null
          return
        }
      })
      if (documents?.length > 5) {
        toast.error('Cannot add more than 5 files')
        setAttachments([])
        e.target.value = null
        return
      }
    }
  }

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Purchase Order Return Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          <Formik
            enableReinitialize
            initialValues={purchaseOrderReturn}
            validationSchema={Yup.object({
              purchaseOrderNumber: Yup.string().required('Purchase Order No is required'),
              purchaseFor: Yup.string()
                .trim('Purchase For must have non-empty spaces')
                .strict(true)
                .required('Purchase For is required'),
              supplier: Yup.object().shape({
                name: Yup.string().required('Supplier is required'),
              }),
              items: Yup.array().of(
                Yup.object().shape({
                  product: Yup.string().required('Product is required'),
                  quantity: Yup.number()
                    .required('Quantity is required')
                    .typeError('Quantity is invalid')
                    .nullable()
                    .positive('Quantity must be more than 0')
                    .test(
                      'quantity',
                      'Quantity must be smaller then the previous quantity',
                      function (value: any, el: any) {
                        const product = el.parent._id
                        const oldItem = goodsReceivedNote?.items?.find(
                          (item: any) => item._id == product
                        )

                        return !(value > oldItem?.quantity)
                      }
                    )
                    .test(
                      'quantity',
                      'Quantity must be smaller than available in stock',
                      function (value: any, itemData: any) {
                        const data = inventories.find(
                          (inventory: any, index: any) => itemData.parent.qrCode == inventory.qrCode
                        )
                        return value <= data?.quantityInStock
                      }
                    ),
                  rate: Yup.number()
                    .required('Rate is required')
                    .typeError('Rate is invalid')
                    .nullable()
                    .positive('Rate must be more than 0'),

                  tax: Yup.number()
                    .typeError('Tax is invalid')
                    .nullable()
                    .min(0, 'Tax must be 0 or more than 0')
                    .test(
                      'tax',
                      'The tax amount should be less than its previous value',
                      function (value: any, el: any) {
                        const product = el.parent._id
                        const oldItem = goodsReceivedNote?.items?.find(
                          (item: any) => item._id == product
                        )
                        return !(value > oldItem?.tax)
                      }
                    ),

                  priceInclusiveTax: Yup.number(),

                  discount: Yup.number()
                    .typeError('Discount is invalid')
                    .nullable()
                    .min(0, 'Discount must be 0 or more than 0')
                    .test(
                      'discount',
                      'Discount must be smaller than price inclusive tax',
                      function (value: any) {
                        return value <= this.parent.priceInclusiveTax || value == null
                      }
                    )
                    .test(
                      'discount',
                      'The discount amount should be less than its previous value',
                      function (value: any, el: any) {
                        const product = el.parent._id
                        const oldItem = goodsReceivedNote?.items?.find(
                          (item: any) => item._id == product
                        )
                        return !(value > oldItem?.discount)
                      }
                    ),
                })
              ),
            })}
            onSubmit={(values: any) => {
              setLoading(true)
              dispatch(PurchaseOrderReturnAction.create(values, attachments))
                .then((response: string) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/purchase-order-returns')
                })
                .catch((errorMsg: any) => {
                  toast.error(errorMsg)
                  setLoading(false)
                })
            }}
          >
            {({values, setFieldValue}) => (
              <Form>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='form-group col'>
                    <label htmlFor='goodsReceivedNote' className='fw-bold required'>
                      Goods Received Note No.
                    </label>
                    <Select
                      options={
                        Array.isArray(goodsReceivedNotes)
                          ? goodsReceivedNotes?.map((goodsReceivedNote: any, index: any) => {
                              return {
                                value: goodsReceivedNote._id,
                                label: `${goodsReceivedNote.number} (${goodsReceivedNote?.purchaseOrder?.number})`,
                                key: index,
                              }
                            })
                          : []
                      }
                      placeholder='Select'
                      className='multi-select-container'
                      classNamePrefix='multi-select'
                      name='goodsReceivedNote'
                      onChange={(e: any) => {
                        if (e?.value) {
                          setFieldValue('goodsReceivedNote', e.value)
                          dispatch(actions.onChangeGoodsReceivedNote(e, values, goodsReceivedNotes))
                        } else {
                          setFieldValue('goodsReceivedNote', '')
                        }
                      }}
                      closeMenuOnSelect={true}
                      isClearable={true}
                      components={animatedComponents}
                    />

                    <div className='text-danger'>
                      <ErrorMessage name='goodsReceivedNote' />
                    </div>
                  </div>

                  <div className='form-group col'>
                    <label htmlFor='purchaseOrderNumber' className='fw-bold'>
                      Purchase Order No.
                    </label>
                    <Field
                      className='form-control form-control-md'
                      name='purchaseOrderNumber'
                      placeholder='Purchase For'
                      disabled={true}
                    />
                  </div>

                  <div className='form-group col'>
                    <label htmlFor='PurchaseFor' className='fw-bold'>
                      Purchase For
                    </label>
                    <Field
                      className='form-control form-control-md'
                      name='purchaseFor'
                      placeholder='Purchase For'
                      disabled={true}
                    />
                  </div>

                  <div className='form-group col'>
                    <label htmlFor='supplierName' className='fw-bold'>
                      Supplier Name
                    </label>
                    <Field
                      className='form-control form-control-md'
                      name='supplier.name'
                      placeholder='Supplier Name'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='NTN' className='fw-bold'>
                      NTN No.
                    </label>
                    <Field
                      name='supplier.NTN'
                      placeholder='NTN No.'
                      className='form-control form-control-lg'
                      type='text'
                      disabled={true}
                    />
                  </div>

                  <div className='form-group col'>
                    <label htmlFor='address' className='fw-bold'>
                      Address
                    </label>
                    <Field
                      name='supplier.city'
                      placeholder='Address'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='strnNo' className='fw-bold'>
                      STRN No.
                    </label>
                    <Field
                      name='supplier.STRN'
                      placeholder='STRN No.'
                      className='form-control form-control-lg'
                      type='text'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='supplier.phone' className='fw-bold'>
                      Phone No.
                    </label>
                    <Field
                      name='supplier.phone'
                      placeholder='Phone No.'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='supplier.bank.accountTitle' className='fw-bold'>
                      Account Title
                    </label>
                    <Field
                      name='supplier.bank.accountTitle'
                      placeholder='Account Title'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='supplier.bank.name' className='fw-bold'>
                      Bank Name
                    </label>
                    <Field
                      name='supplier.bank.name'
                      placeholder='Bank Name'
                      className='form-control form-control-lg'
                      disabled={true}
                    />
                  </div>

                  <div className='form-group col'>
                    <label htmlFor='attachments' className='fw-bold'>
                      Upload Files (Allowed Extensions: jpeg, jpg, png, pdf)
                    </label>
                    <Field
                      className='form-control form-control-md'
                      name='attachments'
                      type='file'
                      onChange={(e: any) => onChangeAttachments(e)}
                      accept='image/png, image/jpg, image/jpeg ,application/pdf'
                      multiple
                    ></Field>
                    {attachments?.map((attachment: any, i: number) => (
                      <React.Fragment key={i}>
                        {attachment.name} {'  '}
                      </React.Fragment>
                    ))}
                  </div>
                </div>

                <FieldArray
                  name='items'
                  render={() => (
                    <div className='mt-15'>
                      <table className='table tbl-xl-responsivness'>
                        <thead>
                          <tr>
                            <th>
                              <label className='form-label required'>QR Code</label>
                            </th>
                            <th>
                              <label className='form-label required'>Product</label>
                            </th>
                            <th>
                              <label className='form-label'>UOM</label>
                            </th>
                            <th>
                              <label className='form-label required'>Quantity</label>
                            </th>
                            <th>
                              <label className='form-label required'>Rate</label>
                            </th>
                            <th>
                              <label className='form-label '>Price</label>
                            </th>
                            <th>
                              <label className='form-label'>Tax</label>
                            </th>
                            <th>
                              <label className='form-label'>Price Inclusive Tax</label>
                            </th>
                            <th>
                              <label className='form-label'>Discount</label>
                            </th>
                            <th>
                              <label className='form-label'>Total</label>
                            </th>
                            <th>
                              <label className='form-label'></label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {values?.items?.length > 0 &&
                            values?.items?.map((materialItem: any, index: any) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td data-label='QR Code'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.qrCode`}
                                        placeholder='QR Code'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Product'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.name`}
                                        placeholder='Product'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                    </div>
                                  </td>

                                  <td data-label='UOM'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.UOM`}
                                        placeholder='UOM'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Quantity'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.quantity`}
                                        placeholder='Quantity'
                                        className='form-control form-control-md'
                                        onBlur={(e: any) => {
                                          const value: number = e.target.value
                                          const roundedValue = formatNumber(value)
                                          setFieldValue(`items.${index}.quantity`, roundedValue)
                                        }}
                                        onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.quantity`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label='Rate'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.rate`}
                                        placeholder='Rate'
                                        className='form-control form-control-md'
                                        onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                        disabled
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.rate`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label='Price'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.price`}
                                        placeholder='Price'
                                        className='form-control form-control-md'
                                        onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Tax'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.tax`}
                                        placeholder='Tax'
                                        className='form-control form-control-md'
                                        onBlur={(e: any) => {
                                          const value: number = e.target.value
                                          const roundedValue = formatNumber(value)
                                          setFieldValue(`items.${index}.tax`, roundedValue)
                                        }}
                                        onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.tax`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label='Price Inclusive Tax'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.priceInclusiveTax`}
                                        placeholder='Price Inclusive Tax'
                                        className='form-control form-control-md'
                                        onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Discount'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.discount`}
                                        placeholder='Discount'
                                        className='form-control form-control-md'
                                        onBlur={(e: any) => {
                                          const value: number = e.target.value
                                          const roundedValue = formatNumber(value)
                                          setFieldValue(`items.${index}.discount`, roundedValue)
                                        }}
                                        onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.discount`} />
                                      </div>
                                    </div>
                                  </td>
                                  <td data-label='Total'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.total`}
                                        placeholder='Total'
                                        className='form-control form-control-md'
                                        onKeyUp={(e: any) => dispatch(actions.onChangeItem(values))}
                                        disabled={true}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className='d-flex flex-column w-100'>
                                      <button
                                        type='button'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                        onClick={() => {
                                          dispatch(
                                            actions.removePurchaseOrderReturnItem(values, index)
                                          )
                                        }}
                                        data-bs-toggle='tooltip'
                                        data-bs-placement='top'
                                        title='Remove'
                                        disabled={values.items.length > 1 ? false : true}
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen027.svg'
                                          className='svg-icon  svg-icon-3'
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}

                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Price
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Price'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalPrice'
                                  placeholder='Total Price'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                            <td></td>
                          </tr>

                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Tax
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Tax'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalTax'
                                  placeholder='Total Tax'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Discount
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Discount'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalDiscount'
                                  placeholder='Total Discount'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Amount
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Amount'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalAmount'
                                  placeholder='Total Amount'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                />

                <div className='d-flex justify-content-end mt-10'>
                  <Link
                    to='/purchase-orders'
                    className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Cancel'
                  >
                    <span className='indicator-label'>Cancel</span>
                  </Link>

                  <button
                    type='submit'
                    className='btn btn-lg  btn-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Submit'
                    id='liveToastBtn'
                    disabled={loading}
                  >
                    <span className='indicator-label'>{loading ? 'Submitting...' : 'Submit'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
