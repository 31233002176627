import {combineReducers} from 'redux'
import {all} from 'redux-saga/effects'
import {AssetSlice} from '../../app/modules/assets/redux/AssetSlice'
import * as auth from '../../app/modules/auth'
import {BudgetSlice} from '../../app/modules/budget/redux/BudgetSlice'
import {DepartmentSlice} from '../../app/modules/departments/redux/DepartmentSlice'
import {GoodsReceivedNoteSlice} from '../../app/modules/goods-received-note/redux/GoodsReceivedNoteSlice'
import {InventorySlice} from '../../app/modules/inventory/redux/InventorySlice'
import {InwardSlice} from '../../app/modules/inward/redux/InwardSlice'
import {LocationSlice} from '../../app/modules/locations/redux/LocationSlice'
import {MaterialInspectionSlice} from '../../app/modules/material-inspection/redux/MaterialInspectionSlice'
import {MaterialIssuanceSlice} from '../../app/modules/material-issuance/redux/MaterialIssuanceSlice'
import {MaterialReturnSlice} from '../../app/modules/material-return/redux/MaterialReturnSlice'
import {MaterialRequisitionSlice} from '../../app/modules/material-requisition/redux/MaterialRequisitionSlice'
import {OutwardSlice} from '../../app/modules/outward/redux/OutwardSlice'
import {productCategorySlice} from '../../app/modules/product-categories/redux/ProductCategorySlice'
import {ProductSlice} from '../../app/modules/products/redux/ProductSlice'
import {PurchaseOrderSlice} from '../../app/modules/purchase-orders/redux/PurchaseOrderSlice'
import {supplierSlice} from '../../app/modules/suppliers/redux/SupplierSlice'
import {SupplierBillSlice} from '../../app/modules/supplier-bills/redux/SupplierBillSlice'
import {UserSlice} from '../../app/modules/users/redux/UserSlice'
import {rolesSlice} from '../../app/modules/roles/redux/RoleSlice'
import {NotificationSlice} from '../../app/modules/notification/redux/NotificationSlice'
import {PurchaseOrderReturnSlice} from '../../app/modules/purchase-order-returns/redux/PurchaseOrderReturnSlice'
import {SettingsSlice} from '../../app/modules/settings/redux/SettingSlice'
import {StatisticsSlice} from '../../app/modules/statistics/redux/StatisticsSlice'
import {StockSlice} from '../../app/modules/stock-movement-report/redux/StockSlice'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  department: DepartmentSlice.reducer,
  materialRequisition: MaterialRequisitionSlice.reducer,
  productCategories: productCategorySlice.reducer,
  products: ProductSlice.reducer,
  suppliers: supplierSlice.reducer,
  setting: SettingsSlice.reducer,
  supplierBills: SupplierBillSlice.reducer,
  inventories: InventorySlice.reducer,
  assets: AssetSlice.reducer,
  materialIssuance: MaterialIssuanceSlice.reducer,
  materialReturn: MaterialReturnSlice.reducer,
  purchaseOrders: PurchaseOrderSlice.reducer,
  purchaseOrderReturns: PurchaseOrderReturnSlice.reducer,
  materialInspections: MaterialInspectionSlice.reducer,
  location: LocationSlice.reducer,
  budget: BudgetSlice.reducer,
  goodsReceivedNotes: GoodsReceivedNoteSlice.reducer,
  outwards: OutwardSlice.reducer,
  inwards: InwardSlice.reducer,
  users: UserSlice.reducer,
  roles: rolesSlice.reducer,
  notification: NotificationSlice.reducer,
  statistics: StatisticsSlice.reducer,
  stocks: StockSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
