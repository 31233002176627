/* eslint-disable jsx-a11y/anchor-is-valid */
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams, Link} from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {deepCopy, formatDate, formatNumber} from '../../../helper'
import * as ProductAction from '../../products/redux/ProductAction'
import {
  BudgetInitValues,
  BudgetSectionInitValues,
  BudgetTableModelInitValues,
} from '../models/BudgetModel'
import * as actions from '../redux/BudgetAction'
import {DatePickerField} from '../../../../_metronic/partials/controls/forms/DatePickerField'
import {ProductListingModelInitValues} from '../../products/models/ProductModel'

type Props = {
  className: string
}

export const BudgetAdd: FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()
  let {type} = useParams<{type: any}>()
  let {status} = useParams<{status: any}>()
  const products: any = useSelector<RootState>((state) => state.products.products, shallowEqual)
  let productsOptions =
    products &&
    products.map((product: any) => {
      return {
        value: product._id,
        label: product.name,
        UOM: product.UOM,
      }
    })

  const animatedComponents = makeAnimated()
  const budget: any = useSelector<RootState>((state) => state.budget.budget, shallowEqual)

  const updatedBudget = {
    ...budget,
  }

  useEffect(() => {
    dispatch(ProductAction.findAll(ProductListingModelInitValues))
    if (id) {
      dispatch(actions.findOne(id))
    } else {
      dispatch(actions.resetBudget(BudgetInitValues))
    }
  }, [dispatch, id])

  const budgetValidationSchema = Yup.object({
    title: Yup.string()
      .required('Title is required')
      .trim('Title must have non-empty spaces')
      .strict(true),
    total: Yup.string().required('Total Amount is required'),
    startDate: Yup.date().required('Start date is required').typeError('Start date is required'),
    endDate: Yup.date()
      .min(Yup.ref('startDate'), "End date can't be before or start date")
      .test('same_dates_test', 'Start and end dates must not be equal.', function (value: any) {
        const {startDate} = this.parent
        return value.getTime() !== startDate.getTime()
      })
      .required('End date is required')
      .typeError('End date is required'),

    sections: Yup.array().of(
      Yup.object().shape({
        title: Yup.string()
          .required('Section Title is required')
          .trim('Section Title must have non-empty spaces')
          .strict(true),
        total: Yup.string().required('Section Total is required'),
        items: Yup.array().of(
          Yup.object().shape({
            product: Yup.string().required('Product is required'),
            UOM: Yup.string().required('UOM is required'),
            quantity: Yup.number()
              .required('Quantity is required')
              .typeError('Quantity is invalid')
              .nullable()
              .positive('Quantity must be more than 0'),
            rate: Yup.number()
              .required('Rate center is required')
              .typeError('Rate is invalid')
              .nullable()
              .positive('Rate must be more than 0'),
            amount: Yup.number()
              .required('Amount is required')
              .typeError('Amount is invalid')
              .nullable()
              .positive('Amount must be more than 0'),
          })
        ),
      })
    ),
  })

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Budgets List', url: '/budgets'},
    {name: 'Budget Form', url: ''},
  ]

  return (
    <div className={`card card-custom ${className}`}>
      <Breadcrumbs routes={routes} />
      <div className='card-body py-10'>
        <h3 className='card-title'>
          <span className='card-label fw-bolder fs-3 mt-15 '>Budget Form </span>
        </h3>
        <div className='mt-10'></div>
        <Formik
          validationSchema={budgetValidationSchema}
          enableReinitialize
          initialValues={updatedBudget}
          onSubmit={(values: any) => {
            setLoading(true)
            let form: any = values

            if (type == 'actual') {
              form.parentId = id ? id : null
              form.type = type == 'actual' ? 'ACTUAL' : 'ESTIMATE'
            }
            if (type == 'copy') {
              form.status = type == 'actual' ? status : 'NEW'
            }

            if (form.startDate == form.endDate) {
              toast.error('Start date and end date must be different')
              setLoading(false)
              return
            }

            dispatch(actions.create(form, BudgetTableModelInitValues))
              .then((response: any) => {
                toast.success(response)
                setLoading(false)
                navigate('/budgets')
              })
              .catch((error: any) => {
                toast.error(error)
                setLoading(false)
              })
          }}
        >
          {({values, setFieldValue, errors}) => (
            <Form className='form' noValidate id='kt_modal_create_app_form'>
              <div className='row'>
                <div className='form-group col-12'>
                  <label htmlFor='name' className='fw-bold required'>
                    Title
                  </label>
                  <Field
                    name='title'
                    className='form-control form-control-lg mt-1'
                    placeholder='Title'
                    maxLength={255}
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='title' />
                  </div>
                </div>

                <div className='mt-5'></div>
                <div className='form-group col-md-6 mb-3'>
                  <label htmlFor='startDate' className='fw-bold required'>
                    Start Date
                  </label>

                  <DatePickerField
                    name='startDate'
                    dateFormat='dd-MM-y'
                    className='form-control form-control-lg mt-1'
                    placeholderText='DD/MM/YYYY'
                    value={values?.startDate && formatDate(values?.startDate)}
                    onKeyDown={(e: any) => {
                      e.preventDefault()
                    }}
                  />

                  <div className='text-danger'>
                    <ErrorMessage name='startDate' />
                  </div>
                </div>
                <div className='form-group col-md-6 mb-3'>
                  <label htmlFor='endDate' className='fw-bold required'>
                    End Date
                  </label>

                  <DatePickerField
                    name='endDate'
                    dateFormat='dd-MM-y'
                    className='form-control form-control-lg mt-1'
                    placeholderText='DD/MM/YYYY'
                    value={values?.endDate && formatDate(values?.endDate)}
                    onKeyDown={(e: any) => {
                      e.preventDefault()
                    }}
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='endDate' />
                  </div>
                </div>
              </div>
              <FieldArray
                name='items'
                render={(arrayHelpers) => (
                  <div className='mt-15'>
                    <div className='clearfix'></div>
                    <button
                      type='button'
                      className='btn btn-primary btn-bg-light btn-sm me-3 float-end '
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Add New Section'
                      onClick={() => {
                        dispatch(actions.addBudgetSection(values, BudgetSectionInitValues))
                      }}
                    >
                      Add New Section
                    </button>
                    <table className='table tbl-lg-responsivness'>
                      <thead></thead>
                      <tbody>
                        {values?.sections?.length > 0 &&
                          values?.sections?.map((section: any, sidx: any) => (
                            <React.Fragment key={sidx}>
                              <tr>
                                <td colSpan={2} className='ps-0 font-bold' data-label='Title'>
                                  <h5>
                                    Section {sidx + 1}
                                    {sidx !== 0 && (
                                      <button
                                        type='button'
                                        className='btn btn-icon btn-bg-light ms-3'
                                        onClick={() => {
                                          dispatch(actions.removeBudgetSection(values, sidx))
                                        }}
                                        data-bs-toggle='tooltip'
                                        data-bs-placement='top'
                                        title='Remove'
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen027.svg'
                                          className='svg-icon  svg-icon-3'
                                        />
                                      </button>
                                    )}
                                  </h5>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4} className='ps-0' data-label='Section Title'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name={`sections.${sidx}.title`}
                                      placeholder='Section Title'
                                      className='form-control form-control-md'
                                      maxLength={255}
                                    />
                                    <div className='text-danger'>
                                      <ErrorMessage name={`sections.${sidx}.title`} />
                                    </div>
                                  </div>
                                </td>
                                <td className='ps-0' data-label='Section Total'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      disabled={true}
                                      name={`sections.${sidx}.total`}
                                      placeholder='Section Total'
                                      className='form-control form-control-md'
                                    />
                                    <div className='text-danger'>
                                      <ErrorMessage name={`sections.${sidx}.total`} />
                                    </div>
                                  </div>
                                </td>
                                <td colSpan={1}></td>
                              </tr>
                              {section?.items?.map((item: any, ridx: any) => (
                                <tr>
                                  <td className='ps-0' data-label='Product'>
                                    <Field
                                      name={`sections.${sidx}.items.${ridx}.name`}
                                      type='hidden'
                                    />

                                    <div className='multisel w-100 d-block'>
                                      <Select
                                        options={productsOptions}
                                        placeholder='Select'
                                        name={`sections.${sidx}.items.${ridx}.product`}
                                        className='multi-select-container'
                                        classNamePrefix='multi-select'
                                        value={productsOptions.filter(function (option: any) {
                                          return (
                                            option.value ==
                                            values.sections[sidx].items[ridx]?.product
                                          )
                                        })}
                                        onChange={(e: any) => {
                                          if (e?.value) {
                                            const found = values?.sections[sidx]?.items?.find(
                                              (item: any, index: any) => {
                                                return item.product == e?.value
                                              }
                                            )
                                            if (found) {
                                              toast.error(
                                                'You cannot add multiple products with the same name!'
                                              )
                                              return
                                            } else {
                                              setFieldValue(
                                                `sections.${sidx}.items.${ridx}.product`,
                                                e.value
                                              )
                                              setFieldValue(
                                                `sections.${sidx}.items.${ridx}.name`,
                                                e.label
                                              )
                                              setFieldValue(
                                                `sections.${sidx}.items.${ridx}.UOM`,
                                                e.UOM
                                              )
                                            }
                                          } else {
                                            setFieldValue(
                                              `sections.${sidx}.items.${ridx}.product`,
                                              ''
                                            )
                                            setFieldValue(`sections.${sidx}.items.${ridx}.name`, '')
                                            setFieldValue(`sections.${sidx}.items.${ridx}.UOM`, '')
                                          }
                                        }}
                                        isClearable={true}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage
                                          name={`sections.${sidx}.items.${ridx}.product`}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td className='ps-0' data-label='UOM'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`sections.${sidx}.items.${ridx}.UOM`}
                                        placeholder='UOM'
                                        disabled={true}
                                        className='form-control form-control-md'
                                        onKeyUp={(e: any) =>
                                          dispatch(actions.onChangeBudgetItem(values))
                                        }
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`sections.${sidx}.items.${ridx}.UOM`} />
                                      </div>
                                    </div>
                                  </td>
                                  <td className='ps-0' data-label='Quantity'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`sections.${sidx}.items.${ridx}.quantity`}
                                        placeholder='Quantity'
                                        className='form-control form-control-md'
                                        onBlur={(e: any) => {
                                          const value: number = e.target.value
                                          const roundedValue = formatNumber(value)
                                          setFieldValue(
                                            `sections.${sidx}.items.${ridx}.quantity`,
                                            roundedValue
                                          )
                                        }}
                                        onKeyUp={(e: any) =>
                                          dispatch(actions.onChangeBudgetItem(values))
                                        }
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage
                                          name={`sections.${sidx}.items.${ridx}.quantity`}
                                        />
                                      </div>
                                    </div>
                                  </td>

                                  <td className='ps-0' data-label='Rate'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`sections.${sidx}.items.${ridx}.rate`}
                                        placeholder='Rate'
                                        className='form-control form-control-md'
                                        onBlur={(e: any) => {
                                          const value: number = e.target.value
                                          const roundedValue = formatNumber(value)
                                          setFieldValue(
                                            `sections.${sidx}.items.${ridx}.rate`,
                                            roundedValue
                                          )
                                        }}
                                        onKeyUp={(e: any) =>
                                          dispatch(actions.onChangeBudgetItem(values))
                                        }
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage
                                          name={`sections.${sidx}.items.${ridx}.rate`}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td className='ps-0' data-label='amount'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`sections.${sidx}.items.${ridx}.amount`}
                                        placeholder='Amount'
                                        disabled={true}
                                        className='form-control form-control-md'
                                        onKeyUp={(e: any) =>
                                          dispatch(actions.onChangeBudgetItem(values))
                                        }
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage
                                          name={`sections.${sidx}.items.${ridx}.amount`}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='d-flex flex-row w-100'>
                                      <button
                                        type='button'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                        data-bs-toggle='tooltip'
                                        data-bs-placement='top'
                                        title='Add New Item'
                                        onClick={() => {
                                          const item = {
                                            product: '',
                                            name: '',
                                            UOM: '',
                                            quantity: '',
                                            rate: '',
                                            amount: '',
                                          }
                                          dispatch(actions.addBudgetSectionItem(values, sidx, item))
                                        }}
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/arrows/arr013.svg'
                                          className='svg-icon  svg-icon-3'
                                        />
                                      </button>

                                      <button
                                        type='button'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                        onClick={() => {
                                          dispatch(
                                            actions.removeBudgetSectionItem(values, sidx, ridx)
                                          )
                                        }}
                                        disabled={
                                          values.sections[sidx].items.length > 1 ? false : true
                                        }
                                        data-bs-toggle='tooltip'
                                        data-bs-placement='top'
                                        title='Remove Item'
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen027.svg'
                                          className='svg-icon  svg-icon-3'
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}

                        <tr>
                          <td colSpan={4} className='ps-0 dl' data-label=''>
                            <div className='d-flex flex-column w-100'>
                              <label className='col-form-label required d-flex justify-content-end align-items-end'>
                                Total Amount
                              </label>
                            </div>
                          </td>
                          <td className='ps-0' data-label='Total Amount'>
                            <div className='d-flex flex-column w-100'>
                              <Field
                                name='total'
                                className='form-control form-control-md'
                                placeholder='Total Amount'
                                disabled={true}
                              />
                              <div className='text-danger'>
                                <ErrorMessage name='total' />
                              </div>
                            </div>
                          </td>
                          <td colSpan={1}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              />
              <div className='d-flex justify-content-end mt-10'>
                <Link
                  to='/budgets'
                  className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Cancel'
                >
                  <span className='indicator-label'>Cancel</span>
                </Link>

                <div>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary me-3'
                    title='Submit'
                    disabled={loading}
                  >
                    <span className='indicator-label'>{loading ? 'Submitting...' : 'Submit'}</span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
