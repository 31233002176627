import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {ProductCategories} from './components/ProductCategories'

const ProductCategoryPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<ProductCategories className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}

export {ProductCategoryPage}
