import {Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {
  PurchaseOrderReturnInitValues,
  PurchaseOrderReturnModel,
} from '../models/PurchaseOrderReturnModel'
import * as PurchaseOrderReturnAction from '../redux/PurchaseOrderReturnAction'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDateTime, formatNumber} from '../../../helper'
import {PurchaseOrderReturnApproveConfirmModal} from './PurchaseOrderReturnApproveConfirmModal '
import {PurchaseOrderReturnRejectConfirmModal} from './PurchaseOrderReturnRejectConfirmModal'
import * as actions from '../redux/PurchaseOrderReturnAction'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const PurchaseOrderReturnApproval: React.FC<Props> = ({className}) => {
  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [purchaseOrderReturnToApprove, setPurchaseOrderReturnToApprove] =
    useState<PurchaseOrderReturnModel>(PurchaseOrderReturnInitValues)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [purchaseOrderReturnToReject, setPurchaseOrderReturnToReject] =
    useState<PurchaseOrderReturnModel>(PurchaseOrderReturnInitValues)

  const purchaseOrderReturn: any = useSelector<RootState>(
    ({purchaseOrderReturns}) => purchaseOrderReturns.purchaseOrderReturn,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({purchaseOrderReturns}) => purchaseOrderReturns.loading,
    shallowEqual
  )

  const purchaseOrderReturnUpdatedValues = {
    ...purchaseOrderReturn,
    createdAt: formatDateTime(purchaseOrderReturn.createdAt),
  }

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(PurchaseOrderReturnAction.fetchOne(id))
    return () => {
      dispatch(actions.resetPurchaseOrderReturn(PurchaseOrderReturnInitValues))
    }
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Purchase Order Returns List', url: '/purchase-order-returns'},
    {name: 'Purchase Order Return Approval Form', url: ''},
  ]

  return (
    <>
      <PurchaseOrderReturnRejectConfirmModal
        show={showRejectDialog}
        handleClose={() => {
          setShowRejectDialog(false)
        }}
        purchaseOrderReturn={purchaseOrderReturnToReject}
      />
      <PurchaseOrderReturnApproveConfirmModal
        show={showApproveDialog}
        handleClose={() => {
          setShowApproveDialog(false)
        }}
        purchaseOrderReturn={purchaseOrderReturnToApprove}
      />
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              Purchase Order Return Approval Form
            </span>
          </h1>
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            purchaseOrderReturn && purchaseOrderReturn?._id !== '' ? (
              <Formik
                initialValues={purchaseOrderReturnUpdatedValues || PurchaseOrderReturnInitValues}
                enableReinitialize
                onSubmit={() => {
                  setShowApproveDialog(true)
                  setPurchaseOrderReturnToApprove(purchaseOrderReturnUpdatedValues)
                }}
              >
                {({values}) => (
                  <Form>
                    <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                      <div className='form-group col'>
                        <label htmlFor='number' className='fw-bold  '>
                          Purchase Order Return No.
                        </label>
                        <Field
                          name='number'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='createdAt' className='fw-bold'>
                          Created Date
                        </label>
                        <Field
                          name='createdAt'
                          className='form-control form-control-lg '
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='number' className='fw-bold  '>
                          Purchase Order No.
                        </label>
                        <Field
                          name='purchaseOrder.number'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='purchaseFor' className='fw-bold  '>
                          Purchase For
                        </label>
                        <Field
                          name='purchaseFor'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='status' className='fw-bold  '>
                          Status
                        </label>
                        <Field
                          name='status'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='supplier.name' className='fw-bold  '>
                          Supplier
                        </label>
                        <Field
                          name='supplier.name'
                          className='form-control form-control-lg'
                          disabled={true}
                        ></Field>
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='supplier.city' className='fw-bold'>
                          Address
                        </label>
                        <Field
                          name='supplier.city'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='supplier.NTN' className='fw-bold'>
                          NTN No.
                        </label>
                        <Field
                          name='supplier.NTN'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='supplier.phone' className='fw-bold'>
                          Phone No.
                        </label>
                        <Field
                          name='supplier.phone'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='supplier.STRN' className='fw-bold'>
                          STRN No.
                        </label>
                        <Field
                          name='supplier.STRN'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='supplier.bank.accountTitle' className='fw-bold'>
                          Account Title
                        </label>
                        <Field
                          name='supplier.bank.accountTitle'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='supplier.bank.name' className='fw-bold'>
                          Bank Name
                        </label>
                        <Field
                          name='supplier.bank.name'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                    </div>

                    <FieldArray
                      name='items'
                      render={(arrayHelpers) => (
                        <div className='mt-15'>
                          <table className='table tbl-md-responsivness'>
                            <thead>
                              <tr>
                                <th>
                                  <label className='form-label'>Product</label>
                                </th>
                                <th>
                                  <label className='form-label'>UOM</label>
                                </th>
                                <th>
                                  <label className='form-label'>Quantity</label>
                                </th>
                                <th>
                                  <label className='form-label'>Rate</label>
                                </th>
                                <th>
                                  <label className='form-label'>Price</label>
                                </th>
                                <th>
                                  <label className='form-label'>Tax</label>
                                </th>
                                <th>
                                  <label className='form-label'>Price Inclusive Tax</label>
                                </th>
                                <th>
                                  <label className='form-label'>Discount/Return</label>
                                </th>
                                <th>
                                  <label className='form-label'>Total</label>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.items?.length > 0 &&
                                values.items?.map((materialItem: any, index: any) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td data-label='Product'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field name={`items.${index}.name`} type='hidden' />
                                          <div className='col'>
                                            <Field
                                              name={`items.${index}.name`}
                                              placeholder='Product'
                                              disabled={true}
                                              className='form-control form-control-md'
                                            />
                                          </div>
                                        </div>
                                      </td>

                                      <td data-label='UOM'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.UOM`}
                                            placeholder='UOM'
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='quantity'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.quantity`}
                                            placeholder='Quantity'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Rate'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.rate`}
                                            placeholder='Rate'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Price'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.price`}
                                            placeholder='Price'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Tax'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.tax`}
                                            placeholder='Tax'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Price Inclusive Tax'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.priceInclusiveTax`}
                                            placeholder='Price Inclusive Tax'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Discount/Return'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.discount`}
                                            placeholder='Discount/Return'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>
                                      <td data-label='Total'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.total`}
                                            placeholder='Total'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}

                              <tr>
                                <td colSpan={8} className='ps-0 dl' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Price
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Price'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalPrice'
                                      placeholder='Total Price'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={8} className='ps-0 dl' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Tax
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Tax'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalTax'
                                      placeholder='Total Tax'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={8} className='ps-0 dl' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Discount
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Discount'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalDiscount'
                                      placeholder='Total Discount'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={8} className='ps-0' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Amount
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Amount'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalAmount'
                                      placeholder='Total Amount'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    />

                    <div className='d-flex justify-content-end mt-10'>
                      <button
                        type='button'
                        className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Reject'
                        onClick={() => {
                          setShowRejectDialog(true)
                          setPurchaseOrderReturnToReject(purchaseOrderReturnUpdatedValues)
                        }}
                      >
                        <span className='indicator-label'>Reject</span>
                      </button>

                      <button
                        type='submit'
                        className='btn btn-lg  btn-primary me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Approve'
                        id='liveToastBtn'
                      >
                        <span className='indicator-label'>Approve</span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
