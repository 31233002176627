import React from 'react'
import {PurchaseOrderReturnAdd} from './components/PurchaseOrderReturnAdd'
import {Route, Routes} from 'react-router-dom'
import {PurchaseOrdersReturn} from './components/PurchaseOrderReturns'
import {PurchaseOrderReturnDetail} from './components/PurchaseOrderReturnDetail'
import {PurchaseOrderReturnApproval} from './components/PurchaseOrderReturnApproval'

export const PurchaseOrderReturnPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<PurchaseOrdersReturn className='mb-5 mb-xl-8' />} />
        <Route path='/add' element={<PurchaseOrderReturnAdd className='mb-5 mb-xl-8' />} />
        <Route path='/:id' element={<PurchaseOrderReturnDetail className='mb-5 mb-xl-8' />} />
        <Route
          path='/:id/approve'
          element={<PurchaseOrderReturnApproval className='mb-5 mb-xl-8' />}
        />
      </Routes>
    </>
  )
}
