interface Stock {
  quantity: number | string
  rate: number | string
  value: number | string
}

export interface StockMovementReportModel {
  _id: string
  createdAt: string
  updatedAt: string
  stockDate: string
  product: string
  name: string
  UOM: string
  openingStock: Stock
  purchases: Stock
  purchaseReturn: Stock
  inventoryAdjustment: Stock
  productIssuance: Stock
  productReturn: Stock
  closingStock: Stock
}

export const StockMovementReportInitValues: StockMovementReportModel = {
  _id: '',
  createdAt: '',
  updatedAt: '',
  stockDate: '',
  product: '',
  name: '',
  UOM: '',
  openingStock: {quantity: 0, rate: 0, value: 0},
  purchases: {quantity: 0, rate: 0, value: 0},
  purchaseReturn: {quantity: 0, rate: 0, value: 0},
  inventoryAdjustment: {quantity: 0, rate: 0, value: 0},
  productIssuance: {quantity: 0, rate: 0, value: 0},
  productReturn: {quantity: 0, rate: 0, value: 0},
  closingStock: {quantity: 0, rate: 0, value: 0},
}

export interface StockMovementReportSearchModel {
  name: string
  stockDate: any
}
export const StockMovementReportSearchModelInitValues: StockMovementReportSearchModel = {
  name: '',
  stockDate: [],
}

export interface StockMovementReportTableModel {
  page: number
  limit?: number
  sortBy: string
  sortOrder: string
  search: StockMovementReportSearchModel
}

export const StockMovementReportTableModelInitValues: StockMovementReportTableModel = {
  page: 1,
  limit: 50,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: StockMovementReportSearchModelInitValues,
}
