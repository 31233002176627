import moment from 'moment'

export function deepCopy(obj: any) {
  return JSON.parse(JSON.stringify(obj))
}

export function formatDate(d: string) {
  return moment(d).format(process.env.REACT_APP_DATE_FORMAT)
}

export function formatDateTime(dt: string) {
  return moment(dt).format(process.env.REACT_APP_DATETIME_FORMAT)
}

export function formatMoney(money: number, isPkr = false) {
  const currency = Intl.NumberFormat('en-PK', {maximumFractionDigits: 2, minimumFractionDigits: 2})
  return isPkr ? 'PKR ' + currency.format(money) : currency.format(money)
}

export function formatStockMoney(money: number) {
  const currency = Intl.NumberFormat('en-PK', {maximumFractionDigits: 2, minimumFractionDigits: 0})
  return currency.format(money)
}

export const agGridCustomComparator = (valueA: any, valueB: any) => {
  return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase())
}

export const formatNumber = (value: any, decimals = 2) => {
  if (value == null) {
    return parseFloat('0').toFixed(decimals)
  } else if (value == 0) {
    return parseFloat('0').toFixed(decimals)
  } else return parseFloat(value).toFixed(decimals)
}

export const getYears = (startYear: any) => {
  var currentYear = new Date().getFullYear(),
    years = []
  startYear = startYear || 1980

  var years = []
  while (startYear <= currentYear) {
    years.push(startYear++)
  }

  return years
}

export const getMaxValueArrays = (arr: any) => {
  const maxValues = arr.map((arr: any) => Math.max(...arr))
  const maxValue = Math.max(...maxValues)
  return maxValue
}

export const getMaxValue = (arr: any) => {
  // const filteredArray = arr.map((value: any) => value !== '')
  const maxValue = Math.max(...arr)
  return maxValue
}

export const formatFileName = (name: any) => {
  const now = moment()
  const datePart = now.format('YYYYMMDD')
  const timePart = now.format('HHmm')
  const filename = `${name}-${datePart}-${timePart}`

  return filename
}
