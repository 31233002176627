import axios from 'axios'
import {
  MaterialRequisitionTableModel,
  MaterialRequisitionModel,
} from '../models/MaterialRequisitionModel'

const API_URL = process.env.REACT_APP_API_URL
const MATERIAL_REQUESITION_URL = `${API_URL}/material-requisition`

export function findAll(params: MaterialRequisitionTableModel) {
  return axios.get(`${MATERIAL_REQUESITION_URL}`, {params})
}
export function findOne(id: any) {
  return axios.get(`${MATERIAL_REQUESITION_URL}/${id}`)
}

export function report(year: number) {
  const REPORT_URL = `${MATERIAL_REQUESITION_URL}/report/${year}`
  return axios.get(REPORT_URL)
}

export function departmentsReport(year: number, month: number) {
  const REPORT_URL = `${MATERIAL_REQUESITION_URL}/report/departments/${year}/${month}`
  return axios.get(REPORT_URL)
}

export function itemsReport(year: any, month: any) {
  const REPORT_URL = `${MATERIAL_REQUESITION_URL}/report/items/${year}/${month}`
  return axios.get(REPORT_URL)
}

export function findApproved() {
  return axios.get(`${MATERIAL_REQUESITION_URL}/approved`)
}

export function create(data: MaterialRequisitionModel) {
  return axios.post(MATERIAL_REQUESITION_URL, data)
}

export function approve(id: any) {
  return axios.patch(`${MATERIAL_REQUESITION_URL}/${id}/approve`)
}

export function reject(id: any, data: any) {
  return axios.patch(`${MATERIAL_REQUESITION_URL}/${id}/reject`, data)
}

export function complete(id: any) {
  return axios.patch(`${MATERIAL_REQUESITION_URL}/${id}/complete`)
}

export function cancel(id: any) {
  return axios.patch(`${MATERIAL_REQUESITION_URL}/${id}/cancel`)
}

export function remove(id: any) {
  return axios.delete(`${MATERIAL_REQUESITION_URL}/${id}`)
}
