import React, {FC, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {PrintButton} from '../../../components/PrintButton'
import {formatDate, formatDateTime, formatMoney, formatNumber} from '../../../helper'
import {BudgetInitValues} from '../models/BudgetModel'
import * as actions from '../redux/BudgetAction'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'
import BudgetDetailPdf from './BudgetDetailPdf'

type Props = {
  className: string
}

export const BudgetDetail: FC<Props> = ({className}) => {
  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()
  const [isPkr, setPkr] = useState(true)

  const budget: any = useSelector<RootState>((state) => state.budget.budget, shallowEqual)
  const loading: any = useSelector<RootState>((state) => state.budget.loading, shallowEqual)

  useEffect(() => {
    if (id) {
      dispatch(actions.findOne(id))
    } else {
      dispatch(actions.resetBudget(BudgetInitValues))
    }
  }, [dispatch, id])

  const budgetUpdatedValues = {
    ...budget,
    approvedAt: formatDateTime(budget?.approvedAt),
    rejectedAt: formatDateTime(budget?.rejectedAt),
  }

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Budgets List', url: '/budgets'},
    {name: 'Budget Detail', url: ''},
  ]

  return (
    <div className={`card card-custom print ${className}`}>
      <a href='/' className='print-view-show'>
        <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
      </a>
      <Breadcrumbs routes={routes} />
      <div className='card-header border-0 pt-5'>
        <h1 className='card-title'>
          <span className='card-label fw-bolder fs-3 mb-1'>Budget Detail</span>
        </h1>

        <div className='d-flex'>
          <div className='m-1 download-btn'>
            <BudgetDetailPdf budget={budget} />
          </div>
          <div className='m-1'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Print PDF'
            >
              <PrintButton />
            </button>
          </div>
        </div>
      </div>
      <div className='card-body py-10'>
        {!loading ? (
          budget && budget?._id !== '' ? (
            <>
              <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                <tbody>
                  <tr>
                    <td>
                      <b>Title </b>
                    </td>
                    <td>{budget?.title}</td>
                    <td>
                      <b>Budget No</b>
                    </td>
                    <td>{budget?.number}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Created Date</b>
                    </td>
                    <td>{formatDateTime(budget?.createdAt)}</td>
                    <td>
                      <b>Status</b>
                    </td>
                    <td>{budget.status}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Start Date </b>
                    </td>
                    <td>{formatDate(budget?.startDate)}</td>
                    <td>
                      <b>End Date</b>
                    </td>
                    <td>{formatDate(budget?.endDate)}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Grand Total</b>
                    </td>
                    <td>
                      <b>{formatMoney(budget?.total, isPkr)}</b>
                    </td>
                    <td>
                      <b>Budget Type</b>
                    </td>
                    <td>{budget.type}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Request by Employee Name</b>
                    </td>
                    <td>{budget?.createdBy?.fullName}</td>
                    <td></td> <td></td>
                  </tr>

                  {budget?.approvedAt && budget?.approvedBy && (
                    <tr>
                      <td>
                        <b>Approved At</b>
                      </td>
                      <td>{formatDateTime(budget?.approvedAt)}</td>
                      <td>
                        <b>Approved By</b>
                      </td>
                      <td>{budget?.approvedBy.fullName}</td>
                    </tr>
                  )}
                  {budget?.rejectedAt && budget?.rejectedBy && (
                    <tr>
                      <td>
                        <b>Rejected At</b>
                      </td>
                      <td>{formatDateTime(budget.rejectedAt)}</td>
                      <td>
                        <b>Rejected By</b>
                      </td>
                      <td>{budget?.rejectedBy.fullName}</td>
                    </tr>
                  )}
                  {budget?.reason && (
                    <tr>
                      <td>
                        <b>Reason to Reject</b>
                      </td>
                      <td colSpan={3}>{budget.reason}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {budget?.sections?.map((section: any, index: any) => (
                <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                  <thead>
                    <tr>
                      <th colSpan={3}>
                        <h3>Section Title</h3>
                      </th>
                      <th>
                        <h3>{section.title}</h3>
                      </th>
                      <th>
                        <h3>Section Total</h3>
                      </th>
                      <th>
                        <h3>{formatMoney(section.total, isPkr)}</h3>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={2}>
                        <b>Product</b>
                      </th>
                      <th>
                        <b>UOM</b>
                      </th>
                      <th>
                        <b>Quantity</b>
                      </th>
                      <th>
                        <b>Rate</b>
                      </th>
                      <th>
                        <b>Amount</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {section?.items?.map((sectionItem: any, index: any) => (
                      <tr key={index}>
                        <td data-label='Product' colSpan={2}>
                          {index + 1}. {sectionItem.name}
                        </td>
                        <td data-label='UOM'>{sectionItem.UOM}</td>
                        <td data-label='Quantity'>{formatNumber(sectionItem.quantity)}</td>
                        <td data-label='Rate'>{formatMoney(sectionItem.rate)}</td>
                        <td data-label='Amount'>{formatMoney(sectionItem.amount)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}
              <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                <tbody>
                  <tr>
                    <td className='text-end' colSpan={4}>
                      <h2>Grand Total</h2>
                    </td>
                    <td>
                      <h2>{formatMoney(budget?.total, isPkr)}</h2>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <NoItemFound />
          )
        ) : (
          <Loader />
        )}
      </div>
    </div>
  )
}
