import React from 'react'
import {useField, useFormikContext} from 'formik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faCalendarDays} from '@fortawesome/free-solid-svg-icons'

const getFieldCSSClasses = (touched, errors) => {
  const classes = ['form-control']
  if (touched && errors) {
    classes.push('is-invalid')
  }

  if (touched && !errors) {
    classes.push('is-valid')
  }

  return classes.join(' ')
}

export function DatePickerField({...props}) {
  const {setFieldValue, errors, touched} = useFormikContext()
  const [field] = useField(props)

  return (
    <>
      {props.label && <label className='d-block'>{props.label}</label>}

      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        wrapperClassName='w-100'
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val)
        }}
        // calenderIcon={<FontAwesomeIcon icon={faCalendarDays} />}
      />

      {/* {errors[field.name] && touched[field.name] ? (
        <div className='date-field' style={{color: '#f1416c'}}>
          {errors[field.name].toString()}
        </div>
      ) : (
        <div className='feedback'>
          Please enter <b>{props.label}</b>
        </div>
      )} */}
    </>
  )
}
