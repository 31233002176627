import axios from 'axios'
import {MaterialIssuanceModel, MaterialIssuanceTableModel} from '../models/MaterialIssuanceModel'
const API_URL = process.env.REACT_APP_API_URL
const MATERIAL_ISSUANCE_URL = `${API_URL}/material-issuance`

export function create(data: MaterialIssuanceModel) {
  return axios.post(MATERIAL_ISSUANCE_URL, data)
}

export function findAll(params: MaterialIssuanceTableModel) {
  return axios.get(MATERIAL_ISSUANCE_URL, {params})
}

export function findOne(id: any) {
  return axios.get(`${MATERIAL_ISSUANCE_URL}/${id}`)
}

export function findApproved() {
  return axios.get(`${MATERIAL_ISSUANCE_URL}/approved`)
}

export function findReturn() {
  return axios.get(`${MATERIAL_ISSUANCE_URL}/return`)
}

export function approve(id: any) {
  return axios.patch(`${MATERIAL_ISSUANCE_URL}/${id}/approve`)
}

export function reject(id: any, data: any) {
  return axios.patch(`${MATERIAL_ISSUANCE_URL}/${id}/reject`, data)
}

export function remove(id: any) {
  return axios.delete(`${MATERIAL_ISSUANCE_URL}/${id}`)
}
