import axios from 'axios'
import {ProductTableModel} from '../models/ProductModel'

const API_URL = process.env.REACT_APP_API_URL
const PRODUCTS_URL = `${API_URL}/products`
const PRODUCTS__CATEGORIES_URL = `${API_URL}/product-categories`

export function findAll(params: ProductTableModel) {
  return axios.get(PRODUCTS_URL, {params})
}

export function findOne(_id: string) {
  return axios.get(`${PRODUCTS_URL}/${_id}`)
}

export function create(name: string, UOM: string, category: string, code: string) {
  return axios.post(PRODUCTS_URL, {name, UOM, category, code})
}
export function update(_id: string, name: string, UOM: string, category: string, code: string) {
  return axios.patch(`${PRODUCTS_URL}/${_id}`, {name, UOM, category, code})
}
export function remove(_id: string) {
  return axios.delete(`${PRODUCTS_URL}/${_id}`)
}
