import {Field, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Bar} from 'react-chartjs-2'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {NoItemFound} from '../../../components/NoItemFound'
import {PrintButton} from '../../../components/PrintButton'
import {formatMoney, getYears, getMaxValue, getMaxValueArrays} from '../../../helper/index'
import * as actions from '../redux/PurchaseOrderAction'
import {Loader} from '../../../components/Loader'

type Props = {
  className: string
}

export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const PurchaseOrderReport: React.FC<Props> = ({className}) => {
  const dispatch: any = useDispatch()

  const [showChart, setShowChart] = useState('table')
  const [view, setView] = useState('table')

  const reports: any = useSelector<RootState>((state) => state.purchaseOrders.report, shallowEqual)
  const totalYearlyData =
    reports.length &&
    reports?.reduce(
      (acc: any, curr: any) => ({
        totalPO: acc.totalPO + curr.totalPO,
        totalAmount: acc.totalAmount + curr.totalAmount,
        totalNew: acc.totalNew + curr.totalNew,
        totalApproved: acc.totalApproved + curr.totalApproved,
        totalRejected: acc.totalRejected + curr.totalRejected,
        totalCompleted: acc.totalCompleted + curr.totalCompleted,
        totalMI: acc.totalMI + curr.totalMI,
        totalGRN: acc.totalGRN + curr.totalGRN,
      }),
      {
        totalPO: 0,
        totalAmount: 0,
        totalNew: 0,
        totalApproved: 0,
        totalRejected: 0,
        totalCompleted: 0,
        totalMI: 0,
        totalGRN: 0,
      }
    )

  const date = new Date().getFullYear()
  const yearList = getYears(2022)
  const [initValues] = useState<any>({year: date})

  const [currentYear, setCurrentYear] = useState(initValues.year)
  const handleUpdate = (e: any) => {
    setCurrentYear(e.target.value)
  }

  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const totalAmount =
    reports.length &&
    labels.map(
      (label: string, index: number) =>
        reports?.find((report: any) => report?.month === index + 1)?.totalAmount || ''
    )

  const totalPO =
    reports.length &&
    labels.map(
      (label: string, index: number) =>
        reports?.find((report: any) => report?.month === index + 1)?.totalPO || ''
    )

  const totalMI =
    reports.length &&
    labels.map(
      (label: string, index: number) =>
        reports?.find((report: any) => report?.month === index + 1)?.totalMI || ''
    )

  const totalGRN =
    reports.length &&
    labels.map(
      (label: string, index: number) =>
        reports?.find((report: any) => report?.month === index + 1)?.totalGRN || ''
    )

  const totalNew =
    reports.length &&
    labels.map(
      (label: string, index: number) =>
        reports?.find((report: any) => report?.month === index + 1)?.totalNew || ''
    )

  const totalApproved =
    reports.length &&
    labels.map(
      (label: string, index: number) =>
        reports?.find((report: any) => report?.month === index + 1)?.totalApproved || ''
    )

  const totalRejected =
    reports.length &&
    labels.map(
      (label: string, index: number) =>
        reports?.find((report: any) => report?.month === index + 1)?.totalRejected || ''
    )

  const totalCompleted =
    reports.length &&
    labels.map(
      (label: string, index: number) =>
        reports?.find((report: any) => report?.month === index + 1)?.totalCompleted || ''
    )

  const maxValuesArray = [
    totalPO,
    totalMI,
    totalGRN,
    totalNew,
    totalApproved,
    totalRejected,
    totalCompleted,
  ]

  const overallMax = totalPO && getMaxValueArrays(maxValuesArray)
  const maxAmount = totalAmount && getMaxValue(totalAmount)

  useEffect(() => {
    dispatch(actions.fetchReport(currentYear))
  }, [dispatch, currentYear])

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        title: {
          display: true,
          text: 'Purchase Orders Report ' + currentYear,
          font: {
            family: 'Arial',
            size: 16,
            weight: 'bold',
          },
          color: '#333',
          padding: 10,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (count: any) {
            return count
          },
        },
        min: 0,
        max: Math.ceil(overallMax / 10) * 10,
      },
    },
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'PO',
        data: totalPO,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 1.5)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
      {
        label: 'MI',
        data: totalMI,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(230, 90, 9, 0.8)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
      {
        label: 'GRN',
        data: totalGRN,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(175, 95, 240, 0.97)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
      {
        label: 'New',
        data: totalNew,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(194, 163, 37, 1)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
      {
        label: 'Approved',
        data: totalApproved,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(23, 40, 145, 0.8)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
      {
        label: 'Rejected',
        data: totalRejected,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(187, 39, 68, 0.97)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
      {
        label: 'Completed',
        data: totalCompleted,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(7, 135, 67, 0.8)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
    ],
  }

  const optionsAmount: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        title: {
          display: true,
          text: 'Purchase Orders Amount Report ' + currentYear,
          font: {
            family: 'Arial',
            size: 16,
            weight: 'bold',
          },
          color: '#333',
          padding: 10,
        },
      },
    },

    scales: {
      y: {
        ticks: {
          callback: function (amount: any) {
            return 'PKR-' + formatMoney(amount)
          },
        },
        min: 0,
        max: Math.ceil(maxAmount / 10) * 10,
      },
    },
  }

  const dataAmount = {
    labels: labels,
    datasets: [
      {
        label: 'Amount',
        data: totalAmount,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 1.5)',
        barPercentage: 0.9,
        categoryPercentage: 0.6,
      },
    ],
  }

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Purchase Orders List', url: '/purchase-orders'},
    {name: 'Purchase Orders Report', url: '/purchase-orders/report'},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Purchase Orders Report</span>
          </h1>
          <PrintButton />
        </div>
        <Formik
          validationSchema={Yup.object().shape({
            year: Yup.string(),
          })}
          initialValues={initValues}
          onSubmit={(values: any) => {}}
        >
          {({values}) => (
            <Form className='form' noValidate id='kt_modal_create_app_form'>
              <div className='row ms-5'>
                <div className='col-md-4 print-view-none fv-row'>
                  <div className='d-flex report-row'>
                    <label className='fs-6 fw-bold m-2'>Year</label>
                    <div className='col-6 '>
                      <Field
                        as='select'
                        name='year'
                        className='form-select'
                        style={{minHeight: '41px', maxHeight: '41px'}}
                        value={currentYear}
                        onChange={handleUpdate}
                      >
                        <option value=''>Select</option>
                        {yearList?.map((year, i) => (
                          <option key={i} value={year}>
                            {year}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className='report-btn'>
                      <div className='btn-group ms-5' role='group' aria-label='Basic example'>
                        <button
                          type='button'
                          className={`btn btn-sm btn-secondary ${view == 'table' ? 'active' : ''}`}
                          onClick={() => {
                            setShowChart('table')
                            setView('table')
                          }}
                        >
                          Table
                        </button>
                        <button
                          type='button'
                          className={`btn btn-sm btn-secondary ${view == 'chart' ? 'active' : ''}`}
                          onClick={() => {
                            setShowChart('chart')
                            setView('chart')
                          }}
                        >
                          Chart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <div className='card-body py-10'>
          {reports ? (
            showChart == 'chart' && reports?.length !== 0 ? (
              <>
                <div className='bar-chart-container'>
                  <Bar options={options} data={data} />
                </div>
                <br />
                <div className='bar-chart-container'>
                  <Bar options={optionsAmount} data={dataAmount} />
                </div>
              </>
            ) : reports?.length !== 0 ? (
              <table className='table tbl-border tbl-md-responsivness table-striped'>
                <thead>
                  <tr>
                    <th>
                      <b> Month </b>
                    </th>
                    <th>
                      <b> PO </b>
                    </th>
                    <th>
                      <b> Inspections </b>
                    </th>
                    <th>
                      <b> GRN </b>
                    </th>
                    <th>
                      <b> Amount </b>
                    </th>
                    <th>
                      <b> New </b>
                    </th>
                    <th>
                      <b> Approved </b>
                    </th>
                    <th>
                      <b> Rejected </b>
                    </th>
                    <th>
                      <b> Completed </b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reports?.map(
                    (report: any, index: any) =>
                      report?.totalAmount > 0 && (
                        <tr key={index}>
                          <td data-label='Month'>
                            {labels[report?.month - 1]} - {report?.year}
                          </td>
                          <td data-label='Total PO'>{report?.totalPO}</td>
                          <td data-label='Total Inspections'>{report?.totalMI}</td>
                          <td data-label='Total GRN'>{report?.totalGRN}</td>
                          <td data-label='Total Amount'>{formatMoney(report?.totalAmount)}</td>
                          <td data-label='Total New'>{report?.totalNew}</td>
                          <td data-label='Total Approved'>{report?.totalApproved}</td>
                          <td data-label='Total Rejected'>{report?.totalRejected}</td>
                          <td data-label='Total Completed'>{report?.totalCompleted}</td>
                        </tr>
                      )
                  )}
                  <tr>
                    <td>
                      <b>Overall Total</b>
                    </td>
                    <td data-label='Total PO'>
                      <b>{totalYearlyData?.totalPO}</b>
                    </td>
                    <td data-label='Total MI'>
                      <b>{totalYearlyData?.totalMI}</b>
                    </td>
                    <td data-label='Total GRN'>
                      <b>{totalYearlyData?.totalGRN}</b>
                    </td>
                    <td data-label='Total Amount'>
                      <b>{formatMoney(totalYearlyData?.totalAmount)}</b>
                    </td>
                    <td data-label='Total New'>
                      <b>{totalYearlyData?.totalNew}</b>
                    </td>
                    <td data-label='Total Approved'>
                      <b>{totalYearlyData?.totalApproved}</b>
                    </td>
                    <td data-label='Total Rejected'>
                      <b>{totalYearlyData?.totalRejected}</b>
                    </td>
                    <td data-label='Total Completed'>
                      <b>{totalYearlyData?.totalCompleted}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
