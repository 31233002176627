import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {settings} from '../../account/models/NotificationModel'

interface NotificationState {
  notification: any
}

const initialState: NotificationState = {
  notification: {},
}

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state: {notification: any}, action: PayloadAction<any>) => {
      state.notification = action.payload.notification
    },
  },
})
