import {Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {NoItemFound} from '../../../components/NoItemFound'
import {PrintButton} from '../../../components/PrintButton'
import {formatMoney, formatNumber} from '../../../helper/index'
import * as actions from '../redux/PurchaseOrderAction'
import {DatePicker} from 'rsuite'
import {Loader} from '../../../components/Loader'
import 'rsuite/dist/rsuite.css'

type Props = {
  className: string
}

export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const PurchaseOrderItemsReport: React.FC<Props> = ({className}) => {
  const dispatch: any = useDispatch()

  const reports: any = useSelector<RootState>((state) => state.purchaseOrders.report, shallowEqual)

  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1

  const [initValues] = useState<any>({year: year, month: month})
  const [currentYear, setCurrentYear] = useState(initValues.year)
  const [currentMonth, setCurrentMonth] = useState(initValues.month)

  const onDatePickerChange = (value: any) => {
    if (value) {
      setCurrentYear(value.getFullYear())
      setCurrentMonth(value.getMonth() + 1)
    }
  }
  useEffect(() => {
    dispatch(actions.fetchItemsReport(currentYear, currentMonth))
  }, [dispatch, currentYear, currentMonth])

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Purchase Orders List', url: '/purchase-orders'},
    {name: 'Purchase Order Items Report', url: '/purchase-orders/report/items'},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Purchase Order Items Report</span>
          </h1>
          <PrintButton />
        </div>
        <Formik
          validationSchema={Yup.object().shape({
            year: Yup.string(),
          })}
          initialValues={initValues}
          onSubmit={(values: any) => {}}
        >
          {({values}) => (
            <Form className='form' noValidate id='kt_modal_create_app_form'>
              <div className='row ms-5'>
                <div className='col-md-4 print-view-none fv-row'>
                  <div className='d-flex '>
                    <label className='fs-6 fw-bold m-2'> Month</label>
                    <div className='col-6 '>
                      <DatePicker
                        placeholder='Month & Year'
                        format='MMM yyyy'
                        ranges={[]}
                        style={{width: 300}}
                        shouldDisableDate={(date: any) => {
                          return date.getFullYear() < 2022 || date.getFullYear() > year
                        }}
                        onChange={onDatePickerChange}
                        defaultValue={date}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <div className='card-body py-10'>
          {reports ? (
            reports?.length !== 0 ? (
              <table className='table tbl-border tbl-md-responsivness table-striped'>
                <thead>
                  <tr>
                    <th>
                      <b> Product </b>
                    </th>
                    <th>
                      <b> UOM </b>
                    </th>
                    <th>
                      <b> PO </b>
                    </th>
                    <th>
                      <b> Pending Qty </b>
                    </th>
                    <th>
                      <b> Approved Qty </b>
                    </th>
                    <th>
                      <b> Inspected Qty </b>
                    </th>
                    <th>
                      <b> Amount </b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reports?.map((report: any, index: any) => (
                    <tr key={index}>
                      <td data-label='Product'>{report?.productName}</td>
                      <td data-label='UOM'>{report?.UOM}</td>
                      <td data-label='PO'>{report?.totalPO}</td>
                      <td data-label='Pending Quantity'>{formatNumber(report?.pendingQuantity)}</td>
                      <td data-label='Approved Quantity'>
                        {formatNumber(report?.approvedQuantity)}
                      </td>
                      <td data-label='Inspected Quantity'>
                        {formatNumber(report?.inspectedQuantity)}
                      </td>
                      <td data-label='Amount'>{formatMoney(report?.totalAmount)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
