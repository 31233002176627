import axios from 'axios'
import {AssetModel, AssetTableModel} from '../models/AssetModel'

const API_URL = process.env.REACT_APP_API_URL
const ASSET_URL = `${API_URL}/assets`

export function fetchAll(params: AssetTableModel) {
  return axios.get(`${ASSET_URL}`, {params})
}

export function fetchOne(id: any) {
  const SINGLE_ASSET_URL = `${ASSET_URL}/${id}`
  return axios.get(SINGLE_ASSET_URL)
}

export function create(data: AssetModel) {
  return axios.post(ASSET_URL, data)
}

export function update(id: any, data: any) {
  const EDIT_ASSET_URL = `${ASSET_URL}/${id}`
  return axios.patch(EDIT_ASSET_URL, data)
}

export function remove(id: any) {
  const REMOVE_ASSET_URL = `${ASSET_URL}/${id}`
  return axios.delete(REMOVE_ASSET_URL)
}
