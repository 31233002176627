import {UserModel, UserInitValues} from './../models/UserModel'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {settings} from '../../account/models/NotificationModel'

interface UserState {
  loading: boolean
  user: UserModel
  users: [UserModel?]
  roles: any
  notification: any
  pagination: {total: number; pages: number}
}

const initialState: UserState = {
  loading: false,
  user: UserInitValues,
  users: [],
  roles: [],
  notification: settings,
  pagination: {total: 0, pages: 0},
}

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setUsers: (state: {users: any}, action: PayloadAction<any>) => {
      state.users = action.payload.users
    },
    setUser: (state: {user: any}, action: PayloadAction<any>) => {
      state.user = action.payload.user
    },
    setRoles: (state: {roles: any}, action: PayloadAction<any>) => {
      state.roles = action.payload.roles
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
