import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {AppRoutes} from './routing/Routes'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './assets/sass/custom.scss'
import './assets/sass/print.scss'
import {AbilityContext} from './rbac/Can'
import ability from './rbac/Ability'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <AbilityContext.Provider value={ability}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter basename={basename}>
          <I18nProvider>
            <LayoutProvider>
              <ToastContainer />
              <AuthInit>
                <AppRoutes />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </BrowserRouter>
      </Suspense>
    </AbilityContext.Provider>
  )
}

export {App}
