import {Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Bar, Doughnut} from 'react-chartjs-2'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {NoItemFound} from '../../../components/NoItemFound'
import {PrintButton} from '../../../components/PrintButton'
import {formatMoney, getMaxValue} from '../../../helper/index'
import * as actions from '../redux/PurchaseOrderAction'
import {DatePicker} from 'rsuite'
import {Loader} from '../../../components/Loader'
import moment from 'moment'
import 'rsuite/dist/rsuite.css'

ChartJS.register(ArcElement, Tooltip, Legend)

type Props = {
  className: string
}

export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const PurchaseOrderSuppliersReport: React.FC<Props> = ({className}) => {
  const dispatch: any = useDispatch()

  const [showChart, setShowChart] = useState('table')
  const [view, setView] = useState('table')

  const reports: any = useSelector<RootState>((state) => state.purchaseOrders.report, shallowEqual)

  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1

  const [initValues] = useState<any>({year: year, month: month})
  const [currentYear, setCurrentYear] = useState(initValues.year)
  const [currentMonth, setCurrentMonth] = useState(initValues.month)

  const onDatePickerChange = (value: any) => {
    if (value) {
      setCurrentYear(value.getFullYear())
      setCurrentMonth(value.getMonth() + 1)
    }
  }

  useEffect(() => {
    dispatch(actions.fetchSuppliersReport(currentYear, currentMonth))
  }, [dispatch, currentYear, currentMonth])

  const labels = reports.length && reports?.map((report: any) => report?.supplierName)

  const totalAmount =
    reports.length &&
    labels.map(
      (label: string) =>
        reports?.find((report: any) => report?.supplierName === label)?.totalAmount || ''
    )

  const maxAmount = totalAmount && getMaxValue(totalAmount)

  const totalPO =
    reports.length &&
    labels.map(
      (label: string) =>
        reports?.find((report: any) => report?.supplierName === label)?.totalPO || ''
    )

  const totalMI =
    reports.length &&
    labels.map(
      (label: string) =>
        reports?.find((report: any) => report?.supplierName === label)?.totalMI || ''
    )

  const totalGRN =
    reports.length &&
    labels.map(
      (label: string) =>
        reports?.find((report: any) => report?.supplierName === label)?.totalGRN || ''
    )

  // bar chart
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Amount',
        data: totalAmount,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 1.5)',
        barPercentage: 0.3,
        categoryPercentage: 0.6,
      },
    ],
  }

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        title: {
          display: true,
          text:
            'Purchase Order Amounts ' +
            moment(`${currentYear}-${currentMonth}-01`).format('MMM YYYY'),
          font: {
            family: 'Arial',
            size: 16,
            weight: 'bold',
          },
          color: '#333',
          padding: 10,
        },
      },
    },

    scales: {
      y: {
        ticks: {
          callback: function (amount: any) {
            return 'PKR-' + formatMoney(amount)
          },
        },
        fontSize: 10,
        min: 0,
        max: Math.ceil(maxAmount / 10) * 10,
      },
    },
  }

  // doughnut chart
  const dataDoughnut = {
    labels: labels,
    datasets: [
      {
        label: '# PO',
        data: totalPO,
        backgroundColor: [
          'rgba(65, 147, 90, 1)',
          'rgba(249, 200, 0, 1)',
          'rgba(69, 102, 159, 1)',
          'rgba(107, 113, 123, 0.87)',
          'rgba(95, 63, 50, 0.96)',
          'rgba(120, 199, 85, 0.96)',
          'rgba(184, 76, 57, 0.99)',
          'rgba(130, 83, 230, 1)',
          'rgba(145, 51, 101, 1)',
        ],
        borderColor: 'rgba(255, 255, 255, 1)',
        borderWidth: 1,
      },
      {
        label: '# GRN',
        data: totalGRN,
        backgroundColor: [
          'rgba(65, 147, 90, 1)',
          'rgba(249, 200, 0, 1)',
          'rgba(69, 102, 159, 1)',
          'rgba(107, 113, 123, 0.87)',
          'rgba(95, 63, 50, 0.96)',
          'rgba(120, 199, 85, 0.96)',
          'rgba(184, 76, 57, 0.99)',
          'rgba(130, 83, 230, 1)',
          'rgba(145, 51, 101, 1)',
        ],
        borderColor: 'rgba(255, 255, 255, 1)',
        borderWidth: 1,
      },
    ],
  }

  const optionsDoughnut: any = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        title: {
          display: true,
          text:
            'Purchase Order Records ' +
            moment(`${currentYear}-${currentMonth}-01`).format('MMM YYYY'),
          font: {
            family: 'Arial',
            size: 16,
            weight: 'bold',
          },
          color: '#333',
          padding: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const datasetLabel = data.labels[context.dataIndex]
            return `${datasetLabel} ${context.dataset.label}: ${context.parsed}`
          },
        },
      },
    },
  }

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Purchase Orders List', url: '/purchase-orders'},
    {name: 'Purchase Order Suppliers Report', url: '/purchase-orders/report/suppliers'},
  ]

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Purchase Order Suppliers Report</span>
          </h1>
          <PrintButton />
        </div>
        <Formik
          validationSchema={Yup.object().shape({
            year: Yup.string(),
          })}
          initialValues={initValues}
          onSubmit={(values: any) => {}}
        >
          {({values}) => (
            <Form className='form' noValidate id='kt_modal_create_app_form'>
              <div className='row ms-5'>
                <div className='col-md-4 print-view-none fv-row'>
                  <div className='d-flex report-row'>
                    <label htmlFor='Market PR' className='fs-6 fw-bold m-2'>
                      Month
                    </label>
                    <div className='col-6 '>
                      <DatePicker
                        placeholder='Month & Year'
                        format='MMM yyyy'
                        ranges={[]}
                        style={{width: 300}}
                        shouldDisableDate={(date: any) => {
                          return date.getFullYear() < 2022 || date.getFullYear() > year
                        }}
                        onChange={onDatePickerChange}
                        defaultValue={date}
                      />
                    </div>
                    <div className='report-btn'>
                      <div className='btn-group ms-5' role='group' aria-label='Basic example'>
                        <button
                          type='button'
                          className={`btn btn-sm btn-secondary ${view == 'table' ? 'active' : ''}`}
                          onClick={() => {
                            setShowChart('table')
                            setView('table')
                          }}
                        >
                          Table
                        </button>
                        <button
                          type='button'
                          className={`btn btn-sm btn-secondary ${view == 'chart' ? 'active' : ''}`}
                          onClick={() => {
                            setShowChart('chart')
                            setView('chart')
                          }}
                        >
                          Chart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <div className='card-body py-10'>
          {reports ? (
            showChart == 'chart' && reports?.length !== 0 ? (
              <>
                <div className='doughnut-chart-container'>
                  <Doughnut data={dataDoughnut} options={optionsDoughnut} />
                </div>
                <br />
                <div className='bar-chart-container'>
                  <Bar options={options} data={data} />
                </div>
              </>
            ) : reports?.length !== 0 ? (
              <table className='table tbl-border tbl-md-responsivness table-striped'>
                <thead>
                  <tr>
                    <th>
                      <b> Supplier </b>
                    </th>
                    <th>
                      <b> PO </b>
                    </th>
                    <th>
                      <b> Inspections </b>
                    </th>
                    <th>
                      <b> GRN </b>
                    </th>
                    <th>
                      <b> Amount </b>
                    </th>
                    <th>
                      <b> New </b>
                    </th>
                    <th>
                      <b> Approved </b>
                    </th>
                    <th>
                      <b> Rejected </b>
                    </th>
                    <th>
                      <b> Completed </b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reports?.map((report: any, index: any) => (
                    <tr key={index}>
                      <td data-label='Supplier'>
                        <Link to={`/suppliers/${report?.supplier}`}>{report?.supplierName}</Link>
                      </td>
                      <td data-label='Total PO'>{report?.totalPO}</td>
                      <td data-label='Total Inspections'>{report?.totalMI}</td>
                      <td data-label='Total GRN'>{report?.totalGRN}</td>
                      <td data-label='Total Amount'>{formatMoney(report?.totalAmount)}</td>
                      <td data-label='Total New'>{report?.totalNew}</td>
                      <td data-label='Total Approved'>{report?.totalApproved}</td>
                      <td data-label='Total Rejected'>{report?.totalRejected}</td>
                      <td data-label='Total Completed'>{report?.totalCompleted}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
