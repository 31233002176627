/* eslint-disable jsx-a11y/anchor-is-valid */
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_metronic/helpers'
import {LocationModel} from '../models/LocationModel'
import {update} from '../redux/LocationAction'
import {LocationTableModelInitValues} from '../models/LocationModel'

interface Props {
  show: boolean
  handleClose: () => void
  location: LocationModel
}

export const LocationEdit: FC<Props> = ({show, handleClose, location}) => {
  let navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const dispatch: any = useDispatch()
  const createLocationSchema = Yup.object().shape({
    name: Yup.string()
      .trim('Name must have non-empty spaces')
      .strict(true)
      .required('Name is required')
      .label('Name'),
  })

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Edit Location</h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body flex-row py-lg-10 px-lg-10'>
          <Formik
            validationSchema={createLocationSchema}
            enableReinitialize
            initialValues={location}
            onSubmit={(values: any) => {
              setLoading(true)
              dispatch(update(location?._id, values.name, LocationTableModelInitValues))
                .then((response: any) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/locations')
                  handleClose()
                })
                .catch((error: any) => {
                  toast.error(error)
                  setLoading(false)
                })
            }}
          >
            {({values}) => (
              <Form className='form' noValidate id='kt_modal_create_app_form'>
                <div className='d-flex flex-stack'>
                  <div className='form-group col-12 mt-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Name</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg'
                      name='name'
                      placeholder='name'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='name' />
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-stack pt-5'>
                  <div className='me-2'></div>

                  <div>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary me-3'
                      disabled={loading}
                    >
                      <span className='indicator-label'>
                        {loading ? 'Submitting...' : 'Submit'}
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
