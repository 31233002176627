import {InventoryTableModel} from '../models/InventoryModel'
import {InventoryReportTableModel} from '../models/InventoryReportModel'
import * as InventoryCrud from './InventoryCRUD'
import {InventorySlice} from './InventorySlice'

const {actions} = InventorySlice

export const fetchAll: any = (params: InventoryTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return InventoryCrud.findAll(params).then((res) => {
    dispatch(actions.setInventories({inventories: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchReport: any = (params: InventoryReportTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return InventoryCrud.fetchReport(params).then((res) => {
    dispatch(actions.setInventories({inventories: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchOne = (id: any) => async (dispatch: any) => {
  try {
    const res = await InventoryCrud.fetchOne(id)
    dispatch(actions.setInventory({inventory: res.data.data}))
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const create = (data: any) => async (dispatch: any) => {
  try {
    const res = await InventoryCrud.create(data)
    const inventory = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setInventory({inventory: inventory}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg = errors
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong!'
    }
    throw errorMsg
  }
}
