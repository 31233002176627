import * as SettingCRUD from './SettingCRUD'
import {SettingsSlice} from './SettingSlice'

const {actions} = SettingsSlice

export const get = (params: any) => (dispatch: any) => {
  return SettingCRUD.get(params).then((response) => {
    dispatch(actions.setSetting({name: params?.name, setting: response?.data?.data}))
  })
}
