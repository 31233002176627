import {ProductModel, ProductInitValues} from './../models/ProductModel'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface ProductState {
  loading: boolean
  product: [ProductModel]
  products: [ProductModel?]
  pagination: {total: number; pages: number}
}

const initialState: ProductState = {
  loading: false,
  product: [ProductInitValues],
  products: [],
  pagination: {total: 0, pages: 0},
}

export const ProductSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setProducts: (state: {products: any}, action: PayloadAction<any>) => {
      state.products = action.payload.products
    },
    setProduct: (state: {product: any}, action: PayloadAction<any>) => {
      state.product = action.payload.product
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
