import axios from 'axios'
import {RoleTableModel, Subject} from '../models/RoleModel'

const API_URL = process.env.REACT_APP_API_URL
const PERMISSION_URL = `${API_URL}/permissions`
const ROLE_URL = `${API_URL}/roles`

export function findAll(params: RoleTableModel) {
  return axios.get(`${ROLE_URL}`, {params})
}

export function findOne({id}: {id: string}) {
  return axios.get(`${ROLE_URL}/${id}`)
}

export function create({
  name,
  subject,
  roleType,
}: {
  name: string
  subject: Subject
  roleType: string
}) {
  return axios.post(ROLE_URL, {
    name,
    permissions: subject,
    roleType: roleType,
  })
}

export function update({
  name,
  roleType,
  subject,
  id,
}: {
  name: string
  roleType: string
  subject: Subject
  id: string
}) {
  return axios.patch(`${ROLE_URL}/${id}`, {
    name,
    permissions: subject,
    roleType: roleType,
  })
}

export function remove(id: string) {
  return axios.delete(`${ROLE_URL}/${id}`)
}

export function fetchPermissions() {
  return axios.get(`${PERMISSION_URL}`)
}
