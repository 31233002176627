import {
  MaterialRequisitionModel,
  MaterialRequisitionInitValues,
} from './../models/MaterialRequisitionModel'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface MaterialRequisitionState {
  loading: boolean
  materialRequisition: MaterialRequisitionModel
  materialRequisitions: [MaterialRequisitionModel?]
  report: any
  pagination: {total: number; pages: number}
}

const initialState: MaterialRequisitionState = {
  loading: false,
  materialRequisition: MaterialRequisitionInitValues,
  materialRequisitions: [],
  report: '',
  pagination: {total: 0, pages: 0},
}

export const MaterialRequisitionSlice = createSlice({
  name: 'materialrequisition',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setRequisitions: (state: {materialRequisitions: any}, action: PayloadAction<any>) => {
      state.materialRequisitions = action.payload.materialRequisitions
    },
    setRequisition: (state: {materialRequisition: any}, action: PayloadAction<any>) => {
      state.materialRequisition = action.payload.materialRequisition
    },
    setMaterialRequisitionReport: (state: {report: any}, action: PayloadAction<any>) => {
      state.report = action.payload.report
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
