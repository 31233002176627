import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {PrintButton} from '../../../components/PrintButton'
import {formatDateTime} from '../../../helper'
import * as SupplierAction from '../redux/SupplierAction'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const SupplierDetail: React.FC<Props> = ({className}) => {
  const supplier: any = useSelector<RootState>(({suppliers}) => suppliers.supplier, shallowEqual)
  const loading: any = useSelector<RootState>(({suppliers}) => suppliers.loading, shallowEqual)

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(SupplierAction.fetchOne(id))
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Suppliers List', url: '/suppliers'},
    {name: 'Suppliers Detail', url: ''},
  ]
  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Suppliers Detail</span>
          </h1>
          <PrintButton />
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            supplier && supplier?._id !== '' ? (
              <>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Name</b>
                      </td>
                      <td>{supplier?.name}</td>
                      <td>
                        <b>Created Date</b>
                      </td>
                      <td>{formatDateTime(supplier?.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Address</b>
                      </td>
                      <td>{supplier?.address}</td>
                      <td>
                        <b>City</b>
                      </td>
                      <td>{supplier?.city}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Phone No.</b>
                      </td>
                      <td>{supplier?.phone}</td>
                      <td>
                        <b>NTN</b>
                      </td>
                      <td>{supplier?.NTN}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>STRN</b>
                      </td>
                      <td>{supplier?.STRN}</td>
                      <td colSpan={2}></td>
                    </tr>
                    {supplier?.approvedAt && supplier?.approvedBy && (
                      <tr>
                        <td>
                          <b>Approved At</b>
                        </td>
                        <td>{formatDateTime(supplier?.approvedAt)}</td>
                        <td>
                          <b>Approved By</b>
                        </td>
                        <td>{supplier?.approvedBy?.fullName}</td>
                      </tr>
                    )}
                    {supplier?.rejectedAt && supplier?.rejectedBy && (
                      <tr>
                        <td>
                          <b>Rejected At</b>
                        </td>
                        <td>{formatDateTime(supplier?.rejectedAt)}</td>
                        <td>
                          <b>Rejected By</b>
                        </td>
                        <td>{supplier?.rejectedBy?.fullName}</td>
                      </tr>
                    )}
                    {supplier?.rejectionReason && (
                      <tr>
                        <td>
                          <b>Reason to Reject</b>
                        </td>
                        <td colSpan={3}>{supplier?.rejectionReason}</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <h1 className='card-title'>
                  <span className='card-label fw-bolder fs-5 mb-1'>Bank Details</span>
                </h1>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Bank Name</b>
                      </td>
                      <td>{supplier?.bank.name}</td>
                      <td>
                        <b>Account Title</b>
                      </td>
                      <td>{supplier?.bank.accountTitle}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>IBAN</b>
                      </td>
                      <td>{supplier?.bank.IBAN}</td>
                      <td colSpan={2}></td>
                    </tr>
                  </tbody>
                </table>

                <h1 className='card-title'>
                  <span className='card-label fw-bolder fs-5 mb-1'>Evaluation Details</span>
                </h1>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Production Capacity</b>
                      </td>
                      <td>{supplier?.evaluation.productionCapactiy}</td>
                      <td>
                        <b>FBR Registration Status</b>
                      </td>
                      <td>{supplier?.evaluation.FbrRegistrationStatus}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Order Capacity</b>
                      </td>
                      <td>{supplier?.evaluation.orderCapacity}</td>
                      <td>
                        <b>Market PR.</b>
                      </td>
                      <td>{supplier?.evaluation.marketPR}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Customer Relation Management</b>
                      </td>
                      <td>{supplier?.evaluation.customerRelationManagement}</td>
                      <td>
                        <b>Payment Terms</b>
                      </td>
                      <td>{supplier?.evaluation.paymentsTerms}</td>
                    </tr>
                  </tbody>
                </table>

                <h1 className='card-title'>
                  <span className='card-label fw-bolder fs-5 mb-1'>Product Details</span>
                </h1>
                <table className='table tbl-border tbl-md-responsivness table-striped'>
                  <thead>
                    <tr>
                      <th style={{width: 40}}>
                        <b>#</b>
                      </th>
                      <th>
                        <b>Name</b>
                      </th>
                      <th>
                        <b>Description</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {supplier?.products.map((materialItem: any, index: any) => (
                      <tr key={index}>
                        <td data-label='#'>{index + 1}</td>
                        <td data-label='Name'>{materialItem.name}</td>
                        <td data-label='UOM'>{materialItem.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
