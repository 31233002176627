import {StockMovementReportTableModel} from '../models/StockMovementReportModel'
import * as StockCRUD from './StockCRUD'
import {StockSlice} from './StockSlice'

const {actions} = StockSlice

export const fetchAll: any = (params: StockMovementReportTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return StockCRUD.findAll(params).then((res) => {
    dispatch(actions.setStocks({stocks: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.setDateRange({dateRange: res.data.dateRange}))
    dispatch(actions.stopLoading())
  })
}
