export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.paramsSerializer = (params: any) => {
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const paramValue = params[key]
        // Check if the parameter value is an object (nested structure)
        if (typeof paramValue === 'object' && paramValue !== null) {
          // Convert the object to a JSON string
          params[key] = JSON.stringify(paramValue)
        }
      }
    }
    return new URLSearchParams(params).toString()
  }
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    function (response: any) {
      return response
    },
    function (error: any) {
      if (error?.response?.data?.statusCode === 403) {
        return (window.location.href = '/error/403')
      }
      if (error?.response?.data?.statusCode === 404) {
        return (window.location.href = '/error/404')
      }

      return Promise.reject(error)
    }
  )
}
