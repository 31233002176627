import axios from 'axios'
import {
  MaterialInspectionModel,
  MaterialInspectionTableModel,
} from '../models/MaterialInspectionModel'

const API_URL = process.env.REACT_APP_API_URL
const MATERIAL_INSPECTION_URL = `${API_URL}/material-inspection`

export function findAll(params: MaterialInspectionTableModel) {
  return axios.get(`${MATERIAL_INSPECTION_URL}`, {params})
}

export function findOne(id: any) {
  return axios.get(`${MATERIAL_INSPECTION_URL}/${id}`)
}

export function findApproved() {
  return axios.get(`${MATERIAL_INSPECTION_URL}/approved`)
}

export function create(data: MaterialInspectionModel) {
  return axios.post(MATERIAL_INSPECTION_URL, data)
}

export function approve(id: any) {
  return axios.patch(`${MATERIAL_INSPECTION_URL}/${id}/approve`)
}

export function reject(id: any, data: any) {
  return axios.patch(`${MATERIAL_INSPECTION_URL}/${id}/reject`, data)
}

export function remove(id: any) {
  return axios.delete(`${MATERIAL_INSPECTION_URL}/${id}`)
}
