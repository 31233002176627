import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {RootState} from '../../../../setup'
import {Breadcrumbs, Route} from '../../../components/Breadcrumbs'
import {PrintButton} from '../../../components/PrintButton'
import {formatDateTime, formatNumber, formatMoney, formatDate} from '../../../helper'
import {PurchaseOrderReturnInitValues} from '../models/PurchaseOrderReturnModel'
import * as PurchaseOrderReturnAction from '../redux/PurchaseOrderReturnAction'
import * as actions from '../redux/PurchaseOrderReturnAction'
import QRCode from 'qrcode.react'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const PurchaseOrderReturnDetail: React.FC<Props> = ({className}) => {
  const purchaseOrderReturn: any = useSelector<RootState>(
    ({purchaseOrderReturns}) => purchaseOrderReturns.purchaseOrderReturn,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({purchaseOrderReturns}) => purchaseOrderReturns.loading,
    shallowEqual
  )

  const dispatch: any = useDispatch()
  let navigate = useNavigate()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(PurchaseOrderReturnAction.fetchOne(id))

    return () => {
      dispatch(actions.resetPurchaseOrderReturn(PurchaseOrderReturnInitValues))
    }
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Purchase Order Returns List', url: '/purchase-order-returns'},
    {name: 'Purchase Order Return Detail', url: ''},
  ]
  const imageFunction = (attachment: any) => {
    return process.env.REACT_APP_API_URL + attachment?.path
  }
  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Purchase Order Return Detail</span>
          </h1>
          <PrintButton />
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            purchaseOrderReturn && purchaseOrderReturn?._id !== '' ? (
              <>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Purchase Order Return No.</b>
                      </td>
                      <td>{purchaseOrderReturn?.number}</td>
                      <td>
                        <b>Created Date</b>
                      </td>
                      <td>{formatDateTime(purchaseOrderReturn?.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Purchase Order No.</b>
                      </td>
                      <td>{purchaseOrderReturn?.purchaseOrder?.number}</td>
                      <td>
                        <b>Good Receive Note No.</b>
                      </td>
                      <td>{purchaseOrderReturn?.goodsReceivedNote?.number}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Supplier</b>
                      </td>
                      <td>{purchaseOrderReturn?.supplier?.name}</td>
                      <td>
                        <b>Address</b>
                      </td>
                      <td>{purchaseOrderReturn?.supplier?.city}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>NTN No.</b>
                      </td>
                      <td>{purchaseOrderReturn?.supplier?.NTN}</td>
                      <td>
                        <b>Phone No.</b>
                      </td>
                      <td>{purchaseOrderReturn?.supplier?.phone}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>STRN No.</b>
                      </td>
                      <td>{purchaseOrderReturn?.supplier?.STRN}</td>
                      <td>
                        <b>Account Title</b>
                      </td>
                      <td>{purchaseOrderReturn?.supplier?.bank?.accountTitle}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Bank Name</b>
                      </td>
                      <td>{purchaseOrderReturn?.supplier?.bank?.name}</td>
                      <td>
                        <b>Status</b>
                      </td>
                      <td>{purchaseOrderReturn?.status}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Purchase For</b>
                      </td>
                      <td>{purchaseOrderReturn?.purchaseFor}</td>
                    </tr>
                    {purchaseOrderReturn?.approvedAt && purchaseOrderReturn?.approvedBy && (
                      <tr>
                        <td>
                          <b>Approved At</b>
                        </td>
                        <td>{formatDateTime(purchaseOrderReturn?.approvedAt)}</td>
                        <td>
                          <b>Approved By</b>
                        </td>
                        <td>{purchaseOrderReturn?.approvedBy?.fullName}</td>
                      </tr>
                    )}
                    {purchaseOrderReturn?.rejectedAt && purchaseOrderReturn?.rejectedBy && (
                      <tr>
                        <td>
                          <b>Rejected At</b>
                        </td>
                        <td>{formatDateTime(purchaseOrderReturn?.rejectedAt)}</td>
                        <td>
                          <b>Rejected By</b>
                        </td>
                        <td>{purchaseOrderReturn?.rejectedBy?.fullName}</td>
                      </tr>
                    )}
                    {purchaseOrderReturn?.rejectionReason && (
                      <tr>
                        <td>
                          <b>Reason to Reject</b>
                        </td>
                        <td colSpan={3}>{purchaseOrderReturn?.rejectionReason}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {purchaseOrderReturn?.attachments?.length > 0 && (
                  <>
                    <h1 className='mt-10'> Attachments </h1>
                    {purchaseOrderReturn?.attachments.map((attachment: any, index: number) => {
                      return (
                        <a
                          href={imageFunction(attachment)}
                          target='_blank'
                          className='m-2 badge bg-success'
                        >
                          {attachment.originalFileName}
                        </a>
                      )
                    })}
                  </>
                )}
                <table className='table tbl-border tbl-md-responsivness table-striped mt-10'>
                  <thead>
                    <tr>
                      <th style={{width: 40}}>
                        <b>#</b>
                      </th>
                      <th>
                        <b>Product</b>
                      </th>
                      <th>
                        <b>UOM</b>
                      </th>
                      <th>
                        <b>Status</b>
                      </th>
                      <th style={{width: 80}}>
                        <b>Quantity</b>
                      </th>
                      <th>
                        <b>Rate</b>
                      </th>
                      <th>
                        <b>Price</b>
                      </th>
                      <th style={{width: 100}}>
                        <b>Tax</b>
                      </th>
                      <th>
                        <b>Price Inclusive Tax</b>
                      </th>
                      <th>
                        <b>Discount</b>
                      </th>
                      <th>
                        <b>Expiry Date</b>
                      </th>
                      <th>
                        <b>QR Code</b>
                      </th>
                      <th>
                        <b>Total</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseOrderReturn?.items.map((materialItem: any, index: any) => (
                      <tr key={index}>
                        <td data-label='#'>{index + 1}</td>
                        <td data-label='Name'>{materialItem?.name}</td>
                        <td data-label='UOM'>{materialItem?.UOM}</td>
                        <td data-label='Status'>{materialItem?.status}</td>
                        <td data-label='Quantity'>{formatNumber(materialItem?.quantity)}</td>
                        <td data-label='Rate'>{formatMoney(materialItem?.rate)}</td>
                        <td data-label='Price'>{formatMoney(materialItem?.price)}</td>
                        <td data-label='Tax'>{formatMoney(materialItem?.tax)}</td>
                        <td data-label='Price Inclusive Tax'>
                          {formatMoney(materialItem?.priceInclusiveTax)}
                        </td>
                        <td data-label='Discount'>{formatMoney(materialItem?.discount)}</td>
                        <td data-label='Expiry Date'>
                          {materialItem.expiryDate ? formatDate(materialItem?.expiryDate) : '-'}
                        </td>
                        <td data-label='QR Code'>
                          {materialItem?.qrCode && (
                            <div
                              style={{
                                height: 'auto',
                                margin: '0 auto',
                                maxWidth: 64,
                                width: '100%',
                              }}
                            >
                              <QRCode
                                id={`${purchaseOrderReturn?.qrCode}`}
                                size={144}
                                bgColor='#FFF'
                                fgColor='#000'
                                includeMargin
                                level={'H'}
                                style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                                value={purchaseOrderReturn?.qrCode?.toString()}
                              />
                            </div>
                          )}
                        </td>
                        <td data-label='Total'>{formatMoney(materialItem?.total)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={11}></td>
                      <td>
                        <b>Total Price</b>
                      </td>
                      <td data-label='Total Price'>
                        {formatMoney(purchaseOrderReturn?.totalPrice)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={11}></td>
                      <td>
                        <b>Total Tax</b>
                      </td>
                      <td data-label='Total Tax'>{formatMoney(purchaseOrderReturn?.totalTax)}</td>
                    </tr>
                    <tr>
                      <td colSpan={11}></td>
                      <td>
                        <b>Total Discount</b>
                      </td>
                      <td data-label='Total Discount'>
                        {formatMoney(purchaseOrderReturn?.totalDiscount)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={11}></td>
                      <td>
                        <b>Total Amount</b>
                      </td>
                      <td data-label='Total Amount'>
                        {formatMoney(purchaseOrderReturn?.totalAmount)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
