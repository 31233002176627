import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  StockMovementReportInitValues,
  StockMovementReportModel,
} from '../models/StockMovementReportModel'

interface StockState {
  loading: boolean
  stock: StockMovementReportModel
  stocks: [StockMovementReportModel?]
  pagination: {total: number; pages: number}
  dateRange: {openingStockDate: any; closingStockDate: any}
}

const initialState: StockState = {
  loading: false,
  stock: StockMovementReportInitValues,
  stocks: [],
  pagination: {total: 0, pages: 0},
  dateRange: {openingStockDate: '', closingStockDate: ''},
}

export const StockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setStocks: (state, action: PayloadAction<any>) => {
      const {stocks} = action.payload
      state.stocks = stocks
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
    setDateRange: (state: {dateRange: any}, action: PayloadAction<any>) => {
      state.dateRange = action.payload.dateRange
    },
  },
})
