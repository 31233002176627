import {ProductTableModel} from '../models/ProductModel'
import * as ProductCRUD from './ProductCRUD'
import {ProductSlice} from './ProductSlice'

const {actions} = ProductSlice

export const findAll: any = (params: ProductTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return ProductCRUD.findAll(params).then((response) => {
    dispatch(actions.setProducts({products: response.data.data}))
    dispatch(actions.setPagination({pagination: response.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const findOne = (_id: string) => (dispatch: any) => {
  return ProductCRUD.findOne(_id).then((response) => {
    dispatch(actions.setProduct({product: response.data.data}))
  })
}

export const create =
  (name: string, UOM: string, category: string, code: string, params: ProductTableModel) =>
  async (dispatch: any) => {
    try {
      const res = await ProductCRUD.create(name, UOM, category, code)
      const successMsg = res.data.message
      ProductCRUD.findAll(params).then((response) => {
        dispatch(actions.setProducts({products: response.data.data}))
      })
      return successMsg
    } catch (error: any) {
      const errors = error?.response?.data?.message
      let errorMsg
      if (typeof errors === 'string') {
        errorMsg = errors
      } else {
        errorMsg = 'Something went wrong'
      }
      throw errorMsg
    }
  }

export const update =
  (
    _id: string,
    name: string,
    UOM: string,
    category: string,
    code: string,
    params: ProductTableModel
  ) =>
  async (dispatch: any) => {
    try {
      const res = await ProductCRUD.update(_id, name, UOM, category, code)
      const successMsg = res.data.message
      ProductCRUD.findAll(params).then((response) => {
        dispatch(actions.setProducts({products: response.data.data}))
      })
      return successMsg
    } catch (error: any) {
      const errors = error?.response?.data?.message
      let errorMsg
      if (typeof errors === 'string') {
        errorMsg = errors
      } else {
        errorMsg = 'Something went wrong'
      }
      throw errorMsg
    }
  }
export const remove = (_id: string, params: ProductTableModel) => async (dispatch: any) => {
  try {
    const res = await ProductCRUD.remove(_id)
    const successMsg = res.data.message
    ProductCRUD.findAll(params).then((response) => {
      dispatch(actions.setProducts({products: response.data.data}))
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export function fetchProducts(): any {
  throw new Error('Function not implemented.')
}
