import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import * as GoodsReceivedNoteAction from '../redux/GoodsReceivedNoteAction'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDate, formatDateTime, formatMoney, formatNumber} from '../../../helper'
import {PrintButton} from '../../../components/PrintButton'
import * as ProductAction from '../../products/redux/ProductAction'
import {ProductListingModelInitValues} from '../../products/models/ProductModel'
import QRCodeDocument from '../../../components/QRCodeDocument'
import QRCode from 'qrcode.react'
import {PDFDownloadLink} from '@react-pdf/renderer'
import moment from 'moment'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const GoodsReceivedNoteDetail: React.FC<Props> = ({className}) => {
  const goodsReceivedNote: any = useSelector<RootState>(
    ({goodsReceivedNotes}) => goodsReceivedNotes.goodsReceivedNote,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({goodsReceivedNotes}) => goodsReceivedNotes.loading,
    shallowEqual
  )

  const goodsReceivedNoteUpdatedValues = {
    ...goodsReceivedNote,
    createdAt: formatDateTime(goodsReceivedNote?.createdAt),
    approvedAt: formatDateTime(goodsReceivedNote?.approvedAt),
  }

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()
  useEffect(() => {
    dispatch(ProductAction.findAll(ProductListingModelInitValues))
    dispatch(GoodsReceivedNoteAction.fetchOne(id))
  }, [dispatch, id])

  const qrCodeData = goodsReceivedNoteUpdatedValues.items.map((data: any) => data)

  useEffect(() => {
    dispatch(ProductAction.findAll(ProductListingModelInitValues))
  }, [dispatch])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Goods Received Notes List', url: '/goods-received-note'},
    {name: 'Goods Received Note Detail', url: ''},
  ]
  const imageFunction = (attachment: any) => {
    return process.env.REACT_APP_API_URL + attachment?.path
  }

  return (
    <>
      <div className={`card card-custom print ${className}`}>
        <a href='/' className='print-view-show'>
          <img alt='Logo' src='https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg' />
        </a>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Goods Received Note Detail</span>
          </h1>
          <PrintButton />
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            goodsReceivedNote && goodsReceivedNote?._id !== '' ? (
              <>
                <table className='table tbl-border tbl-md-responsivness tbl-responsivness-noLabel'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Goods Received Note No.</b>
                      </td>
                      <td>{goodsReceivedNote?.number}</td>
                      <td>
                        <b>Created Date</b>
                      </td>
                      <td>{formatDateTime(goodsReceivedNote?.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Purchase Order No.</b>
                      </td>
                      <td>{goodsReceivedNote?.materialInspection?.purchaseOrder?.number}</td>
                      <td>
                        <b>Requested By</b>
                      </td>
                      <td>{goodsReceivedNote?.createdBy?.fullName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Material Inspection No.</b>
                      </td>
                      <td>{goodsReceivedNote?.materialInspection?.number}</td>
                      <td>
                        <b>Delivery Challan No.</b>
                      </td>
                      <td>{goodsReceivedNote?.deliveryChallanNumber}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Status</b>
                      </td>

                      <td>{goodsReceivedNote?.status}</td>
                      <td>
                        <b>Location</b>
                      </td>

                      <td>{goodsReceivedNote?.location?.name}</td>
                    </tr>
                    {goodsReceivedNote?.approvedAt && goodsReceivedNote?.approvedBy && (
                      <tr>
                        <td>
                          <b>Approved At</b>
                        </td>
                        <td>{formatDateTime(goodsReceivedNote?.approvedAt)}</td>
                        <td>
                          <b>Approved By</b>
                        </td>
                        <td>{goodsReceivedNote?.approvedBy?.fullName}</td>
                      </tr>
                    )}
                    {goodsReceivedNote?.rejectedAt && goodsReceivedNote?.rejectedBy && (
                      <tr>
                        <td>
                          <b>Rejected At</b>
                        </td>
                        <td>{formatDateTime(goodsReceivedNote?.rejectedAt)}</td>
                        <td>
                          <b>Rejected By</b>
                        </td>
                        <td>{goodsReceivedNote?.rejectedBy?.fullName}</td>
                      </tr>
                    )}
                    {goodsReceivedNote?.rejectionReason && (
                      <tr>
                        <td>
                          <b>Reason to Reject</b>
                        </td>
                        <td colSpan={3}>{goodsReceivedNote?.rejectionReason}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {goodsReceivedNote?.attachments?.length > 0 && (
                  <>
                    <h1 className='mt-10'> Attachments </h1>
                    {goodsReceivedNote?.attachments.map((attachment: any, index: number) => {
                      return (
                        <a
                          href={imageFunction(attachment)}
                          target='_blank'
                          className='m-2 badge bg-success'
                        >
                          {attachment.originalFileName}
                        </a>
                      )
                    })}
                  </>
                )}

                {goodsReceivedNoteUpdatedValues.items.map(
                  (data: any) =>
                    data?.qrCode && (
                      <QRCode
                        id={`${data?.qrCode}`}
                        size={144}
                        bgColor='#FFF'
                        fgColor='#000'
                        includeMargin
                        level={'H'}
                        style={{height: 'auto', maxWidth: '100%', width: '100%', display: 'none'}}
                        value={data.qrCode.toString()}
                      />
                    )
                )}

                <div
                  className='card-toolbar d-flex flex-row justify-content-end align-items-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Download PDF'
                >
                  <PDFDownloadLink
                    document={<QRCodeDocument qrCodeData={qrCodeData} comp='grn' />}
                    fileName={`${goodsReceivedNoteUpdatedValues.number}-${moment(new Date()).format(
                      'YYYYMMDD'
                    )}.pdf`}
                  >
                    {({loading}) =>
                      loading ? (
                        <span className='btn btn-sm btn-light-primary'>Loading</span>
                      ) : (
                        <span className='btn btn-sm btn-light-primary'>Print QR Codes</span>
                      )
                    }
                  </PDFDownloadLink>
                </div>

                <table className='mytest table tbl-border tbl-md-responsivness table-striped mt-10'>
                  <thead>
                    <tr>
                      <th style={{width: 40}}>
                        <b>#</b>
                      </th>
                      <th>
                        <b>Product</b>
                      </th>
                      <th>
                        <b>UOM</b>
                      </th>
                      <th style={{width: 80}}>
                        <b>Quantity</b>
                      </th>
                      <th>
                        <b>Rate</b>
                      </th>
                      <th>
                        <b>Price</b>
                      </th>
                      <th style={{width: 100}}>
                        <b>Tax</b>
                      </th>
                      <th>
                        <b>Price Inclusive Tax</b>
                      </th>
                      <th>
                        <b>Discount</b>
                      </th>
                      <th>
                        <b>Expiry Date</b>
                      </th>
                      <th>
                        <b>QR Code</b>
                      </th>
                      <th>
                        <b>Total</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {goodsReceivedNote?.items?.map((materialItem: any, index: any) => (
                      <tr key={index}>
                        <td data-label='#'>{index + 1}</td>
                        <td data-label='Name'>{materialItem.name}</td>
                        <td data-label='UOM'>{materialItem.UOM}</td>
                        <td data-label='Quantity'>{formatNumber(materialItem.quantity)}</td>
                        <td data-label='Rate'>{formatMoney(materialItem.rate)}</td>
                        <td data-label='Price'>{formatMoney(materialItem.price)}</td>
                        <td data-label='Tax'>{formatMoney(materialItem.tax)}</td>
                        <td data-label='Price Inclusive Tax'>
                          {formatMoney(materialItem.priceInclusiveTax)}
                        </td>
                        <td data-label='Discount'>{formatMoney(materialItem.discount)}</td>
                        <td data-label='Expiry Date'>
                          {materialItem?.expiryDate ? formatDate(materialItem?.expiryDate) : '-'}
                        </td>
                        <td data-label='QR Code'>
                          {materialItem?.qrCode && (
                            <div
                              style={{
                                height: 'auto',
                                margin: '0 auto',
                                maxWidth: 64,
                                width: '100%',
                              }}
                            >
                              <QRCode
                                id={`${materialItem?.qrCode}`}
                                size={144}
                                bgColor='#FFF'
                                fgColor='#000'
                                includeMargin
                                level={'H'}
                                style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                                value={materialItem?.qrCode.toString()}
                              />
                            </div>
                          )}
                        </td>

                        <td data-label='Total'>{formatMoney(materialItem.total)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={10}></td>
                      <td>
                        <b>Total Price</b>
                      </td>
                      <td data-label='Total Price'>{formatMoney(goodsReceivedNote?.totalPrice)}</td>
                    </tr>
                    <tr>
                      <td colSpan={10}></td>
                      <td>
                        <b>Total Tax</b>
                      </td>
                      <td data-label='Total Tax'>{formatMoney(goodsReceivedNote?.totalTax)}</td>
                    </tr>
                    <tr>
                      <td colSpan={10}></td>
                      <td>
                        <b>Total Discount</b>
                      </td>
                      <td data-label='Total Discount'>
                        {formatMoney(goodsReceivedNote?.totalDiscount)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}></td>
                      <td>
                        <b>Total Amount</b>
                      </td>
                      <td data-label='Total Amount'>
                        {formatMoney(goodsReceivedNote?.totalAmount)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
