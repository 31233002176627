import {Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {GoodsReceivedNoteInitValues, GoodsReceivedNoteModel} from '../models/GoodsReceivedNoteModel'
import * as GoodsReceivedNoteAction from '../redux/GoodsReceivedNoteAction'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDateTime, formatNumber} from '../../../helper'
import {GoodsReceivedNoteRejectConfirmModal} from './GoodsReceivedNoteRejectConfirmModal'
import {GoodsReceivedNoteApproveConfirmModal} from './GoodsReceivedNoteApproveConfirmModal'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const GoodsReceivedNoteApproval: React.FC<Props> = ({className}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const goodsReceivedNotes: any = useSelector<RootState>(
    ({goodsReceivedNotes}) => goodsReceivedNotes.goodsReceivedNote,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({goodsReceivedNotes}) => goodsReceivedNotes.loading,
    shallowEqual
  )

  const items = goodsReceivedNotes?.items?.map((item: any, index: number) => {
    const itemsData = {
      rate: formatNumber(item?.rate),
      tax: formatNumber(item?.tax),
      UOM: item?.UOM,
      qrCode: item?.qrCode,
      productCode: item?.productCode,
      name: item?.name,
      price: formatNumber(item?.price),
      priceInclusiveTax: formatNumber(item?.priceInclusiveTax),
      quantity: item?.quantity,
      discount: formatNumber(item?.discount),
      total: formatNumber(item?.total),
      product: item?.product,
      _id: item?._id,
    }
    return itemsData
  })

  const goodsReceivedNoteUpdatedValues = {
    ...goodsReceivedNotes,
    items,
    createdAt: formatDateTime(goodsReceivedNotes?.createdAt),
    totalPrice: formatNumber(goodsReceivedNotes?.totalPrice),
    totalTax: formatNumber(goodsReceivedNotes?.totalTax),
    totalDiscount: formatNumber(goodsReceivedNotes?.totalDiscount),
    totalAmount: formatNumber(goodsReceivedNotes?.totalAmount),
  }

  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [goodsReceivedNoteToApprove, setGoodsReceivedNoteToApprove] =
    useState<GoodsReceivedNoteModel>(GoodsReceivedNoteInitValues)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [goodsReceivedNoteToReject, setGoodsReceivedNoteToReject] =
    useState<GoodsReceivedNoteModel>(GoodsReceivedNoteInitValues)

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(GoodsReceivedNoteAction.fetchOne(id))
  }, [dispatch, id])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Goods Received Notes List', url: '/goods-received-note'},
    {name: 'Goods Received Note Approval Form', url: ''},
  ]

  return (
    <>
      <GoodsReceivedNoteRejectConfirmModal
        show={showRejectDialog}
        handleClose={() => {
          setShowRejectDialog(false)
        }}
        goodsReceivedNote={goodsReceivedNoteToReject}
      />
      <GoodsReceivedNoteApproveConfirmModal
        show={showApproveDialog}
        handleClose={() => {
          setShowApproveDialog(false)
        }}
        goodsReceivedNote={goodsReceivedNoteToApprove}
      />
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              Goods Received Note Approval Form
            </span>
          </h1>
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            goodsReceivedNotes && goodsReceivedNotes?._id !== '' ? (
              <Formik
                enableReinitialize
                initialValues={goodsReceivedNoteUpdatedValues || GoodsReceivedNoteInitValues}
                onSubmit={(values: any) => {
                  setShowApproveDialog(true)
                  setGoodsReceivedNoteToApprove(goodsReceivedNoteUpdatedValues)
                }}
              >
                {({values}) => (
                  <Form>
                    <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                      <div className='form-group col'>
                        <label htmlFor='number' className='fw-bold  '>
                          Goods Received Note No.
                        </label>
                        <Field
                          name='number'
                          className='form-control form-control-lg  '
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='createdAt' className='fw-bold'>
                          Created Date
                        </label>
                        <Field
                          name='createdAt'
                          className='form-control form-control-lg  '
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='purchaseOrder' className='fw-bold'>
                          Purchase Order No.
                        </label>

                        <Field
                          className='form-control form-control-md   '
                          name='materialInspection.purchaseOrder.number'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='requestedBy' className='fw-bold'>
                          Employee Name
                        </label>
                        <Field
                          name='requestedBy'
                          value={user.fullName}
                          className='form-control form-control-lg  '
                          type='text'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='materialInspection' className='fw-bold'>
                          Material Inspection No.
                        </label>

                        <Field
                          className='form-control form-control-md   '
                          name='materialInspection.number'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='deliveryChallanNumber' className='fw-bold'>
                          Delivery Challan No.
                        </label>
                        <Field
                          name='deliveryChallanNumber'
                          className='form-control form-control-lg  '
                          disabled={true}
                        />
                      </div>
                    </div>
                    <FieldArray
                      name='items'
                      render={(arrayHelpers) => (
                        <div className='mt-15'>
                          <table className='table tbl-md-responsivness'>
                            <thead>
                              <tr>
                                <th>
                                  <label className='form-label'>QR Code</label>
                                </th>
                                <th>
                                  <label className='form-label'>Product</label>
                                </th>
                                <th>
                                  <label className='form-label'>UOM</label>
                                </th>
                                <th>
                                  <label className='form-label'>Quantity</label>
                                </th>
                                <th>
                                  <label className='form-label'>Rate</label>
                                </th>
                                <th>
                                  <label className='form-label'>Price</label>
                                </th>
                                <th>
                                  <label className='form-label'>Tax</label>
                                </th>
                                <th>
                                  <label className='form-label'>Price Inclusive Tax</label>
                                </th>
                                <th>
                                  <label className='form-label'>Discount</label>
                                </th>
                                <th>
                                  <label className='form-label'>Total</label>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.items?.length > 0 &&
                                values.items?.map((materialItem: any, index: any) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td data-label='QR Code'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.qrCode`}
                                            placeholder='QR Code'
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Product'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.name`}
                                            placeholder='Product'
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='UOM'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.UOM`}
                                            placeholder='UOM'
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Quantity'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.quantity`}
                                            placeholder='Quantity'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Rate'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.rate`}
                                            placeholder='Rate'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Price'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.price`}
                                            placeholder='Price'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Tax'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.tax`}
                                            placeholder='Tax'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Price Inclusive Tax'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.priceInclusiveTax`}
                                            placeholder='Price Inclusive Tax'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Discount'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.discount`}
                                            placeholder='Discount'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>
                                      <td data-label='Total'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.total`}
                                            placeholder='Total'
                                            className='form-control form-control-md'
                                            disabled={true}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}

                              <tr>
                                <td colSpan={8} className='ps-0 dl' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Price
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Price'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalPrice'
                                      placeholder='Total Price'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={8} className='ps-0 dl' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Tax
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Tax'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalTax'
                                      placeholder='Total Tax'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={8} className='ps-0 dl' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Discount
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Discount'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalDiscount'
                                      placeholder='Total Discount'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={8} className='ps-0' data-label=''>
                                  <div className='d-flex flex-column w-100'>
                                    <label className='col-form-label d-flex justify-content-end align-items-end'>
                                      Total Amount
                                    </label>
                                  </div>
                                </td>
                                <td data-label='Total Amount'>
                                  <div className='d-flex flex-column w-100'>
                                    <Field
                                      name='totalAmount'
                                      placeholder='Total Amount'
                                      className='form-control form-control-md'
                                      disabled={true}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    />
                    <div className='d-flex justify-content-end mt-10'>
                      <button
                        type='button'
                        className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Reject'
                        onClick={() => {
                          setShowRejectDialog(true)
                          setGoodsReceivedNoteToReject(goodsReceivedNoteUpdatedValues)
                        }}
                      >
                        <span className='indicator-label'>Reject</span>
                      </button>

                      <button
                        type='submit'
                        className='btn btn-lg  btn-primary me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Approve'
                        id='liveToastBtn'
                      >
                        <span className='indicator-label'>Approve</span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
