import {ProductCategory, ProductCategoryInitValues} from './../models/ProductCategoryModel'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface ProductCategoryState {
  loading: boolean
  parentCategories: [ProductCategory]
  productCategories: [ProductCategory?]
  pagination: {total: number; pages: number}
}

const initialState: ProductCategoryState = {
  loading: false,
  productCategories: [],
  parentCategories: [ProductCategoryInitValues],
  pagination: {total: 0, pages: 0},
}

export const productCategorySlice = createSlice({
  name: 'productCategories',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setProductCategories: (state: {productCategories: any}, action: PayloadAction<any>) => {
      state.productCategories = action.payload.productCategories
    },
    setParentCategories: (state: {parentCategories: any}, action: PayloadAction<any>) => {
      state.parentCategories = action.payload.parentCategories
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
