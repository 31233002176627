import {useState} from 'react'
import * as Yup from 'yup'
import {Link, useParams, useNavigate} from 'react-router-dom'
import {resetPassword} from '../redux/AuthCRUD'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {ResetPasswordInitValues} from '../models/ResetPasswordModel'
import {toast} from 'react-toastify'

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const {token}: any = useParams()
  const navigate = useNavigate()

  return (
    <div>
      <div className='card-header text-center border-0 pt-5'>
        <h1 className='card-title'>
          <span className='card-label fw-bolder fs-3 mb-1'>Reset Password</span>
        </h1>
        <h1 className='card-title'>
          <span className='card-label text-gray-400 fw-bolder fs-3 mb-1'>
            Enter your new password
          </span>
        </h1>
      </div>
      <div className='card-body py-10'>
        <Formik
          initialValues={ResetPasswordInitValues}
          validationSchema={Yup.object().shape({
            newPassword: Yup.string()
              .required('Password is required')
              .matches(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                'Password must contain upper and lowercase letters and at least one number'
              ),
            confirmPassword: Yup.string().when('newPassword', (newPassword, schema) => {
              return newPassword && newPassword.length > 0
                ? schema
                    .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
                    .required('Confirm Password is required')
                : schema
            }),
          })}
          onSubmit={(values) => {
            setLoading(true)
            setTimeout(() => {
              resetPassword(values.newPassword, token)
                .then(({data: {message}}) => {
                  toast.success(message)
                  setLoading(false)
                  navigate('/login')
                })
                .catch((error) => {
                  setLoading(false)
                  toast.error(error.response.data.message)
                })
            }, 1000)
          }}
        >
          <Form>
            <div className='row'>
              <div className='form-group col'>
                <label htmlFor='newPassword' className='fw-bold required'>
                  New Password
                </label>
                <Field
                  name='newPassword'
                  placeholder='New Password'
                  className='form-control form-control-lg'
                  type='password'
                />
                <div className='text-danger'>
                  <ErrorMessage name='newPassword' />
                </div>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='form-group'>
                <label htmlFor='confirmPassword' className='fw-bold required'>
                  Confirm Password
                </label>
                <Field
                  name='confirmPassword'
                  placeholder=' Confirm Password'
                  className='form-control form-control-lg'
                  type='password'
                />
                <div className='text-danger'>
                  <ErrorMessage name='confirmPassword' />
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-end mt-10'>
              <Link
                to='/login'
                type='submit'
                className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Cancel'
              >
                <span className='indicator-label'>Cancel</span>
              </Link>
              <button
                type='submit'
                className='btn btn-lg  btn-primary me-3'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Submit'
                id='liveToastBtn'
              >
                {!loading && 'Submit'}
                {loading && (
                  <span className='indicator-label' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}
