import {KTSVG} from '../../../../_metronic/helpers'
import * as actions from '../redux/RoleAction'
import {RootState} from '../../../../setup'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RoleDelete} from './RoleDelete'
import {Can} from '../../../rbac/Can'
import {Table} from '../../../components/ReactTable'
import {formatDateTime} from '../../../helper/index'
import {Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import 'rsuite/dist/rsuite.css'
import {Link} from 'react-router-dom'
import {RoleSearchModelInitValues, RoleTableModelInitValues} from '../models/RoleModel'

type Props = {
  className: string
}

export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const RoleTable: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()

  const [showDialogBox, setShowDialogBox] = useState(false)
  const [rolesId, setUserId] = useState('')

  const loading: any = useSelector<RootState>(({roles}) => roles.loading, shallowEqual)

  const roles: any = useSelector<RootState>(({roles}) => roles.roles, shallowEqual)
  const pages: any = useSelector<RootState>(({roles}) => roles.pagination?.pages, shallowEqual)

  const [searchParams, setSearchParams]: any = useState({})

  const [show, setShow] = useState(false)
  const filterHandel = () => {
    setShow(!show)
  }
  useEffect(() => {
    dispatch(actions.findAll(RoleTableModelInitValues))
  }, [dispatch])

  const deleteRole = (id: string) => async (event: any) => {
    setUserId(id)
    setShowDialogBox(true)
  }

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Roles List', url: '/roles'},
  ]

  const fetchData = useCallback(
    ({pageSize, pageIndex, params, sortBy}: any) => {
      const queryParams = {
        page: pageIndex + 1,
        sortBy: sortBy[0]?.id || 'createdAt',
        sortOrder: sortBy[0]?.desc === false ? 'asc' : 'desc',
        search: params,
      }
      dispatch(actions.findAll(queryParams))
    },
    [searchParams]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: (cellInfo: any) => {
          if (cellInfo?.row?.original?.createdAt) {
            return formatDateTime(cellInfo?.row?.original?.createdAt)
          } else {
            return '-'
          }
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
      },

      {
        Header: 'Role Type',
        accessor: 'roleType',
      },

      {
        Header: 'Actions',
        Cell: (params: any) => (
          <div className='d-flex flex-shrink-0'>
            <Can do='update' on='roles'>
              <Link
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                to={`/roles/${params?.row?.original._id}/edit`}
                title='Edit Role'
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </Link>
            </Can>
            <Can do='delete' on='roles'>
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm '
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_delete_app'
                onClick={deleteRole(params.data._id)}
                title='Delete Role'
              >
                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
              </button>
            </Can>
          </div>
        ),
      },
    ],
    []
  )
  return (
    <>
      <RoleDelete
        show={showDialogBox}
        handleClose={() => {
          setShowDialogBox(false)
        }}
        id={rolesId}
      />

      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Roles List</span>
          </h1>
          <Can do='create' on='roles'>
            <div
              className='card-toolbar mt-0 pt-2'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Create Role'
            >
              <Link
                to={`/roles/create`}
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Create Role
              </Link>
            </div>
          </Can>
        </div>
        <div className='card-body'>
          <div
            className={`d-flex align-items-center ${
              !show ? 'justify-content-end' : 'justify-content-between'
            }`}
          >
            <p className='my-0 me-3' style={{color: '#004B87', fontSize: '14px'}}>
              <b>Search Filters</b>
            </p>
            <span onClick={filterHandel}>
              <img src='/media/svg/icons/General/filter.svg' alt='' />
            </span>
          </div>
          {show ? (
            <Formik
              initialValues={RoleSearchModelInitValues}
              validationSchema={Yup.object({
                name: Yup.string(),
                roleType: Yup.string(),
              })}
              onSubmit={(values: any) => {
                if (values.roleType === 'Select Role') {
                  values.roleType = ''
                }
                setSearchParams(values)
              }}
            >
              {({values, setFieldValue}) => (
                <Form>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                    <div className='form-group col'>
                      <label htmlFor='name' className='fw-bold'>
                        Name
                      </label>
                      <Field
                        name='name'
                        placeholder='Name'
                        className='form-control form-control-lg'
                      />
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='roleType' className='fw-bold'>
                        Select Role
                      </label>
                      <Field
                        as='select'
                        className='form-select form-select-md'
                        data-control='select2'
                        name='roleType'
                      >
                        <option>Select Role</option>
                        <option value='administrator'>Administrator</option>
                        <option value='manager'>Manager</option>
                        <option value='user'>User</option>
                      </Field>
                    </div>
                  </div>

                  <div className='d-flex justify-content-end mt-10'>
                    <Link
                      to='/roles'
                      className='btn btn-sm btn-light-primary me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancel'
                      onClick={() => {
                        setSearchParams(RoleSearchModelInitValues)
                        setFieldValue('name', '')
                        setFieldValue('roleType', '')
                      }}
                    >
                      <span className='indicator-label'>Cancel</span>
                    </Link>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Search'
                      id='liveToastBtn'
                    >
                      <span className='indicator-label'>Search</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}
          <div className='d-flex mt-5 '>
            <div className='react-table-wrapper' style={{width: '50%'}}>
              <Table
                columns={columns}
                data={roles}
                params={searchParams}
                pageCount={pages}
                fetchData={fetchData}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
