import {DepartmentModel, DepartmentInitValues} from './../models/DepartmentModel'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface DepartmentState {
  loading: boolean
  department: DepartmentModel
  departments: [DepartmentModel?]
  pagination: {total: number; pages: number}
}

const initialState: DepartmentState = {
  loading: false,
  department: DepartmentInitValues,
  departments: [],
  pagination: {total: 0, pages: 0},
}

export const DepartmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setDepartments: (state: {departments: any}, action: PayloadAction<any>) => {
      state.departments = action.payload.departments
    },
    setDepartment: (state: {department: any}, action: PayloadAction<any>) => {
      state.department = action.payload.department
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
