import {useState} from 'react'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {forgotPassword} from '../redux/AuthCRUD'
import {ForgotPasswordInitValues} from '../models/ForgotPasswordModel'
import {toast} from 'react-toastify'

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  return (
    <div>
      <div className='card-header text-center border-0 pt-5'>
        <h1 className='card-title'>
          <span className='card-label fw-bolder fs-3 mb-1'>Forgot Password?</span>
        </h1>
        <h1 className='card-title'>
          <span className='card-label text-gray-400 fw-bolder fs-3 mb-1'>
            Enter your email to reset your password.
          </span>
        </h1>
      </div>
      <div className='card-body py-10'>
        <Formik
          initialValues={ForgotPasswordInitValues}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required('Email is required'),
          })}
          onSubmit={(values) => {
            setLoading(true)
            setTimeout(() => {
              forgotPassword(values.email)
                .then(({data: {message}}) => {
                  toast.success(message)
                  setLoading(false)
                })
                .catch((error) => {
                  setLoading(false)
                  toast.error(error.response.data.message)
                })
            }, 1000)
          }}
        >
          <Form>
            <div className='row'>
              <div className='form-group col'>
                <label htmlFor='email' className='fw-bold required'>
                  Email
                </label>
                <Field
                  name='email'
                  placeholder='Email'
                  className='form-control form-control-lg'
                  type='email'
                />
                <div className='text-danger'>
                  <ErrorMessage name='email' />
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end mt-10'>
              <Link
                to='/login'
                type='submit'
                className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Cancel'
              >
                <span className='indicator-label'>Cancel</span>
              </Link>
              <button
                type='submit'
                className='btn btn-lg  btn-primary me-3'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Submit'
                id='liveToastBtn'
              >
                {!loading && 'Submit'}
                {loading && (
                  <span className='indicator-label' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}
