import * as RoleCRUD from './RoleCRUD'
import {rolesSlice} from './RoleSlice'
import {RoleTableModel, Subject} from '../models/RoleModel'

const {actions} = rolesSlice

export const findAll: any = (params: RoleTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return RoleCRUD.findAll(params).then((response) => {
    dispatch(actions.setRoles({roles: response?.data?.data}))
    dispatch(actions.setPagination({pagination: response.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const findOne =
  ({id}: {id: string}) =>
  async (dispatch: any) => {
    dispatch(actions.startLoading())
    try {
      return RoleCRUD.findOne({id}).then((response) => {
        const {data} = response
        dispatch(
          actions.setRole({
            role: data,
          })
        )
        dispatch(actions.stopLoading())
      })
    } catch (error: any) {
      dispatch(actions.stopLoading())
      const errors = error?.response?.data?.message
      let errorMsg
      if (typeof errors === 'string') {
        errorMsg = errors
      } else {
        errorMsg = 'Something went wrong'
      }
      throw errorMsg
    }
  }

export const create = (data: any, params: RoleTableModel) => async (dispatch: any) => {
  try {
    const res = await RoleCRUD.create(data)
    const successMsg = res.data.message
    RoleCRUD.findAll(params).then((response) => {
      dispatch(actions.setRoles({roles: response.data.data}))
      dispatch(actions.setPagination({pagination: response.data.pagination}))
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const update =
  (
    {name, roleType, subject, id}: {name: string; roleType: string; subject: Subject; id: string},
    params: RoleTableModel
  ) =>
  async (dispatch: any) => {
    try {
      const res = await RoleCRUD.update({
        name,
        subject,
        id,
        roleType,
      })
      const successMsg = res.data.message
      RoleCRUD.findAll(params).then((response) => {
        dispatch(actions.setRoles({roles: response.data.data}))
        dispatch(actions.setPagination({pagination: response.data.pagination}))
      })
      return successMsg
    } catch (error: any) {
      const errors = error?.response?.data?.message
      let errorMsg
      if (typeof errors === 'string') {
        errorMsg = errors
      } else {
        errorMsg = 'Something went wrong'
      }
      throw errorMsg
    }
  }

export const remove = (id: string, params: RoleTableModel) => async (dispatch: any) => {
  try {
    const res = await RoleCRUD.remove(id)
    const successMsg = res.data.message
    RoleCRUD.findAll(params).then((response) => {
      dispatch(actions.setRoles({roles: response.data}))
      dispatch(actions.setPagination({pagination: response.data.pagination}))
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const fetchPermissions = () => (dispatch: any) => {
  return RoleCRUD.fetchPermissions().then((response) => {
    const {data} = response

    dispatch(
      actions.setPermissions({
        permissions: data,
      })
    )
  })
}
