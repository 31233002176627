import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {RootState} from '../../../../setup'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_metronic/helpers'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as ProductAction from '../../products/redux/ProductAction'
import * as LocationAction from '../../locations/redux/LocationAction'
import {AssetInitValues} from '../models/AssetModel'
import * as AssetAction from '../redux/AssetAction'
import {toast} from 'react-toastify'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {LocationTableModelInitValues} from '../../locations/models/LocationModel'
import {ProductListingModelInitValues} from '../../products/models/ProductModel'

type Props = {
  className: string
}

export const AssetAdd: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(false)
  const products: any = useSelector<RootState>((state) => state.products.products, shallowEqual)

  const locations: any = useSelector<RootState>(({location}) => location.locations, shallowEqual)

  let fixedProducts =
    products &&
    products.filter((product: any) => {
      return product?.category?.assetType === 'FIXED'
    })

  const dispatch: any = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(ProductAction.findAll(ProductListingModelInitValues))
    dispatch(LocationAction.fetchAll(LocationTableModelInitValues))
  }, [dispatch])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Assets List', url: '/assets'},
    {name: 'Add Asset', url: ''},
  ]

  return (
    <>
      <div className={`card ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3'>Add Asset</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          <Formik
            enableReinitialize
            initialValues={AssetInitValues}
            validationSchema={Yup.object({
              number: Yup.string()
                .trim('Asset No must have non-empty spaces')
                .strict(true)
                .required('Asset No is required'),
              GRN: Yup.string().trim('Goods Received Note No is required').strict(true).nullable(),
              product: Yup.string().required('Product is required'),
              location: Yup.string().required('Location is required'),
            })}
            onSubmit={(values: any) => {
              setLoading(true)
              dispatch(AssetAction.create(values))
                .then((response: string) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/assets')
                })
                .catch((errorMsg: any) => {
                  toast.error(errorMsg)
                  setLoading(false)
                })
            }}
          >
            {({setFieldValue}) => (
              <Form>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='form-group col'>
                    <label htmlFor='name' className='fw-bold required'>
                      Asset No.
                    </label>
                    <Field
                      name='number'
                      placeholder='Asset No.'
                      className='form-control form-control-lg'
                      type='text'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='number' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='GRN' className='fw-bold'>
                      Goods Received Note No.
                    </label>
                    <Field
                      name='grn'
                      placeholder='Enter a valid GRN number'
                      className='form-control form-control-lg'
                      type='text'
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='product' className='fw-bold required'>
                      Product
                    </label>
                    <Field
                      as='select'
                      className='form-select form-select-md'
                      name='product'
                      onChange={(e: any) => {
                        if (e.target.value && e.target.value !== 'Select') {
                          setFieldValue('product', e.target.value)
                        } else {
                          setFieldValue('product', '')
                        }
                      }}
                    >
                      <option> Select</option>
                      {fixedProducts.map((product: any, index: any) => (
                        <option key={index} value={product._id || ''}>
                          {product.name}
                        </option>
                      ))}
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='product' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='quantity' className='fw-bold'>
                      Quantity
                    </label>
                    <Field
                      name='quantity'
                      placeholder='Quantity'
                      className='form-control form-control-lg'
                      disabled
                    />
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='location' className='fw-bold required'>
                      Location
                    </label>
                    <Field
                      as='select'
                      className='form-select form-select-md'
                      name='location'
                      onChange={(e: any) => {
                        if (e.target.value && e.target.value !== 'Select') {
                          setFieldValue('location', e.target.value)
                        } else {
                          setFieldValue('location', '')
                        }
                      }}
                    >
                      <option> Select</option>
                      {locations &&
                        locations.map((location: any, index: any) => (
                          <option key={index} value={location._id || ''}>
                            {location.name}
                          </option>
                        ))}
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='location' />
                    </div>
                  </div>

                  <div className='form-group col'>
                    <label htmlFor='notes' className='fw-bold '>
                      Notes
                    </label>
                    <Field
                      name='notes'
                      placeholder='write a note'
                      className='form-control form-control-lg'
                      type='text'
                    />
                  </div>
                </div>

                <div className='d-flex justify-content-end mt-10'>
                  <Link to='/assets'>
                    <button
                      type='submit'
                      className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancel'
                    >
                      <span className='indicator-label'>Cancel</span>
                    </button>
                  </Link>
                  <button
                    type='submit'
                    className='btn btn-lg  btn-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Submit'
                    id='liveToastBtn'
                    disabled={loading}
                  >
                    <span className='indicator-label'>{loading ? 'Submitting...' : 'Submit'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
