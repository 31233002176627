export interface Subject {
  'material-requisition': string[]
  'material-issuance': string[]
  outward: string[]
  inward: string[]
  'purchase-orders': string[]
  'material-inspection': string[]
  suppliers: string[]
  'supplier-bills': string[]
  inventory: string[]
  assets: string[]
  budgets: string[]
  locations: string[]
  departments: string[]
  'product-categories': string[]
  products: string[]
  users: string[]
  roles: string[]
}

export const initSubjectValues: Subject = {
  'material-requisition': [],
  'material-issuance': [],
  outward: [],
  inward: [],
  'purchase-orders': [],
  'material-inspection': [],
  suppliers: [],
  'supplier-bills': [],
  inventory: [],
  assets: [],
  budgets: [],
  locations: [],
  departments: [],
  'product-categories': [],
  products: [],
  users: [],
  roles: [],
}

export interface Role {
  id: string
  name: string
  subject: Subject
  roleType: string
}

export const initRoleValues: Role = {
  id: '',
  name: '',
  subject: initSubjectValues,
  roleType: '',
}

export interface RoleSearchModel {
  name: string
  roleType: string
}
export const RoleSearchModelInitValues: RoleSearchModel = {
  name: '',
  roleType: '',
}

export interface RoleTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: RoleSearchModel
}

export const RoleTableModelInitValues: RoleTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: RoleSearchModelInitValues,
}
