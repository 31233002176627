import {InwardTableModel} from '../models/InwardModel'
import * as InwardCRUD from './InwardCRUD'
import {InwardSlice} from './InwardSlice'

const {actions} = InwardSlice

export const fetchAll: any = (params: InwardTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return InwardCRUD.findAll(params).then((res) => {
    dispatch(actions.setInwards({inwards: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchOne = (id: any) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await InwardCRUD.findOne(id)
    dispatch(actions.stopLoading())
    dispatch(actions.setInward({inward: res.data.data}))
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const fetchApproved = () => (dispatch: any) => {
  return InwardCRUD.findApproved().then((res) => {
    dispatch(actions.setInwards({inwards: res.data.data}))
  })
}

export const create = (data: any) => async (dispatch: any) => {
  try {
    const res = await InwardCRUD.create(data)
    const inward = res.data.data
    const successMsg = res.data.message
    dispatch(actions.setInward({inward: inward}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const approve = (id: any) => async (dispatch: any) => {
  try {
    const res = await InwardCRUD.approve(id)

    const inward = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setInward({inward: inward}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const reject = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await InwardCRUD.reject(id, data)

    const inward = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setInward({inward: inward}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const remove = (id: any, params: InwardTableModel) => async (dispatch: any) => {
  try {
    const res = await InwardCRUD.remove(id)

    const inward = res.data.data
    const successMsg = res.data.message

    InwardCRUD.findAll(params).then((res) => {
      dispatch(actions.setInwards({inwards: res.data.data}))
      dispatch(actions.setPagination({pagination: res.data.pagination}))
      dispatch(actions.stopLoading())
    })

    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}
