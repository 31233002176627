import {Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {MaterialIssuanceInitValues, MaterialIssuanceModel} from '../models/MaterialIssuanceModel'
import {fetchOne} from '../redux/MaterialIssuanceAction'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDateTime, formatNumber} from '../../../helper'
import {MaterialIssuanceRejectConfirmModal} from './MaterialIssuanceRejectConfirmModal'
import {MaterialIssuanceApproveConfirmModal} from './MaterialIssuanceApproveConfirmModal'
import * as InventoryAction from '../../inventory/redux/InventoryAction'
import {InventoryReportListingModelInitValues} from '../../inventory/models/InventoryReportModel'
import {Loader} from '../../../components/Loader'
import {NoItemFound} from '../../../components/NoItemFound'

type Props = {
  className: string
}

export const MaterialIssuanceApprove: React.FC<Props> = ({className}) => {
  const materialIssuance: any = useSelector<RootState>(
    ({materialIssuance}) => materialIssuance.materialIssuance,
    shallowEqual
  )

  const loading: any = useSelector<RootState>(
    ({materialIssuance}) => materialIssuance.loading,
    shallowEqual
  )

  const inventoryReports: any = useSelector<RootState>(
    ({inventories}) => inventories.inventories,
    shallowEqual
  )
  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [invalidInventoryStock, setInvalidInventoryStock] = useState<any>([])
  const [materialIssuanceToApprove, setMaterialIssuanceToApprove] = useState<MaterialIssuanceModel>(
    MaterialIssuanceInitValues
  )
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [materialIssuanceToReject, setMaterialIssuanceToReject] = useState<MaterialIssuanceModel>(
    MaterialIssuanceInitValues
  )
  const materialIssuanceUpdatedValues = {
    ...materialIssuance,
    createdAt: formatDateTime(materialIssuance?.createdAt),
    approvedAt: formatDateTime(materialIssuance?.approvedAt),
  }

  const dispatch: any = useDispatch()
  let {id} = useParams<{id: any}>()

  useEffect(() => {
    dispatch(fetchOne(id))
    dispatch(InventoryAction.fetchReport(InventoryReportListingModelInitValues))
  }, [dispatch, id])

  useEffect(() => {
    const invalidItems: any = []
    const materialIssuanceItems = materialIssuance?.items?.reduce((accumulator: any, item: any) => {
      const qrCode = item.qrCode
      if (!accumulator[qrCode]) {
        accumulator[qrCode] = {
          quantity: 0,
          name: item.name,
          product: item.product,
        }
      }
      accumulator[qrCode].quantity += item.quantity
      return accumulator
    }, {})

    Object.keys(materialIssuanceItems).forEach((qrCode: any) => {
      const matchedInventory = inventoryReports.find(
        (inventory: any) => inventory.qrCode === qrCode
      )
      if (
        +formatNumber(materialIssuanceItems[qrCode].quantity) >
        +formatNumber(matchedInventory?.quantityInStock ?? 0)
      ) {
        invalidItems.push(matchedInventory)
      }
      if (!matchedInventory) {
        invalidItems.push({qrCode: '', product: materialIssuanceItems[qrCode].product})
      }
    })
    setInvalidInventoryStock(invalidItems)
  }, [inventoryReports, materialIssuance])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Issuances List', url: '/material-issuance'},
    {name: 'Material Issuance Approval Form', url: ''},
  ]

  return (
    <>
      <MaterialIssuanceRejectConfirmModal
        show={showRejectDialog}
        handleClose={() => {
          setShowRejectDialog(false)
        }}
        materialIssuance={materialIssuanceToReject}
      />
      <MaterialIssuanceApproveConfirmModal
        show={showApproveDialog}
        handleClose={() => {
          setShowApproveDialog(false)
        }}
        materialIssuance={materialIssuanceToApprove}
      />
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Material Issuance Approval Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          {!loading ? (
            materialIssuance && materialIssuance?._id !== '' ? (
              <Formik
                enableReinitialize={true}
                initialValues={materialIssuanceUpdatedValues || MaterialIssuanceInitValues}
                onSubmit={(values: any) => {
                  setShowApproveDialog(true)
                  setMaterialIssuanceToApprove(materialIssuanceUpdatedValues)
                }}
              >
                {({values}) => (
                  <Form>
                    <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                      <div className='form-group col'>
                        <label htmlFor='materialRequisition' className='fw-bold'>
                          Material Issuance No.
                        </label>

                        <Field
                          name='number'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='createdAt' className='fw-bold'>
                          Created Date
                        </label>
                        <Field
                          name='createdAt'
                          className='form-control form-control-lg'
                          type='text'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='materialRequisition.number' className='fw-bold'>
                          Material Requisition No.
                        </label>

                        <Field
                          name='materialRequisition.number'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='providedToEmployee' className='fw-bold'>
                          Provided to Employee Name
                        </label>
                        <Field
                          name='providedToEmployee.fullName'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='department' className='fw-bold'>
                          Provided to Department Name
                        </label>
                        <Field
                          name='providedToDepartment.name'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                      <div className='form-group col'>
                        <label htmlFor='location' className='fw-bold'>
                          Location
                        </label>
                        <Field
                          name='location.name'
                          className='form-control form-control-lg'
                          disabled={true}
                        />
                      </div>
                    </div>
                    <FieldArray
                      name='items'
                      render={(arrayHelpers) => (
                        <div className='mt-15'>
                          <table className='table tbl-md-responsivness'>
                            <thead>
                              <tr>
                                <th>
                                  <label className='form-label '>Product</label>
                                </th>
                                <th>
                                  <label className='form-label '>UOM</label>
                                </th>
                                <th>
                                  <label className='form-label '>Quantity</label>
                                </th>
                                <th>
                                  <label className='form-label '>Asset No.</label>
                                </th>
                                <th>
                                  <label className='form-label '>Remarks</label>
                                </th>
                                <th>
                                  <label className='form-label '>Stock Availability</label>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.items?.length > 0 &&
                                values.items?.map((materialItem: any, index: any) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td data-label='Product'>
                                        <Field
                                          name={`items.${index}.name`}
                                          disabled={true}
                                          className='form-control form-control-md'
                                        />
                                      </td>

                                      <td data-label='UOM'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.UOM`}
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Quantity'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.quantity`}
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Asset No.'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.assetNumber`}
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>

                                      <td data-label='Remarks'>
                                        <div className='d-flex flex-column w-100'>
                                          <Field
                                            name={`items.${index}.remarks`}
                                            disabled={true}
                                            className='form-control form-control-md'
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        {invalidInventoryStock?.filter(function (e: any) {
                                          return (
                                            e?.qrCode == values.items[index]?.qrCode ||
                                            (e?.qrCode == '' &&
                                              e?.product == values.items[index]?.product)
                                          )
                                        }).length > 0 ? (
                                          <span className='m-4 badge rounded-pill bg-danger'>
                                            Out of stock
                                          </span>
                                        ) : (
                                          <span className='m-4 badge rounded-pill bg-success'>
                                            In stock
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    />

                    <div className='d-flex justify-content-end mt-10'>
                      <button
                        type='button'
                        className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Reject'
                        onClick={() => {
                          setShowRejectDialog(true)
                          setMaterialIssuanceToReject(materialIssuanceUpdatedValues)
                        }}
                      >
                        <span className='indicator-label'>Reject</span>
                      </button>

                      <button
                        type='submit'
                        className='btn btn-lg  btn-primary me-3'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Approve'
                        id='liveToastBtn'
                        disabled={invalidInventoryStock.length > 0 ? true : false}
                      >
                        <span className='indicator-label'>Approve</span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <NoItemFound />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}
