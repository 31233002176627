import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {StepNav2} from '../../../components/step-nav2'
import * as PurchaseOrderAction from '../../purchase-orders/redux/PurchaseOrderAction'
import {MaterialInspectionInitValues} from '../models/MaterialInspectionModel'
import * as MaterialInspectionAction from '../redux/MaterialInspectionAction'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import * as LocationAction from '../../locations/redux/LocationAction'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import * as actions from '../redux/MaterialInspectionAction'
import {LocationTableModelInitValues} from '../../locations/models/LocationModel'

type Props = {
  className: string
}

export const MaterialInspectionAdd: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(false)
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const purchaseOrders: any = useSelector<RootState>(
    ({purchaseOrders}) => purchaseOrders.purchaseOrders,
    shallowEqual
  )
  const locations: any = useSelector<RootState>(({location}) => location.locations, shallowEqual)
  const animatedComponents = makeAnimated()
  const materialInspection: any = useSelector<RootState>(
    ({materialInspections}) => materialInspections.materialInspection,
    shallowEqual
  )

  const dispatch: any = useDispatch()
  let navigate = useNavigate()
  useEffect(() => {
    dispatch(PurchaseOrderAction.fetchApproved())
    dispatch(LocationAction.fetchAll(LocationTableModelInitValues))
    return () => {
      dispatch(actions.resetMaterialInspection(MaterialInspectionInitValues))
    }
  }, [dispatch])

  let routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Material Inspections List', url: '/material-inspection'},
    {name: 'Material Inspection Form', url: ''},
  ]

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='border-0 pt-5 p-5'>
          <StepNav2 itemNumber={2} />
        </div>
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title'>
            <span className='card-label fw-bolder fs-3 mb-1'>Material Inspection Form</span>
          </h1>
        </div>
        <div className='card-body py-10'>
          <Formik
            initialValues={materialInspection}
            enableReinitialize
            validationSchema={Yup.object({
              purchaseOrder: Yup.string().required('Purchase Order No is required'),
              invoiceNo: Yup.string()
                .required('Invoice No is required')
                .trim('Invoice No must have non-empty spaces')
                .strict(true),
              location: Yup.string().required('Location is required'),
              items: Yup.array().of(
                Yup.object().shape({
                  remarks: Yup.string()
                    .trim('Remarks must have non-empty spaces')
                    .strict(true)
                    .required('Remarks are required'),
                })
              ),
            })}
            onSubmit={(values: any) => {
              setLoading(true)
              dispatch(MaterialInspectionAction.create(values))
                .then((response: string) => {
                  toast.success(response)
                  setLoading(false)
                  navigate('/material-inspection')
                })
                .catch((errorMsg: any) => {
                  toast.error(errorMsg)
                  setLoading(false)
                })
            }}
          >
            {({setFieldValue, values}) => (
              <Form>
                <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5'>
                  <div className='form-group col'>
                    <label htmlFor='purchaseOrder' className='fw-bold required'>
                      Purchase Order No.
                    </label>
                    <Select
                      options={
                        Array.isArray(purchaseOrders)
                          ? purchaseOrders?.map((purchaseOrder: any, index: any) => {
                              return {
                                value: purchaseOrder._id,
                                label: `${purchaseOrder.number}`,
                                key: index,
                              }
                            })
                          : []
                      }
                      placeholder='Select'
                      className='multi-select-container'
                      classNamePrefix='multi-select'
                      name='purchaseOrder'
                      onChange={(e: any) => {
                        if (e?.value) {
                          setFieldValue('purchaseOrder', e.value)
                          dispatch(actions.onChangePurchaseOrder(e, values, purchaseOrders))
                        } else {
                          setFieldValue('purchaseOrder', '')
                        }
                      }}
                      closeMenuOnSelect={true}
                      isClearable={true}
                      components={animatedComponents}
                    />

                    <div className='text-danger'>
                      <ErrorMessage name='purchaseOrder' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='requestedBy' className='fw-bold'>
                      Employee Name
                    </label>
                    <Field
                      name='requestedBy'
                      value={user.fullName}
                      className='form-control form-control-lg '
                      type='text'
                      disabled={true}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='requestedBy' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='location' className='fw-bold required'>
                      Invoice No
                    </label>
                    <Field
                      name='invoiceNo'
                      placeholder='Invoice No'
                      className='form-control form-control-lg '
                      type='text'
                      maxLength={255}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='invoiceNo' />
                    </div>
                  </div>
                  <div className='form-group col'>
                    <label htmlFor='location' className='fw-bold required'>
                      Location
                    </label>

                    <Field
                      as='select'
                      className='form-select form-select-md '
                      data-control='select2'
                      name='location'
                    >
                      <option>Select</option>
                      {locations &&
                        locations.map((locations: any, index: any) => (
                          <option value={locations._id} key={index}>
                            {locations.name}
                          </option>
                        ))}
                    </Field>
                    <div className='text-danger'>
                      <ErrorMessage name='location' />
                    </div>
                  </div>
                </div>

                <FieldArray
                  name='items'
                  render={(arrayHelpers) => (
                    <div className='mt-15'>
                      <table className='table tbl-xxl-responsivness'>
                        <thead>
                          <tr>
                            <th>
                              <label className='form-label'>Product</label>
                            </th>
                            <th>
                              <label className='form-label'>UOM</label>
                            </th>
                            <th>
                              <label className='form-label'>Quantity</label>
                            </th>
                            <th>
                              <label className='form-label'>Rate</label>
                            </th>
                            <th>
                              <label className='form-label'>Price</label>
                            </th>
                            <th>
                              <label className='form-label'>Tax</label>
                            </th>
                            <th>
                              <label className='form-label'>Price Inclusive Tax</label>
                            </th>
                            <th>
                              <label className='form-label'>Discount</label>
                            </th>
                            <th>
                              <label className='form-label'>Total</label>
                            </th>
                            <th>
                              <label className='form-label required '>Remarks</label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.items?.length > 0 &&
                            values.items?.map((materialItem: any, index: any) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td data-label='Product'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.name`}
                                        placeholder='Product'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                    </div>
                                  </td>

                                  <td data-label='UOM'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.UOM`}
                                        placeholder='UOM'
                                        disabled={true}
                                        className='form-control form-control-md'
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Quantity'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.quantity`}
                                        placeholder='Quantity'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Rate'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.rate`}
                                        placeholder='Rate'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Price'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.price`}
                                        placeholder='Price'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Tax'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.tax`}
                                        placeholder='Tax'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Price Inclusive Tax'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.priceInclusiveTax`}
                                        placeholder='Price Inclusive Tax'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Discount'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.discount`}
                                        placeholder='Discount'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>
                                  <td data-label='Total'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.total`}
                                        placeholder='Total'
                                        className='form-control form-control-md'
                                        disabled={true}
                                      />
                                    </div>
                                  </td>

                                  <td data-label='Remarks'>
                                    <div className='d-flex flex-column w-100'>
                                      <Field
                                        name={`items.${index}.remarks`}
                                        placeholder='Remarks'
                                        className='form-control form-control-md'
                                        maxLength={255}
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name={`items.${index}.remarks`} />
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <div className='d-flex flex-column w-100'>
                                      <button
                                        type='button'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                        // onClick={() => arrayHelpers.remove(index)}
                                        onClick={() => {
                                          dispatch(
                                            actions.removeMaterialInspectionItem(values, index)
                                          )
                                        }}
                                        data-bs-toggle='tooltip'
                                        data-bs-placement='top'
                                        title='Remove'
                                        disabled={values.items.length > 1 ? false : true}
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen027.svg'
                                          className='svg-icon  svg-icon-3'
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Price
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Price'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalPrice'
                                  placeholder='Total Price'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                            <td></td>
                          </tr>

                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Tax
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Tax'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalTax'
                                  placeholder='Total Tax'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Discount
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Discount'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalDiscount'
                                  placeholder='Total Discount'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan={8} className='ps-0 dl' data-label=''>
                              <div className='d-flex flex-column w-100'>
                                <label className='col-form-label d-flex justify-content-end align-items-end'>
                                  Total Amount
                                </label>
                              </div>
                            </td>
                            <td data-label='Total Amount'>
                              <div className='d-flex flex-column w-100'>
                                <Field
                                  name='totalAmount'
                                  placeholder='Total Amount'
                                  className='form-control form-control-md'
                                  disabled={true}
                                />
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                />

                <div className='d-flex justify-content-end mt-10'>
                  <Link
                    to='/material-inspection'
                    className='btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Cancel'
                  >
                    <span className='indicator-label'>Cancel</span>
                  </Link>

                  <button
                    type='submit'
                    className='btn btn-lg  btn-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Submit'
                    id='liveToastBtn'
                    disabled={loading}
                  >
                    <span className='indicator-label'>{loading ? 'Submitting...' : 'Submit'}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
