export interface LocationModel {
  _id: string
  name: string
}

export const LocationInitValues: LocationModel = {
  _id: '',
  name: '',
}

export interface LocationSearchModel {
  name: string
}

export const LocationSearchModelInitValues: LocationSearchModel = {
  name: '',
}

export interface LocationTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: LocationSearchModel
}

export const LocationTableModelInitValues: LocationTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: LocationSearchModelInitValues,
}
