import React, {FC} from 'react'
import {Navigate, useRoutes} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {MasterInit} from '../../_metronic/layout/MasterInit'

const AppRoutes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const routes = useRoutes([
    !isAuthorized
      ? /*Render auth page when user at `/auth` and not authorized.*/
        {path: '/auth', element: <AuthPage />}
      : /*Otherwise Navigate to root page (`/`)*/
        {path: '/login', element: <Navigate to='/' replace />},

    {path: '/error/*', element: <ErrorsPage />},
    {path: '/logout', element: <Logout />},

    !isAuthorized
      ? /*Navigate to `/auth` when user is not authorized*/
        {path: '*', element: <AuthPage />}
      : {
          path: '*',
          element: (
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          ),
        },
  ])

  return (
    <>
      {routes}
      <MasterInit />
    </>
  )
}

export {AppRoutes}
