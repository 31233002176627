export interface InventoryModel {
  type: string
  reference: string
  product: string
  quantity: number
  location: string
  expiryDate: string
  qrCode?: string
  productCode?: string
}

export const InventoryInitValues: InventoryModel = {
  type: '',
  reference: '',
  product: '',
  quantity: 0,
  location: '',
  expiryDate: '',
  productCode: '',
  qrCode: '',
}

export interface InventorySearchModel {
  reference: string
  type: string
  quantity: string
  quantityInStock: string
}
export const InventorySearchModelInitValues: InventorySearchModel = {
  reference: '',
  type: '',
  quantity: '',
  quantityInStock: '',
}

export interface InventoryTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: InventorySearchModel
}

export const InventoryTableModelInitValues: InventoryTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: InventorySearchModelInitValues,
}
