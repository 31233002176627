interface SupplierBillItem {
  _id: string
  product: string
  name: string
  UOM: string
  quantity: number | string
  rate: number | string
  price: number | string
  tax: number | string
  priceInclusiveTax: number | string
  discount: number | string
  total: number | string
}

export interface SupplierBillModel {
  _id: string
  createdAt: string
  approvedAt: string
  rejectedAt: string
  approvedBy: string
  rejectedBy: string
  number: string
  goodsReceivedNote: string
  purchaseOrderReturn: string
  rejectionReason: string
  purchaseOrder: string
  deliveryChallanNumber: string
  items: [SupplierBillItem]
  totalPrice: number | string
  totalTax: number | string
  totalDiscount: number | string
  totalAmount: number | string
}

export const SupplierBillInitValues: SupplierBillModel = {
  _id: '',
  createdAt: '',
  approvedAt: '',
  rejectedAt: '',
  approvedBy: '',
  rejectedBy: '',
  number: '',
  goodsReceivedNote: '',
  purchaseOrderReturn: '',
  purchaseOrder: '',
  deliveryChallanNumber: '',
  rejectionReason: '',
  items: [
    {
      _id: '',
      product: '',
      name: '',
      UOM: '',
      quantity: '',
      rate: '',
      price: '',
      tax: '',
      priceInclusiveTax: '',
      discount: '',
      total: '',
    },
  ],
  totalPrice: '',
  totalTax: '',
  totalDiscount: '',
  totalAmount: '',
}

export interface SupplierBillSearchModel {
  number: string
  status: string
  createdAt: any
  approvedBy: string
  approvedAt: any
}
export const SupplierBillSearchModelInitValues: SupplierBillSearchModel = {
  number: '',
  status: '',
  createdAt: [],
  approvedBy: '',
  approvedAt: [],
}

export interface SupplierBillTableModel {
  page: number
  sortBy: string
  sortOrder: string
  search: SupplierBillSearchModel
}

export const SupplierBillTableModelInitValues: SupplierBillTableModel = {
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: SupplierBillSearchModelInitValues,
}
