import React from 'react'
import {Statistics} from './components/Statistics'
import {Route, Routes} from 'react-router-dom'

export const StatisticsPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Statistics className='mb-5 mb-xl-8' />} />
      </Routes>
    </>
  )
}
