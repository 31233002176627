import {OutwardTableModel} from '../models/OutwardModel'
import * as OutwardCRUD from './OutwardCRUD'
import {OutwardSlice} from './OutwardSlice'

const {actions} = OutwardSlice

export const fetchAll: any = (params: OutwardTableModel) => (dispatch: any) => {
  dispatch(actions.startLoading())
  return OutwardCRUD.findAll(params).then((res) => {
    dispatch(actions.setOutwards({outwards: res.data.data}))
    dispatch(actions.setPagination({pagination: res.data.pagination}))
    dispatch(actions.stopLoading())
  })
}

export const fetchOne = (id: any) => async (dispatch: any) => {
  dispatch(actions.startLoading())
  try {
    const res = await OutwardCRUD.findOne(id)
    dispatch(actions.setOutward({outward: res.data.data}))
    dispatch(actions.stopLoading())
  } catch (error: any) {
    dispatch(actions.stopLoading())
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const fetchApproved = () => (dispatch: any) => {
  return OutwardCRUD.findApproved().then((res) => {
    dispatch(actions.setOutwards({outwards: res.data.data}))
  })
}

export const create = (data: any) => async (dispatch: any) => {
  try {
    const res = await OutwardCRUD.create(data)
    const outward = res.data.data
    const successMsg = res.data.message
    dispatch(actions.setOutward({outward: outward}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }
    throw errorMsg
  }
}

export const approve = (id: any) => async (dispatch: any) => {
  try {
    const res = await OutwardCRUD.approve(id)

    const outward = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setOutward({outward: outward}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const reject = (id: any, data: any) => async (dispatch: any) => {
  try {
    const res = await OutwardCRUD.reject(id, data)

    const outward = res.data.data
    const successMsg = res.data.message

    dispatch(actions.setOutward({outward: outward}))
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}

export const remove = (id: any, params: OutwardTableModel) => async (dispatch: any) => {
  try {
    const res = await OutwardCRUD.remove(id)
    const successMsg = res.data.message

    OutwardCRUD.findAll(params).then((res) => {
      dispatch(actions.setOutwards({outwards: res.data.data}))
      dispatch(actions.setPagination({pagination: res.data.pagination}))
      dispatch(actions.stopLoading())
    })
    return successMsg
  } catch (error: any) {
    const errors = error?.response?.data?.message
    let errorMsg
    if (typeof errors === 'string') {
      errorMsg = errors
    } else {
      errorMsg = 'Something went wrong'
    }

    throw errorMsg
  }
}
