import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  MaterialInspectionInitValues,
  MaterialInspectionModel,
} from '../models/MaterialInspectionModel'

interface MaterialInspectionState {
  loading: boolean
  materialInspection: MaterialInspectionModel
  materialInspections: [MaterialInspectionModel?]
  pagination: {total: number; pages: number}
}

const initialState: MaterialInspectionState = {
  loading: false,
  materialInspection: MaterialInspectionInitValues,
  materialInspections: [],
  pagination: {total: 0, pages: 0},
}

export const MaterialInspectionSlice = createSlice({
  name: 'materialInspection',
  initialState,
  reducers: {
    startLoading: (state: {loading: boolean}) => {
      state.loading = true
    },
    stopLoading: (state: {loading: boolean}) => {
      state.loading = false
    },
    setMaterialInspections: (state: {materialInspections: any}, action: PayloadAction<any>) => {
      state.materialInspections = action.payload.materialInspections
    },
    setMaterialInspection: (state: {materialInspection: any}, action: PayloadAction<any>) => {
      state.materialInspection = action.payload.materialInspection
    },
    setPagination: (state: {pagination: any}, action: PayloadAction<any>) => {
      state.pagination = action.payload.pagination
    },
  },
})
