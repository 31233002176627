import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import * as actions from '../redux/ProductCategoryAction'
import {
  ProductCategory,
  ProductCategoryInitValues,
  ProductCategorySearchModelInitValues,
  ProductCategoryTableModelInitValues,
} from '../models/ProductCategoryModel'
import {ProductCategoryAdd} from './ProductCategoryAdd'
import {ProductCategoryEdit} from './ProductCategoryEdit'
import {ProductCategoryDelete} from './ProductCategoryDelete'
import {Route, Breadcrumbs} from '../../../components/Breadcrumbs'
import {formatDateTime} from '../../../helper'
import {Can} from '../../../rbac/Can'
import {Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import 'rsuite/dist/rsuite.css'
import {Link} from 'react-router-dom'
import {Table} from '../../../components/ReactTable'

type Props = {
  className: string
}
type ProductCategoryModel = [
  {
    _id: string
    name: string
    description: string
  },
]
export const defaultColDef = {
  resizable: true,
  initialWidth: 100,
  filter: true,
  floatingFilter: true,
  suppressMovable: true,
  suppressKeyboardEvent: (params: any) => params.editing,
}

export const ProductCategories: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [categoryToEdit, setCategoryToEdit] = useState<ProductCategory>(ProductCategoryInitValues)

  const [categoryToDelete, setCategoryToDelete] =
    useState<ProductCategory>(ProductCategoryInitValues)

  const loading: any = useSelector<RootState>(
    ({productCategories}) => productCategories.loading,
    shallowEqual
  )

  const productCategoryData: ProductCategoryModel = useSelector<RootState>(
    (state) => state.productCategories.productCategories,
    shallowEqual
  ) as ProductCategoryModel

  const pages: any = useSelector<RootState>(
    ({productCategories}) => productCategories.pagination?.pages,
    shallowEqual
  )

  const [searchParams, setSearchParams]: any = useState({})

  const [show, setShow] = useState(false)
  const filterHandel = () => {
    setShow(!show)
  }
  useEffect(() => {
    dispatch(actions.findAll(ProductCategoryTableModelInitValues))
  }, [dispatch])

  const fetchData = useCallback(
    ({pageSize, pageIndex, params, sortBy}: any) => {
      const queryParams = {
        page: pageIndex + 1,
        sortBy: sortBy[0]?.id || 'createdAt',
        sortOrder: sortBy[0]?.desc === false ? 'asc' : 'desc',
        search: params,
      }
      dispatch(actions.findAll(queryParams))
    },
    [searchParams]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: (cellInfo: any) => {
          if (cellInfo?.row?.original?.createdAt) {
            return formatDateTime(cellInfo?.row?.original?.createdAt)
          } else {
            return '-'
          }
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Asset Type',
        accessor: 'assetType',
      },
      {
        Header: 'Parent Category',
        accessor: 'parent.name',
        disableSortBy: true,
        cellStyle: {textAlign: 'center'},
        Cell: (params: any) => {
          if (!params?.row?.original?.parent) return '-'
          return params?.row?.original?.parent.name
        },
      },

      {
        Header: 'Actions',
        Cell: (params: any) => (
          <div className='action-btn-wrapper d-flex flex-shrink-0'>
            <Can do='update' on='product-categories'>
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title='Edit Product Category'
                onClick={(e) => {
                  const category = {...params?.row?.original}
                  category.parent = category.parent
                    ? {label: category.parent.name, value: category.parent._id}
                    : null

                  setCategoryToEdit(category)
                  setShowEditDialog(true)
                }}
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button>
            </Can>
            <Can do='delete' on='product-categories'>
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_delete_app'
                title='Delete Product Category'
                onClick={() => {
                  setCategoryToDelete(params?.row?.original)
                  setShowDeleteDialog(true)
                }}
              >
                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
              </button>
            </Can>
          </div>
        ),
      },
    ],
    []
  )

  const routes: Route[] = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Product Categories List', url: '/product-categories'},
  ]

  return (
    <>
      <ProductCategoryAdd
        show={showAddDialog}
        handleClose={() => {
          setShowAddDialog(false)
        }}
      />

      <ProductCategoryEdit
        show={showEditDialog}
        handleClose={() => {
          setShowEditDialog(false)
        }}
        category={categoryToEdit}
      />

      <ProductCategoryDelete
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        category={categoryToDelete}
      />
      <div className={`card card-custom ${className}`}>
        <Breadcrumbs routes={routes} />
        <div className='card-header border-0 pt-5'>
          <h1 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Product Categories List</span>
          </h1>
          <Can do='create' on='product-categories'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Add Product Category'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                id='kt_toolbar_primary_button'
                onClick={() => {
                  setShowAddDialog(true)
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add Product Category
              </a>
            </div>
          </Can>
        </div>

        <div className='card-body'>
          <div
            className={`d-flex align-items-center ${
              !show ? 'justify-content-end' : 'justify-content-between'
            }`}
          >
            <p className='my-0 me-3' style={{color: '#004B87', fontSize: '14px'}}>
              <b>Search Filters</b>
            </p>
            <span onClick={filterHandel}>
              <img src='/media/svg/icons/General/filter.svg' alt='' />
            </span>
          </div>
          {show ? (
            <Formik
              initialValues={ProductCategorySearchModelInitValues}
              validationSchema={Yup.object({
                name: Yup.string(),
                assetType: Yup.string(),
              })}
              onSubmit={(values: any) => {
                if (values.assetType === 'Asset Type') {
                  values.assetType = ''
                }
                setSearchParams(values)
              }}
            >
              {({values, setFieldValue}) => (
                <Form>
                  <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1 g-5 mt-1'>
                    <div className='form-group col'>
                      <label htmlFor='name' className='fw-bold'>
                        Name
                      </label>
                      <Field
                        name='name'
                        placeholder='Name'
                        className='form-control form-control-lg'
                      />
                    </div>
                    <div className='form-group col'>
                      <label htmlFor='assetType' className='fw-bold'>
                        Status
                      </label>
                      <Field
                        as='select'
                        className='form-select form-select-md'
                        data-control='select2'
                        name='assetType'
                      >
                        <option>Asset Type</option>
                        <option value='CURRENT'>CURRENT</option>
                        <option value='FIXED'>FIXED</option>
                      </Field>
                    </div>
                  </div>

                  <div className='d-flex justify-content-end mt-10'>
                    <Link
                      to='/product-categories'
                      className='btn btn-sm btn-light-primary me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancel'
                      onClick={() => {
                        setSearchParams(ProductCategorySearchModelInitValues)
                        setFieldValue('name', '')
                        setFieldValue('assetType', '')
                      }}
                    >
                      <span className='indicator-label'>Cancel</span>
                    </Link>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Search'
                      id='liveToastBtn'
                    >
                      <span className='indicator-label'>Search</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}
          <div className='d-flex mt-5 '>
            <div className='react-table-wrapper' style={{width: '100%'}}>
              <Table
                columns={columns}
                data={productCategoryData}
                params={searchParams}
                pageCount={pages}
                fetchData={fetchData}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
